/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PasswordReset_viewer$ref: FragmentReference;
declare export opaque type PasswordReset_viewer$fragmentType: PasswordReset_viewer$ref;
export type PasswordReset_viewer = {|
  +canResetPassword: ?boolean,
  +$refType: PasswordReset_viewer$ref,
|};
export type PasswordReset_viewer$data = PasswordReset_viewer;
export type PasswordReset_viewer$key = {
  +$data?: PasswordReset_viewer$data,
  +$fragmentRefs: PasswordReset_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "passwordResetToken"
    },
    {
      "kind": "RootArgument",
      "name": "token"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PasswordReset_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "passwordResetToken",
          "variableName": "token"
        }
      ],
      "kind": "ScalarField",
      "name": "canResetPassword",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8fc5ab497eb3365e3ae556599625fbdf';

module.exports = node;
