import React from 'react';
import PropTypes from 'prop-types';
import {
  QueryRenderer,
  graphql,
} from 'react-relay';
import Helmet from 'react-helmet';
import { Col, Row } from 'antd';
import App from '../App';
import { environment } from '../../app';

export const NotFoundConent = (
  <>
    <Helmet>
      <meta name="render:status_code" content="404" />
    </Helmet>
    <Row>
      <Col span={12}>
        <h1>404 Not Found</h1>
        <p>We could not find the page you are looking for.</p>
      </Col>

      <Col span={12}>
        <img alt="404 Not Found" src="/static/images/404.svg" />
      </Col>
    </Row>
  </>
);

export default class NotFound extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
    }).isRequired,
    router: PropTypes.shape({
    }).isRequired,
  }

  render() {

    /* FIXME
     * pollyfills match prop, for some reason after upgraded to Found V0.5.9
     * match no longer presents in this component
     */
    const match = {
      location: this.props.location,
      router: this.props.router,
    };

    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query NotFoundQuery {
            viewer {
              ...App_viewer
            }
          }
        `}
        variables={{}}
        render={({ props }) => {
          if (props) {
            return (
              <App {...props} match={match} {...this.props}>
                {NotFoundConent}
              </App>
            );
          }
            return null;
        }}
      />
    );
  }
}
