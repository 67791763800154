import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Popover as PO } from 'antd';

export default class Popover extends React.Component {
  static ShowPopover = (props) => {
    const { btn, product } = props;
    const notCancellable = (product.name.includes("[Pre-Order]") && (product.name.includes("Hand Sanitiser") || product.name.includes("Wipes")));

    let content = "";

    if (product.nonStock) {
      content += "Non-stock items are not in stock but can be sourced typically within 72 hours.<br />";
    }

    if (notCancellable) {
      content += "This Pre-Order item cannot be cancelled after purchase unless the shipment date is delayed.<br />";
    }

    if (!product.nonStock && product.earnPoints === false) {
      content += "This product does not incur bonus points.<br />";
    }

    if (product.alerts) {
      content += product.alerts.join("<br />");
    }

    if (content) {
      return (
        <Popover
          overlayClassName="stock-popover"
          overlayStyle={{maxWidth: '500px'}}
          content={<div dangerouslySetInnerHTML={{__html: content}} />}
        >
          {btn}
        </Popover>
      );
    }

    return btn;
  }

  static propTypes = {
    children: PropTypes.element,
    content: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  static defaultProps ={
    children: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      timeoutFn: null,
    };
  }

  componentWillUnmount() {
    this.setState = () => {
      return {
        visible: false
      };
    };
  }

  handleOnClick = () => {
    this.setState({
      visible: true,
    }, () => {
      this.delayDimissPopover();
    });
  }

  // / When on focus, do not dismiss the popover. When on blur, dismiss the popover again.
  content = (content) => (
    <div
      style={{paddingLeft: '5px'}}
      onMouseEnter={() => {
        const { timeoutFn } = this.state;

        if (timeoutFn) {
          clearTimeout(timeoutFn);
          this.setState({timeoutFn: null});
        }
      }}
      onMouseLeave={() => this.delayDimissPopover(2000)}
    >
      <ExclamationCircleFilled style={{position: 'absolute', left: '3px', color: "#faad14"}} />
      {content}
    </div>
  )

  // set a ref to the setTimeout, once it is executed, remove the ref
  delayDimissPopover = (ms = 4000) => {
    this.setState({
      timeoutFn: setTimeout(() => {
        this.setState({
          visible: false,
        }, () => {
          if (this.state.timeoutFn) {
            this.setState({timeoutFn: null});
          }
        });
      }, ms),
    });
  }

  render() {
    const { children, content, ...rest } = this.props;
    const { visible } = this.state;

    return (
      <PO
        visible={visible}
        content={this.content(content)}
        trigger={["click"]}
        onClick={this.handleOnClick}
        placement="bottom"
        {...rest}
      >
        {children}
      </PO>
    )
  }
}
