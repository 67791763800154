/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PostFeedbackFormInput = {|
  desiredOutcome: string,
  email?: ?string,
  feedback: string,
  location?: ?string,
  name: string,
  refNumber?: ?string,
  staffName?: ?string,
  telephone?: ?string,
|};
export type PostFeedbackFormMutationVariables = {|
  input: PostFeedbackFormInput
|};
export type PostFeedbackFormMutationResponse = {|
  +postFeedbackForm: ?{|
    +result: ?boolean
  |}
|};
export type PostFeedbackFormMutation = {|
  variables: PostFeedbackFormMutationVariables,
  response: PostFeedbackFormMutationResponse,
|};
*/


/*
mutation PostFeedbackFormMutation(
  $input: PostFeedbackFormInput!
) {
  postFeedbackForm(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostFeedbackFormPayload",
    "kind": "LinkedField",
    "name": "postFeedbackForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostFeedbackFormMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostFeedbackFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b43422119e1ac9659b408ecedbe74e17",
    "id": null,
    "metadata": {},
    "name": "PostFeedbackFormMutation",
    "operationKind": "mutation",
    "text": "mutation PostFeedbackFormMutation(\n  $input: PostFeedbackFormInput!\n) {\n  postFeedbackForm(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '61c1a821bad9fc59493119c3c0e807a5';

module.exports = node;
