/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductCard_viewer$ref: FragmentReference;
declare export opaque type ProductCard_viewer$fragmentType: ProductCard_viewer$ref;
export type ProductCard_viewer = {|
  +id: string,
  +freeShippingAmount: ?number,
  +$refType: ProductCard_viewer$ref,
|};
export type ProductCard_viewer$data = ProductCard_viewer;
export type ProductCard_viewer$key = {
  +$data?: ProductCard_viewer$data,
  +$fragmentRefs: ProductCard_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCard_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeShippingAmount",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '077c19227e8244d9678606391ee87e52';

module.exports = node;
