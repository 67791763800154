import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { AlertOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

const style = {
  padding: '8px 0px',
  fontWeight: 600,
};

class EventAlertModal extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    btnStyle: PropTypes.objectOf(PropTypes.string),
    iconStyle: PropTypes.objectOf(PropTypes.string),
    spanStyle: PropTypes.objectOf(PropTypes.string),
  }

  static defaultProps = {
    btnStyle: {},
    iconStyle: {},
    spanStyle: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    }
  }

  showModal = () => {
    this.props.relay.refetch({}, null, () => {
      this.setState({
        visible: true,
      });
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    const { viewer, btnStyle, iconStyle, spanStyle } = this.props;
    const topAlerts = get(viewer, 'topAlerts.edges', []).map(({node}) => node.content).join('');

    return [
      <Button key="eventAlertBtn" type="link" onClick={() => { this.showModal() }} style={btnStyle}>
        <AlertOutlined style={iconStyle} />
        <span style={spanStyle}>Event Alerts</span>
      </Button>,
      <Modal
        key="eventAlertModal"
        title={
          <div>
            <AlertOutlined style={{ color: "#cb0000" }} /> Event Alerts
          </div>
        }
        visible={this.state.visible}
        onCancel={this.handleCancel}
        footer={null}
      >

        {topAlerts.length === 0 && (
          <div style={{...style, textAlign: 'center'}}>
            No Active Events
          </div>
        )}
        {topAlerts.length > 0 && (
          <div
            dangerouslySetInnerHTML={{__html: topAlerts}}
            style={style}
          />
        )}
      </Modal>
    ];
  }
}
export default createRefetchContainer(
  EventAlertModal, {
  viewer: graphql`
    fragment EventAlertModal_viewer on Customer {
      topAlerts(first: 5) @connection(key: "App_viewer_topAlerts") {
        edges {
          node {
            id
            content
          }
        }
      }
    }
  `,
  },
  graphql`
  query EventAlertModalRefetchQuery {
    viewer {
      ...EventAlertModal_viewer
    }
  }
`,
);
