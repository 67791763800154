/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ShippingQuote_viewer$ref = any;
export type ShippingQuoteRefetchQueryVariables = {|
  productId: string,
  suburb: string,
  city?: ?string,
  postcode: string,
|};
export type ShippingQuoteRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ShippingQuote_viewer$ref
  |}
|};
export type ShippingQuoteRefetchQuery = {|
  variables: ShippingQuoteRefetchQueryVariables,
  response: ShippingQuoteRefetchQueryResponse,
|};
*/


/*
query ShippingQuoteRefetchQuery(
  $productId: ID!
  $suburb: String!
  $city: String
  $postcode: String!
) {
  viewer {
    ...ShippingQuote_viewer_7cJFY
    id
  }
}

fragment ShippingQuote_viewer_7cJFY on Customer {
  freeShippingAmount
  shippingMethods(first: 20, productId: $productId, suburb: $suburb, city: $city, postcode: $postcode) {
    edges {
      node {
        code
        title
        price
        priceFormatted
        extra
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postcode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suburb"
},
v4 = {
  "kind": "Variable",
  "name": "city",
  "variableName": "city"
},
v5 = {
  "kind": "Variable",
  "name": "postcode",
  "variableName": "postcode"
},
v6 = {
  "kind": "Variable",
  "name": "productId",
  "variableName": "productId"
},
v7 = {
  "kind": "Variable",
  "name": "suburb",
  "variableName": "suburb"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShippingQuoteRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ShippingQuote_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ShippingQuoteRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "freeShippingAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v4/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "concreteType": "ShippingRateConnection",
            "kind": "LinkedField",
            "name": "shippingMethods",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ShippingRateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShippingRate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceFormatted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "extra",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3c838a7d9eb0e087f5083823d72857c",
    "id": null,
    "metadata": {},
    "name": "ShippingQuoteRefetchQuery",
    "operationKind": "query",
    "text": "query ShippingQuoteRefetchQuery(\n  $productId: ID!\n  $suburb: String!\n  $city: String\n  $postcode: String!\n) {\n  viewer {\n    ...ShippingQuote_viewer_7cJFY\n    id\n  }\n}\n\nfragment ShippingQuote_viewer_7cJFY on Customer {\n  freeShippingAmount\n  shippingMethods(first: 20, productId: $productId, suburb: $suburb, city: $city, postcode: $postcode) {\n    edges {\n      node {\n        code\n        title\n        price\n        priceFormatted\n        extra\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f76d5e064ae6e24990fab2ebd981ae8a';

module.exports = node;
