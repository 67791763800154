import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { get } from 'lodash';
import { importScript } from '~/helper';
import { cards } from './CreditCard';

let payment = null;

let apiKey = "OQWoz4URtcsXFQeGXBh5_3DS";
if (process.env.NODE_ENV === 'production') {
  apiKey = "sqjl2wVJuAdoXlkGlW0R";
}

export default class Till extends React.Component {
  static importScript = ({cb} = {}) => {
    importScript({url: 'https://gateway.tillpayments.com/js/integrated/payment.1.2.min.js', data: {main: 'payment-js'}}, cb);
  }

  static init = (form, submit, { setLoading }) => {

    const fields = form.getFieldsValue();

    /*
     * When re-setting a password is present, the above guard is not enough to prevent order placing
     * as `checkoutPassword` field is removed.
     */
    if (fields.billing && Object.keys(fields.billing).length === 1 && fields.billing.email) {
      message.error('Please login to place your order')
      return;
    }

    // Form wasn't scrolling when theres a missing field
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = {
          cardHolder: values.ccOwner,
          month: values.ccMonth,
          year: values.ccYear,
        };

        setLoading(true);

        payment.tokenize(
          data,
          (token, cardData) => { // success callback function
            setLoading(false);
            form.setFieldsValue({
              tillData: {
                token,
                cardData,
              }
            }, () => {
              // attempt to place order on authorising
              if (token && cardData) {
                submit();
              }
            });
          },
          (errors) => { // error callback function
            setLoading(false);
            if (errors && Array.isArray(errors)) {
              errors.forEach(e => {
                message.error(e.message);
              });
            }
          }
        );
      }
    })
  }

  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    updateCardType: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const style = {
      'box-sizing': 'border-box',
      'margin': '0',
      'font-variant': 'tabular-nums',
      'font-family': 'Montserrat, sans-serif',
      'list-style': 'none',
      'font-feature-settings': 'tnum',
      'position': 'relative',
      'display': 'inline-block',
      'width': '100%',
      'height': '32px',
      'padding': '4px 11px',
      'color': 'rgb(33, 33, 33)',
      'font-size': '16px',
      'line-height': '1.5',
      'background-color': '#fff',
      'background-image': 'none',
      'border': '1px solid #d9d9d9',
      'border-radius': '0px',
      'transition': 'all 0.3s',
      'margin-top': '4px',
    };

    const initPayment = () => {
      /* eslint-disable no-undef */
      payment = new PaymentJs();
      /* eslint-enable no-undef */

      payment.init(apiKey, 'ccNumber', 'ccCvv', (paymentObject) => {
        paymentObject.setNumberStyle(style);
        paymentObject.setNumberPlaceholder('Credit Card Number');
        paymentObject.setCvvStyle(style);
        paymentObject.setCvvPlaceholder('CVV');
        paymentObject.numberOn('input', (data) => {
          if (data.cardType) {
            this.setState({ cardType: data.cardType });
          } else {
            this.setState({ cardType: null });
          }
        })
      });
    }

    if (typeof PaymentJs === 'undefined') {
      Till.importScript({cb: initPayment});
    } else {
      initPayment();
    }

    this.state = {
      cardType: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevCardType = get(prevState, 'cardType');
    const currCardType = get(this.state, 'cardType');

    if (prevCardType !== currCardType) {
      if (currCardType) {
        this.props.updateCardType(cards[currCardType]);
      } else {
        this.props.updateCardType(null);
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('tillData')(<div />)}
      </div>
    );
  }
}
