import { Socket } from 'phoenix';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const socketName = "frontend";
let channel = null;
let newVersion = false;

const fetchVersion = (VERSION) => {
  fetch('/static/app_version').then(response => {
    if (response.status === 404) {
      // force reload if 404
      return 'dummy_version';
    }
    return response.text();
  })
  .then((version) => {
    if (VERSION !== version.trim()) {
      newVersion = true;
    }
  });
}

const init = function connect(VERSION) {
  const socket = new Socket(`${((window.location.protocol === 'https:') ? 'wss://' : 'ws://') + window.location.host}/${socketName}-socket`, {
    params: {
      "cart_id": cookies.get("cart_id"),
      "version": VERSION,
    }
  });
  socket.connect();

  channel = socket.channel(`${socketName}:lobby`, {});
  channel.join()
    .receive('ok', () => {
      if (process.env.NODE_ENV === 'production') {
        fetchVersion(VERSION);
      }
    })
    .receive('error', (resp) => { console.error('Unable to join', resp); });

  return socket;
};

const onNewVersion = function onNewVersion(VERSION) {
  if (!channel) {
    return;
  }

  channel.on('new_version', () => {
    fetchVersion(VERSION);
  });
};


const isNewVersion = function isNewVersion() {
  return newVersion;
}

const socket = {
  init,
  onNewVersion,
  isNewVersion,
};

export default socket;
