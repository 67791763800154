import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { startCase } from 'lodash';
import { sanitiseState } from '~/helper';

const StoreListQuery = graphql`
  query routes_StoreList_Query{
    viewer {
      ...StoreList_viewer
    }
  }
`;

const StoreQuery = graphql`
  query routes_Store_Query($filterBy: [FilterBy]){
    viewer {
      ...Store_viewer
    }
  }
`;

const routes = (
  <Route
    path="/stores"
  >
    <Route
      path="/:state?"
      getComponent={() => (
        import(/* webpackChunkName: "StoreList" */'./StoreList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={StoreListQuery}
    />

    <Route
      path="/:state/:store"
      getComponent={() => (
        import(/* webpackChunkName: "StorePage" */'./Store').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={StoreQuery}
      prepareVariables={(params) => {
        const filterBy = [{
          field: "state",
          filter: sanitiseState(params.state),
          filterType: "text",
          type: "equals",
        },
        {
          field: "name",
          filter: startCase(params.store),
          filterType: "text",
          type: "equals",
        }]

        return {filterBy};
    }}
    />
  </Route>
);

export default routes;
