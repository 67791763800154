const renderOption = (propName, item) => {
  // FIXME
  // key is defined in `suburb--postocde` format, which is used by addressCheckbox() at AddressView
  // otherwise AutoComplete will only remember the location id rather than suburb or postocde
  return {
    key: `${item.location}--${item.postcode}`,
    item,
    value: `${item.location} ${item.postcode}`,
  };
};

module.exports = {
  renderOption,
}