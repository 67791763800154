import React from 'react';
import Icon from '@ant-design/icons';

const giftCard = function GiftCard() {
  return (
    <svg x={0} y={0} viewBox="0 0 390.9 390.9" focusable="false" width="1em" height="1em">
      <path
        fill="currentColor"
        d="M350.9,62.9H40.1C21.8,62.9,6.9,77.8,6.9,96v198.9c0,18.3,14.9,33.1,33.1,33.1h310.8c18.3,0,33.1-14.9,33.1-33.1V96
          C384,77.8,369.1,62.9,350.9,62.9z M117.4,305.9H40.1c-6.1,0-11-5-11-11V173.4h23.9c5.5,16.5,22.4,26.4,39.5,23l-17.4,26.1
          c-3.4,5.1-2,11.9,3.1,15.3c5.1,3.4,11.9,2,15.3-3.1l24-35.9V305.9z M73.2,162.3c0-10.1,11.2-16.1,19.6-10.5l15.7,10.5l-15.7,10.5
          C84.4,178.4,73.2,172.4,73.2,162.3z M117.4,141.7l-12.4-8.2c-19.1-12.7-44.9-3.7-52.1,17.8H29V96c0-6.1,5-11,11-11h77.3V141.7z
          M361.9,294.9c0,6.1-5,11-11,11H139.5V198.8l24,35.9c3.4,5.1,10.2,6.4,15.3,3.1c5.1-3.4,6.4-10.2,3.1-15.3l-17.4-26.1
          c17,3.3,34-6.5,39.5-23h157.9V294.9z M148.4,162.3l15.7-10.5c8.4-5.6,19.6,0.4,19.6,10.5c0,10.1-11.2,16.1-19.6,10.5L148.4,162.3
          z M361.9,151.3H204c-7.2-21.6-33-30.5-52.1-17.8l-12.4,8.2V85h211.4c6.1,0,11,5,11,11V151.3z"
      />
      <path
        fill="currentColor"
        d="M328.8,262.3h-89.9c-6.1,0-11,4.9-11,11s4.9,11,11,11h89.9c6.1,0,11-4.9,11-11S334.9,262.3,328.8,262.3z"
      />
    </svg>
  )
};

export default function GiftCardIcon(props) {
  return (
    <Icon component={giftCard} {...props} />
  )
}
