import { Button, Modal } from 'antd';
import Cookies from 'universal-cookie';
import React from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { get, throttle } from 'lodash';
import { LocationReminderMutation } from './mutations';

const cookies = new Cookies();

export default class LocationReminder extends React.Component {
  static propTypes = {
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  };

  constructor(props) {
      super(props);

      this.throttledHandleScroll = throttle(this.handleScroll, 50);

      this.state = {
        buttonStyle: {
          position: 'fixed',
          color: 'white',
          right: '25px',
          bottom: '90px',
          zIndex: 20,
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderColor: 'transparent'
        },
        modalVisible: false,
        redirect: false,
        customerCountry: '',
        website: {}
      };
    }

    componentDidMount(){
      LocationReminderMutation({
        environment: this.props.relay.environment,
        variables: {},
        onCompleted: (response) => {
          const { customerCountry, website: {link, label} } = get(response, "getRedirectInfo.redirectInfo", {});
          let redirect = false;
          let website = {};

          if (customerCountry && link && label) {
            redirect = true;
            website = {link, label};
          }

          this.setState({
            modalVisible: !cookies.get('redirectionWarning'),
            redirect,
            customerCountry,
            website,
          });

        },
      });

      window.addEventListener('scroll', this.throttledHandleScroll);
    }

    componentWillUnmount(){
      window.removeEventListener('scroll', this.throttledHandleScroll);
    }

    handleScroll = () => {
      const scrollHeight = window.scrollY;
      this.setState(prevState => ({
        buttonStyle: {
          ...prevState.buttonStyle,
          bottom: scrollHeight > 400 ? '140px' : '90px'
        }
      }));
    };

    handleModalDismiss = () => {
      this.setState({ modalVisible: false}, () => {
        const distantFutureDate = new Date('9999-12-31');
        cookies.set('redirectionWarning', false, { expires: distantFutureDate });
      });
    };

    handleButtonOpen = () => {
      this.setState({modalVisible: true})
    };

    render() {
      const {buttonStyle, modalVisible, redirect, customerCountry, website: {link, label}} = this.state;

      if (!redirect) {
        return null;
      }

      return (
        <div>
          <Modal
            visible={modalVisible}
            closable={false}
            footer={[
              <Button
                key="link"
                href={link}
                type="primary"
              >
                <span>Go To Sydney Tools <b>{customerCountry}</b></span>
              </Button>,
              <Button key="dismiss" onClick={this.handleModalDismiss}>Stay</Button>
          ]}
          >
            <p>You are on the Sydney Tools <b>{label}</b> website!</p>
            <p style={{fontSize: 12}}>Looks like you are in <b>{customerCountry}</b>. For the best shopping experience, head to our <b>{customerCountry}</b> website.</p>
          </Modal>

          <Button
            icon={<GlobalOutlined />}
            onClick={this.handleButtonOpen}
            style={buttonStyle}
          />
        </div>
      );
    }
}
