import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateAddressMutation($input: UpdateAddressInput!) {
    updateAddress(input:$input) {
      address {
        id
        firstname
        lastname
        street
        city
        postcode
        region
        country {
          name
        }
        telephone
        company
        fax
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
