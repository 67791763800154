import GeocodeMutation from './GeocodeMutation';
import PriceMatchMutation from './PriceMatchMutation';
import PriceTagMutation from './PriceTagMutation';
import QueryProductStockMutation from './QueryProductStockMutation';
import SearchPostcodeSuburbMutation from './SearchPostcodeSuburbMutation';

module.exports = {
  GeocodeMutation,
  PriceMatchMutation,
  PriceTagMutation,
  QueryProductStockMutation,
  SearchPostcodeSuburbMutation,
};
