import React from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { CrossHairIcon } from '~/components/svg/';

import './style.css';

const MyLocationBtn = (props) => {
  const { type, disabled, style, icon, children } = props;

  const onClick = () => {
    const { geolocation } = window.navigator;
    const msg = "Sorry We cannot locate you. Please enter your postcode/suburb instead.";

    if (geolocation) {
      geolocation.getCurrentPosition(({ coords }) => {
        props.onClick(coords);
      }, () => {
        message.warning(msg);
      });
    } else {
      message.warning(msg);
    }
  };

  return (
    <Button
      className="location-btn"
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={style}
      icon={icon ? <CrossHairIcon /> : null}
    >
      {children}
    </Button>
  )
};

MyLocationBtn.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  icon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MyLocationBtn.defaultProps = {
  type: "default",
  disabled: false,
  onClick: () => { },
  style: {},
  icon: false,
  children: null,
};

export default MyLocationBtn;


