/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CmsBanner_viewer$ref: FragmentReference;
declare export opaque type CmsBanner_viewer$fragmentType: CmsBanner_viewer$ref;
export type CmsBanner_viewer = {|
  +cmsBanners: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +link: ?string,
        +urlPaths: ?$ReadOnlyArray<?string>,
        +categoryBig: ?string,
        +categorySmall: ?string,
      |}
    |}>
  |},
  +$refType: CmsBanner_viewer$ref,
|};
export type CmsBanner_viewer$data = CmsBanner_viewer;
export type CmsBanner_viewer$key = {
  +$data?: CmsBanner_viewer$data,
  +$fragmentRefs: CmsBanner_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CmsBanner_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 999
        }
      ],
      "concreteType": "CmsBannerConnection",
      "kind": "LinkedField",
      "name": "cmsBanners",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CmsBannerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CmsBanner",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "link",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlPaths",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categoryBig",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categorySmall",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "cmsBanners(first:999)"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7bd5a3b15ee30cd040c16f6e82480ffd';

module.exports = node;
