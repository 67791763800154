import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { Button, Form, Input, message } from 'antd';
import { PostSptoolsFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

class SptoolsPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    PostSptoolsFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success("Thank you very much.");
        this.formRef.current.resetFields();
      },
      onError: () => {
        message.error("There was an error, please try again.");
      },
    });
  }

  render() {
    const { viewer: { cmsPage: page } } = this.props;

    return (
      <div>
        <Helmet title={page.title} />
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />

        <Form ref={this.formRef} onFinish={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label="First Name"
            name="firstname"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="First Name" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Surname"
            name="surname"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Surname" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Phone Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Address"
            name="address"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Address" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Invoice Number"
            name="invoiceNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Invoice Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Why?"
            name="reason"
            rules={[
              { required: true, message: 'Required' },
              { required: true, message: '25 words or less', validator: (rule, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                const words = value.trim().split(/\s+/).filter(w => w !== "");

                if (words.length > 25) {
                  return Promise.reject(new Error("25 words or less"));
                }

                return Promise.resolve();
              }},
            ]}
            extra="in 25 words or less tell us why you should win."
          >
            <TextArea />
          </FormItem>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>

        </Form>

      </div>
    );
  }
}

export default createFragmentContainer(SptoolsPage, {
  viewer: graphql`
    fragment SptoolsPage_viewer on Customer {
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
      }
    }
  `,
});
