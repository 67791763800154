import React from 'react';
import PropTypes from 'prop-types';

const DayOfWeeks = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const HoursInfo = ({ store: { hours }, style }) => {
  if (Object.keys(hours || {}).length === 0) {
    return null;
  }

  return (
    <div style={style}>
      <b>Business Hours</b>:
      <ul style={{ width: '230px', listStyle: 'none', padding: '0' }}>
        {DayOfWeeks.map(day => (
          <li key={day}>
            {day.toLocaleUpperCase()}:
            <span style={{ float: 'right' }}>
              {hours[day] ? `${hours[day].open} - ${hours[day].close}` : 'Closed'}
            </span>
          </li>
          ))}
      </ul>
    </div>
  );
};

HoursInfo.propTypes = {
  store: PropTypes.shape({
    hours: PropTypes.shape({
      monday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
      tuesday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
      wednesday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
      thursday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
      friday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
      saturday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
      sunday: PropTypes.shape({
        open: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  }).isRequired,
  style: PropTypes.shape({}),
};

HoursInfo.defaultProps = {
  style: {},
};

export default HoursInfo;
