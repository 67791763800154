import React from 'react'
import PropTypes from 'prop-types';
import { get } from 'lodash';

function renderDiscounts(discounts) {
  if (discounts.length > 0) {
    return discounts.map(d => (
      <div key={d.name}>
        <b>Discount:</b> {d.name} {d.amount && <span>-${d.amount}</span>}
      </div>
    ))
  }

  return null;
}

function OrderTotals({style, order}) {
  const subtotal = get(order, 'subtotal', 0).toFixed(2);
  const shippingTotal = get(order, 'shippingTotal', 0).toFixed(2);
  const grandTotal = get(order, 'grandTotal', 0).toFixed(2);

  const surcharges = get(order, 'surcharges', []);
  const discounts = get(order, 'discounts', []);

  return (
    <div style={style}>
      <div>
        Subtotal:
        <span className="price"> ${subtotal}</span>
      </div>

      {order.surcharge > 0 && (
        <div>
          AMEX Surcharge:
          <span className="price"> ${order.surcharge}</span>
        </div>
      )}

      {surcharges.length > 0 && surcharges.map((surcharge) => (
        <div key={surcharge.name}>
          {surcharge.name}:
          <span className="price"> ${surcharge.amount}</span>
        </div>
      ))}

      {order.discount > 0 && (
        <div>Discount: -{order.discount && <span className="price">${order.discount}</span>}</div>
      )}

      {renderDiscounts(discounts)}

      <div>Shipping Total: <span className="price">${shippingTotal}</span> ({order.shippingDescription})</div>

      <div>Grand Total: <span className="price">${grandTotal}</span></div>
    </div>
  );
}

OrderTotals.propTypes = {
  style: PropTypes.shape({
  }),
  order: PropTypes.shape({
    discount: PropTypes.number,
    surcharge: PropTypes.number,
    shippingDescription: PropTypes.string,
  }).isRequired,
}

OrderTotals.defaultProps = {
  style: {},
}

export default OrderTotals;
