import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const ContactQuery = graphql`
  query routes_Contact_Query {
    viewer {
      ...Contact_viewer
    }
  }
`;

const routes = (
  <Route
    path="/contacts"
    getComponent={() => (
      import(/* webpackChunkName: "Contact" */'~/components/contact').then(module => module.Contact)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={ContactQuery}
  />
);

export default routes;
