import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from 'found';
import { get } from 'lodash';

import CmsBanner from '../cms/CmsBanner';
import Helmet from '../page/Helmet';
import Breadcrumb from '../page/Breadcrumb';
import HeadingTitle from '../page/HeadingTitle';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

export default class FilterList extends React.Component {
  static propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object),
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({}).isRequired,
        pathname: PropTypes.string,
      }).isRequired,
      params: PropTypes.shape({
        brandUrlSlug: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    filters: [],
  }

  render() {
    const { filters, viewer, match: { location, params: { brandUrlSlug }} } = this.props;
    const category = get(viewer, 'categories.edges[0].node', {});
    const catalogues = get(category, 'catalogues', []) || get(viewer, 'catalogues', []);
    const subcategory = get(catalogues, 'subcategory', {});
    const brand = get(category, 'catalogues.brand', {});

    return (
      <div>
        <Helmet category={category} subcategory={subcategory} brand={brand} location={location} />

        <Breadcrumb category={category} subcategory={subcategory} location={location} brand={brand} />

        <HeadingTitle category={category} subcategory={subcategory} brand={brand} location={location} />

        <CmsBanner viewer={viewer} location={location} />

        {category.description && (<div dangerouslySetInnerHTML={{ __html: category.description }} />)}

        <Row>
          {(filters || []).flatMap(f =>
            (f.buckets.map(b => {
              let url = `/category/${category.urlSlug}`;

              const name = `${b.key} ${get(subcategory, 'name', '')}`;

              const queryFilter = {
                filters: JSON.stringify({
                  [f.key]: [b.key]
                })
              };

              if (brandUrlSlug) {
                url += `/${brandUrlSlug}`;
              }

              if (subcategory) {
                let currpath = location.pathname;
                if (currpath.includes(url) && currpath.includes(`/${subcategory.urlSlug}`)) {
                  currpath = currpath.replace(url, "").replace(`/${subcategory.urlSlug}`, "");
                  url += currpath;
                }
                url += `/${subcategory.urlSlug}`;
              }

              return (
                <Col key={b.key} xs={12} sm={8} md={6} lg={4} xl={4} style={{ margin: '-1px 0 0 -1px', height: '185px', textAlign: 'center', border: '1px solid #e8e8e8' }}>
                  <Link
                    to={{
                      pathname: url,
                      query: Object.assign({}, location.query, queryFilter),
                    }}
                    className="nav-text black-href"
                    style={{display: 'block', height: '100%', width: '100%', paddingTop: '10px'}}
                  >
                    <div className="click-state">
                      {b.imageUrl &&
                      <img width="120" height="120" title={name} alt={name} className="image-link__image" src={b.imageUrl} />
                    }
                      {!b.imageUrl &&
                      <img width="120" height="120" title={name} alt={name} className="image-link__image" src={IMAGE_PLACEHOLDER} />
                    }
                    </div>
                    <div style={{ paddingLeft: '2px', paddingRight: '2px', lineHeight: 1, marginTop: '10px' }}>{name} ({b.docCount})</div>
                  </Link>
                </Col>
              );
            }))
          )}
        </Row>
      </div>
    );
  }
}
