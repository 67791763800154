/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProductCard_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type List_viewer$ref: FragmentReference;
declare export opaque type List_viewer$fragmentType: List_viewer$ref;
export type List_viewer = {|
  +id: string,
  +$fragmentRefs: ProductCard_viewer$ref,
  +$refType: List_viewer$ref,
|};
export type List_viewer$data = List_viewer;
export type List_viewer$key = {
  +$data?: List_viewer$data,
  +$fragmentRefs: List_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "List_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCard_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e79897ac5c81affd21add34c95d0342e';

module.exports = node;
