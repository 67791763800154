import React from 'react';
import PropTypes from 'prop-types';

import Cookies from 'universal-cookie';
import { AppleFilled, FacebookFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { get } from 'lodash';

import { formItemLayout } from '~/components/form';
import { LoginMutation, OauthLoginMutation } from '../auth/mutations';
import ResetPassword from '../auth/ResetPassword';
import GoogleSignin from '../auth/GoogleSignin';
import FacebookSignin from '../auth/FacebookSignin';
import AppleSignin from '../auth/AppleSignin';
import MicrosoftSignin from '../auth/MicrosoftSignin';

import { getMaxAge, handleSaveSuburbPostcode } from '../auth/Login';
import { recordGA } from './helper';

const FormItem = Form.Item;
const cookies = new Cookies();

class LoginForm extends React.Component {
  static propTypes ={
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      resetPassword: false,
      loading: false,
    }
  }

  onOauthSuccess = (tokenId, provider) => {
    if (tokenId && provider) {
      recordGA("click", provider);
      this.oauthMutation({tokenId, provider});
    }
  }

  onOauthFailure = (errmsg) => {
    message.error(errmsg);
    this.setState({loading: false});
  }


  getLoginForm = (viewer) => {
    return (
      <Form ref={this.formRef}>

        <FormItem
          {...formItemLayout}
          label="Email"
          name={["login", "email"]}
          rules={[
            { transform: (value) => (value || "").trim() },
            { required: true, message: 'Required' },
            { type: 'email', message: 'Invalid Email' },
          ]}
          style={{margin: '0 0 5px 0'}}
        >
          <Input placeholder="Email Address" />
        </FormItem>

        {!this.state.resetPassword && (
          <div>
            <FormItem
              {...formItemLayout}
              label="Password"
              name={["login", "password"]}
              rules={[
                { required: true, message: 'Required' },
              ]}
              style={{margin: '0'}}
            >
              <Input.Password placeholder="Password" />
            </FormItem>

            <FormItem
              style={{textAlign: 'right', margin: '0 0 3px 0'}}
            >
              <Button
                style={{color: '#cb0000', border: 'none', boxShadow: 'none', fontSize: '15px', fontWeight: '700'}}
                onClick={()=> this.setState({resetPassword: true})}
              >
                Forgot password?
              </Button>
            </FormItem>

            <FormItem
              style={{textAlign: 'center', margin: '0px'}}
            >
              <Button
                style={{width: '100%'}}
                type="primary"
                onClick={this.handleSubmit}
                loading={this.state.loading}
              >
                Sign in
              </Button>
            </FormItem>
          </div>
        )}

        {!this.state.resetPassword && (
          <div>
            <p style={{textAlign: 'center', margin: '10px 0px', fontWeight: '700'}}>Or with</p>
            <Row
              gutter={[10, 8]}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                marginBottom: '20px',
              }}
            >
              <Col>
                <FacebookSignin
                  style={{padding: '5px 8px', backgroundColor: '#1877f2', color: 'white', borderRadius: '4px'}}
                  onSuccess={this.onOauthSuccess}
                  onFailure={this.onOauthFailure}
                >
                  <FacebookFilled style={{fontSize: '22px', verticalAlign: 'text-top'}} />
                </FacebookSignin>
              </Col>
              <Col>
                <GoogleSignin
                  id="google-checkout-login-button"
                  type="icon"
                  onSuccess={this.onOauthSuccess}
                  onFailure={this.onOauthFailure}
                />
              </Col>
              <Col>
                <AppleSignin
                  style={{padding: '5px 8px', backgroundColor: 'black', color: 'white', borderRadius: '4px'}}
                  onSuccess={this.onOauthSuccess}
                  onFailure={this.onOauthFailure}
                >
                  <AppleFilled style={{fontSize: '22px', verticalAlign: 'text-top'}} />
                </AppleSignin>
              </Col>
              <Col>
                <MicrosoftSignin
                  style={{padding: '5px 8px', borderRadius: '4px'}}
                  onSuccess={this.onOauthSuccess}
                  onFailure={this.onOauthFailure}
                >
                  <MicrosoftSignin.Logo />
                </MicrosoftSignin>
              </Col>
            </Row>
          </div>
        )}

        {this.state.resetPassword && (
          <div>
            <ResetPassword formRef={this.formRef} size="default" field={["login", "email"]} viewer={viewer} relay={this.props.relay} />
            <FormItem>
              <Button style={{width: '100%'}} onClick={()=> this.setState({resetPassword: false})}>Back To Login</Button>
            </FormItem>
          </div>
        )}
      </Form>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    recordGA("click", "email");

    this.formRef.current.validateFields([["login", "email"], ["login", "password"]]).then((values) => {
      this.setState({ loading: true });
      const v = values.login;

      LoginMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: v },
        viewer: this.props.viewer,
        onCompleted: (resp, errors) => {
          this.setState({loading: false});
          if (!errors) {

            cookies.set('auth_token', resp.login.token, { path: '/', maxAge: getMaxAge(60) });

            const defaultAddress = get(resp, 'login.viewer.defaultAddress') || {};
            handleSaveSuburbPostcode(defaultAddress);

            if (!resp.login.viewer.securePassword) {
              const path = window.location.pathname + window.location.search;
              window.location.href = `/password-reset?redirect=${encodeURIComponent(path)}`;
            }
            else {
              window.location.reload();
            }
          }
        },
        onError: (errors) => {
          message.error(errors[0].message);
          this.setState({loading: false});
        }
      });
    }).catch(() => {});
  }

  oauthMutation = (variables) => {
    OauthLoginMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: variables },
      viewer: this.props.viewer,
      onCompleted: (resp, errors) => {
        this.setState({loading: false});
        if (!errors) {

          cookies.set('auth_token', resp.oauthLogin.token, { path: '/', maxAge: getMaxAge(60) });

          const defaultAddress = get(resp, 'oauthLogin.viewer.defaultAddress') || {};
          handleSaveSuburbPostcode(defaultAddress);

          window.location.reload();
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
        this.setState({loading: false});
      }
    });
  }

  render() {
    const { viewer } = this.props;

    return (
      <div style={{marginTop: '10px'}}>
        <span style={{marginLeft: '10px'}}>Already have an Account?</span>
        <div style={{margin: '10px'}}>
          {this.getLoginForm(viewer)}
        </div>
      </div>
    )
  }
}

export default LoginForm;
