import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, message } from 'antd';
import { ApplyCouponCodeMutation } from './mutations';

const Coupon = (props) => {
  const { viewer, relay, getPreVars, setCaptcha, setLoading, style } = props;
  const [couponCode, setCouponCode] = useState(null);

  const applyCouponCode = (code) => {
    if (code) {
      ApplyCouponCodeMutation.commit({
        environment: relay.environment,
        variables: {
          input: { code },
          ...getPreVars(),
        },
        viewer,
        onCompleted: () => {
          message.destroy();
          setCouponCode(null);
        },
        onError: (errors) => {
          message.error(errors[0].message, 10);
          setCaptcha(errors);
          setLoading(false);
        },
      });
    }
  };

  return (
    <Input.Group compact style={style}>
      <Input
        style={{ width: 'calc(100% - 79px)' }}
        placeholder="Enter a Coupon or Gift Card Code"
        value={couponCode}
        onChange={(e) => {
          const { value } = e.target;
          setCouponCode(value);
        }}
        onPressEnter={() => applyCouponCode(couponCode)}
      />
      <Button type="primary" onClick={() => applyCouponCode(couponCode)}>
        Apply
      </Button>
    </Input.Group>
  )
};

Coupon.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}),
  }).isRequired,
  getPreVars: PropTypes.func.isRequired,
  setCaptcha: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

Coupon.defaultProps = {
  style: {},
};

export default Coupon;
