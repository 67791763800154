/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StoreSelector_viewer$ref: FragmentReference;
declare export opaque type StoreSelector_viewer$fragmentType: StoreSelector_viewer$ref;
export type StoreSelector_viewer = {|
  +id: string,
  +email: ?string,
  +myStore: ?{|
    +id: string
  |},
  +cart: ?{|
    +stores: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +address: ?string,
          +city: ?string,
          +postcode: ?string,
          +state: ?string,
          +description: ?string,
          +phone: ?string,
          +lat: ?number,
          +lng: ?number,
          +canPickup: ?boolean,
          +excludeBulkyGood: ?boolean,
          +stocks: ?$ReadOnlyArray<?{|
            +productId: ?string,
            +stock: ?string,
          |}>,
          +hours: ?{|
            +monday: ?{|
              +open: ?string,
              +close: ?string,
            |},
            +tuesday: ?{|
              +open: ?string,
              +close: ?string,
            |},
            +wednesday: ?{|
              +open: ?string,
              +close: ?string,
            |},
            +thursday: ?{|
              +open: ?string,
              +close: ?string,
            |},
            +friday: ?{|
              +open: ?string,
              +close: ?string,
            |},
            +saturday: ?{|
              +open: ?string,
              +close: ?string,
            |},
            +sunday: ?{|
              +open: ?string,
              +close: ?string,
            |},
          |},
        |}
      |}>
    |}
  |},
  +$refType: StoreSelector_viewer$ref,
|};
export type StoreSelector_viewer$data = StoreSelector_viewer;
export type StoreSelector_viewer$key = {
  +$data?: StoreSelector_viewer$data,
  +$fragmentRefs: StoreSelector_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "open",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "close",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreSelector_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Store",
      "kind": "LinkedField",
      "name": "myStore",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 999
            }
          ],
          "concreteType": "StoreConnection",
          "kind": "LinkedField",
          "name": "stores",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Store",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "city",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "postcode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phone",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lat",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lng",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canPickup",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "excludeBulkyGood",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StockLevel",
                      "kind": "LinkedField",
                      "name": "stocks",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "stock",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StoreHour",
                      "kind": "LinkedField",
                      "name": "hours",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "monday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "tuesday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "wednesday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "thursday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "friday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "saturday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BusinessHour",
                          "kind": "LinkedField",
                          "name": "sunday",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "stores(first:999)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'da258166c82b076b01de3bc4b9f200a2';

module.exports = node;
