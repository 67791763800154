import React from 'react';


import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';

const getCenter = () => {
  if (process.env.COUNTRY === 'NZ') {
    return {lat: -41.145876, lng: 172.510375};
  }
  return {lat: -27.950810, lng: 134.057570};
}

const getZoom = () => {
  if (process.env.COUNTRY === 'NZ') {
    return 5;
  }
  return 3.8;
}

const Map = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    center={props.center}
    defaultCenter={getCenter()}
    defaultZoom={3.8}
    ref={props.onMapMounted}
    zoom={props.zoom}
    onZoomChanged={props.onZoomChanged}
  >
    {props.markers}
    {props.infoBox}
    {props.circle}
  </GoogleMap>
)));

module.exports = {
  getCenter,
  getZoom,
  Map,
};
