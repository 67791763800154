import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveProductFromCartCheckoutMutation(
    $input: RemoveProductFromCartInput!,
    $forklift: Boolean,
    $street: String,
    $suburb: String,
    $city: String,
    $postcode: String,
    $useCredit: Boolean,
    $useLoyaltyPoints: Boolean,
    $shippingMethod: String,
    $ccType: String,
    $stripeToken: String,
  ) {
    removeProductFromCart(input:$input) {
      cart {
        id
        storeCredits(first: 999) @connection(key: "CartView_storeCredits") {
          edges {
            node {
              id
              name
              creditAmount
            }
          }
        }
        subtotal
        shippingCost(shippingMethod: $shippingMethod)
        grandTotal
        surcharges(ccType: $ccType, stripeToken: $stripeToken, forklift: $forklift) {
          name
          amount
        }
        loyaltyPoints {
          accruing
        }
        discount
        discounts(useCredit: $useCredit, useLoyaltyPoints: $useLoyaltyPoints) {
          id
          name
          amount
          removable
        }
        isOptFreebiesInCart
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              unitPrice
              quantity
              unitDiscount
              unitSurcharge
              rowTotal
              priorityShipping {
                getByDate
              }
              product {
                id
                mainImage {
                  id
                  thumbnail
                }
                priorityShipping {
                  getByDate
                }
              }
            }
          }
        }
        shippingMethods(first: 20, street: $street, suburb: $suburb, city: $city, postcode: $postcode) {
          edges {
            node {
              code
              title
              price
              priceFormatted
            }
          }
        }
        paymentMethods(first: 20) {
          edges {
            node {
              code
              title
              type
            }
          }
        }
        ...FreebiePopup_cart
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
