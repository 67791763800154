/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PriceTag_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_PriceTag_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>
|};
export type routes_PriceTag_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PriceTag_viewer$ref
  |}
|};
export type routes_PriceTag_Query = {|
  variables: routes_PriceTag_QueryVariables,
  response: routes_PriceTag_QueryResponse,
|};
*/


/*
query routes_PriceTag_Query(
  $filterBy: [FilterBy]
) {
  viewer {
    ...PriceTag_viewer
    id
  }
}

fragment PriceTag_viewer on Customer {
  id
  stores(first: 999, filterBy: $filterBy) {
    edges {
      node {
        id
        name
      }
    }
  }
  searchProducts: products(first: 40, query: "") {
    edges {
      node {
        id
        name
        type
        attributes
        sku
        status
        mainImage {
          id
          url
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PriceTag_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PriceTag_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_PriceTag_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              }
            ],
            "concreteType": "StoreConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "searchProducts",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 40
              },
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sku",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductImage",
                        "kind": "LinkedField",
                        "name": "mainImage",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "products(first:40,query:\"\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "143a665d5824658868223946f28816fe",
    "id": null,
    "metadata": {},
    "name": "routes_PriceTag_Query",
    "operationKind": "query",
    "text": "query routes_PriceTag_Query(\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...PriceTag_viewer\n    id\n  }\n}\n\nfragment PriceTag_viewer on Customer {\n  id\n  stores(first: 999, filterBy: $filterBy) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  searchProducts: products(first: 40, query: \"\") {\n    edges {\n      node {\n        id\n        name\n        type\n        attributes\n        sku\n        status\n        mainImage {\n          id\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88e819757980c7671a202c9f25d04cb0';

module.exports = node;
