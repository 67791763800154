import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Col, Row } from 'antd';
import { Link } from 'found';
import { get } from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Helmet from '../page/Helmet';
import Breadcrumb from '../page/Breadcrumb';
import HeadingTitle from '../page/HeadingTitle';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

export const imageStyle={
  margin: 'auto',
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  maxWidth: '80%',
  maxHeight: '80%',
  display: 'block',
};

export const renderBrand = (brand, url, query={}) => {
  const imageUrl = brand.imageUrl ? brand.imageUrl : IMAGE_PLACEHOLDER;

  return (
    <React.Fragment>
      <Link
        to={{
          pathname: url,
          query,
        }}
        className="nav-text"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
          height: '120px',
          position: 'relative',
        }}
      >
        <LazyLoadComponent placeholder={<div style={imageStyle} />}>
          <img
            style={imageStyle}
            className="brand-logo click-state"
            src={imageUrl}
            alt={brand.name}
          />
        </LazyLoadComponent>
      </Link>
      <Link className="black-href" to={url}>{brand.name}</Link>
    </React.Fragment>
  );
}

export const renderBrands = (category, brands) => (
  <Row>
    {brands.map(({node: s}) => {
    const url = `/category/${category.urlSlug}/${s.urlSlug}`;

    return (
      <Col key={s.id} xs={12} sm={8} md={6} lg={4} xl={4} style={{ height: '168px', textAlign: 'center' }}>
        {renderBrand(s, url)}
      </Col>
    );
  })}
  </Row>
);

class BrandList extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({}).isRequired,
        pathname: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  getDescripiton = (category) => {
    if (get(category, 'description')) {
      return <div dangerouslySetInnerHTML={{ __html: category.description }} />;
    }
    return null;
  }

  getDescripiton2 = (category) => {
    if (get(category, 'description2')) {
      return <div dangerouslySetInnerHTML={{ __html: category.description2 }} style={{marginTop: '10px'}} />;
    }
    return null;
  }

  render() {
    const { match: { location } , viewer } = this.props;
    const category = get(viewer, 'categories.edges[0].node', {});
    const brands = get(category, 'brands.edges', []);

    return (
      <div>
        <Helmet category={category} location={location} show404={brands.length === 0} />
        <Breadcrumb category={category} location={location} />
        <HeadingTitle category={category} location={location} />
        {this.getDescripiton(category)}
        {brands.length === 0 && 'No Products Were Found' }
        {renderBrands(category, brands)}
        {this.getDescripiton2(category)}
      </div>
    );
  }
}

export default createFragmentContainer(BrandList, {
  viewer: graphql`
    fragment BrandList_viewer on Customer {
      categories(first: 1, urlSlug: $categoryUrlSlug) {
        edges {
          node {
            id
            name
            urlSlug
            description
            description2
            metaTitle
            metaDescription
            brands(first: 999) @connection(key: "BrandList_brands") {
              edges {
                node {
                  id
                  imageUrl
                  name
                  position
                  urlSlug
                  metaTitle
                  metaDescription
                }
              }
            }
          }
        }
      }
    }
  `,
});
