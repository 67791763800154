/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RemindPasswordReset_viewer$ref = any;
export type routes_RemindPasswordReset_QueryVariables = {||};
export type routes_RemindPasswordReset_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: RemindPasswordReset_viewer$ref
  |}
|};
export type routes_RemindPasswordReset_Query = {|
  variables: routes_RemindPasswordReset_QueryVariables,
  response: routes_RemindPasswordReset_QueryResponse,
|};
*/


/*
query routes_RemindPasswordReset_Query {
  viewer {
    ...RemindPasswordReset_viewer
    id
  }
}

fragment RemindPasswordReset_viewer on Customer {
  id
  email
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_RemindPasswordReset_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RemindPasswordReset_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_RemindPasswordReset_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de0e1b75186cc36f9136528c27619170",
    "id": null,
    "metadata": {},
    "name": "routes_RemindPasswordReset_Query",
    "operationKind": "query",
    "text": "query routes_RemindPasswordReset_Query {\n  viewer {\n    ...RemindPasswordReset_viewer\n    id\n  }\n}\n\nfragment RemindPasswordReset_viewer on Customer {\n  id\n  email\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '0e3971c457cc0cbb6aee79bb9e1cec73';

module.exports = node;
