const isFreeShipping = function isFreeShipping(product, freeShippingAmount) {
  const { globalFreeShipping, freeShipping, price } = product;

  if (freeShipping) {
    return true;
  }

  if (globalFreeShipping && freeShippingAmount && price >= freeShippingAmount) {
    return true;
  }

  return false;
}

const urlPrefix = "/product/"
const getUrl = (urlSlug) => {
  return `${urlPrefix}${urlSlug}`;
}

module.exports = {
  getUrl,
  isFreeShipping
};
