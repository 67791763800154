import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import ResetPassword from './ResetPassword';

module.exports = {
  Login,
  Logout,
  Register,
  ResetPassword,
};
