import Pos from './Pos';
import PosLogin from './PosLogin';
import PosOrder from './PosOrder';
import PosOrderList from './PosOrderList';
import PosSuccessView from './PosSuccessView';

module.exports = {
  Pos,
  PosLogin,
  PosOrder,
  PosOrderList,
  PosSuccessView,
};
