import AddProductToCartMutation from './AddProductToCartMutation';
import ChangeFreebiesMutation from './ChangeFreebiesMutation';
import ChangeItemQtyMutation from './ChangeItemQtyMutation';
import EmptyCartMutation from './EmptyCartMutation';
import RemoveProductFromCartMutation from './RemoveProductFromCartMutation';

module.exports = {
  AddProductToCartMutation,
  ChangeFreebiesMutation,
  ChangeItemQtyMutation,
  EmptyCartMutation,
  RemoveProductFromCartMutation,
};
