import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation PostSparePartsFormMutation($input: PostSparePartsFormInput!) {
    postSparePartsForm(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };

