/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VerifyOrderInput = {|
  firstAmount: number,
  id: string,
  secondAmount: number,
|};
export type VerifyOrderMutationVariables = {|
  input: VerifyOrderInput
|};
export type VerifyOrderMutationResponse = {|
  +verifyOrder: ?{|
    +order: ?{|
      +verified: ?number
    |}
  |}
|};
export type VerifyOrderMutation = {|
  variables: VerifyOrderMutationVariables,
  response: VerifyOrderMutationResponse,
|};
*/


/*
mutation VerifyOrderMutation(
  $input: VerifyOrderInput!
) {
  verifyOrder(input: $input) {
    order {
      verified
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyOrderPayload",
        "kind": "LinkedField",
        "name": "verifyOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrder",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyOrderPayload",
        "kind": "LinkedField",
        "name": "verifyOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SalesOrder",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08fc41ee63d9592efe0a01087d994dcf",
    "id": null,
    "metadata": {},
    "name": "VerifyOrderMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyOrderMutation(\n  $input: VerifyOrderInput!\n) {\n  verifyOrder(input: $input) {\n    order {\n      verified\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '331c1daa54ff7e3eb2f8df0718f1568f';

module.exports = node;
