import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import List from '../product/List';

class FeatureList extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      featured: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    router: PropTypes.shape({
    })
  }

  static defaultProps = {
    router: null,
  }

  render() {
    const { router, viewer } = this.props;
    const category = get(viewer, 'featured.edges[0].node', {});
    const products = get(category, 'products.edges', []);

    return (
      <div>
        <List viewer={viewer} data={products} router={router} />
      </div>
    );
  }
}
export default createFragmentContainer(FeatureList, {
  viewer: graphql`
    fragment FeatureList_viewer on Customer {
      ...List_viewer
      featured: categories(first: 1, urlSlug: "home-page") {
        edges {
          node {
            id
            name
            products(first: 999) @connection(key: "FeatureList_products") {
              edges {
                node {
                  id
                  adjacentBanners(first: 99) {
                    edges {
                      node {
                        id
                        name
                        placement
                        imageUrl
                        link
                      }
                    }
                  }
                  ...ProductCard_product
                }
              }
            }
          }
        }
      }
    }
  `,
});
