import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import * as msal from '@azure/msal-browser';

const getClientId = () => {
  const { COUNTRY } = process.env;

  if (COUNTRY === "AU") {
    return '7970ac98-708b-4c78-bdf4-a453eef72c37';
  } else if (COUNTRY === "NZ") {
    return '973ec645-d500-4077-8925-d8b78d2e9cfd';
  }
  throw new Error(`recognised tld ${COUNTRY}`);
};

const config = {
  auth: {
    clientId: getClientId(),
    redirectUri: `https://${window.location.hostname}`,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const loginRequest = {
  scopes: ["user.read"],
  prompt: "select_account",
};

const msalInstance = new msal.PublicClientApplication(config);

let popupWindow = null;

msalInstance.addEventCallback((message) => {
  if (message.eventType === "msal:popupOpened") {
    // Save the popup window for focusing later
    ({ popupWindow } = message.payload);
  }
});

const Logo = ({ style }) => {
  return (
    <img
      style={style}
      src="/static/images/auth/ms_logo.svg"
      alt="Microsoft logo"
    />
  );
};

Logo.propTypes = {
  style: PropTypes.shape({}),
};

Logo.defaultProps = {
  style: {},
};

const MicrosoftSignin = ({ children, onSuccess, style }) => {
  const authHandler = () => {
    if (popupWindow && !popupWindow.closed && popupWindow.focus) {
      popupWindow.focus();
    } else {
      msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          onSuccess(JSON.stringify(response), "microsoft");
        })
        .catch((err) => {
          if (err.errorCode !== "user_cancelled") {
            console.error(err);
          }
        });
    }
  };

  return (
    <Button onClick={authHandler} style={style} size="large">
      {children}
    </Button>
  );
};

MicrosoftSignin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onSuccess: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

MicrosoftSignin.defaultProps = {
  style: {},
};

MicrosoftSignin.Logo = Logo;

export default MicrosoftSignin;
