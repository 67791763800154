import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Button, Checkbox, Popconfirm, Radio } from 'antd';
import { Link } from 'found';
import { fromGlobalId } from '~/helper';

import GA4 from '~/ga4';
import AfterPay from './payment/AfterPay';
import ApplePay from './payment/ApplePay';
import GooglePay from './payment/GooglePay';
import CreditCard, { AmexRate } from './payment/CreditCard';
import Paypal from './payment/Paypal';
import Zip from './payment/Zip';
import Stripe from './payment/Stripe';
import LatitudePay from '../checkout/payment/LatitudePay';
import Till from './payment/Till';
import Tyro from './payment/Tyro';

import {
  GetTyroSessionMutation,
  RemoveCreditCardMutation,
} from './mutations';

const { Group: RadioGroup } = Radio;
const { Item: FormItem } = Form;

const radioStyle = {
  display: 'block',
};

const { CC_TYPES } = CreditCard;

class PaymentView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      storeCredits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      cart: PropTypes.shape({
        grandTotal: PropTypes.number,
        discounts: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
          amount: PropTypes.number,
        })),
        paymentMethods: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }).isRequired,
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    refetch: PropTypes.func.isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const paymentMethods = get(this.props.viewer.cart, 'paymentMethods.edges', []);
    const hasTill3ds = paymentMethods.find(edge => edge.node.code === "till_3ds");

    if (hasTill3ds) {
      Till.importScript();
    }

    this.state = {
      canGooglePay: false,
    }

  }

  componentDidMount() {
    const { viewer, form } = this.props;
    const cart = get(viewer, 'cart', {});
    const payments = get(viewer, 'cart.paymentMethods.edges', []);
    const paymentMethod = get(viewer, 'cart.checkoutSnapshot.paymentMethod');
    const savedCards = get(viewer, 'creditCards.edges', []);

    let isContainMethod = null;
    if (paymentMethod && paymentMethod.includes("till_")) {
      const savedPaymentId = paymentMethod.replace("till_", "");
      isContainMethod = savedCards.find(({node}) => node.id === savedPaymentId);
    } else {
      isContainMethod = payments.find(({node}) => node.code === paymentMethod);
    }

    if (isContainMethod) {
      const paymentType = get(isContainMethod, 'node.title');
      form.setFieldsValue({ paymentMethod });
      GA4.addPaymentInfo(cart, paymentType);
    }

    GooglePay.canShow(payments)
      .then(resp => this.setState({canGooglePay: resp}))
      .catch(() => this.setState({canGooglePay: false}));
  }

  static getTyroSession = (method, { cb, value, relay }) => {
    GetTyroSessionMutation.commit({
      environment: relay.environment,
      variables: { input: { data: value || null } },
      onCompleted: (resp) => {
        const { sessionId } = resp.getTyroSession;
        const version = get(method, 'extra.version');

        if (sessionId && version) {
          if (cb && typeof cb === 'function') {
            cb(sessionId);
          }
        }
      },
    });
  }

  onChange = (e) => {
    let ccType = null
    const cart = get(this.props.viewer, 'cart', {});
    const ccBrand = get(e.target, 'method.card.ccBrand');
    const paymentType = get(e.target, 'method.title');

    if (ccBrand) {
      ccType = ccBrand;
    }

    if (e.target.method.code !== 'cc') {
      this.props.refetch({ ccType });
    }

    GA4.addPaymentInfo(cart, paymentType);
  }

  setPaypalNonce = (form, payload) => {
    form.setFieldsValue({ paypalNonce: payload.nonce });
    this.props.handleSubmit()
  }

  removeCard = (card) => {
    RemoveCreditCardMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: card.id
        },
      },
      viewer: this.props.viewer,
    });
  }


  useCredit = (e) => {
    this.props.refetch({ useCredit: e.target.checked });
  }

  useLoyaltyPoints = (e) => {
    this.props.refetch({ useLoyaltyPoints: e.target.checked });
  }

  updateCardType = (ccType, stripeToken) => {
    const { form } = this.props;
    this.props.refetch({ ccType, stripeToken });
    form.setFieldsValue({ ccType });
  }

  renderCreditCard = (props, method) => {
    const { form, viewer, relay, handleSubmit } = props;
    if (method.code === "tyro") {
      const cart = get(viewer, 'cart', {});

      return (
        <Tyro.CreditCard
          cart={cart}
          form={form}
          relay={relay}
          submit={handleSubmit}
          getTyroSession={PaymentView.getTyroSession}
          updateCardType={this.updateCardType}
          method={method}
        />
      )
    } else if (method.code === 'stripe') {
      return (<Stripe viewer={viewer} form={form} updateCardType={this.updateCardType} />);
    }

    return (
      <CreditCard form={form} is3ds={method.code === 'till_3ds'} updateCardType={this.updateCardType} />
    );
  }

  render() {
    const { viewer, form } = this.props;
    const cart = get(viewer, 'cart', {});
    const methods = get(viewer, 'cart.paymentMethods.edges', []);
    const savedCards = get(viewer, 'creditCards.edges', []);
    const lines = get(cart, 'lines.edges', []);
    const { getFieldDecorator } = form;

    const hasGiftCard = lines.find(({node}) => node.name.toLowerCase().includes("gift card"));
    const paymentMethod = form.getFieldValue('paymentMethod');
    const shippingMethod = form.getFieldValue('shippingMethod');

    const discount = get(cart, 'discounts', []).filter(d => {
      const { type } = fromGlobalId(d.id);

      if (type !== 'LoyaltyPoints' && type !== 'GiftCard') {
        return true;
      }
      return false;

    }).reduce((acc, d) => acc + d.amount, 0);

    return (
      <div>
        <h2>Payment</h2>

        {viewer.storeCredits.edges.length > 0 && (
        <FormItem >
          {getFieldDecorator('useCredit', {
            valuePropName: 'checked',
            initialValue: true,
          })(
            <Checkbox
              onChange={this.useCredit}
            >
              Use Points ${discount}
            </Checkbox>
          )}
        </FormItem>
        )}

        {get(cart, 'loyaltyPoints.dollars', 0.0) > 0 && (
        <FormItem >
          {getFieldDecorator('useLoyaltyPoints', {
            valuePropName: 'checked',
            initialValue: true,
          })(
            <Checkbox
              onChange={this.useLoyaltyPoints}
            >
              Use Loyalty Points ${cart.loyaltyPoints.dollars}
              <Link style={{marginLeft: '5px'}}to="/p/loyalty-programme"> <QuestionCircleOutlined title="Learn More" /></Link>
            </Checkbox>
          )}
        </FormItem>
        )}

        <FormItem >
          {getFieldDecorator('paymentMethod', {
            rules: [
              { required: true, message: 'Required' },
            ],
          })(
            <RadioGroup
              style={{width: '100%', padding: '0px 5px'}}
              onChange={this.onChange}
            >
              {methods.map((edge) => {
              const method = edge.node;
              const value = form.getFieldValue('paymentMethod');

              if (cart.grandTotal === 0 && method.type === 'free') {
                return (
                  <Radio
                    style={radioStyle}
                    value={method.code}
                    key={method.code}
                    method={method}
                  >
                    {method.title}
                  </Radio>
                );
              }

              if (cart.grandTotal > 0) {
                if (method.type === 'paypal' && shippingMethod !== "storepickup") {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="PayPal Logo" className="payment-logo" src="/static/images/payments/paypal.svg" height="40px" />
                      {method.title}
                      {value === method.code && (
                      <Paypal
                        form={form}
                      />
                      )}
                    </Radio>
                  );
                }
                if (method.type === 'cc' && method.code === 'securepay') {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Credit Card Logo" className="payment-logo" src="/static/images/payments/creditcard.svg" height="40px" />
                      {method.title}
                      {value === method.code &&
                      <CreditCard form={form} updateCardType={this.updateCardType} />
                      }
                    </Radio>
                  );
                }

                if (method.type === 'afterpay' && method.code === 'afterpay' && cart.grandTotal <= 3000.0) {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="AfterPay Logo" className="payment-logo" src="/static/images/payments/afterpay.svg" height="40px" />
                      {method.title}
                      {value === method.code && <AfterPay form={form} amount={cart.grandTotal} />}
                    </Radio>
                  );
                }

                if (method.type === 'cc' && (['till', 'till_3ds', 'tyro', 'stripe'].includes(method.code))) {
                  return (
                    <div
                      key={method.code}
                    >
                      {savedCards.map(({node}) => (
                        <Radio
                          style={radioStyle}
                          value={`${method.code}_${node.id}`}
                          key={node.id}
                          method={{
                            card: node,
                            ...method
                          }}
                        >
                          {get(CC_TYPES[node.ccBrand], 'logo')} {node.ccHolder} &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {node.ccLast4} Exp: {node.ccExpMonth.toString().padStart(2, '0')}/{node.ccExpYear}
                          <Popconfirm
                            title="Remove this card?"
                            onConfirm={() => {
                              this.removeCard(node)
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button style={{marginLeft: '5px'}} icon={<DeleteOutlined />} shape="circle" title="Remove" />
                          </Popconfirm>

                          {(method.code === "tyro" && value === `${method.code}_${node.id}`) && [
                            <Tyro.SavedCard
                              key={value}
                              node={node}
                              form={form}
                              method={method}
                            />,
                            <Tyro.Threeds key={`${value}_3ds`} form={form} />
                          ]}
                          {(method.code === "stripe" && value === `${method.code}_${node.id}`) && <Stripe.SavedCard key={value} />}
                          {paymentMethod && paymentMethod.includes(node.id) && node.ccBrand === "AE" && <AmexRate code={method.code} />}
                        </Radio>
                      ))}
                      <Radio
                        style={radioStyle}
                        value={method.code}
                        method={method}
                      >
                        <img alt="Credit Card Logo" className="payment-logo" src="/static/images/payments/creditcard.svg" height="40px" />
                        {method.title}
                        {value === method.code && (
                        <div>
                          {this.renderCreditCard(this.props, method)}
                          {form.getFieldValue('ccType') === 'AE' && method.code !== "stripe" && (
                            <AmexRate />
                          )}
                          {method.extra && method.extra.canSave && (
                          <FormItem>
                            {getFieldDecorator('cc_save', {
                            valuePropName: 'checked',
                            initialValue: true,
                          })(
                            <Checkbox>
                              <b>Save for future payments</b>
                            </Checkbox>
                          )}
                          </FormItem>
                          )}

                          {value === 'till_3ds' && (<Till form={form} updateCardType={this.updateCardType} />)}
                          {value === 'tyro' && (<Tyro.Threeds form={form} />)}
                        </div>
                        )}
                      </Radio>
                    </div>
                  );
                }

                if (this.state.canGooglePay && method.type === 'googlepay' && (method.code === 'till_googlepay' || method.code === 'tyro_googlepay')) {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Google Pay Logo" className="payment-logo" src="/static/images/payments/googlepay.svg" height="40px" style={{margin: '5px 0'}} />
                      {paymentMethod && paymentMethod.includes("_googlepay") && <AmexRate />}
                    </Radio>
                  );
                }

                if (ApplePay.canShow(cart.paymentMethods) && method.type === 'applepay' && (method.code === 'till_applepay'|| method.code === 'tyro_applepay')) {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Apple Pay Logo" className="payment-logo" src="/static/images/payments/applepay.svg" height="40px" style={{margin: '5px 0'}} />
                      {paymentMethod && paymentMethod.includes("_applepay") && <AmexRate />}
                    </Radio>
                  );
                }

                if (method.type === 'eft' && shippingMethod !== "storepickup") {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      {method.title}
                      {value === method.code && (
                      <div style={{ marginTop: '10px', whiteSpace: 'pre-line' }}>
                        Please use the <b>Order Number</b> as reference for
                        DIRECT DEPOSIT (EFT) payments to following account: <br />
                        <b>BSB: 082184</b><br />
                        <b>Account No: 888940942</b>
                      </div>
                      )}
                    </Radio>
                  );
                }
                if (method.type === 'humm') {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Humm Logo" className="payment-logo" src="/static/images/payments/humm.svg" height="40px" />
                      {method.title}
                    </Radio>
                  );
                }
                if (!hasGiftCard && method.code === 'latitude_financial') {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Latitude Financial Logo" className="payment-logo" style={{filter: 'brightness(0.5)'}} src="/static/images/payments/latitude.svg" height="40px" />
                      {method.title}
                      {value === method.code && (
                        <div style={{marginTop: '10px'}}>
                          <p>Accepted Interest Free Cards</p>
                          <img alt="Latitude Financial Cards" src="/static/images/payments/latitude_financial_cards.png" className="img-fluid" height="40px" />
                        </div>
                      )}
                    </Radio>
                  );
                }
                if (cart.grandTotal >= 20.0 && method.code === 'latitude_pay') {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Latitude Pay Logo" className="payment-logo" src="/static/images/payments/latitude.svg" height="40px" />
                      {method.title}
                      {value === method.code && (
                        <div style={{marginLeft: '28px', height: '55px'}}>
                          <img src={`https://images.latitudepayapps.com/v2/snippet.svg?amount=${cart.grandTotal}&services=LPAY,LPAYPLUS&style=checkout&terms=6,12`} alt="Latitude Pay" />
                          {LatitudePay.displayBreakdown()}
                        </div>
                      )}
                    </Radio>
                  );
                }
                if (method.type === 'zip') {
                  return (
                    <Radio
                      style={radioStyle}
                      value={method.code}
                      key={method.code}
                      method={method}
                    >
                      <img alt="Zip Logo" className="payment-logo" src="/static/images/payments/zip.svg" height="40px" />
                      {method.title}
                      {value === method.code && (
                        <div>
                          <Zip
                            form={form}
                            cart={cart}
                          />
                          <p>You will be redirected to sign up or log in to your account to finalise payment</p>
                        </div>
                      )}
                    </Radio>
                  );
                }
              }

              return null;
            })}
            </RadioGroup>
          )}
        </FormItem>
      </div>
    );
  }
}
export default createFragmentContainer(PaymentView, {
  viewer: graphql`
    fragment PaymentView_viewer on Customer {
      id
      firstname
      lastname
      storeCredits(first: 999) @connection(key: "App_viewer_storeCredits") {
        edges {
          node {
            id
            name
            creditAmount
            creditRemaining
            brands
            expiry
          }
        }
      }
      creditCards(first: 999) @connection(key: "App_viewer_creditCards") {
        edges {
          node {
            id
            ccBrand
            ccHolder
            ccLast4
            ccExpMonth
            ccExpYear
            ccHolder
          }
        }
      }
      cart{
        id
        checkoutSnapshot
        loyaltyPoints {
          dollars
        }
        grandTotal
        discounts(useCredit: $useCredit, useLoyaltyPoints: $useLoyaltyPoints) {
          id
          name
          amount
        }
        paymentMethods(first: 20) {
          edges {
            node {
              code
              title
              type
              env
              extra
            }
          }
        }
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              quantity
              unitPrice
              product {
                id
                sku
                brand {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
});
