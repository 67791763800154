/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PosOrderList_viewer$ref = any;
export type routes_PosOrderList_QueryVariables = {|
  storeIds: $ReadOnlyArray<string>
|};
export type routes_PosOrderList_QueryResponse = {|
  +viewer: ?{|
    +salesPosOrders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +firstname: ?string,
          +lastname: ?string,
          +email: ?string,
          +grandTotal: ?number,
          +operator: ?string,
          +store: ?{|
            +id: string,
            +name: ?string,
          |},
          +insertedAt: ?string,
        |}
      |}>
    |},
    +$fragmentRefs: PosOrderList_viewer$ref,
  |}
|};
export type routes_PosOrderList_Query = {|
  variables: routes_PosOrderList_QueryVariables,
  response: routes_PosOrderList_QueryResponse,
|};
*/


/*
query routes_PosOrderList_Query(
  $storeIds: [String!]!
) {
  viewer {
    ...PosOrderList_viewer
    salesPosOrders(first: 9999, storeIds: $storeIds) {
      edges {
        node {
          id
          name
          firstname
          lastname
          email
          grandTotal
          operator
          store {
            id
            name
          }
          insertedAt
        }
      }
    }
    id
  }
}

fragment PosOrderList_viewer on Customer {
  salesPosOrders(first: 9999, storeIds: $storeIds) {
    edges {
      node {
        id
        name
        firstname
        lastname
        email
        grandTotal
        operator
        store {
          id
          name
        }
        insertedAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "storeIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 9999
    },
    {
      "kind": "Variable",
      "name": "storeIds",
      "variableName": "storeIds"
    }
  ],
  "concreteType": "SalesPosOrderConnection",
  "kind": "LinkedField",
  "name": "salesPosOrders",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesPosOrderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesPosOrder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grandTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "operator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Store",
              "kind": "LinkedField",
              "name": "store",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "insertedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PosOrderList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PosOrderList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_PosOrderList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "431e0f552fdc0d144ecaa2eff9c25475",
    "id": null,
    "metadata": {},
    "name": "routes_PosOrderList_Query",
    "operationKind": "query",
    "text": "query routes_PosOrderList_Query(\n  $storeIds: [String!]!\n) {\n  viewer {\n    ...PosOrderList_viewer\n    salesPosOrders(first: 9999, storeIds: $storeIds) {\n      edges {\n        node {\n          id\n          name\n          firstname\n          lastname\n          email\n          grandTotal\n          operator\n          store {\n            id\n            name\n          }\n          insertedAt\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment PosOrderList_viewer on Customer {\n  salesPosOrders(first: 9999, storeIds: $storeIds) {\n    edges {\n      node {\n        id\n        name\n        firstname\n        lastname\n        email\n        grandTotal\n        operator\n        store {\n          id\n          name\n        }\n        insertedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff2a04f9811994e4d4b332b4a32a0568';

module.exports = node;
