import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isEqual } from 'lodash';
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Menu } from 'antd';
import { PriorityIcon } from '~/components/svg';
import { hiddenKey, getBuckets } from '../Filter';
import PriceFilter, { parseRange, getLabel } from '../PriceFilter';
import { disableBtn, showPriorityFilter } from '../PriorityFilter';
import FilterCheckbox from './FilterCheckbox';

const priceRangeKey = 'price_range';
const defaultHeader = 'Filters';

const getExtra = (activeFilters, key) => {
  const activeKeys = get(activeFilters, key, []);

  return activeKeys.map((name, index) => {
    let comma = "";

    if (index !== activeKeys.length - 1) {
      comma = ", ";
    }

    if (key === 'price_range') {
      const [from, to] = parseRange(name);
      name = getLabel(to, from);
    }

    return `${name}${comma}`;
  });
}

const getItem = ({ key, name, extra, filter, activeFilters, setCheckable, resetFilters }) => {
  const checked = !!get(activeFilters, key);
  if (key === 'priority_shipping') {
    const disabled = disableBtn(filter);

    return {
      key,
      label: (
        <>
          <div style={{ width: '100%' }}>
            <Checkbox
              className="filter-drawer-checkbox"
              style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', fontSize: '14px', color: 'rgb(0, 137, 182)', fontWeight: 'bold' }}
              onClick={(e) => { setCheckable(key, e); }}
              checked={checked}
              disabled={disabled}
            >
              <PriorityIcon style={{ fontSize: '20px', marginRight: '5px' }} />
              {name}
            </Checkbox>
          </div>
        </>
      )
    }
  }

  return {
    key,
    label: (
      <>
        <div style={{ width: '100%' }}>
          <span style={{ fontSize: '14px' }}>{name}</span>
          <span
            style={{
              display: 'block',
              fontSize: '12px',
              lineHeight: 'initial',
              position: 'absolute',
              bottom: '2px',
              color: 'rgba(0,0,0,.45)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: 'calc(100% - 48px)',
            }}
          >
            {extra}
          </span>
        </div>

        {checked && (
          <Button
            type="link"
            style={{
              fontSize: '12px',
              marginRight: '10px',
              color: 'rgba(0,0,0,.45)',
            }}
            onClick={(e) => {

              e.stopPropagation();
              resetFilters(key);
            }}
          >
            <u>Clear</u>
          </Button>
        )}
        <ArrowRightOutlined style={{ display: 'inline-block' }} />
      </>
    )
  }
}



const FilterDrawer = (props) => {
  const {
    match,
    totalCount,
    filters,
    activeFilters,
    showPriority,
    updateFilters,
    updatePriceRange,
    setCheckable,
    resetFilters,
    handleVisible,
    visible,
  } = props;

  const [header, setHeader] = useState(defaultHeader);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const filter = filters.find((f) => f.key === selected?.key)

    if (filter && !isEqual(filter, selected)) {
      setSelected(filter);
    }
  }, [filters, selected]);

  const onMenuItemClick = ({ key }) => {
    if (key === priceRangeKey) {
      key = 'price_stats';
    }

    const filter = filters.find((f) => f.key === key);

    if (filter && key !== 'priority_shipping') {
      setHeader(filter.name);
      setSelected(filter);
    }
  }

  const getItems = () => {
    const items = filters.filter(({ key }) => !hiddenKey.includes(key)).map(({ key, name }) => {
      const extra = getExtra(activeFilters, key);

      return getItem({ key, name, extra, activeFilters, resetFilters });
    });

    const extra = getExtra(activeFilters, priceRangeKey);

    items.unshift(getItem({ key: priceRangeKey, name: "Price", extra, activeFilters, resetFilters }));

    if (showPriority && showPriorityFilter(match)) {
      const psKey = 'priority_shipping';
      const filter = filters.find(({ key }) => key === psKey);

      items.unshift(getItem({ key: psKey, name: "PRIORITY", filter, activeFilters, setCheckable }));
    }

    return items;
  }

  const disableClearBtn = () => {
    let selectedFilters = Object.keys(activeFilters).filter((f) => !hiddenKey.includes(f));

    if (selected) {
      selectedFilters = get(activeFilters, selected.key, []);
    }

    return isEmpty(selectedFilters);
  }

  const drawerContents = () => {
    const menu = (
      <Menu
        className="drawer-filter-menu"
        style={{ width: '100%', border: 'none' }}
        items={getItems()}
        onClick={onMenuItemClick}
      />
    );

    if (selected?.key === 'priority_shipping') {
      return menu;
    } else if (selected?.key === 'price_stats') {
      return (
        <PriceFilter
          filter={selected}
          activeFilters={activeFilters}
          updatePriceRange={updatePriceRange}
        />
      )
    } else if (selected?.key) {
      const { key } = selected;

      return (
        <FilterCheckbox
          filterKey={key}
          buckets={getBuckets(selected, activeFilters)}
          updateFilters={updateFilters}
          showSearch={["brand", "category", "subcategory"].includes(key)}
          checkboxContainerStyle={{ display: 'flex', flexDirection: 'column' }}
          checkboxInnerStyle={{ fontSize: '14px', marginLeft: '0px', minHeight: '35px', alignItems: 'center' }}
        />
      )
    }

    return menu;
  }

  const drawerFooter = () => {
    const buttonStyle = { flex: 1, margin: '0 5px', height: '40px', fontSize: '14px' };

    return (
      <>
        <span style={{ fontSize: '14px', display: 'inline-block', textAlign: 'center', width: '100%', marginBottom: '10px' }}>
          Show all {totalCount} items
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <Button
            style={buttonStyle}
            onClick={() => {
              if (selected) {
                resetFilters(selected.key);
              } else {
                resetFilters();
              }
            }}
            disabled={disableClearBtn()}
          >
            Clear {header === defaultHeader ? 'All' : ''}
          </Button>
          <Button
            type="primary"
            style={buttonStyle}
            onClick={() => { handleVisible(false) }}
          >
            Done
          </Button>
        </div>
      </>
    )
  }

  return (
    <Drawer
      contentWrapperStyle={{ maxWidth: '378px', width: '100%' }}
      title={
        <div style={{ height: '32px', width: '100%', display: 'inline-flex', alignItems: 'center' }}>
          {(selected !== null) && (
            <Button
              type="text"
              icon={<ArrowLeftOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
              onClick={() => {
                setHeader(defaultHeader);
                setSelected(null);
              }}
              style={{ position: 'absolute' }}
            />
          )}
          <div style={{ textAlign: 'center', width: '100%' }}>
            <b>{header}</b>
          </div>
          <Button
            type="text"
            icon={<CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
            onClick={() => { handleVisible(false); }}
            style={{ position: 'absolute', right: '24px' }}
          />
        </div>
      }
      bodyStyle={{ padding: '10px', flexGrow: 'unset' }}
      closable={false}
      placement="right"
      onClose={() => { handleVisible(false); }}
      visible={visible}
      footer={drawerFooter()}
    >
      {drawerContents()}
    </Drawer>
  )
}

FilterDrawer.propTypes = {
  match: PropTypes.shape({}).isRequired,
  totalCount: PropTypes.number.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeFilters: PropTypes.shape({}).isRequired,
  showPriority: PropTypes.bool.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updatePriceRange: PropTypes.func.isRequired,
  setCheckable: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  handleVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}

export default FilterDrawer;
