import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const JssQuery = graphql`
  query routes_Jss_Query {
    viewer {
      ...Jss_viewer
    }
  }
`;

const routes = (
  <Route
    path="/jss"
    getComponent={() => (
      import(/* webpackChunkName: "Jss" */'~/components/jss').then(module => module.Jss)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={JssQuery}
  />
);

export default routes;
