import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import { EMAIL_REGEX, EMAIL_CON_REGEX } from '~/helper';
import { formItemLayout } from '~/components/form';

import { CheckEmailMutation } from './mutations';
import { recordGA } from './helper';

const { Item: FormItem } = Form;

const GuestEmail = (props) => {
  const { form, viewer, relay, setIsGuest } = props;

  const checkEmail = (rule, value, callback) => {
    const email = (value || '').trim();

    if (typeof email === 'string' && email.match(EMAIL_REGEX)) {
      CheckEmailMutation.commit({
        environment: relay.environment,
        variables: { input: { email } },
        viewer,
        onCompleted: (resp, errors) => {
          if (!errors && resp.checkEmail.result) {
            callback("Account with this email already exist.\nPlease login with your existing email.");

            form.resetFields([
              "billing[]firstname", "billing[]lastname",
              "billing[]street", "billing[]city",
              "billing[]postcode", "billing[]region",
              "billing[]telephone", "billing[]company",
              "billing[]fax", "billing[]country",
              "shipping[]firstname", "shipping[]lastname",
              "shipping[]street", "shipping[]city",
              "shipping[]postcode", "shipping[]region",
              "shipping[]telephone", "shipping[]company",
              "shipping[]fax", "shipping[]country",
              "signupPassword", "signupConfirmPassword"
            ]);

            setIsGuest(false);
            recordGA("input", "existing_email");


          } else {
            setIsGuest(true);
            callback();
          }
        },
      });
    } else {
      callback();
    }
  }

  if (viewer.email !== null) {
    return null;
  }

  return (
    <div>
      <h2>Guest Checkout</h2>

      <FormItem
        {...formItemLayout}
        style={{marginRight: '10px'}}
        label="Email"
        key="email"
        hasFeedback
        help={form.isFieldValidating('shipping[]email') ? "Checking Email. . ." : undefined}
      >
        {form.getFieldDecorator('shipping[]email', {
          rules: [
            { transform: (value) => value ? value.trim() : value },
            { type: 'email', message: 'Invalid Email' },
            { required: true, message: 'Required' },
            { required: false, message: 'Please double check your email', pattern: EMAIL_CON_REGEX },
            { required: false, validator: checkEmail },
          ]
        })(
          <Input />
        )}
      </FormItem>
    </div>
  )
};

GuestEmail.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    isFieldValidating: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  viewer: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  setIsGuest: PropTypes.func.isRequired,
};

export default createFragmentContainer(GuestEmail, {
  viewer: graphql`
    fragment GuestEmail_viewer on Customer {
      email
    }
  `,
});
