import { get, startCase } from 'lodash';
import Cookies from 'universal-cookie';
import { fromGlobalId } from '~/helper';

const cookies = new Cookies();

const gTag = (...args) => {
  if (window.gtag === undefined) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
    };
  }

  window.gtag(...args);
}

const check = () => {
  if (window.dataLayer) {
    return true;
  }

  return false;
}

const event = (params) => {
  if (!check()) {
    return;
  };

  window.dataLayer.push(params);
}

/*
 * params = {description: <string>, fatal: <boolean>}
 */
const exception = (params) => {
  if (!check()) {
    return;
  };

  gTag('event', 'exception', params)
}

const viewItem = (product) => {
  if (!check()) {
    return;
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      items: [{
        item_name: product.name,
        item_id: product.sku,
        price: product.price,
        item_brand: product.brand.name,
        quantity: 1
      }]
    }
  });
};

const selectItem = (product, category, index = null) => {
  if (!check()) {
    return;
  };

  const item = {
    item_name: product.name,
    item_id: product.sku,
    price: product.price,
    item_brand: product.brand.name,
    item_list_name: category,
    quantity: 1,
  };

  if (index != null) {
    item.index = index;
  }

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      items: [item]
    }
  });
};

const selectPromotion = (banner, index) => {
  if (!check()) {
    return;
  };

  const id = get(banner, "id");
  const name = get(banner, "title") || get(banner, "name");

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "select_promotion",
    ecommerce: {
      promotion_id: id,
      promotion_name: name,
      creative_slot: index,
    },
  });
}

const getItem = (productOrLine, quantity) => {
  const { type } = fromGlobalId(productOrLine.id);

  if (type === 'Product') {
    return {
      item_name: productOrLine.name,
      item_id: productOrLine.sku,
      price: productOrLine.price,
      item_brand: get(productOrLine, 'brand.name'),
      quantity
    };
  }

  return {
    item_name: productOrLine.name,
    item_id: productOrLine.product.sku,
    price: productOrLine.unitPrice,
    item_brand: productOrLine.product.brand.name,
    quantity
  };
};

const addToCart = (productOrLine, quantity = 1) => {
  if (!check()) {
    return;
  };

  const value = get(productOrLine, 'price') || get(productOrLine, 'unitPrice');

  const ecommerce = {
    currency: process.env.CURRENCY,
    value,
    items: [ getItem(productOrLine, quantity) ]
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    ecommerce,
    event: "add_to_cart",
  });
};

const removeFromCart = (productOrLine, quantity = 1) => {
  if (!check()) {
    return;
  };

  const value = get(productOrLine, 'price') || get(productOrLine, 'unitPrice');

  const ecommerce = {
    currency: process.env.CURRENCY,
    value,
    items: [ getItem(productOrLine, quantity) ]
  };

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    ecommerce,
    event: "remove_from_cart",
  });
};

const removeAllFromCart = (cart) => {
  if (!check()) {
    return;
  };

  const lines = get(cart, 'lines.edges', []);

  const items = lines.map(({node}) => (
    {
      item_name: node.name,
      item_id: node.product.sku,
      price: node.unitPrice,
      item_brand: node.product.brand.name,
      quantity: node.quantity
    }
  ));

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: process.env.CURRENCY,
      value: get(cart, 'grandTotal'),
      items,
    }
  });
};

const beginCheckout = (cart) => {
  if (!check()) {
    return;
  }

  const items = get(cart, 'lines.edges', []).map(({node}) => getItem(node, node.quantity));

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: { items },
  });
}

const addPaymentInfo = (cart, paymentType) => {
  if (!check() || !paymentType) {
    return;
  }

  const items = get(cart, 'lines.edges', []).map(({node}) => getItem(node, node.quantity));

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      currency: process.env.CURRENCY,
      value: get(cart, 'grandTotal'),
      payment_type: paymentType,
      items,
    }
  });
}

const addShippingInfo = (cart, shippingMethod) => {
  if (!check() || !shippingMethod) {
    return;
  }

  let shippingTier = "";

  if (shippingMethod === "storepickup") {
    shippingTier = "Store Pickup";
  } else if (shippingMethod === "freeshipping") {
    shippingTier = "Free Shipping";
  } else if (shippingMethod !== "storepickup") {
    shippingTier = startCase(shippingMethod);
  }

  const items = get(cart, 'lines.edges', []).map(({node}) => getItem(node, node.quantity));

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "add_shipping_info",
    ecommerce: {
      currency: process.env.CURRENCY,
      value: get(cart, 'grandTotal'),
      shipping_tier: shippingTier,
      items,
    }
  });
}

const purchase = (order, callback = null) => {
  if (!check()) {
    return;
  };

  const { salesOrder } = order.createOrder;
  const coupon = get(salesOrder, 'discounts', []).map(d => d.name).join(", ");
  const lines = get(salesOrder, 'lines.edges', []);

  const ref = cookies.get('_ref');
  const trackingCode = cookies.get('_refv');

  const items =
    lines.map(({node}) => {
      const data = {
        item_name: node.name,
        item_id: node.product.sku,
        price: node.unitPrice,
        item_brand: get(node.product, 'brand.name'),
        quantity: node.quantity
      };

      if (ref === 'ca-wtb' && trackingCode) {
        data.affiliation = trackingCode;
      }

      return data;
    });

  const data = {
    event: "purchase",
    ecommerce: {
      transaction_id: salesOrder.name,
      value: salesOrder.grandTotal,
      shipping: get(salesOrder, 'shippingTotal', 0),
      currency: process.env.CURRENCY,
      items
    }
  };

  if (coupon) {
    data.ecommerce.coupon = coupon;
  }

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(data);

  if (callback && typeof callback === "function") {
    callback();
  }
};

export default {
  event,
  exception,
  addToCart,
  addPaymentInfo,
  addShippingInfo,
  beginCheckout,
  purchase,
  removeAllFromCart,
  removeFromCart,
  selectItem,
  selectPromotion,
  viewItem,
};
