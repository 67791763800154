import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { TagFilled } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { PriceMatchMutation } from './mutations';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class PriceMatch extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      priceMatch: PropTypes.bool.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      loading: false,
      visible: false,
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = ()  => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = (values) => {
    this.setState({ loading: true });

    PriceMatchMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success("Thank you very much, we have received your price match request.");
        this.formRef.current.resetFields();
        this.setState({ loading: false, visible: false, });
      },
      onError: () => {
        this.setState({ loading: false });
      },
    });
  };

  render() {
    const { loading } = this.state;
    const { product } = this.props;

    if (!product.priceMatch) {
      return null;
    }

    return (
      <div>
        <Button
          onClick={this.showModal}
          style={{marginTop: '10px', width: '100%', fontWeight: 'bold'}}
        >
          <TagFilled /> Price Match
        </Button>
        <Modal
          title="Price Match"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              Submit
            </Button>,
          ]}
        >
          <p>{product.name}</p>

          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            <Form.Item
              name="productId"
              rules={[{ required: true }]}
              initialValue={product.id}
              hidden
            >
              <Input type="hidden" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label="Email"
              name="email"
              rules={[
                { required: true },
                { type: 'email', message: 'Invalid Email' },
                { transform: (value) => value ? value.trim() : value },
              ]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label="Phone Number"
              name="phone"
              rules={[{ required: true }]}
            >
              <Input type="tel" placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label="Your Postcode"
              name="postcode"
              rules={[{ required: true }]}
            >
              <Input type="tel" placeholder="Postcode" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label="Competitor's Link"
              name="link"
              rules={[{ required: true }]}
            >
              <Input type="url" placeholder="Competitor's Link" />
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label="Comment"
              name="comment"
            >
              <TextArea placeholder="Comment" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default createFragmentContainer(PriceMatch, {
  viewer: graphql`
    fragment PriceMatch_viewer on Customer {
      id
    }
  `,
  product: graphql`
    fragment PriceMatch_product on Product {
      id
      name
      priceMatch
    }
  `,
});
