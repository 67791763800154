/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDefaultAddressInput = {|
  id: string
|};
export type UpdateDefaultAddressMutationVariables = {|
  input: UpdateDefaultAddressInput
|};
export type UpdateDefaultAddressMutationResponse = {|
  +updateDefaultAddress: ?{|
    +viewer: ?{|
      +defaultAddress: ?{|
        +id: string
      |}
    |}
  |}
|};
export type UpdateDefaultAddressMutation = {|
  variables: UpdateDefaultAddressMutationVariables,
  response: UpdateDefaultAddressMutationResponse,
|};
*/


/*
mutation UpdateDefaultAddressMutation(
  $input: UpdateDefaultAddressInput!
) {
  updateDefaultAddress(input: $input) {
    viewer {
      defaultAddress {
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerAddress",
  "kind": "LinkedField",
  "name": "defaultAddress",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDefaultAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDefaultAddressPayload",
        "kind": "LinkedField",
        "name": "updateDefaultAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDefaultAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDefaultAddressPayload",
        "kind": "LinkedField",
        "name": "updateDefaultAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7aa77b9ebd85974a978427a4d72c9bb3",
    "id": null,
    "metadata": {},
    "name": "UpdateDefaultAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDefaultAddressMutation(\n  $input: UpdateDefaultAddressInput!\n) {\n  updateDefaultAddress(input: $input) {\n    viewer {\n      defaultAddress {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc9081e4f1642b24dfa2b4b92d212668';

module.exports = node;
