/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddressList_viewer$ref = any;
type AddressView_viewer$ref = any;
type FreebiePopup_cart$ref = any;
type FreebiePopup_viewer$ref = any;
type GuestEmail_viewer$ref = any;
type ItemList_viewer$ref = any;
type Offer_viewer$ref = any;
type PaymentView_viewer$ref = any;
type ShippingView_viewer$ref = any;
type StorePickup_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CheckoutView_viewer$ref: FragmentReference;
declare export opaque type CheckoutView_viewer$fragmentType: CheckoutView_viewer$ref;
export type CheckoutView_viewer = {|
  +email: ?string,
  +captcha: ?boolean,
  +cart: ?{|
    +id: string,
    +checkoutSnapshot: ?any,
    +subtotal: ?number,
    +shippingCost: ?number,
    +grandTotal: ?number,
    +surcharges: ?$ReadOnlyArray<?{|
      +name: ?string,
      +amount: ?number,
    |}>,
    +discount: ?number,
    +discounts: ?$ReadOnlyArray<?{|
      +id: ?string,
      +name: ?string,
      +amount: ?number,
      +removable: ?boolean,
    |}>,
    +paymentMethods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +code: ?string,
          +title: ?string,
          +type: ?string,
          +env: ?string,
          +extra: ?any,
        |}
      |}>
    |},
    +shippingMethods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +code: ?string,
          +title: ?string,
          +priceFormatted: ?string,
          +forklift: ?boolean,
        |}
      |}>
    |},
    +isOptFreebiesInCart: ?boolean,
    +lines: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isFreebie: ?boolean,
          +unitPrice: ?number,
          +quantity: ?number,
          +unitDiscount: ?number,
          +unitSurcharge: ?number,
          +rowTotal: ?number,
          +product: ?{|
            +id: string,
            +sku: ?string,
            +name: ?string,
            +model: ?string,
            +onlineOnly: ?boolean,
            +bulkyGood: ?boolean,
            +stockAvailable: ?number,
            +limitedStock: ?boolean,
            +brand: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
        |}
      |}>
    |},
    +$fragmentRefs: FreebiePopup_cart$ref,
  |},
  +addresses: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstname: ?string,
        +lastname: ?string,
        +street: ?string,
        +city: ?string,
        +postcode: ?string,
        +region: ?string,
        +telephone: ?string,
        +company: ?string,
        +fax: ?string,
        +country: ?{|
          +name: ?string
        |},
      |}
    |}>
  |},
  +$fragmentRefs: FreebiePopup_viewer$ref & ItemList_viewer$ref & AddressList_viewer$ref & AddressView_viewer$ref & GuestEmail_viewer$ref & Offer_viewer$ref & ShippingView_viewer$ref & StorePickup_viewer$ref & PaymentView_viewer$ref,
  +$refType: CheckoutView_viewer$ref,
|};
export type CheckoutView_viewer$data = CheckoutView_viewer;
export type CheckoutView_viewer$key = {
  +$data?: CheckoutView_viewer$data,
  +$fragmentRefs: CheckoutView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "ccType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "city"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forklift"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "postcode"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shippingMethod"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "street"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stripeToken"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suburb"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "useCredit"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "useLoyaltyPoints"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "cart",
          "lines"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "addresses"
        ]
      }
    ]
  },
  "name": "CheckoutView_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "captcha",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkoutSnapshot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "shippingMethod",
              "variableName": "shippingMethod"
            }
          ],
          "kind": "ScalarField",
          "name": "shippingCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grandTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "ccType",
              "variableName": "ccType"
            },
            {
              "kind": "Variable",
              "name": "forklift",
              "variableName": "forklift"
            },
            {
              "kind": "Variable",
              "name": "stripeToken",
              "variableName": "stripeToken"
            }
          ],
          "concreteType": "Surcharge",
          "kind": "LinkedField",
          "name": "surcharges",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "useCredit",
              "variableName": "useCredit"
            },
            {
              "kind": "Variable",
              "name": "useLoyaltyPoints",
              "variableName": "useLoyaltyPoints"
            }
          ],
          "concreteType": "Discount",
          "kind": "LinkedField",
          "name": "discounts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "removable",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v3/*: any*/)
          ],
          "concreteType": "PaymentMethodConnection",
          "kind": "LinkedField",
          "name": "paymentMethods",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentMethodEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentMethod",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "env",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "extra",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "paymentMethods(first:20)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "city",
              "variableName": "city"
            },
            (v3/*: any*/),
            {
              "kind": "Variable",
              "name": "postcode",
              "variableName": "postcode"
            },
            {
              "kind": "Variable",
              "name": "street",
              "variableName": "street"
            },
            {
              "kind": "Variable",
              "name": "suburb",
              "variableName": "suburb"
            }
          ],
          "concreteType": "ShippingRateConnection",
          "kind": "LinkedField",
          "name": "shippingMethods",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShippingRateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShippingRate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "priceFormatted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forklift",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOptFreebiesInCart",
          "storageKey": null
        },
        {
          "alias": "lines",
          "args": null,
          "concreteType": "QuoteLineConnection",
          "kind": "LinkedField",
          "name": "__CartView_lines_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuoteLineEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuoteLine",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isFreebie",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitDiscount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitSurcharge",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rowTotal",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Product",
                      "kind": "LinkedField",
                      "name": "product",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sku",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "model",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "onlineOnly",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bulkyGood",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "stockAvailable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "limitedStock",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Brand",
                          "kind": "LinkedField",
                          "name": "brand",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FreebiePopup_cart"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "addresses",
      "args": null,
      "concreteType": "CustomerAddressConnection",
      "kind": "LinkedField",
      "name": "__AddressBook_addresses_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerAddressEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerAddress",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "street",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "city",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postcode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "region",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "telephone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "country",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreebiePopup_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemList_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddressList_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddressView_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GuestEmail_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Offer_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShippingView_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorePickup_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentView_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '70de312c678d6abfc1e3ece02674382b';

module.exports = node;
