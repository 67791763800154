import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
} from 'react-relay';
import { EMAIL_CON_REGEX } from '~/helper';
import Cookies from 'universal-cookie';
import { Button, Form, Input, message } from 'antd';
import { confirmPassword } from '~/components/form';
import { RegisterCustomerMutation as RegisterMutation } from './mutations';

const FormItem = Form.Item;
const cookies = new Cookies();

class Registration extends React.Component {
  static propTypes = {
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      loading: false,
    }
  }

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const input = values.signup;
    delete input.confirmPassword;

    RegisterMutation.commit({
      environment: this.props.relay.environment,
      variables: { input },
      onCompleted: (resp, errors) => {
        this.setState({loading: false});
        if (!errors) {
          cookies.set('auth_token', resp.registerCustomer.token, { path: '/' });
          window.location.reload();
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
        this.setState({loading: false});
      }
    });
  }

  render() {
    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <FormItem
          name={["signup", "firstname"]}
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input placeholder="First Name" />
        </FormItem>

        <FormItem
          name={["signup", "lastname"]}
          rules={[{ required: true, message: 'Required' }]}
        >
          <Input placeholder="Last Name" />
        </FormItem>

        <FormItem
          name={["signup", "email"]}
          validateFirst
          rules={[
            { required: true, message: 'Required', },
            { type: 'email', message: 'Invalid Email', transform: (value) => (value || '').trim() },
            { required: false, message: 'Please double check your email', pattern: EMAIL_CON_REGEX },
          ]}
        >
          <Input placeholder="Email" />
        </FormItem>

        <FormItem
          name={["signup", "password"]}
          rules={[
            { required: true, message: 'Required' },
            (form) => ({
              validator: (rule, value) => confirmPassword(["signup", "confirmPassword"], {form, rule, value})
            }),
          ]}
        >
          <Input.Password placeholder="Password" />
        </FormItem>

        <FormItem
          name={["signup", "confirmPassword"]}
          rules={[
            { required: true, message: 'Required' },
            (form) => ({
              validator: (rule, value) => confirmPassword(["signup", "password"], {form, rule, value})
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </FormItem>

        <FormItem>
          <Button
            style={{width: '100%'}}
            type="primary"
            htmlType="submit"
            loading={this.state.loading}
            size="large"
          >
            Sign Up
          </Button>
        </FormItem>

      </Form>
    );
  }
}
export default createFragmentContainer(Registration, {
});
