import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation VerifyOrderMutation($input: VerifyOrderInput!) {
    verifyOrder(input:$input) {
      order {
        verified
      }
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };

