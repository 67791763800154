import React from 'react';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';

import { Button } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReviewList from './ReviewList';

class ReviewPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      reviews: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
    }).isRequired,
  }

  loadMore() {
    if (!this.props.relay.hasMore() || this.props.relay.isLoading()) {
      return;
    }

    this.props.relay.loadMore(10);
  }

  render() {
    const { viewer } = this.props;
    const reviews = get(viewer, 'reviews.edges', []);

    return (
      <div>
        <h2>Reviews</h2>
        {reviews.length === 0 &&
          'No Reviews Found'
        }

        <h3>Product Feedback & Review</h3>
        <p>
          At Sydney Tools we strive to administer a memorable experience for all our customers.
          Our priority is customer satisfaction. Feedback allows consumers to share valuable
          product reviews and service experiences in the public domain.
        </p>

        <h3>Why Leave Feedback?</h3>
        <p>
          Sydney Tools management are flagged to any negative experiences and will act without
          delay to aid, and liaise with the customer in attempt to reverse a negative experience
          through swift action, compensation or alternative means. Leaving a product review will
          assist other consumers when deciding to make a purchase.
        </p>

        <h3>How To Leave Feedback?</h3>
        <p>
          In order to leave a feedback, you will need to log in to the website using
          the email address you initially used to set up your Sydney Tools account,
          and locate the item you wish to review. We sincerely appreciate you taking
          the time to provide your comments and feedback.
        </p>

        <br />
        <ReviewList reviews={reviews} />
        <Button onClick={() => this.loadMore()} >Load More</Button>
      </div>
    );
  }
}

export default createPaginationContainer(
  ReviewPage,
  {
    viewer: graphql`
    fragment ReviewPage_viewer on Customer {
      reviews(first: $count, after: $cursor) @connection(key: "ReviewPage_reviews") {
        edges {
          node {
            id
            content
            replies(first: 100) {
              edges {
                node {
                  id
                  content
                  customer {
                    firstname
                  }
                  insertedAt
                }
              }
            }
            ratings(first: 10) {
              edges {
                node {
                  id
                  star
                  percentage
                  ratingType {
                    code
                  }
                }
              }
            }
            product {
              name
              urlSlug
              mainImage {
                id
                url
              }
            }
            customer {
              firstname
            }
            insertedAt
            status
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.reviews;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    query ReviewPagePaginationQuery(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...ReviewPage_viewer
      }
    }
  `,
  },
);
