import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

// createOrder alias is used because onCheckoutCompleted function is shared
const mutation = graphql`
  mutation PaymentRetryMutation($input: PaymentRetryInput!) {
    createOrder : paymentRetry(input:$input) {
      salesOrder {
        id
        encryptedId
        name
        verified
        grandTotal
        shippingTotal
        paymentMethod
        status
        lines(first: 99) {
          edges {
            node {
              id
              name
              quantity
              unitPrice
              rowTotal
              product {
                id
                sku
                name
                brand {
                  id
                  name
                }
              }
              children(first: 99) {
                edges {
                  node {
                    id
                    name
                    quantity
                    unitPrice
                    product {
                      id
                      sku
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };
