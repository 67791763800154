/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Covid19Page_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_Covid19Page_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>
|};
export type routes_Covid19Page_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +cmsPage: ?{|
      +title: ?string,
      +urlSlug: ?string,
      +content: ?string,
    |},
    +faqs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +question: ?string,
          +answer: ?string,
        |}
      |}>
    |},
    +$fragmentRefs: Covid19Page_viewer$ref,
  |}
|};
export type routes_Covid19Page_Query = {|
  variables: routes_Covid19Page_QueryVariables,
  response: routes_Covid19Page_QueryResponse,
|};
*/


/*
query routes_Covid19Page_Query(
  $filterBy: [FilterBy]
) {
  viewer {
    ...Covid19Page_viewer
    id
    cmsPage(urlSlug: "covid-19") {
      title
      urlSlug
      content
    }
    faqs(first: 99, filterBy: $filterBy) {
      edges {
        node {
          id
          question
          answer
        }
      }
    }
  }
}

fragment Covid19Page_viewer on Customer {
  id
  cmsPage(urlSlug: "covid-19") {
    title
    urlSlug
    content
  }
  faqs(first: 99, filterBy: $filterBy) {
    edges {
      node {
        id
        question
        answer
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "urlSlug",
      "value": "covid-19"
    }
  ],
  "concreteType": "CmsPage",
  "kind": "LinkedField",
  "name": "cmsPage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": "cmsPage(urlSlug:\"covid-19\")"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filterBy",
      "variableName": "filterBy"
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 99
    }
  ],
  "concreteType": "FaqConnection",
  "kind": "LinkedField",
  "name": "faqs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FaqEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Faq",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "question",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Covid19Page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Covid19Page_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_Covid19Page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b357ec5a711609ba1e1147d3a921b7d9",
    "id": null,
    "metadata": {},
    "name": "routes_Covid19Page_Query",
    "operationKind": "query",
    "text": "query routes_Covid19Page_Query(\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...Covid19Page_viewer\n    id\n    cmsPage(urlSlug: \"covid-19\") {\n      title\n      urlSlug\n      content\n    }\n    faqs(first: 99, filterBy: $filterBy) {\n      edges {\n        node {\n          id\n          question\n          answer\n        }\n      }\n    }\n  }\n}\n\nfragment Covid19Page_viewer on Customer {\n  id\n  cmsPage(urlSlug: \"covid-19\") {\n    title\n    urlSlug\n    content\n  }\n  faqs(first: 99, filterBy: $filterBy) {\n    edges {\n      node {\n        id\n        question\n        answer\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c0add775802199657bfd1ec13806624';

module.exports = node;
