/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Search_viewer$ref = any;
export type SearchRefetchQueryVariables = {|
  query?: ?string
|};
export type SearchRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Search_viewer$ref
  |}
|};
export type SearchRefetchQuery = {|
  variables: SearchRefetchQueryVariables,
  response: SearchRefetchQueryResponse,
|};
*/


/*
query SearchRefetchQuery(
  $query: String
) {
  viewer {
    ...Search_viewer_1Qr5xf
    id
  }
}

fragment Search_viewer_1Qr5xf on Customer {
  searchAutocomplete(query: $query)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "Search_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "searchAutocomplete",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "969ba227c3dc52377e47025e462b81ff",
    "id": null,
    "metadata": {},
    "name": "SearchRefetchQuery",
    "operationKind": "query",
    "text": "query SearchRefetchQuery(\n  $query: String\n) {\n  viewer {\n    ...Search_viewer_1Qr5xf\n    id\n  }\n}\n\nfragment Search_viewer_1Qr5xf on Customer {\n  searchAutocomplete(query: $query)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec466cf7f95adea971f5494485331fa0';

module.exports = node;
