import React from 'react';
import PropTypes from 'prop-types';

const FilterLabel = ({ label, docCount }) => {
  return (
    <>
      <span style={{ marginRight: '10px' }}>
        {label}
      </span>
      <span>
        {docCount}
      </span>
    </>
  )
}

FilterLabel.propTypes = {
  label: PropTypes.string.isRequired,
  docCount: PropTypes.number.isRequired,
}

export default FilterLabel;