/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Related_product$ref: FragmentReference;
declare export opaque type Related_product$fragmentType: Related_product$ref;
export type Related_product = {|
  +related: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +brand: ?{|
          +id: string,
          +name: ?string,
        |},
        +name: ?string,
        +type: ?string,
        +nonStock: ?boolean,
        +sku: ?string,
        +price: ?number,
        +urlSlug: ?string,
        +callForPrice: ?boolean,
        +mainImage: ?{|
          +id: string,
          +thumbnail: ?string,
        |},
        +configurables: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +type: ?string,
              +nonStock: ?boolean,
              +sku: ?string,
              +price: ?number,
              +urlSlug: ?string,
              +callForPrice: ?boolean,
              +mainImage: ?{|
                +id: string,
                +thumbnail: ?string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: Related_product$ref,
|};
export type Related_product$data = Related_product;
export type Related_product$key = {
  +$data?: Related_product$data,
  +$fragmentRefs: Related_product$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nonStock",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callForPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Related_product",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "ProductConnection",
      "kind": "LinkedField",
      "name": "related",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Brand",
                  "kind": "LinkedField",
                  "name": "brand",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": (v0/*: any*/),
                  "concreteType": "ProductConnection",
                  "kind": "LinkedField",
                  "name": "configurables",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/),
                            (v9/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "configurables(first:99)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "related(first:99)"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f0bd4460eb5c5fc04375560e8397139';

module.exports = node;
