import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as BC } from 'antd';
import { Link } from 'found';
import { attempt, get, isEmpty, startCase } from 'lodash';
import { shouldAppendIndex } from './helper';
import { getStateName } from '../store/StoreList';

// Follow the sytle from commit f2e2df82f81f7958bdfa0a0da7c398dc4317fde8
export const showFiltedCategory = (subcategory, {query = {}} = {}) => {
  const { filters } = query;
  let filteredCategory = null;
  let bcItem = null;

  if (typeof filters === 'string') {
    const f = attempt(JSON.parse.bind(null, filters));
    if (Object.keys(f).length === 1 && f.category) {
      filteredCategory = get(f, 'category[0]');
    }
  }

  if (filteredCategory && isEmpty(subcategory)) {
    bcItem = filteredCategory;
  }

  return bcItem;
};

export default class Breadcrumb extends React.Component {
  static propTypes = {
    category: PropTypes.shape({
      urlSlug: PropTypes.string,
    }),
    subcategory: PropTypes.shape({
      urlSlug: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    prependHome: PropTypes.bool,
    itemStyle: PropTypes.shape({
    }),
    separator: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    states: PropTypes.arrayOf(PropTypes.string),
    store: PropTypes.shape({
      name: PropTypes.string,
      state: PropTypes.string,
    }),
    brand: PropTypes.shape({
      name: PropTypes.string,
    }),
   }

  static defaultProps = {
    category: {},
    subcategory: {},
    prependHome: true,
    itemStyle: {
      fontSize: '12px',
    },
    separator: <span>&rsaquo;</span>,
    states: [],
    store: {},
    brand: {},
  }

  getBreadcrumb = (category, subcategory, location, brand) => {
    const { pathname, query } = location;
    let breadcrumb = [];

    // match against
    // pathname: /category/by-brand/ego
    // brand url slug: /ego
    // `ego` is will match cat`ego`ry rather than the brand urlslug
    const brandlUrlSlug = `/${  get(brand || {}, 'urlSlug')}`

    if (!isEmpty(brand)) {
      breadcrumb.push({
        name: category.name,
        url: pathname.substring(0, pathname.indexOf(brandlUrlSlug))
      });

      breadcrumb.push({
        name: brand.name,
        url: pathname.substring(0, pathname.indexOf(brandlUrlSlug) + brandlUrlSlug.length)
      });
    } else {
      breadcrumb = [{
        name: category.name,
        url: pathname.substring(0, pathname.indexOf(category.urlSlug) + category.urlSlug.length)
      }];
    }

    if (get(subcategory, 'urlSlug')) {
      const paths = pathname.split('/').filter(p => p);
      if (!pathname.includes('by-brand') && paths.length === 4) {
        breadcrumb.push({
          name: startCase(paths[2]),
          url: pathname.substring(0, pathname.indexOf(paths[2]) + paths[2].length)
        });
      } else if (pathname.includes('by-brand') && paths.length === 5) {
        breadcrumb.push({
          name: startCase(paths[3]),
          url: pathname.substring(0, pathname.indexOf(paths[3]) + paths[3].length)
        });
      }
      breadcrumb.push({
        name: subcategory ? subcategory.name : startCase(subcategory.urlSlug),
        url: pathname.substring(0, pathname.indexOf(subcategory.urlSlug) + subcategory.urlSlug.length)
      });
    }

    const key = shouldAppendIndex(category, query);
    if (key) {
      breadcrumb.push({
        name: `${key} ${get(subcategory, 'name', '')}`,
        url: pathname + location.search
      });
    }
    return breadcrumb;
  }

  getStoreBreadcrumb = (states, store, location) => {
    const { pathname } = location;

    let breadcrumb = [{
      name: "Store Locations",
      url: pathname.split("/", 2).join("/"),
    }];

    let storeName = decodeURIComponent(pathname).trim().slice(8);
    storeName = getStateName(storeName);

    if (!isEmpty(states) && states.includes(storeName)) {
      breadcrumb.push({
        name: storeName,
        url: pathname.split("/", 3).join("/"),
      })
    }

    if (!isEmpty(store)) {
      breadcrumb = breadcrumb.concat([{
        name: getStateName(store.state),
        url: pathname.split("/", 3).join("/"),
      },
      {
        name: `Sydney Tools ${store.name}`,
        url: pathname,
      }])
    }

    return breadcrumb;
  }

  showFiltedCategory = (subcategory, location, itemStyle) => {
    const cat = showFiltedCategory(subcategory, location, itemStyle);

    return cat ? <BC.Item key={cat} style={itemStyle}>{cat}</BC.Item> : null;
  }

  render() {
    const {
      category,
      itemStyle,
      location,
      prependHome,
      subcategory,
      separator,
      states,
      store,
      brand,
    } = this.props;

    let breadcrumb = [];

    if (!isEmpty(category)) {
      breadcrumb = this.getBreadcrumb(category, subcategory, location, brand);
    } else {
      breadcrumb = this.getStoreBreadcrumb(states, store, location);
    }

    return (
      <BC separator={separator}>
        {prependHome && (
        <BC.Item style={itemStyle} >
          <Link to='/'>
            Home
          </Link>
        </BC.Item>
        )}
        {breadcrumb.map(i => <BC.Item key={i.name} style={itemStyle} ><Link to={i.url}>{i.name}</Link></BC.Item>)}
        {this.showFiltedCategory(subcategory, location, itemStyle)}
      </BC>
    );
  }
}
