import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import PropTypes from 'prop-types';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';

import URL from 'url-parse';
import { Link } from 'found';
import { get } from 'lodash';
import Shiitake from 'shiitake';
import GAhelper from '~/GAhelper';
import GA4 from '~/ga4';
import { ProductId } from './ProductId';
import { ProductLabel } from './ProductLabel';
import { AddProductToCartMutation } from '../cart/mutations';
import './style.css';
import Popover from './Popover';
import Price from './Price';
import ProductCard from './ProductCard';
import BonusLabel from './label/BonusLabel';
import RedemptionLabel from './label/RedemptionLabel';
import { openCartToast } from '../cart/helper';

export const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';
const { listingName } = GAhelper;

const { ShowPopover } = Popover;

export const productCardstyles = {
  default: {
    cartWrapper: {
      display: 'inline-block',
      position: 'absolute',
      right: '0px',
      bottom: '0px',
    },
    cartButton: {
      width: '36px',
      height: '36px',
      padding: '0px',
      fontWeight: '900',
    },
  },
  suggestions: {
    productName: {
      fontSize: '12px',
      overflow: 'hidden',
    },
    cartButton: {
      width: '100%',
    }
  },
};

export const getProduct = (product) => {
  if (product.type === 'configurable') {
    return get(product, 'configurables.edges[0].node', {});
  }
  return product;
}

const handleAdd = (product, { relay, router, viewer }) => {
  let input = {
    id: product.id,
  };

  if (product.type === 'configurable') {
    const childProduct = getProduct(product);
    input = {
      id: childProduct.id,
      parentProductId: product.id
    }
  }

  const variables = { input };

  AddProductToCartMutation.commit({
    environment: relay.environment,
    variables,
    viewer,
    parent: product,
    onCompleted: () => {
      GA4.addToCart(product);
      openCartToast(product, { router } );
    },
  });
};

export const renderAddToCart = (product, listMode, { relay, router, viewer } = {}) => {
  const parent = product;
  if (product.callForPrice) {
    return null;
  }

  if (product.type === 'configurable') {
    product = getProduct(product);
  }

  const styles = get(productCardstyles, listMode, {});
  const cartButtonStyles = styles.cartButton;

  const onClick = () => handleAdd(parent, { relay, router, viewer });

  const btn = (
    <Button
      style={{
        background: '#cb0000',
        border: 'none',
        borderRadius: '2px',
        color: 'white',
        ...cartButtonStyles
      }}
      onClick={onClick}
      title="Add To Cart"
    >
      <PlusOutlined />
    </Button>
  );

  return <ShowPopover product={product} btn={btn} />;
}


export const renderInStoreOnly = () => (
  <div style={{fontSize: '10px', color: '#cb0000'}}>In Store Only</div>
);

export const renderPrice = (product, {showNormally = true} = {}) => (
  <Price
    product={product}
    priceStyles={{
        dollar: {
          fontSize: '14px',
        },
        integer: {
          fontSize: '23px',
        },
        decimal: {
          fontSize: '10px',
        },
      }}
    showNormally={showNormally}
    showImage={false}
  />
);

export const recordGA = (product, index = null, {listMode}) => {
  const category = listingName(window.location.pathname, listMode);

  GA4.selectItem(product, category, index);
}

class List extends React.Component {
  static propTypes = {
   listMode: PropTypes.string,
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        adjacentBanners: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        })
      }),
    })).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
    }),
  }

  static defaultProps = {
    listMode: 'default',
    router: null,
  }

  onClickBanner = (node, index) => {
    GA4.selectPromotion(node, index);
  }

  renderSuggestionCard = (n, index, urlPrefix = "/product/") => {
    const url = `${urlPrefix}${n.urlSlug}`;
    // FIXME Image placeholder for products without image
    const image = get(n, 'mainImage', {});
    const { listMode, viewer, relay, router } = this.props;
    const styles = get(productCardstyles, listMode, {});
    const redempLabel = n.labels.edges.find(({node}) => node.type === 1);
    const bonusLabels = n.labels.edges.filter(({node}) => node.type === 0);

    return (
      <SwiperSlide key={n.id}>
        <Card className="product-card" bodyStyle={{padding: '7px'}}>
          <div className="product-image" style={{position: 'relative'}}>
            <ProductLabel product={n} type="thumbnail" />
            <Link to={url} onClick={() => { recordGA(n, index, { listMode }); }} >
              <div className="click-state">
                {image &&
                <img className="img-fluid" title={n.name} alt={n.name} src={image.thumbnail} />
              }
                {!image &&
                <img className="img-fluid" title={n.name} alt={n.name} src={IMAGE_PLACEHOLDER} />
              }
              </div>
            </Link>
          </div>
          <div className="product-info">
            <h2 title={n.name} style={{marginBottom: '12px'}}>
              <Link className="black-href" to={url} sku={n.sku} onClick={() => { recordGA(n, index, { listMode }); }} style={get(styles, 'productName')} >
                <Shiitake lines={3} tagName="span">
                  {n.name}
                </Shiitake>
              </Link>
            </h2>
            <div>
              <RedemptionLabel label={redempLabel} />
              <BonusLabel labels={bonusLabels} />
            </div>
            {listMode === 'default' && <ProductId sku={n.sku} model={n.model} secondModel={n.secondModel} />}

            <div className="clearfix">
              <div className="price" style={{ display: 'inline-block', fontSize: '16px', verticalAlign: 'text-top' }} >
                <div>
                  {n.callForPrice ? 'Call For Price' : `$${n.price.toFixed(2)}`}
                  {n.onlineOnly && <div style={{ lineHeight: '1' }}><small>Not available for Click &amp; Collect</small></div>}
                </div>
              </div>
              <div style={get(styles, 'cartWrapper', {})}>
                {n.inStoreOnly ? renderInStoreOnly() : renderAddToCart(n, listMode, { relay, viewer, router })}
              </div>
            </div>
          </div>
        </Card>
      </SwiperSlide>
    );
  }

  renderBanners = (banners) => {
    if (banners.length === 0) {
      return null;
    }

    return banners.map(({node}, index) => {
      const url = new URL(node.link);

      const style={
        display: 'block',
        width: '100%',
        height: '100%',
      };
      let linkNode = <img src={node.imageUrl} alt="banner" style={style} title={node.name} />;

      if (node.link && window.location.host === url.host) {
        linkNode = <Link onClick={() => { this.onClickBanner(node, index); }} style={style} className="click-state" to={url.pathname + url.query}>{linkNode}</Link>;
        ;
      } else if (node.link) {
        linkNode = <a onClick={() => { this.onClickBanner(node, index); }} href={node.link} style={style} className="click-state">{linkNode}</a>;
      }

      return (
        <Col key={node.id} xs={12} sm={8} md={6} lg={6} style={{padding: '0px'}}>
          {linkNode}
        </Col>
      );
    })
  }

  renderList = (data) => (
    <Row className="product-list" style={{backgroundColor: 'white', borderRadius: '10px'}} type="flex">
      {data.length === 0 &&
      <p>No Products Were Found</p>
      }

      {data.map(({node}, index) => {
        const beforeBanners = get(node, 'adjacentBanners.edges', []).filter(({node: b}) => b.placement === 'B');
        const afterBanners = get(node, 'adjacentBanners.edges', []).filter(({node: b}) => b.placement === 'A');

        return (
          <React.Fragment key={node.id}>

            {this.renderBanners(beforeBanners)}

            <Col xs={12} sm={8} md={6} lg={6} style={{padding: '0px'}}>
              <ProductCard
                product={node}
                position={index}
                viewer={this.props.viewer}
                router={this.props.router}
              />
            </Col>

            {this.renderBanners(afterBanners)}
          </React.Fragment>
        );
      })}
    </Row>
  )

  renderSuggestions = (data) => {
    if (data.length > 0) {
      return (
        <div
          className="product-suggestions"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
        >
          <div className="swiper-button-prev" style={{ position: 'relative', left: 'unset', margin: '0px 10px 0px 5px' }} />
          <Swiper
            style={{ width: '100%', margin: '0px' }}
            modules={[FreeMode, Navigation]}
            spaceBetween={20}
            grabCursor
            freeMode={{
              enabled: true,
              momentumRatio: 0.4,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              451: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              576: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              769: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
              993: {
                slidesPerView: 6,
                slidesPerGroup: 6,
              },
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            }}
          >
            {data.map((edge, index) => {
              const n = edge.node;
              return this.renderSuggestionCard(n, index);
            })}
          </Swiper>
          <div className="swiper-button-next" style={{ position: 'relative', right: 'unset', margin: '0px 5px 0px 10px' }} />
        </div>
      );
    }
    return null;
  }

  render() {
    const { data, listMode } = this.props;

    if (listMode === "suggestions") {
      return this.renderSuggestions(data);
    }

    return this.renderList(data);
  }
}

export default createFragmentContainer(List, {
  viewer: graphql`
    fragment List_viewer on Customer {
      id
      ...ProductCard_viewer
    }
  `,
});
