/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FreebiePopup_cart$ref = any;
export type CartUpdatedSubscriptionVariables = {|
  id?: ?string
|};
export type CartUpdatedSubscriptionResponse = {|
  +cartUpdated: ?{|
    +id: string,
    +subtotal: ?number,
    +grandTotal: ?number,
    +storeCredits: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +stealthMode: ?boolean,
          +creditAmount: ?number,
        |}
      |}>
    |},
    +lines: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isFreebie: ?boolean,
          +unitPrice: ?number,
          +quantity: ?number,
          +rowTotal: ?number,
          +unitDiscount: ?number,
          +unitSurcharge: ?number,
          +product: ?{|
            +id: string,
            +urlSlug: ?string,
            +name: ?string,
            +onlineOnly: ?boolean,
            +mainImage: ?{|
              +id: string,
              +thumbnail: ?string,
            |},
          |},
        |}
      |}>
    |},
    +$fragmentRefs: FreebiePopup_cart$ref,
  |}
|};
export type CartUpdatedSubscription = {|
  variables: CartUpdatedSubscriptionVariables,
  response: CartUpdatedSubscriptionResponse,
|};
*/


/*
subscription CartUpdatedSubscription(
  $id: ID
) {
  cartUpdated(id: $id) {
    id
    subtotal
    grandTotal
    storeCredits(first: 999) {
      edges {
        node {
          id
          name
          stealthMode
          creditAmount
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    lines(first: 999) {
      edges {
        node {
          id
          name
          isFreebie
          unitPrice
          quantity
          rowTotal
          unitDiscount
          unitSurcharge
          product {
            id
            urlSlug
            name
            onlineOnly
            mainImage {
              id
              thumbnail
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...FreebiePopup_cart
  }
}

fragment FreebiePopup_cart on Cart {
  freebies(first: 99) {
    edges {
      node {
        id
        mode
        products(first: 99) {
          edges {
            node {
              id
              name
              urlSlug
              mainImage {
                id
                url
                thumbnail
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtotal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grandTotal",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreCreditEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StoreCredit",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stealthMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creditAmount",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QuoteLineEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuoteLine",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFreebie",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitDiscount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitSurcharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v10/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onlineOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductImage",
                "kind": "LinkedField",
                "name": "mainImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CartUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cartUpdated",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "storeCredits",
            "args": null,
            "concreteType": "StoreCreditConnection",
            "kind": "LinkedField",
            "name": "__CartView_storeCredits_connection",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": "lines",
            "args": null,
            "concreteType": "QuoteLineConnection",
            "kind": "LinkedField",
            "name": "__CartView_lines_connection",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FreebiePopup_cart"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CartUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cartUpdated",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "StoreCreditConnection",
            "kind": "LinkedField",
            "name": "storeCredits",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": "storeCredits(first:999)"
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CartView_storeCredits",
            "kind": "LinkedHandle",
            "name": "storeCredits"
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "QuoteLineConnection",
            "kind": "LinkedField",
            "name": "lines",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": "lines(first:999)"
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "CartView_lines",
            "kind": "LinkedHandle",
            "name": "lines"
          },
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "FreebieTierConnection",
            "kind": "LinkedField",
            "name": "freebies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FreebieTierEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FreebieTier",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v14/*: any*/),
                        "concreteType": "ProductConnection",
                        "kind": "LinkedField",
                        "name": "products",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductImage",
                                    "kind": "LinkedField",
                                    "name": "mainImage",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": null
                                      },
                                      (v11/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "products(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "freebies(first:99)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ad47d93663fc5afdaaf767bf266e1a7",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "cartUpdated",
            "storeCredits"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "cartUpdated",
            "lines"
          ]
        }
      ]
    },
    "name": "CartUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription CartUpdatedSubscription(\n  $id: ID\n) {\n  cartUpdated(id: $id) {\n    id\n    subtotal\n    grandTotal\n    storeCredits(first: 999) {\n      edges {\n        node {\n          id\n          name\n          stealthMode\n          creditAmount\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    lines(first: 999) {\n      edges {\n        node {\n          id\n          name\n          isFreebie\n          unitPrice\n          quantity\n          rowTotal\n          unitDiscount\n          unitSurcharge\n          product {\n            id\n            urlSlug\n            name\n            onlineOnly\n            mainImage {\n              id\n              thumbnail\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...FreebiePopup_cart\n  }\n}\n\nfragment FreebiePopup_cart on Cart {\n  freebies(first: 99) {\n    edges {\n      node {\n        id\n        mode\n        products(first: 99) {\n          edges {\n            node {\n              id\n              name\n              urlSlug\n              mainImage {\n                id\n                url\n                thumbnail\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '483745aea7f6ae95c2e49a327277ca44';

module.exports = node;
