import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer } from 'react-relay';

import { Button, Form, Input, message } from 'antd';

import { PostSubscriptionMutation } from './mutations';

const { Item: FormItem } = Form;

class SubscriptionForm extends React.Component {
  static propTypes = {
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      loading: false,
    };
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });
    PostSubscriptionMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { email: values.subscriptionEmail } },
      onCompleted: () => {
        message.success('Thank you for your subscription');
        this.setState({
          loading: false,
        });
      },
      onError: () => {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <Form ref={this.formRef} onFinish={this.handleSubmit} style={{ display: 'flex', color: 'white' }}>
          <FormItem
            name="subscriptionEmail"
            rules={[
              { transform: (value) => value ? value.trim().toLowerCase() : value },
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email' },
            ]}
            wrapperCol={{ xs: 24 }}
            style={{ width: 'calc(100% - 85px)', marginRight: '0px', marginBottom: '0px'}}
          >
            <Input className="small-placeholder" placeholder="Enter a email address" style={{width: '100%', margin: '0'}} />
          </FormItem>
          <FormItem style={{margin: '0px'}}>
            <Button type="primary" htmlType="submit" style={{padding: '0px 10px'}} loading={this.state.loading}>
              <b style={{fontSize: '12px'}}>Subscribe</b>
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}


export default createFragmentContainer(SubscriptionForm, {
});
