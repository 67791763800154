/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CmsBanner_viewer$ref = any;
type CmsPage_viewer$ref = any;
type Logout_viewer$ref = any;
type SideNav_viewer$ref = any;
export type routes_CustomerApplications_QueryVariables = {|
  urlSlug: string
|};
export type routes_CustomerApplications_QueryResponse = {|
  +viewer: ?{|
    +cmsPage: ?{|
      +title: ?string,
      +urlSlug: ?string,
      +content: ?string,
      +metaDescription: ?string,
    |},
    +email: ?string,
    +$fragmentRefs: CmsPage_viewer$ref & CmsBanner_viewer$ref & SideNav_viewer$ref & Logout_viewer$ref,
  |}
|};
export type routes_CustomerApplications_Query = {|
  variables: routes_CustomerApplications_QueryVariables,
  response: routes_CustomerApplications_QueryResponse,
|};
*/


/*
query routes_CustomerApplications_Query(
  $urlSlug: String!
) {
  viewer {
    ...CmsPage_viewer
    ...CmsBanner_viewer
    cmsPage(urlSlug: $urlSlug) {
      title
      urlSlug
      content
      metaDescription
    }
    ...SideNav_viewer
    email
    ...Logout_viewer
    id
  }
}

fragment CmsBanner_viewer on Customer {
  cmsBanners(first: 999) {
    edges {
      node {
        id
        title
        link
        urlPaths
        categoryBig
        categorySmall
      }
    }
  }
}

fragment CmsPage_viewer on Customer {
  ...CmsBanner_viewer
  cmsPage(urlSlug: $urlSlug) {
    title
    urlSlug
    content
    metaDescription
  }
}

fragment Logout_viewer on Customer {
  email
}

fragment SideNav_viewer on Customer {
  email
  ...Logout_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "urlSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "urlSlug",
      "variableName": "urlSlug"
    }
  ],
  "concreteType": "CmsPage",
  "kind": "LinkedField",
  "name": "cmsPage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaDescription",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CustomerApplications_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CmsPage_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CmsBanner_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SideNav_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Logout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_CustomerApplications_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              }
            ],
            "concreteType": "CmsBannerConnection",
            "kind": "LinkedField",
            "name": "cmsBanners",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CmsBannerEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CmsBanner",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urlPaths",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categoryBig",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categorySmall",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "cmsBanners(first:999)"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a42323d03915187bb1e72101dc7a4b7b",
    "id": null,
    "metadata": {},
    "name": "routes_CustomerApplications_Query",
    "operationKind": "query",
    "text": "query routes_CustomerApplications_Query(\n  $urlSlug: String!\n) {\n  viewer {\n    ...CmsPage_viewer\n    ...CmsBanner_viewer\n    cmsPage(urlSlug: $urlSlug) {\n      title\n      urlSlug\n      content\n      metaDescription\n    }\n    ...SideNav_viewer\n    email\n    ...Logout_viewer\n    id\n  }\n}\n\nfragment CmsBanner_viewer on Customer {\n  cmsBanners(first: 999) {\n    edges {\n      node {\n        id\n        title\n        link\n        urlPaths\n        categoryBig\n        categorySmall\n      }\n    }\n  }\n}\n\nfragment CmsPage_viewer on Customer {\n  ...CmsBanner_viewer\n  cmsPage(urlSlug: $urlSlug) {\n    title\n    urlSlug\n    content\n    metaDescription\n  }\n}\n\nfragment Logout_viewer on Customer {\n  email\n}\n\nfragment SideNav_viewer on Customer {\n  email\n  ...Logout_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c8705c4b804b6f26aee81197d30a09b';

module.exports = node;
