import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import Helmet from '~/components/page/Helmet';
import GA4 from '~/ga4';

import { Button, Form, message } from 'antd';
import 'antd/lib/alert/style';

import { PasswordResetMutation } from '../mutations';
import PasswordForm from './PasswordForm';

const FormItem = Form.Item;

class RemindPasswordReset extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({
          redirect: PropTypes.string,
        })
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.redirectPath = get(this.props.match.location, 'query.redirect', '/');
  }

  handleSubmit = (values) => {
    const input = {
      token: "null",
      password: values.newPassword,
    };
    PasswordResetMutation.commit({
      environment: this.props.relay.environment,
      variables: { input },
      viewer: this.props.viewer,
      onCompleted: () => {
        GA4.event({
          event: 'account',
          action: 'Click',
          label: 'Password Change'
        });
        message.success("Your password has been updated.")

        this.props.router.push(this.redirectPath);
      }
    });
  }

  skip = () => {
    GA4.event({
      event: 'account',
      action: 'Click',
      label: 'Skip Password Change'
    });

    this.props.router.push(this.redirectPath);
  }

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="Secure your password - Sydney Tools" />
        <h1>Make your account more secure</h1>

        <p>At <b>Sydney Tools</b>, we take online security seriously. To protect your Sydney Tools Account, we strongly recommend using a stronger password on your account.</p>

        {viewer.email && (
          <div>
            <PasswordForm formRef={this.formRef} onSubmit={this.handleSubmit} />

            <FormItem
              wrapperCol={{
              xs: { span: 24 },
              sm: { span: 16 },
              md: { span: 24 },
              lg: { offset: 10, span: 14 },
            }}
            >
              <Button onClick={this.skip}>Skip for now</Button>
            </FormItem>
          </div>
        )}

      </div>
    );
  }
}

export default createFragmentContainer(RemindPasswordReset, {
  viewer: graphql`
    fragment RemindPasswordReset_viewer on Customer {
      id
      email
    }
  `,
});
