/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Login_viewer$ref = any;
type Logout_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Account_viewer$ref: FragmentReference;
declare export opaque type Account_viewer$fragmentType: Account_viewer$ref;
export type Account_viewer = {|
  +firstname: ?string,
  +email: ?string,
  +$fragmentRefs: Login_viewer$ref & Logout_viewer$ref,
  +$refType: Account_viewer$ref,
|};
export type Account_viewer$data = Account_viewer;
export type Account_viewer$key = {
  +$data?: Account_viewer$data,
  +$fragmentRefs: Account_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Account_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Login_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Logout_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a7a5c03204b4e01780102d90b9f121d5';

module.exports = node;
