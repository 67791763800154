import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GA4 from '~/ga4';

import { datadogRum } from '@datadog/browser-rum';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    pathname: PropTypes.string
  }

  static defaultProps = {
    children: null,
    pathname: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };

  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error) {
    GA4.exception({
      description: error.message,
      fatal: true
    });

    datadogRum.addError(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
