/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ShippingQuote_viewer$ref: FragmentReference;
declare export opaque type ShippingQuote_viewer$fragmentType: ShippingQuote_viewer$ref;
export type ShippingQuote_viewer = {|
  +freeShippingAmount: ?number,
  +shippingMethods: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +code: ?string,
        +title: ?string,
        +price: ?number,
        +priceFormatted: ?string,
        +extra: ?any,
      |}
    |}>
  |},
  +$refType: ShippingQuote_viewer$ref,
|};
export type ShippingQuote_viewer$data = ShippingQuote_viewer;
export type ShippingQuote_viewer$key = {
  +$data?: ShippingQuote_viewer$data,
  +$fragmentRefs: ShippingQuote_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "city"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "postcode"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "productId"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "suburb"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShippingQuote_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeShippingAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "city",
          "variableName": "city"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Variable",
          "name": "postcode",
          "variableName": "postcode"
        },
        {
          "kind": "Variable",
          "name": "productId",
          "variableName": "productId"
        },
        {
          "kind": "Variable",
          "name": "suburb",
          "variableName": "suburb"
        }
      ],
      "concreteType": "ShippingRateConnection",
      "kind": "LinkedField",
      "name": "shippingMethods",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShippingRateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShippingRate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "priceFormatted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "extra",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c1ed5b87b7c0a34934d00253be43024c';

module.exports = node;
