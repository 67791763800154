import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Col, Input, Row, Select } from 'antd';
import { getCountry, getDataSourceName, FormItemPostcode, FormItemRegion, FormItemStreet, FormItemSuburb } from '../form';
import { useHandleSearch } from '../product/ShippingQuote';

import './style.css';

const { Option } = Select;
const { Item: FormItem } = Form;

const formItemLayout = {
  formlayout: 'vertical',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};

const renderAddressInputs = function renderAddressInputs({
  form, address, type, disable = false
}) {
  const { getFieldDecorator } = form;
  const { relay } = this.props;

  const dataSourceName = getDataSourceName(type);
  const dataSource = this.state[dataSourceName];

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="First Name"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]firstname`, {
            rules: [
              { required: true, message: 'Required' },
            ],
            initialValue: get(address, 'firstname', ''),
          })(<Input disabled={disable} />)}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="Last Name"
            key="lastname"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]lastname`, {
              rules: [
                { required: true, message: 'Required' },
              ],
              initialValue: get(address, 'lastname', ''),
            })(<Input disabled={disable} />)}
          </FormItem>
        </Col>

        <Col xs={24}>
          <FormItemStreet
            disabled={disable}
            form={form}
            formItemLayout={formItemLayout}
            relay={relay}
            type={type}
            address={address}
            handleAddressSelect={(addressType, field, value) => {
              const dest = {
                street: value.street,
                suburb: value.suburb,
                city: value.city,
                postcode: value.postcode,
              };

              this.props.onChange({ dest });
            }}
          />
        </Col>

        <Col xs={24} md={12}>
          <FormItemSuburb
            form={form}
            formItemLayout={formItemLayout}
            type={type}
            address={address}
            dataSource={dataSource}
            dataSourceName={dataSourceName}
            disabled={disable}
            handleSearch={this.handleSearch}
            handleAddressSelect={this.handleAddressSelect}
            handleAddressTyping={this.handleAddressTyping}
          />
        </Col>

        <Col xs={24} md={12}>
          <FormItemPostcode
            form={form}
            formItemLayout={formItemLayout}
            type={type}
            address={address}
            dataSource={dataSource}
            dataSourceName={dataSourceName}
            disabled={disable}
            handleSearch={this.handleSearch}
            handleAddressSelect={this.handleAddressSelect}
            handleAddressTyping={this.handleAddressTyping}
          />
        </Col>

        <Col xs={24}>
          <Row gutter={[10, 0]}>
            <Col xs={12}>
              <FormItemRegion
                disabled={disable}
                type={type}
                address={address}
                form={form}
                formItemLayout={formItemLayout}
              />
            </Col>

            <Col xs={12}>
              <FormItem
                {...formItemLayout}
                label="Country"
                hasFeedback
              >
                {getFieldDecorator(`${type}[]country`, {
                  rules: [
                    { required: true, message: 'Required' },
                  ],
                  initialValue: getCountry(address),
                })(
                  <Select disabled>
                    <Option value="AU">Australia</Option>
                    <Option value="NZ">New Zealand</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <FormItem
            {...formItemLayout}
            label="Telephone"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]telephone`, {
              rules: [
                { type: "string", required: true, pattern: /^\+?[()+0-9 ext.]+$/, message: 'Phone Number Only' },
                { required: true, message: 'Required' },
              ],
              initialValue: get(address, 'telephone', ''),
            })(<Input disabled={disable} />)}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="Company"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]company`, {
              rules: [
                { required: false, message: 'Required' },
              ],
              initialValue: get(address, 'company', ''),
            })(<Input disabled={disable} />)}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="Fax"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]fax`, {
              rules: [
                { required: false, message: 'Required' },
              ],
              initialValue: get(address, 'fax', ''),
            })(<Input disabled={disable} />)}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

renderAddressInputs.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  address: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    telephone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    region: PropTypes.string,
    compnay: PropTypes.string,
    country: PropTypes.shape({
      alpha2: PropTypes.string,
    }),
    fax: PropTypes.string,
  }),
  disable: PropTypes.bool,
};

renderAddressInputs.defaultProps = {
  address: {},
  disable: false,
}

const AddressInputs = (props) => {
  const { form, relay, address, type, disabled } = props;
  const { handleAddressSelect, handleAddressTyping } = props;
  const { getFieldDecorator } = form;

  const dataSourceName = getDataSourceName(type);
  const [dataSource, handleSearch] = useHandleSearch(relay, dataSourceName);

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="First Name"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]firstname`, {
            rules: [
              { required: true, message: 'Required' },
            ],
            initialValue: get(address, 'firstname', ''),
          })(<Input disabled={disabled} />)}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="Last Name"
            key="lastname"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]lastname`, {
              rules: [
                { required: true, message: 'Required' },
              ],
              initialValue: get(address, 'lastname', ''),
            })(<Input disabled={disabled} />)}
          </FormItem>
        </Col>

        <Col xs={24}>
          <FormItemStreet
            disabled={disabled}
            form={form}
            formItemLayout={formItemLayout}
            relay={relay}
            type={type}
            address={address}
            handleAddressSelect={(addressType, field, value) => {
              const dest = {
                street: value.street,
                suburb: value.suburb,
                city: value.city,
                postcode: value.postcode,
              };

              props.onChange({ dest });
            }}
          />
        </Col>

        <Col xs={24} md={12}>
          <FormItemSuburb
            form={form}
            formItemLayout={formItemLayout}
            type={type}
            address={address}
            dataSource={dataSource}
            dataSourceName={dataSourceName}
            disabled={disabled}
            handleSearch={handleSearch}
            handleAddressSelect={handleAddressSelect}
            handleAddressTyping={handleAddressTyping}
          />
        </Col>

        <Col xs={24} md={12}>
          <FormItemPostcode
            form={form}
            formItemLayout={formItemLayout}
            type={type}
            address={address}
            dataSource={dataSource}
            dataSourceName={dataSourceName}
            disabled={disabled}
            handleSearch={handleSearch}
            handleAddressSelect={handleAddressSelect}
            handleAddressTyping={handleAddressTyping}
          />
        </Col>

        <Col xs={24}>
          <Row gutter={[10, 0]}>
            <Col xs={12}>
              <FormItemRegion
                disabled={disabled}
                type={type}
                address={address}
                form={form}
                formItemLayout={formItemLayout}
              />
            </Col>

            <Col xs={12}>
              <FormItem
                {...formItemLayout}
                label="Country"
                hasFeedback
              >
                {getFieldDecorator(`${type}[]country`, {
                  rules: [
                    { required: true, message: 'Required' },
                  ],
                  initialValue: getCountry(address),
                })(
                  <Select disabled>
                    <Option value="AU">Australia</Option>
                    <Option value="NZ">New Zealand</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <FormItem
            {...formItemLayout}
            label="Telephone"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]telephone`, {
              rules: [
                { type: "string", required: true, pattern: /^\+?[()+0-9 ext.]+$/, message: 'Phone Number Only' },
                { required: true, message: 'Required' },
              ],
              initialValue: get(address, 'telephone', ''),
            })(<Input disabled={disabled} />)}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="Company"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]company`, {
              rules: [
                { required: false, message: 'Required' },
              ],
              initialValue: get(address, 'company', ''),
            })(<Input disabled={disabled} />)}
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            {...formItemLayout}
            label="Fax"
            hasFeedback
          >
            {getFieldDecorator(`${type}[]fax`, {
              rules: [
                { required: false, message: 'Required' },
              ],
              initialValue: get(address, 'fax', ''),
            })(<Input disabled={disabled} />)}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

AddressInputs.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  relay: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  address: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    telephone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    region: PropTypes.string,
    compnay: PropTypes.string,
    country: PropTypes.shape({
      alpha2: PropTypes.string,
    }),
    fax: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  handleAddressSelect: PropTypes.func,
  handleAddressTyping: PropTypes.func,
};

AddressInputs.defaultProps = {
  address: {},
  disabled: false,
  onChange: () => {},
  handleAddressSelect: () => {},
  handleAddressTyping: () => {},
};

module.exports = {
  renderAddressInputs,
  AddressInputs,
};
