/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetTyroSessionInput = {|
  data?: ?string,
  googleToken?: ?string,
|};
export type GetTyroSessionMutationVariables = {|
  input: GetTyroSessionInput
|};
export type GetTyroSessionMutationResponse = {|
  +getTyroSession: ?{|
    +sessionId: ?string
  |}
|};
export type GetTyroSessionMutation = {|
  variables: GetTyroSessionMutationVariables,
  response: GetTyroSessionMutationResponse,
|};
*/


/*
mutation GetTyroSessionMutation(
  $input: GetTyroSessionInput!
) {
  getTyroSession(input: $input) {
    sessionId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetTyroSessionPayload",
    "kind": "LinkedField",
    "name": "getTyroSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sessionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetTyroSessionMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetTyroSessionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae83fe89fda59277ff367db9588b8327",
    "id": null,
    "metadata": {},
    "name": "GetTyroSessionMutation",
    "operationKind": "mutation",
    "text": "mutation GetTyroSessionMutation(\n  $input: GetTyroSessionInput!\n) {\n  getTyroSession(input: $input) {\n    sessionId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efe6c58e8264c98358a8f4291b57aa68';

module.exports = node;
