/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TierPrice_product$ref: FragmentReference;
declare export opaque type TierPrice_product$fragmentType: TierPrice_product$ref;
export type TierPrice_product = {|
  +price: ?number,
  +tierPrices: ?any,
  +$refType: TierPrice_product$ref,
|};
export type TierPrice_product$data = TierPrice_product;
export type TierPrice_product$key = {
  +$data?: TierPrice_product$data,
  +$fragmentRefs: TierPrice_product$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TierPrice_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tierPrices",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ccd9bbd39a7214b098ac499f6418874f';

module.exports = node;
