import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation CreatePosOrderMutation($input: CreatePosOrderInput!) {
    createPosOrder(input:$input) {
      cartId
      result
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
