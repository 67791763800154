/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type PriceTagRefetchQueryVariables = {|
  first?: ?number,
  query?: ?string,
  orderBy?: ?OrderBy,
  filters?: ?string,
|};
export type PriceTagRefetchQueryResponse = {|
  +viewer: ?{|
    +searchProducts: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +type: ?string,
          +attributes: ?any,
          +sku: ?string,
          +status: ?string,
          +mainImage: ?{|
            +id: string,
            +url: ?string,
          |},
        |}
      |}>
    |}
  |}
|};
export type PriceTagRefetchQuery = {|
  variables: PriceTagRefetchQueryVariables,
  response: PriceTagRefetchQueryResponse,
|};
*/


/*
query PriceTagRefetchQuery(
  $first: Int
  $query: String
  $orderBy: OrderBy
  $filters: String
) {
  viewer {
    searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filters: $filters) {
      edges {
        node {
          id
          name
          type
          attributes
          sku
          status
          mainImage {
            id
            url
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "searchProducts",
  "args": [
    {
      "kind": "Variable",
      "name": "filters",
      "variableName": "filters"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy"
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "ProductConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attributes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sku",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductImage",
              "kind": "LinkedField",
              "name": "mainImage",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PriceTagRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PriceTagRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e19b97a5573e1807acb140312ad72e25",
    "id": null,
    "metadata": {},
    "name": "PriceTagRefetchQuery",
    "operationKind": "query",
    "text": "query PriceTagRefetchQuery(\n  $first: Int\n  $query: String\n  $orderBy: OrderBy\n  $filters: String\n) {\n  viewer {\n    searchProducts: products(first: $first, query: $query, orderBy: $orderBy, filters: $filters) {\n      edges {\n        node {\n          id\n          name\n          type\n          attributes\n          sku\n          status\n          mainImage {\n            id\n            url\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '136e87ea14729f1b91f824d5b6b77fa5';

module.exports = node;
