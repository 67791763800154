import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, Col, Divider, Form, Input, message, Rate, Row } from 'antd';
import { Link } from 'found';
import { get, startCase } from 'lodash';
import moment from 'moment';

import { AddReviewReplyMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;
const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

const ReviewReplyForm = (props) => {
  const { review, relay } = props;

  const [form] = Form.useForm();

  const handleAddReply = (e) => {
    e.preventDefault();

    const targetField = [[review.id, "reviewId"], [review.id, "content"]];

    form.validateFields(targetField).then((values) => {
      const data = values[review.id];

      AddReviewReplyMutation.commit({
        environment: relay.environment,
        variables: { input: data },
        parent: review,
        onCompleted: () => {
          message.success('Thank you for your reply');
          form.resetFields();
        },
      });
    }).catch(() => {});
  }

  return (
    <Form form={form}>
      <FormItem
        name={[review.id, "reviewId"]}
        rules={[{ required: true, message: 'required' }]}
        initialValue={review.id}
        hidden
      >
        <Input />
      </FormItem>

      <FormItem
        name={[review.id, "content"]}
        rules={[{ required: true, message: 'required' }]}
      >
        <TextArea rows={4} placeholder="Reply Now" />
      </FormItem>

      <Button type="primary" onClick={handleAddReply}>Reply</Button>
    </Form>
  )
}

ReviewReplyForm.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
}

class ReviewList extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      reviews: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  render() {
    const { viewer, product } = this.props;
    const reviews = get(product, 'reviews', []);

    return (
      <div>
        {reviews.edges.map((edge) => {
          const r = edge.node;
          const url = `/product/${r.product.urlSlug}`;
          const image = get(r, 'product.mainImage');
          const name = get(r, 'customer.firstname', 'Anonymous');

          return (
            <Row key={r.id}>
              <Col xs={6} sm={2}>
                {image &&
                  <img style={{ maxWidth: '100%', height: 'auto' }} width="100" height="100" alt={r.product.name} src={image.thumbnail} />
                }
                {!image &&
                  <img style={{ maxWidth: '100%', height: 'auto' }} width="100" height="100" alt="Place Holder" src={IMAGE_PLACEHOLDER} />
                }
              </Col>
              <Col xs={18} sm={22} style={{ whiteSpace: 'pre-line' }}>
                <p><Link to={url}>{r.product.name}</Link></p>
              </Col>
              <Col xs={24} sm={22} md={24} style={{ whiteSpace: 'pre-line' }}>
                <u><b>{startCase(name)}:</b></u> {moment(r.insertedAt).format('Do MMM YY')}<br />

                <div>
                  {r.content}
                </div>

                <Row>
                  {r.ratings.edges.map((ratingEdge) => {
                    const rate = ratingEdge.node;
                    return (
                      <Col xs={24} sm={8} md={7} lg={5} xl={4} key={rate.id}>
                        <Row>
                          <Col xs={6} sm={8} md={6} lg={5} xl={6} style={{ textAlign: 'right' }}>
                            <b>{rate.ratingType.code}</b>
                          </Col>
                          <Col xs={18} sm={24}>
                            <Rate disabled value={rate.star} />
                          </Col>
                        </Row>
                      </Col>
                     );
                  })}
                </Row>

                {r.replies.edges.map((replyEdge) => {
                  const reply = replyEdge.node;
                  const customerName = get(reply.customer, 'firstname', reply.customerName) || 'Anonymous';
                  return (
                    <div key={reply.id}>
                      <Divider />
                      <div style={{ marginLeft: '5%', backgroundColor: '#f3f3f3' }}>
                        <u><b>{startCase(customerName)}:</b></u> {moment(reply.insertedAt).format('Do MMM YY')}<br />
                        {reply.content}
                      </div>
                    </div>
                  );
                })}

                {r.customer && viewer.id === r.customer.id && (
                <div style={{ marginTop: '10px', marginLeft: '5%'}}>
                  <ReviewReplyForm review={r} relay={this.props.relay} />
                </div>
                )}

                <Divider />
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default createFragmentContainer(ReviewList, {
  viewer: graphql`
    fragment ReviewList_viewer on Customer {
      id
    }
  `,
  product: graphql`
  fragment ReviewList_product on Product {
    reviews(first: 100) @connection(key: "ReviewList_reviews") {
      edges {
        node {
          id
          content
          replies(first: 100) @connection(key: "ReviewList_replies")  {
            edges {
              node {
                id
                content
                customer {
                  firstname
                }
                customerName
                insertedAt
              }
            }
          }
          ratings(first: 10) {
            edges {
              node {
                id
                star
                percentage
                ratingType {
                  code
                }
              }
            }
          }
          product {
            name
            urlSlug
            mainImage {
              id
              thumbnail
            }
          }
          productName
          customer {
            id
            firstname
          }
          customerName
          insertedAt
          status
        }
      }
    }
  }
`,
});
