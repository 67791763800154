/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FeatureList_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type home_viewer$ref: FragmentReference;
declare export opaque type home_viewer$fragmentType: home_viewer$ref;
export type home_viewer = {|
  +cmsPage: ?{|
    +title: ?string,
    +urlSlug: ?string,
    +content: ?string,
    +metaDescription: ?string,
  |},
  +cmsBanners: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +link: ?string,
        +homeBig: ?string,
        +homeSmall: ?string,
      |}
    |}>
  |},
  +cmsCarouselImages: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +link: ?string,
        +main1: ?string,
        +main2: ?string,
        +bg1: ?string,
        +bg2: ?string,
      |}
    |}>
  |},
  +$fragmentRefs: FeatureList_viewer$ref,
  +$refType: home_viewer$ref,
|};
export type home_viewer$data = home_viewer;
export type home_viewer$key = {
  +$data?: home_viewer$data,
  +$fragmentRefs: home_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "home_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "urlSlug",
          "value": "home"
        }
      ],
      "concreteType": "CmsPage",
      "kind": "LinkedField",
      "name": "cmsPage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaDescription",
          "storageKey": null
        }
      ],
      "storageKey": "cmsPage(urlSlug:\"home\")"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "CmsBannerConnection",
      "kind": "LinkedField",
      "name": "cmsBanners",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CmsBannerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CmsBanner",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "homeBig",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "homeSmall",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "cmsBanners(first:999)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "CmsCarouselImageConnection",
      "kind": "LinkedField",
      "name": "cmsCarouselImages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CmsCarouselImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CmsCarouselImage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "main1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "main2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bg1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bg2",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "cmsCarouselImages(first:999)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureList_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '3536ac32d15f40ed8fc7e18a00aabe99';

module.exports = node;
