import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const SparePartsFormQuery = graphql`
  query routes_SparePartsForm_Query($filterBy: [FilterBy]) {
    viewer {
      ...SparePartsForm_viewer
    }
  }
`;

const routes = (
  <Route
    path="/spareparts"
    getComponent={() => (
      import(/* webpackChunkName: "SparePartsForm" */'~/components/spareparts').then(module => module.SparePartsForm)
        .catch(() => {
          window.location.reload();
        })
    )}
    query={SparePartsFormQuery}
    prepareVariables={() => {
      const filterBy = [{
        field: "status",
        filter: "false,true",
        filterType: "boolean",
        type: "in",
      }];

      return { filterBy };
    }}
  />
);

export default routes;
