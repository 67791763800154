import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Row, Col } from 'antd';
import MediaQuery from 'react-responsive';
import GA4 from '~/ga4';

class CmsBanner extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cmsBanners: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      query: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  onClick = (b, index) => {
    GA4.selectPromotion(b, index);
  }

  isShowable = (banner, location) => {
    if (!banner.categoryBig && !banner.categorySmall) {
      return false;
    }

    if (banner.urlPaths && banner.urlPaths.length) {
      if (banner.urlPaths.find(path => {
        const regex = new RegExp(path);
        return location.pathname.match(regex);
      })) {
        return true;
      }
      return false;
    }

    return true;
  }

  render() {
    const { location, viewer } = this.props;
    const banners = get(viewer, 'cmsBanners.edges', []);

    return (
      <div>
        {banners.length > 0 && (
        <Row style={{ marginBottom: '10px' }}>
          {banners.map(({node: b}, index) => {
            if (this.isShowable(b, location)) {
              return (
                <Col key={b.id}>
                  <a href={b.link} onClick={() => { this.onClick(b, index); }}>
                    <MediaQuery minWidth={769}>
                      <img className="img-fluid " alt={b.title} style={{marginBottom: '5px'}} src={b.categoryBig} />
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                      <img className="img-fluid" alt={b.title} style={{marginBottom: '5px'}} src={b.categorySmall} />
                    </MediaQuery>
                  </a>
                </Col>
              );
            }
            return null;
          })}
        </Row>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(CmsBanner, {
  viewer: graphql`
    fragment CmsBanner_viewer on Customer {
      cmsBanners(first: 999) {
        edges {
          node {
            id
            title
            link
            urlPaths
            categoryBig
            categorySmall
          }
        }
      }
    }
  `,
});
