import LoginMutation from './LoginMutation';
import OauthLoginMutation from './OauthLoginMutation';
import LogoutMutation from './LogoutMutation';
import RegisterMutation from './RegisterMutation';
import RegisterCustomerMutation from './RegisterCustomerMutation';
import ResetPasswordMutation from './ResetPasswordMutation';

module.exports = {
  LoginMutation,
  OauthLoginMutation,
  LogoutMutation,
  RegisterMutation,
  RegisterCustomerMutation,
  ResetPasswordMutation
};
