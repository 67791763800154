import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { splitPrice } from './Price';

const renderDiscount = (product) => {
  const discount = get(product, 'discountCode');

  if (!discount) {
    return null;
  }

  let discountLabel = '';
  let actualPrice = null;

  if (discount.type === 1) {
    discountLabel = `${ discount.amount }% OFF`;
    actualPrice = product.price * (1 - discount.amount / 100);
  }
  else {
    discountLabel = `$${ discount.amount } OFF`;
    actualPrice = product.price - discount.amount;
  }

  const { code } = discount;

  return (
    <div style={{fontSize: '1em'}} className="coupon-code">
      <div
        style={{
          backgroundColor: 'green',
          padding: '10px',
          display: 'inline-block',
          color: 'white',
          textTransform: 'uppercase',
        }}
      >
        <span style={{fontWeight: 900}}>{discountLabel}</span> CODE: {code}
      </div>

      <div style={{color: 'green', lineHeight: '30px', fontWeight: 'bold'}}>
        <div>Coupon Price</div>
        {splitPrice(actualPrice)}
      </div>
    </div>
  );
};

const renderDiscountCode = (product, placeholder = true) => {
  const discount = get(product, 'discountCode');
  const dollars = get(product, 'loyaltyPoints.dollars', 0.0);
  const points = get(product, 'loyaltyPoints.accruing', 0.0);

  const styles = {
    lineHeight: '15px',
    textAlign: 'center',
    color: 'white',
    fontSize: '13px',
    textTransform: 'uppercase',
  };

  const wrapperStyles = {
    height: '60px',
  };

  if (!discount) {
    if (!placeholder) {
      wrapperStyles.height = 'auto';
    }

    return (
      <div style={{...wrapperStyles}}>
        <div style={{...styles}}>
          {dollars > 0.0 && (
            <div
              style={{
               backgroundColor: 'green',
               fontWeight: 'bold',
             }}
            >
              <span>Earn ${dollars.toFixed(2)} ({points} Points)</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  let discountLabel = '';
  let actualPrice = null;

  if (discount.type === 1) {
    discountLabel = `${ discount.amount }% OFF`;
    actualPrice = product.price * (1 - discount.amount / 100);
  }
  else {
    discountLabel = `$${ discount.amount } OFF`;
    actualPrice = product.price - discount.amount;
  }

  const code = <span>{discount.code}</span>;

  return (
    <div style={{...wrapperStyles, fontSize: '1em'}} >
      <div style={{color: 'green', fontWeight: 'bold'}}>
        You Pay ${actualPrice.toFixed(2)}
      </div>

      <div
        style={{
         backgroundColor: 'green',
         ...styles,
       }}
      >
        <b>
          <span style={{fontWeight: 900}}>{discountLabel}</span>  CODE: {code}
        </b>
      </div>
    </div>
  );
};

const parsePlacement = function parsePlacement(placement = "") {
  return placement.split("").map(p => {
    switch (p) {
      case 'L':
        return 'product-label-left';
      case 'R':
        return 'product-label-right';
      case 'T':
        return 'product-label-top';
      case 'B':
        return 'product-label-bottom';
      default:
        return "";
    }
  });
};

const getLabel = (labels, {noClass = false} = {}) => (
    labels
    .map(edge => {
      const label = edge.node;
      if (label && (label.imageUrl || label.productPageImageUrl)) {
        const url = label.productPageImageUrl || label.imageUrl;
        let classes = [];
        if (!noClass) {
          classes = parsePlacement(label.placement).concat(["product-label"]);
        }

        return (
          <div key={label.id} className={classes.join(" ")}>
            <img alt="Product Label" className={noClass ? "" : "img-fluid"} src={url} />
          </div>
        );
      }
      return null;
    })
);

const ProductLabel = (props) => {
  const { type, selectedProduct } = props;
  let { product } = props;

  if (selectedProduct) {
    product = {...selectedProduct};

    const productType = get(props, 'product.type');
    const isClearance = get(props, 'product.clearance');
    if (productType === 'configurable' && isClearance) {
      product.clearance = true;
    }
  }

  const labels = get(product, 'labels.edges', [])
                 .filter(({node}) => ![0, 1].includes(node.type) && (node.id !== 'TGFiZWw6MTMwNg==' || type !== 'thumbnail'));

  // specifically filter out TGFiZWw6MTMwNg==(Label:1306 On Sale label)
  // On Sale label will be placed in the same spot as Price Drop
  return (
    <div>
      {getLabel(labels)}

      <div className="product-label product-label-background product-label-top product-label-right">
        {get(product, 'loyaltyPoints.accruing') > 0 && (
        <img src="/assets/images/earn.png" alt="Earn Points" />
      )}
      </div>

      <div className="product-label product-label-background product-label-top product-label-left" />

      <div className="product-label-top product-label-left" style={{position: 'relative'}} />

      <div className="product-label product-label-bottom" style={{zIndex: 2}}>
        {product.clearance && (
        <div>
          {type === 'main' && (
          <img alt="Clearance" className="img-fluid" src="/static/images/clearance_large.png" />
        )}
          {type === 'thumbnail' && (
          <img alt="Clearance" className="img-fluid" src="/static/images/clearance_small.png" />
        )}
        </div>
      )}
      </div>
    </div>
  )
};
ProductLabel.propTypes = {
  product: PropTypes.shape({
    type: PropTypes.string,
    freeShipping: PropTypes.bool,
    clearance: PropTypes.bool,
    labels: PropTypes.shape({
      id: PropTypes.string,
      imageUrl: PropTypes.string,
      productPageImageUrl: PropTypes.string,
      placement: PropTypes.string,
    }),
  }).isRequired,
  type: PropTypes.string,
  selectedProduct: PropTypes.shape({
    id: PropTypes.string,
  }),
};

ProductLabel.defaultProps = {
  type: "main",
  selectedProduct: null,
};

const renderLoyaltyPoints = (product) => {
  const dollars = get(product.loyaltyPoints, 'dollars', 0.0);
  const points = get(product, 'loyaltyPoints.accruing', 0.0);

  if (dollars <= 0) {
    return null;
  }

  return (
    <div style={{fontSize: '1em'}} className="coupon-code">
      <div
        style={{
          backgroundColor: 'green',
          padding: '10px',
          display: 'inline-block',
          color: 'white',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}
      >
        Earn ${dollars.toFixed(2)} ({points} Points)
      </div>
    </div>
  );
};

module.exports = {
  getLabel,
  ProductLabel,
  renderDiscount,
  renderDiscountCode,
  renderLoyaltyPoints,
};
