import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

const { Group: ButtonGroup } = Button;

const DEFAULT_VIEW_MODE = JSON.stringify({
  wishlist: 'grid',
});

export const getViewMode = function getViewMode(key = null) {
  const viewMode = JSON.parse(localStorage.getItem('view_mode') || DEFAULT_VIEW_MODE);

  if (key) {
    return get(viewMode, key);
  }

  return viewMode;
}

export const setViewMode = function setViewMode(obj) {
  const viewMode = JSON.stringify({
    ...getViewMode(),
    ...obj,
  });

  localStorage.setItem('view_mode', viewMode);
}

const ViewMode = function ViewMode(props) {
  const { name, view, handleClick, label } = props;

  const handleOnClick = (type) => {
    handleClick(type, setViewMode({[name]: type}));
  }

  return (
    <div style={{ marginBottom: '0.5em' }}>
      {label ? (
        <span style={{ paddingRight: '10px' }}>View as:</span>
      ) : null}
      <ButtonGroup>
        <Button
          onClick={() => handleOnClick('grid')}
          disabled={view === 'grid'}
        >
          Grid <AppstoreOutlined />
        </Button>
        <Button
          onClick={() => handleOnClick('list')}
          disabled={view === 'list'}
        >
          List <BarsOutlined />
        </Button>
      </ButtonGroup>
    </div>
  )
}

ViewMode.propTypes = {
  name: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.bool,
}

ViewMode.defaultProps = {
  label: true,
}

export default ViewMode;
