import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation ChangeFreebiesMutation($input: ChangeFreebiesInput!) {
    changeFreebies(input:$input) {
      cart{
        ...CartFragment_cart @relay(mask: false)
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
