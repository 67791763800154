import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button } from 'antd';
import Cookies from 'universal-cookie';
import { LogoutMutation } from './mutations';

const cookies = new Cookies();

const styles = {
  background: 'none',
  border: 'none',
  height: 'auto',
  padding: '0',
  textAlign: 'left',
  width: '100%',
};

class Logout extends Component {
  static propTypes = {
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  logout = () => {
    LogoutMutation.commit({
      environment: this.props.relay.environment,
      viewer: this.props.viewer,
      onCompleted: () => {
        cookies.remove('auth_token', { path: '/' });
        cookies.remove('cart_id', { path: '/' });
        window.location.href = '/';
      },
    });
  }
  render() {
    const { viewer } = this.props;
    return (viewer.email && <Button style={styles} onClick={this.logout}>Logout</Button>) || null;
  }
}
export default createFragmentContainer(Logout, {
  viewer: graphql`
    fragment Logout_viewer on Customer {
      email
    }
  `,
});
