import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Col, Collapse, Progress, Row } from 'antd';
import { CreditIcon } from '~/components/svg';

import './style.css';

const { Panel } = Collapse;

const Credit = ({ offer, hiddenPanels, handleOnChange }) => {
  const { id, name, tierPrice, creditAmount, amountRequired, percent, isMaxTier } = offer;

  const defaultActiveKey = useMemo(() => {
    return get(hiddenPanels, 'data', []).includes(id) ? [] : [id];
  }, []);

  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      style={{ marginBottom: '10px' }}
      onChange={(key) => handleOnChange(id, isEmpty(key))}
    >
      <Panel key={id} className="offer-panel" header="Bonus Point Offer!">
        <Row gutter={[10, 10]} style={{ alignItems: 'center' }}>
          <Col xs={24} sm={16} md={24} lg={16}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: '5px' }}>
              <b style={{ fontSize: '14px', lineHeight: 1 }}>$0</b>
              <Progress className="offer-progress" percent={percent} strokeColor="#cb0000" strokeLinecap="square" showInfo={false} style={{ color: 'red' }} />
              <b style={{ fontSize: '14px', lineHeight: 1 }}>${tierPrice}</b>
            </div>
          </Col>

          <Col xs={24} sm={8} md={24} lg={8}>
            <div style={{ color: '#cb0000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <CreditIcon style={{ fontSize: '60px' }} />
              <span style={{ fontSize: '14px', fontWeight: '700', marginLeft: '5px', textWrap: 'nowrap' }}>
                ${creditAmount} Points
              </span>
            </div>
          </Col>
        </Row>

        <div>
          {isMaxTier ? (
            <span>You will receive <b>${creditAmount}</b> in Bonus Points as part of the <b>{name}</b>.</span>
          ) : (
            <span>You&apos;re <b>${amountRequired}</b> away from receiving <b>${creditAmount}</b> in Bonus Points as part of the <b>{name}</b>.</span>
          )}
          <span style={{ display: 'block', color: '#cb0000' }}>
            Points will be allocated in store for Click & Collect orders.
          </span>
        </div>
      </Panel>
    </Collapse>
  )
};

Credit.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tierPrice: PropTypes.number,
    creditAmount: PropTypes.number,
    amountRequired: PropTypes.number,
    percent: PropTypes.number,
    isMaxTier: PropTypes.bool,
  }).isRequired,
  hiddenPanels: PropTypes.shape({}).isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default Credit;
