/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountForm_viewer$ref = any;
type Logout_viewer$ref = any;
type SideNav_viewer$ref = any;
export type routes_AccountForm_QueryVariables = {||};
export type routes_AccountForm_QueryResponse = {|
  +viewer: ?{|
    +email: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +isPasswordSecure: ?boolean,
    +subscription: ?{|
      +status: ?number
    |},
    +$fragmentRefs: AccountForm_viewer$ref & SideNav_viewer$ref & Logout_viewer$ref,
  |}
|};
export type routes_AccountForm_Query = {|
  variables: routes_AccountForm_QueryVariables,
  response: routes_AccountForm_QueryResponse,
|};
*/


/*
query routes_AccountForm_Query {
  viewer {
    ...AccountForm_viewer
    email
    firstname
    lastname
    isPasswordSecure
    subscription {
      status
    }
    ...SideNav_viewer
    ...Logout_viewer
    id
  }
}

fragment AccountForm_viewer on Customer {
  email
  firstname
  lastname
  isPasswordSecure
  subscription {
    status
  }
}

fragment Logout_viewer on Customer {
  email
}

fragment SideNav_viewer on Customer {
  email
  ...Logout_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPasswordSecure",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AccountForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountForm_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SideNav_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Logout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_AccountForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88443f5ac823b5acfb6283fb0b5a10c4",
    "id": null,
    "metadata": {},
    "name": "routes_AccountForm_Query",
    "operationKind": "query",
    "text": "query routes_AccountForm_Query {\n  viewer {\n    ...AccountForm_viewer\n    email\n    firstname\n    lastname\n    isPasswordSecure\n    subscription {\n      status\n    }\n    ...SideNav_viewer\n    ...Logout_viewer\n    id\n  }\n}\n\nfragment AccountForm_viewer on Customer {\n  email\n  firstname\n  lastname\n  isPasswordSecure\n  subscription {\n    status\n  }\n}\n\nfragment Logout_viewer on Customer {\n  email\n}\n\nfragment SideNav_viewer on Customer {\n  email\n  ...Logout_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd173c3057ca1fd889f65ee5cccd889dd';

module.exports = node;
