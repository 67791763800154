import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveAddressMutation($input: RemoveAddressInput!) {
    removeAddress(input:$input) {
      removedAddressId
    }
  }
`;

function sharedUpdater(store, user, deletedID) {
  const userProxy = store.get(user.id);
  const conn = ConnectionHandler.getConnection(
    userProxy,
    'AddressBook_addresses',
  );

  ConnectionHandler.deleteNode(
    conn,
    deletedID,
  );
}
const commit = buildMutation(mutation, {
  updater: ({ viewer }, store) => {
    const payload = store.getRootField('removeAddress');
    sharedUpdater(store, viewer, payload.getValue('removedAddressId'));
  },
});

export default { commit };
