import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { get, startCase } from 'lodash';
import { Button, Col, Row } from 'antd';
import { Link } from 'found';
import moment from 'moment';
import { getProductUrl, getThumbnail } from '~/helper';

import { AddressForm } from './AddressForm';
import { UpdateSalesAddressMutation } from './mutations';
import Tracking from './order/Tracking';
import Status from './order/Status';
import OrderStatusButton from './OrderStatusButton';
import OrderLine from './OrderLine';
import OrderTotals from './OrderTotals';
import { getDelayMsg } from './OrderHistory';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

class Order extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.now = moment();

    this.state = {
      addressFormVisible: false,
    };
  }

  updateAddress = (address) => {
    this.setState({ address });
    this.showAddressFormModal();
  }

  showAddressFormModal = () => {
    this.setState({
      addressFormVisible: true,
    });
  }

  hideAddressFormModal = () => {
    this.setState({
      addressFormVisible: false,
    });
  }

  renderDiscounts = (discounts) => {
    if (discounts.length > 0) {
      return discounts.map(d => (
        <div key={d.name}>
          <b>Discount:</b> {d.name} {d.amount && <span>-${d.amount}</span>}
        </div>
      ))
    }

    return null;
  }

  renderAddress = (address) => {
    if (address) {
      address = address.node;

      return (
        <div>
          <h3>{startCase(address.addressType)} Address {address.editable && <Button type="primary" onClick={()=>this.updateAddress(address)}>Edit</Button>}</h3>
          <div style={{fontSize: '15px'}}>
            <div>{address.firstname} {address.lastname}</div>
            <div>{address.company}</div>
            <div>{address.telephone}</div>
            <div>{address.fax}</div>
            <div>
              {address.street}
            </div>
            <div>
              {address.city} {address.region} {address.postcode}
            </div>
            <div>
              {address.country.name}
            </div>
          </div>
          <br />
        </div>
      );
    }
    return null;
  }

  // render Image using thumbnail url
  renderImg = line => {
    const thumbnail = getThumbnail(line);

    const style = {width: '100%', maxWidth: '100px'};
    return (
      thumbnail ?
        <img style={style} alt={line.name} src={thumbnail} />
          : <img style={style} alt="Place Holder" src={IMAGE_PLACEHOLDER} />
    );
  }

  renderLine = (line, {isChild = false} = {}) => {
    const url = getProductUrl(line);

    const status = get(line, 'product.status', 'disabled');
    const finalPrice = line.unitPrice - line.unitDiscount + line.unitSurcharge;

    return (
      <div key={line.id}>
        <Row style={{marginLeft: isChild ? '10%' : '0'}}>
          <Col xs={7} sm={3} md={2}>{this.renderImg(line)}</Col>
          <Col xs={17} sm={21} md={22} style={{paddingLeft: '10px', fontSize: '14px', fontWeight: isChild ? 'normal' : 'bold'}}>
            { status === 'enabled' ?
              <Link to={url} className="black-href" style={{ textDecoration: 'underline' }}>{line.name}</Link>
              : <span>{line.name}</span>
            }
            <div style={{marginTop: '5px'}}>
              <span className="price">{line.quantity} x ${(finalPrice).toFixed(2)}</span>
              <Tracking line={line} />
            </div>
          </Col>
        </Row>

        {line.children && line.children.edges.length > 0 && (
          <Row>
            <Col>
              <Row>
                {line.children.edges.map(edge => this.renderLine(edge.node, {isChild: true}))}
              </Row>
            </Col>
          </Row>
         )}
      </div>
    );
  }

  renderOrder = (order, {stores} = {}) => {
    const lines = get(order, 'lines.edges', []);
    const addresses = get(order, 'addresses.edges', []);

    const billingAddress = addresses.find(addr => addr.node.addressType === 'billing');
    const shippingAddress = addresses.find(addr => addr.node.addressType === 'shipping');

    return (
      <div>
        <Row style={{padding: '10px'}}>
          <Col xs={24}>
            <h1 style={{display: 'inline'}}>Order #{order.name}</h1>
            <OrderStatusButton order={order} />
            <div>
              Order Date: {order.insertedAt && (
                  moment(order.insertedAt).format('DD/MM/YYYY')
                )}
            </div>
          </Col>

          <Col xs={24}>
            <div>Payment By: {order.paymentDescription}</div>
          </Col>

          <Col xs={24}>
            <Status order={order} stores={stores} />
          </Col>

        </Row>
        {getDelayMsg(order)}

        <Row style={{padding: '10px'}}>
          <Col xs={24} sm={12}>
            {this.renderAddress(billingAddress)}
          </Col>

          <Col xs={24} sm={12}>
            {this.renderAddress(shippingAddress)}
          </Col>
        </Row>

        <Row style={{padding: '10px'}}>
          <Col>
            {lines.map((e) => {
              const line = e.node;
              return (
                <OrderLine key={line.id} line={line} />
              )
            })}
          </Col>

          <Col style={{fontWeight: 'bold'}} xs={24}>
            <hr />
            <OrderTotals order={order} />
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { viewer } = this.props;
    const order = get(viewer, 'salesOrders.edges[0].node', {});
    const stores = get(viewer, 'stores');

    return (
      <div>
        <Helmet title={`Order - ${order.name}`} />

        <AddressForm
          viewer={viewer}
          address={this.state.address}
          relay={this.props.relay}
          addressFormVisible={this.state.addressFormVisible}
          onCancel={this.hideAddressFormModal}
          updateMutation={UpdateSalesAddressMutation}
        />
        {this.renderOrder(order, {stores})}
      </div>
    )
  }
}

export default createFragmentContainer(Order, {
  viewer: graphql`
    fragment Order_viewer on Customer {
      email
      stores(first: 999) {
        edges {
          node {
            id
            name
            phone
          }
        }
      }
      salesOrders(first: 1, orderNames: $orderNames) {
        edges {
          node {
            id
            name
            shippingTotal
            verified
            verificationLink
            shippingDescription
            paymentDescription
            subtotal
            grandTotal
            insertedAt
            surcharge
            surcharges {
              name
              amount
            }
            discount
            discounts {
              id
              name
              code
              amount
            }
            shipments
            shippingStatus
            status
            addresses(first: 2) {
              edges {
                node {
                  id
                  addressType
                  firstname
                  lastname
                  street
                  city
                  postcode
                  region
                  country {
                    name
                  }
                  telephone
                  company
                  fax
                  editable
                }
              }
            }
            lines(first: 99) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  unitDiscount
                  unitSurcharge
                  rowTotal
                  configParent {
                    id
                    urlSlug
                    mainImage {
                      id
                      thumbnail
                    }
                  }
                  priorityShipping {
                    getByDate
                  }
                  product {
                    urlSlug
                    status
                    mainImage {
                      id
                      url
                      thumbnail
                    }
                  }
                  shipments {
                    tracking
                    location
                    status
                    timestamp
                  }
                  children(first: 99) {
                    edges {
                      node {
                        id
                        name
                        quantity
                        unitPrice
                        unitDiscount
                        unitSurcharge
                        rowTotal
                        product {
                          urlSlug
                          status
                          mainImage {
                            id
                            url
                            thumbnail
                          }
                        }
                        shipments {
                          tracking
                          location
                          status
                          timestamp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
});
