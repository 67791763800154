import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { Form } from '@ant-design/compatible';
import { get, groupBy } from 'lodash';
import { RightOutlined } from '@ant-design/icons';
import { Button, Collapse, Modal, Select, message } from 'antd';
import GA4 from '~/ga4';

import HoursInfo from './HoursInfo';
import StockInfo from './StockInfo';
import StoreSelector from './StoreSelector';
import { Map } from '../store/Map';
import { MyStoreBtn, getMyStoreId } from '../store/MyStoreBtn';
import { GOOGLE_MAP_URL } from '../store/StoreList';
import { getMarker, getStoreLoc } from '../store/Store';

const { Panel } = Collapse;
const { Item: FormItem } = Form;
const { Option, OptGroup } = Select;

const getDefaultStore = (stores, myStoreId) => {
  if (stores && stores.length > 0) {
    const { node: store } = stores.find(({ node }) => node.id === myStoreId) || {};

    if (store) {
      return store;
    }
  }

  return null;
};

const getStore = (stores, checkoutSnapshot) => {
  const shippingMethod = get(checkoutSnapshot, 'shippingMethod', "");
  const pickupStoreId = get(checkoutSnapshot, 'pickupStoreId', "");

  const { node: store } = stores.find(({ node }) => node.id === pickupStoreId) || {};

  if (shippingMethod === "storepickup" && store) {
    return store;
  }

  return null;
};

const useFnHook = (form, lines, setState) => {
  const displayMessage = (store) => {
    const shippingMethodValue = form.getFieldValue('shippingMethod');

    if (shippingMethodValue === 'storepickup') {
      let msg = '';

      const excludeBulkyGood = get(store, 'excludeBulkyGood', false);
      const bulkyGoods = lines
        .filter(({ node }) => node.product.bulkyGood)
        .map(({ node }) => node.product.model).join(", ");
      const hasBulkyGoods = excludeBulkyGood && !!bulkyGoods;

      if (hasBulkyGoods) {
        msg = `Click & Collect is not available for ${bulkyGoods} at ${store.name} store.`;
      }

      const limitedStock = lines
        .filter(({ node }) => node.product.limitedStock)
        .map(({ node }) => node.product.model).join(", ");

      if (limitedStock) {
        msg = `Click & Collect is not available for ${limitedStock}`;
      }

      if (hasBulkyGoods || limitedStock) {
        message.error(
          <div style={{ display: 'inherit' }}>
            {msg}
          </div>
        );

        setState((s) => ({ ...s, storeId: null }));
      }
    }
  };

  return { displayMessage };
};

const StorePickup = (props) => {
  const { viewer, relay, form } = props;
  const { getFieldDecorator } = form;

  const checkoutSnapshot = get(viewer, 'cart.checkoutSnapshot');
  const lines = get(viewer, 'cart.lines.edges', []);
  const stores = get(viewer, 'cart.stores.edges', []);

  const [state, setState] = useState(() => {
    const myStoreId = getMyStoreId(props);
    const store = getStore(stores, checkoutSnapshot);
    const defaultStore = getDefaultStore(stores, myStoreId);

    const storeId = store?.id || defaultStore?.id

    return {
      myStoreId,
      storeId,
      showMap: false,
      collapseKey: null,
    }
  });

  const { displayMessage } = useFnHook(form, lines, setState);

  useEffect(() => {
    const cart = get(viewer, 'cart', {});
    GA4.addShippingInfo(cart, "storepickup");
  }, []);

  useEffect(() => {
    const storeId = form.getFieldValue("pickupStoreId");
    const { node: store } = stores.find(({ node }) => node.id === storeId) || {};

    displayMessage(store);
  }, [lines]);

  useEffect(() => {
    if (!state.storeId) {
      form.setFieldsValue({ pickupStoreId: undefined });
    }
  }, [state]);

  const setMyStoreId = (myStoreId) => {
    setState((s) => ({ ...s, myStoreId }))
  };

  const updateStore = (_stores, storeId) => {
    const store = _stores.find(({ node }) => node.id === storeId);

    setState((s) => ({ ...s, storeId, store }));
    form.setFieldsValue({ pickupStoreId: storeId });
  };

  const pickUpSelect = () => {
    const states = groupBy(stores, 'node.state');
    const hasBulkyGoods = lines.filter(({ node }) => node.product.bulkyGood);

    return Object.keys(states).sort().map((key) => (
      <OptGroup key={key} label={key}>
        {states[key].map(({ node }) => {
          const canPickup = !(node.excludeBulkyGood && hasBulkyGoods.length || !node.canPickup);

          return (
            <Option key={node.id} value={node.id}>
              {node.name}
              {canPickup ? (
                <StockInfo
                  store={node}
                  style={{
                    display: 'inline-block',
                    fontSize: '12px',
                    fontWeight: '700',
                    color: 'white',
                    padding: '0px 5px',
                    marginRight: '10px',
                    float: 'right',
                  }}
                />
              ) : (
                <span style={{ color: '#cb0000', fontSize: '12px', marginLeft: '10px', float: 'right' }}>
                  Click & Collect Unavailable
                </span>
              )}
            </Option>
          )
        })}
      </OptGroup>
    ));
  };

  const hideMap = () => setState((s) => ({ ...s, showMap: false }));

  const renderMap = (store) => {
    if (!store) {
      return null;
    }

    return (
      <Modal
        footer={null}
        onOk={hideMap}
        onCancel={hideMap}
        visible={state.showMap}
      >
        <Map
          center={getStoreLoc(store)}
          markers={getMarker(store)}
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          zoom={15}
        />
      </Modal>
    )
  };

  const hasOnlineOnly = lines.some(({ node }) => node.product.onlineOnly);
  const { node: store } = stores.find(({ node }) => node.id === state.storeId) || {};
  const { collapseKey } = state;
  const btnStyle = { padding: '0px', fontSize: '14px', lineHeight: '20px', whiteSpace: 'pre-wrap', height: 'auto' };

  return (
    <div style={{ lineHeight: '20px' }}>
      {getFieldDecorator('shippingMethod', {
        rules: [{ required: true, message: 'Required' }],
        initialValue: "storepickup"
      })(<div />)}

      <p><b>You will be contacted when the order is ready for pickup.</b></p>

      {hasOnlineOnly && (
        <p style={{ color: '#cb0000' }}>Please note: Some products are not available for Click & Collect</p>
      )}

      <StoreSelector viewer={viewer} updateStore={updateStore} />

      <FormItem style={{ margin: '0px' }}>
        {getFieldDecorator('pickupStoreId', {
          initialValue: store ? store.id : null,
          rules: [
            { required: true, message: 'Required' },
            {
              validator: (rule, value, callback) => {
                const { node: selectedStore } = stores.find(({ node }) => node.id === value) || {};
                displayMessage(selectedStore);

                callback();
              }
            },
          ],
        })(
          <Select
            className="pickup-stores"
            showSearch
            placeholder="Select a Store"
            optionFilterProp="children"
            onChange={(storeId) => {
              const { node: selectedStore } = stores.find(({ node }) => node.id === storeId) || {};
              setState((s) => ({ ...s, storeId, store: selectedStore }));
            }}
            style={{ marginBottom: '10px' }}
          >
            {pickUpSelect()}
          </Select>
        )}
      </FormItem>

      {state.storeId && (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, whiteSpace: 'nowrap', fontSize: '16px' }}>
              <div>
                {store.address} <br />
                {store.city} {store.postcode} {store.state} <br />
              </div>
              {store.phone && (
                <>
                  <b>Phone</b>: <a href={`tel:${store.phone}`}>{store.phone}</a>
                </>
              )}
              {store.fax && (<><b>Fax</b>: <a href={`fax:${store.fax}`}>{store.fax}</a></>)}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
              <Button
                type="link"
                style={btnStyle}
                onClick={() => setState((s) => ({ ...s, showMap: true }))}
              >
                Show In Map
              </Button>

              <MyStoreBtn
                viewer={viewer}
                relay={relay}
                store={store}
                style={btnStyle}
                myStoreId={state.myStoreId}
                setMyStoreId={setMyStoreId}
              />
            </div>
          </div>

          <Button
            type="link"
            style={{ paddingLeft: '0px' }}
            onClick={() => {
              if (collapseKey) {
                setState((s) => ({ ...s, collapseKey: null }));
              } else {
                setState((s) => ({ ...s, collapseKey: "store-details" }));
              }
            }}
          >
            <RightOutlined
              style={{
                fontSize: '12px',
                transition: 'all 0.3s, visibility 0s',
                transform: collapseKey ? 'rotate(90deg)' : null,
              }}
            />
            View Business Hours
          </Button>

          <Collapse
            activeKey={collapseKey}
            accordion
            bordered={false}
          >
            <Panel className="panel-no-header" showArrow={false} header={null} key="store-details" style={{ color: "red" }}>
              <HoursInfo store={store} style={{ fontSize: '14px' }} />
            </Panel>
          </Collapse>
        </>
      )}

      {renderMap(store)}
    </div>
  )
};

StorePickup.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  relay: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired
};

export default createFragmentContainer(StorePickup, {
  viewer: graphql`
    fragment StorePickup_viewer on Customer {
      ...StoreSelector_viewer
      id
      email
      myStore {
        id
      }
      cart {
        grandTotal
        checkoutSnapshot
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              unitPrice
              quantity
              product {
                sku
                name
                model
                onlineOnly
                bulkyGood
                stockAvailable
                limitedStock
                brand {
                  id
                  name
                }
              }
            }
          }
        }
        stores(first: 999) {
          edges {
            node {
              id
              name
              address
              city
              postcode
              region
              state
              description
              phone
              lat
              lng
              canPickup
              excludeBulkyGood
              stocks {
                productId
                stock
              }
              hours {
                monday {
                  open
                  close
                }
                tuesday {
                  open
                  close
                }
                wednesday {
                  open
                  close
                }
                thursday {
                  open
                  close
                }
                friday {
                  open
                  close
                }
                saturday {
                  open
                  close
                }
                sunday {
                  open
                  close
                }
              }
            }
          }
        }
      }
    }
  `,
});
