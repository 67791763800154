import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { get } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';

export default class Total extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cart: PropTypes.shape({
        subtotal: PropTypes.number,
        grandTotal: PropTypes.number,
        shippingCost: PropTypes.number,
        discount: PropTypes.number,
        lines: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
        discounts: PropTypes.arrayOf(PropTypes.object),
        surcharges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    removeDiscount: PropTypes.func.isRequired,
  }

  calculateLineDiscount = (lines) => lines.reduce((t, {node}) => t + (node.quantity * node.unitDiscount), 0)

  renderDiscounts = (discounts) => {
    if (discounts.length > 0) {
      return discounts.map(d => {
        const key = d.id || d.name;

        return [
          <Col key={`${key}Discount`} span={8} style={{textAlign: 'right', paddingRight: '10px', top: '4px'}}>
            <b>Discount:</b>
          </Col>,
          <Col key={`${key}DiscountPrice`} span={16} style={{textAlign: 'left'}}>
            {d.name} {d.amount && <span className="price">${d.amount.toFixed(2)}</span>}
            {d.removable && (
              <Button
                onClick={() => { this.props.removeDiscount(d); }}
                style={{border: "none"}}
                title="Remove Discount"
                icon={<CloseCircleOutlined />}
                small="small"
                shape="circle"
              />
            )}
          </Col>
        ]
      });
    }

    return null;
  }

  renderTotal = (cart, shippingCost, lineDiscount, discounts, surcharges) => (
    <Row>
      {cart && [
        <Col key="subtotal" span={8} style={{textAlign: 'right', paddingRight: '10px'}}>
          <b>Subtotal:</b>
        </Col>,
        <Col key="subtotalPrice" span={16}>
          <span className="price">${cart.subtotal.toFixed(2)}</span>
        </Col>
      ]}

      {shippingCost != null && [
        <Col key="shipping" span={8} style={{textAlign: 'right', paddingRight: '10px'}}>
          <b>Shipping:</b>
        </Col>,
        <Col key="shippingPrice" span={16}>
          <span className="price">${shippingCost.toFixed(2)}</span>
        </Col>
      ]}

      {(lineDiscount > 0) && [
        <Col key="discount" span={8} style={{textAlign: 'right', paddingRight: '10px'}}>
          <b>Saved:</b>
        </Col>,
        <Col key="discountPrice" span={16}>
          <span className="discount price">${lineDiscount.toFixed(2)}</span>
        </Col>
      ]}

      {this.renderDiscounts(discounts)}

      {(surcharges.length > 0) &&
        surcharges.map(d => [
          <Col key={`${d.name}Surcharge`} span={8} style={{textAlign: 'right', paddingRight: '10px'}}>
            <b>Surcharge:</b>
          </Col>,
          <Col key={`${d.name}SurchargePrice`} span={16}>
            <span className="price">${d.amount.toFixed(2)}</span>
            {d.name ? <div style={{display: "inline", fontSize: '14px'}}> ({d.name})</div> : null}
          </Col>
        ]
      )}

      {cart && [
        <Col key="grandTotalHr" span={24}>
          <hr />
        </Col>,
        <Col key="grandTotal" span={8} style={{textAlign: 'right', paddingRight: '10px'}}>
          <b>Grand Total:</b>
        </Col>,
        <Col key="grandTotalPrice" span={16}>
          <span className="price">${cart.grandTotal.toFixed(2)}</span>
        </Col>
      ]}
    </Row>
  )

  render() {
    const { viewer } = this.props;
    const cart = get(viewer, 'cart', {});
    const lines = get(cart, 'lines.edges', []);
    const discounts = get(cart, 'discounts', []);
    const surcharges = get(cart, 'surcharges', []);
    const shippingCost = get(cart, 'shippingCost', null);
    const lineDiscount = this.calculateLineDiscount(lines);

    return this.renderTotal(cart, shippingCost, lineDiscount, discounts, surcharges);
  }
}
