import { graphql } from 'react-relay';
import { buildMutation, edgeUpdater } from '../../../mutation';

const mutation = graphql`
  mutation PostReviewMutation($input: PostReviewInput!) {
    postReview(input:$input) {
      reviewEdge {
        __typename
        cursor
        node {
          id
          content
          replies(first: 100) @connection(key: "ReviewList_replies")  {
            edges {
              node {
                id
                content
                customer {
                  firstname
                }
                insertedAt
              }
            }
          }
          ratings(first: 10) {
            edges {
              node {
                id
                star
                percentage
                ratingType {
                  code
                }
              }
            }
          }
          product {
            name
            urlSlug
            mainImage {
              id
              url
            }
          }
          customer {
            firstname
          }
          insertedAt
          status
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'postReview', edgeName: 'reviewEdge', connectionKey: 'ReviewList_reviews', insertPosition: 'before' }),
});

export default { commit };
