import React from 'react';
import { Route } from 'found';
import { get } from 'lodash';
import { graphql } from 'react-relay';
import { base64encode } from '~/helper';
import { getOrderBy, PRODUCT_PER_PAGE } from '~/components/product/SearchView';

const CatalogueQuery = graphql`
  query routes_Catalogue_Query(
    $categoryUrlSlug: String!,
    $subcategoryUrlSlug: String,
    $subcategoryUrlSlug2: String,
    $brandUrlSlug: String,
    $count: Int!,
    $cursor: String,
    $filters: String,
    $orderBy: OrderBy,
    $page: Int
    $priorityPage: Boolean = false
  ) {
    viewer {
      ...Catalogue_viewer @arguments(
        categoryUrlSlug: $categoryUrlSlug,
        subcategoryUrlSlug: $subcategoryUrlSlug,
        subcategoryUrlSlug2: $subcategoryUrlSlug2,
        brandUrlSlug: $brandUrlSlug,
        count: $count,
        cursor: $cursor,
        filters: $filters,
        orderBy: $orderBy,
        page: $page
        priorityPage: $priorityPage
      )
    }
  }
`;

const BrandListQuery = graphql`
  query routes_BrandList_Query($categoryUrlSlug: String!) {
    viewer {
      ...BrandList_viewer
    }
  }
`;

const routes = (
  <Route
    path="/category"
  >
    <Route
      path="/by-brand"
      getComponent={() => (
        import(/* webpackChunkName: "BrandList" */'~/components/category/BrandList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={BrandListQuery}
      prepareVariables={params => ({ categoryUrlSlug: 'by-brand', subcategoryUrlSlug: params.subcategoryUrlSlug })}
    />

    <Route
      path="/by-brand/:brandUrlSlug"
      getComponent={() => (
        import(/* webpackChunkName: "SubcategoryList" */'~/components/category/Catalogue').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={CatalogueQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          return <Component {...props} />;
        }
        return undefined;
      }}
      prepareVariables={(params, {location: {query}}) => ({
        count: 9999,
        categoryUrlSlug: 'by-brand',
        brandUrlSlug: params.brandUrlSlug,
        filters: query.filters,
      })}
    />

    <Route
      path="/by-brand/:brandUrlSlug/:subcategoryUrlSlug/:subcategoryUrlSlug2?"
      getComponent={() => (
        import(/* webpackChunkName: "ProductList" */'~/components/category/Catalogue').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={CatalogueQuery}
      prepareVariables={(params, props) => {
        const page = parseInt(get(props.location.query, 'p', 1), 10);
        const count = PRODUCT_PER_PAGE;
        const after = base64encode(`arrayconnection:${count - 1}`);

        const orderBy  = getOrderBy(props.location)
        let { filters } = props.location.query;

        if (!filters) {
          filters = JSON.stringify(get(props.location, 'filters', {}));
        }

        return {
          count,
          after,
          page,
          categoryUrlSlug: 'by-brand',
          brandUrlSlug: params.brandUrlSlug,
          subcategoryUrlSlug: params.subcategoryUrlSlug,
          subcategoryUrlSlug2: params.subcategoryUrlSlug2,
          filters,
          orderBy,
        };
      }}
    />

    <Route
      path="/:categoryUrlSlug"
      getComponent={() => (
        import(/* webpackChunkName: "Catalogue" */'~/components/category/Catalogue').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={CatalogueQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          return <Component {...props} />;
        }
        return undefined;
      }}
      prepareVariables={(params, props) => {
        const page = parseInt(get(props.location.query, 'p', 1), 10);
        const count = PRODUCT_PER_PAGE;
        const after = base64encode(`arrayconnection:${count - 1}`);

        const orderBy  = getOrderBy(props.location)
        let { filters } = props.location.query;

        if (!filters) {
          filters = JSON.stringify(get(props.location, 'filters', {}));
        }

        return {
          count,
          after,
          page,
          categoryUrlSlug: params.categoryUrlSlug,
          filters,
          orderBy,
          priorityPage: params.categoryUrlSlug === 'priority'
        };
      }}
    />

    <Route
      path="/:categoryUrlSlug/:subcategoryUrlSlug/:subcategoryUrlSlug2?"
      getComponent={() => (
        import(/* webpackChunkName: "ProductList" */'~/components/category/Catalogue').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={CatalogueQuery}
      prepareVariables={(params, props) => {
        const page = parseInt(get(props.location.query, 'p', 1), 10);
        const count = PRODUCT_PER_PAGE;
        const after = base64encode(`arrayconnection:${count - 1}`);

        const orderBy  = getOrderBy(props.location)

        let { filters } = props.location.query;

        if (!filters) {
          filters = JSON.stringify(get(props.location, 'filters', {}));
        }

        return {
          count,
          after,
          page,
          categoryUrlSlug: params.categoryUrlSlug,
          subcategoryUrlSlug: params.subcategoryUrlSlug,
          subcategoryUrlSlug2: params.subcategoryUrlSlug2,
          filters,
          orderBy,
        };
      }}
    />
  </Route>
);

export default routes;
