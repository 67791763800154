import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import CmsBanner from '../cms/CmsBanner';

class CmsPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
      }).isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    }).isRequired,
  }

  render() {
    const { viewer, viewer: { cmsPage: page }, match: { location } } = this.props;

    if (page === null) {
      return null;
    }

    return (
      <div>
        <Helmet title={page.title} >
          {page.metaDescription && (
          <meta name="description" content={page.metaDescription} />
          )}
        </Helmet>
        <h1>{page.title}</h1>
        <CmsBanner viewer={viewer} location={location} />
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
      </div>
    );
  }
}

export default createFragmentContainer(CmsPage, {
  viewer: graphql`
    fragment CmsPage_viewer on Customer {
      ...CmsBanner_viewer
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
        metaDescription
      }
    }
  `,
});
