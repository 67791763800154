import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Link } from 'found';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

import { clearSession } from './Pos';

class PosSuccessView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesPosOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    // clear session for the checkout and ready for the next one
    clearSession();
  }

  render() {
    const { edges } = this.props.viewer.salesPosOrders;

    const order = edges.length > 0 ? edges[0].node : null;
    return (
      <div>
        <h1>Checkout Completed</h1>
        <div style={{textAlign: 'center', fontWeight: 'bold'}}>
          <CheckCircleOutlined style={{fontSize: '95px', color: 'green'}} />

          <p style={{fontSize: '24px'}}>PAYMENT IS SUCCESSFUL</p>

          {order && (
            <p>
              The order number is: <Link to={`/pos/order/${order.id}`} title="View Order">#{order.name}</Link><br />
              A confirmation email has been sent to the customer.
            </p>
          )}

          <Row>
            <Col xs={24} style={{marginBottom: '20px'}}>
              <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
                <Link to={`/pos/order/${order.id}`}>
                  Print Confirmation
                </Link>
              </Button>
            </Col>

            <Col xs={24} style={{marginBottom: '20px'}}>
              <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
                <Link to="/pos">Restart</Link>
              </Button>
            </Col>
            <Col xs={24} style={{marginBottom: '20px'}}>
              <Button type="primary" style={{width: '250px', fontWeight: 'bold'}}>
                <Link to="/pos/list">View All Orders</Link>
              </Button>
            </Col>
          </Row>

        </div>
      </div>
    );
  }
}

export default createFragmentContainer(PosSuccessView, {
  viewer: graphql`
    fragment PosSuccessView_viewer on Customer {
      salesPosOrders(first: 1, ids: $ids, storeIds: $storeIds) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
