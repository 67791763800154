import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Input } from 'antd';
import 'antd/lib/alert/style';
import { confirmPassword, formItemLayout } from '~/components/form';

const FormItem = Form.Item;

export default class PasswordForm extends React.Component {
  static propTypes = {
    formRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({}),
    ]).isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  render() {
    const { formRef } = this.props;

    return (
      <Form ref={formRef} onFinish={this.props.onSubmit}>
        <FormItem
          {...formItemLayout}
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: 'required' },
            (form) => ({
              validator: (rule, value) => confirmPassword("confirmNewPassword", {form, rule, value})
            }),
          ]}
        >
          <Input.Password placeholder="New Password" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Confirm New Password"
          name="confirmNewPassword"
          rules={[
            { required: true, message: 'required' },
            (form) => ({
              validator: (rule, value) => confirmPassword("newPassword", {form, rule, value})
            }),
          ]}
        >
          <Input.Password type="password" placeholder="Confirm New Password" />
        </FormItem>

        <FormItem
          wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 24 },
          lg: { offset: 10, span: 14 },
        }}
        >
          <Button type="primary" htmlType="submit">Change Password</Button>
        </FormItem>
      </Form>
    );
  }
}
