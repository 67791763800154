import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation TyroAuthenticatePayerMutation($input: TyroAuthenticatePayerInput!) {
    tyroAuthenticatePayer(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
