/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompleteRedirectOrderInput = {|
  hummQuery?: ?string,
  latitudeFinancialQuery?: ?string,
  latitudePayQuery?: ?string,
  orderName?: ?string,
  paymentMethod: string,
  zipCheckoutId?: ?string,
  zipCheckoutState?: ?string,
|};
export type CompleteRedirectOrderMutationVariables = {|
  input: CompleteRedirectOrderInput
|};
export type CompleteRedirectOrderMutationResponse = {|
  +createOrder: ?{|
    +cartId: ?string,
    +token: ?string,
    +salesOrder: ?{|
      +id: string,
      +encryptedId: ?string,
      +name: ?string,
      +verified: ?number,
      +grandTotal: ?number,
      +shippingTotal: ?number,
      +paymentMethod: ?string,
      +status: ?number,
      +lines: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +quantity: ?number,
            +unitPrice: ?number,
            +rowTotal: ?number,
            +product: ?{|
              +id: string,
              +sku: ?string,
              +name: ?string,
              +brand: ?{|
                +id: string,
                +name: ?string,
              |},
            |},
            +children: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                  +quantity: ?number,
                  +unitPrice: ?number,
                  +product: ?{|
                    +id: string,
                    +sku: ?string,
                  |},
                |}
              |}>
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type CompleteRedirectOrderMutation = {|
  variables: CompleteRedirectOrderMutationVariables,
  response: CompleteRedirectOrderMutationResponse,
|};
*/


/*
mutation CompleteRedirectOrderMutation(
  $input: CompleteRedirectOrderInput!
) {
  createOrder: completeRedirectOrder(input: $input) {
    cartId
    token
    salesOrder {
      id
      encryptedId
      name
      verified
      grandTotal
      shippingTotal
      paymentMethod
      status
      lines(first: 99) {
        edges {
          node {
            id
            name
            quantity
            unitPrice
            rowTotal
            product {
              id
              sku
              name
              brand {
                id
                name
              }
            }
            children(first: 99) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  product {
                    id
                    sku
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v7 = [
  {
    "alias": "createOrder",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompleteRedirectOrderPayload",
    "kind": "LinkedField",
    "name": "completeRedirectOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cartId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SalesOrder",
        "kind": "LinkedField",
        "name": "salesOrder",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "encryptedId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grandTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shippingTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "SalesOrderLineConnection",
            "kind": "LinkedField",
            "name": "lines",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrderLineEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderLine",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowTotal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Brand",
                            "kind": "LinkedField",
                            "name": "brand",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v3/*: any*/),
                        "concreteType": "SalesOrderLineConnection",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderLineEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderLine",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "children(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "lines(first:99)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompleteRedirectOrderMutation",
    "selections": (v7/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompleteRedirectOrderMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "c0f067c11c3f9fb9953d2924b5743841",
    "id": null,
    "metadata": {},
    "name": "CompleteRedirectOrderMutation",
    "operationKind": "mutation",
    "text": "mutation CompleteRedirectOrderMutation(\n  $input: CompleteRedirectOrderInput!\n) {\n  createOrder: completeRedirectOrder(input: $input) {\n    cartId\n    token\n    salesOrder {\n      id\n      encryptedId\n      name\n      verified\n      grandTotal\n      shippingTotal\n      paymentMethod\n      status\n      lines(first: 99) {\n        edges {\n          node {\n            id\n            name\n            quantity\n            unitPrice\n            rowTotal\n            product {\n              id\n              sku\n              name\n              brand {\n                id\n                name\n              }\n            }\n            children(first: 99) {\n              edges {\n                node {\n                  id\n                  name\n                  quantity\n                  unitPrice\n                  product {\n                    id\n                    sku\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dfa086bf27ce86722648adf74aed4f0f';

module.exports = node;
