/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Account_viewer$ref = any;
type CartView_viewer$ref = any;
type EventAlertModal_viewer$ref = any;
type Footer_viewer$ref = any;
type Logout_viewer$ref = any;
type Nav_viewer$ref = any;
type Search_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type App_viewer$ref: FragmentReference;
declare export opaque type App_viewer$fragmentType: App_viewer$ref;
export type App_viewer = {|
  +id: string,
  +firstname: ?string,
  +lastname: ?string,
  +email: ?string,
  +configs: ?any,
  +freeShippingAmount: ?number,
  +storeCredits: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +creditAmount: ?number,
        +creditRemaining: ?number,
        +brands: ?$ReadOnlyArray<?string>,
        +expiry: ?string,
      |}
    |}>
  |},
  +$fragmentRefs: Search_viewer$ref & Logout_viewer$ref & Footer_viewer$ref & Account_viewer$ref & Nav_viewer$ref & CartView_viewer$ref & EventAlertModal_viewer$ref,
  +$refType: App_viewer$ref,
|};
export type App_viewer$data = App_viewer;
export type App_viewer$key = {
  +$data?: App_viewer$data,
  +$fragmentRefs: App_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "storeCredits"
        ]
      }
    ]
  },
  "name": "App_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeShippingAmount",
      "storageKey": null
    },
    {
      "alias": "storeCredits",
      "args": null,
      "concreteType": "CustomerStoreCreditConnection",
      "kind": "LinkedField",
      "name": "__App_viewer_storeCredits_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerStoreCreditEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerStoreCredit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creditAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creditRemaining",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "brands",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiry",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Search_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Logout_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Footer_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Account_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Nav_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CartView_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAlertModal_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '7cd13e48c155c16c0bf73cfff912b896';

module.exports = node;
