/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AutoLoginInput = {|
  email: string,
  lastname: string,
|};
export type AutoLoginMutationVariables = {|
  input: AutoLoginInput
|};
export type AutoLoginMutationResponse = {|
  +autoLogin: ?{|
    +message: ?string,
    +result: ?boolean,
    +token: ?string,
  |}
|};
export type AutoLoginMutation = {|
  variables: AutoLoginMutationVariables,
  response: AutoLoginMutationResponse,
|};
*/


/*
mutation AutoLoginMutation(
  $input: AutoLoginInput!
) {
  autoLogin(input: $input) {
    message
    result
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AutoLoginPayload",
    "kind": "LinkedField",
    "name": "autoLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AutoLoginMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AutoLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be29e6eb213ee1e8f11afe7c20959ada",
    "id": null,
    "metadata": {},
    "name": "AutoLoginMutation",
    "operationKind": "mutation",
    "text": "mutation AutoLoginMutation(\n  $input: AutoLoginInput!\n) {\n  autoLogin(input: $input) {\n    message\n    result\n    token\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a1828cc2caee58281898dc8c1864496';

module.exports = node;
