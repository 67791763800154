/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StockReport_viewer$ref = any;
export type routes_StockReport_QueryVariables = {||};
export type routes_StockReport_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: StockReport_viewer$ref
  |}
|};
export type routes_StockReport_Query = {|
  variables: routes_StockReport_QueryVariables,
  response: routes_StockReport_QueryResponse,
|};
*/


/*
query routes_StockReport_Query {
  viewer {
    ...StockReport_viewer
    id
  }
}

fragment StockReport_viewer on Customer {
  stockReport {
    id
    name
    sku
    ac
    stockAvailable
    status
    mainImage {
      id
      thumbnail
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_StockReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StockReport_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_StockReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "stockReport",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sku",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ac",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stockAvailable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductImage",
                "kind": "LinkedField",
                "name": "mainImage",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnail",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3aecd7cc77f8a3471f338cb17b4408a7",
    "id": null,
    "metadata": {},
    "name": "routes_StockReport_Query",
    "operationKind": "query",
    "text": "query routes_StockReport_Query {\n  viewer {\n    ...StockReport_viewer\n    id\n  }\n}\n\nfragment StockReport_viewer on Customer {\n  stockReport {\n    id\n    name\n    sku\n    ac\n    stockAvailable\n    status\n    mainImage {\n      id\n      thumbnail\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88f8748b3fee588a9b4a5295e21e5114';

module.exports = node;
