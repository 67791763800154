import React from 'react';
import PropTypes from 'prop-types';
import { PriorityIcon } from '~/components/svg';
import { deliveryTime } from '../product/ShippingQuote';
import GetByDate from '../priority/GetByDate';

export const COLOR = {
  priority: '#0089B6',
  standard: '#65B526',
  unavailable: '#F5F5F5',
};

const SERVICE_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const Service = (props) => {
  const { children, style } = props;

  return (
    <div style={{ ...SERVICE_STYLE, color: 'white', ...style }}>
      {children}
    </div>
  )
};

Service.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.shape({}),
};

Service.defaultProps = {
  children: null,
  style: {},
};

const Priority = (props) => {
  const { getByDate } = props;

  const backgroundColor = getByDate ? COLOR.priority : COLOR.unavailable;
  const color = getByDate ? 'white' : '#7F7F7F';
  const border = getByDate ? null : '1px solid rgba(0, 0, 0, 0.1)';

  const innerStyle = { ...SERVICE_STYLE, alignItems: 'center', width: '100%' };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', height: '100%', backgroundColor, color, border }}>
      {getByDate ? (
        <>
          <PriorityIcon style={{ fontSize: '20px', margin: '0px 8px' }} />
          <div>
            <div style={innerStyle}>
              <span style={{ fontSize: '14px' }}>Priority Service</span>
              <GetByDate date={getByDate} showIcon={false} size="bold" />
            </div>
          </div>
        </>
      ) : (
        <div style={innerStyle}>
          <span style={{ fontSize: '14px' }}>Priority Service</span>
          <span>Unavailable</span>
        </div>
      )}
    </div>
  )
};

Priority.propTypes = {
  getByDate: PropTypes.string,
};

Priority.defaultProps = {
  getByDate: null,
};

const Standard = (props) => {
  const { type } = props;

  return (
    <div style={{ ...SERVICE_STYLE, backgroundColor: COLOR.standard, color: 'white' }}>
      <span style={{ fontSize: '14px' }}>Standard Service</span>
      <span style={{ fontSize: '11px' }}><b>{deliveryTime[type]}</b> Business Days</span>
    </div>
  )
};

Standard.propTypes = {
  type: PropTypes.oneOf(['normal', 'nonStock']).isRequired,
};

Service.Priority = Priority;
Service.Standard = Standard;

export default Service;
