import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { importScript } from '~/helper';

const getClientId = () => {
  const { COUNTRY } = process.env;

  if (COUNTRY === "AU") {
    return '1075087343475-11mvvbb9nllejje8faamfu8cpsfevkvb.apps.googleusercontent.com';
  } else if (COUNTRY === "NZ") {
    return '634602695617-qgq70n0i4886n8cvolh14dsm5t620k8e.apps.googleusercontent.com';
  }
  throw new Error(`recognised tld ${COUNTRY}`);
};

export default class GoogleSignin extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
  }

  static defaultProps = {
    type: "button",
    onSuccess: null,
    onFailure: null,
  }

  constructor(props) {
    super(props);

    const { id, type, onSuccess, onFailure } = props;

    importScript("https://accounts.google.com/gsi/client", () => {
      if (window.google?.accounts) {
        window.google.accounts.id.initialize({
          client_id: getClientId(),
          callback: (resp) => {
            const token = get(resp, "credential");

            if (token) {
              onSuccess(token, "google");
            } else {
              onFailure("Google login failed.");
            }
          },
        });

        const btnElement = document.getElementById(id);

        window.google.accounts.id.renderButton(btnElement, {
          type,
          theme: "outline",
          size: "large",
          width: btnElement.offsetWidth,
        });
      }
    })
  }

  render() {
    const { id } = this.props;

    return (
      <div id={id} />
    )
  }
}
