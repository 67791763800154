/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateLoyaltyOptOutInput = {|
  id: string,
  optOut: boolean,
|};
export type UpdateLoyaltyOptOutMutationVariables = {|
  input: UpdateLoyaltyOptOutInput
|};
export type UpdateLoyaltyOptOutMutationResponse = {|
  +updateLoyaltyOptOut: ?{|
    +loyaltyOptOut: ?boolean
  |}
|};
export type UpdateLoyaltyOptOutMutation = {|
  variables: UpdateLoyaltyOptOutMutationVariables,
  response: UpdateLoyaltyOptOutMutationResponse,
|};
*/


/*
mutation UpdateLoyaltyOptOutMutation(
  $input: UpdateLoyaltyOptOutInput!
) {
  updateLoyaltyOptOut(input: $input) {
    loyaltyOptOut
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLoyaltyOptOutPayload",
    "kind": "LinkedField",
    "name": "updateLoyaltyOptOut",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loyaltyOptOut",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLoyaltyOptOutMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateLoyaltyOptOutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "513141006f415de31de4d37e90072fb0",
    "id": null,
    "metadata": {},
    "name": "UpdateLoyaltyOptOutMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLoyaltyOptOutMutation(\n  $input: UpdateLoyaltyOptOutInput!\n) {\n  updateLoyaltyOptOut(input: $input) {\n    loyaltyOptOut\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11b6af465fadfcfd041113120e35028c';

module.exports = node;
