/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PosLogin_viewer$ref: FragmentReference;
declare export opaque type PosLogin_viewer$fragmentType: PosLogin_viewer$ref;
export type PosLogin_viewer = {|
  +id: string,
  +$refType: PosLogin_viewer$ref,
|};
export type PosLogin_viewer$data = PosLogin_viewer;
export type PosLogin_viewer$key = {
  +$data?: PosLogin_viewer$data,
  +$fragmentRefs: PosLogin_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PosLogin_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '25cd91b1f6d4bdb8ceddf5879e6e02a8';

module.exports = node;
