import React from 'react';

import {
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import { load } from './index';
import { setStripeInstance } from './Card';

export default class SavedCard extends React.Component {
  constructor(props) {
    super(props);

    this.stripePromise = load();
  }

  render() {
    return (
      <Elements stripe={this.stripePromise}>
        <ElementsConsumer>
          {({stripe}) => {
            if (stripe) {
              setStripeInstance(stripe);
            }
            return null;
          }}
        </ElementsConsumer>
      </Elements>
    );
  }
}
