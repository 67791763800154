import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const AusPostDamagedFormQuery = graphql`
  query routes_AusPostDamagedForm_Query {
    viewer {
      ...AusPostDamagedForm_viewer
    }
  }
`;

const routes = (
  <Route
    path="/auspost"
    getComponent={() => (
      import(/* webpackChunkName: "AusPostDamagedForm" */'~/components/claim').then(module => module.AusPostDamagedForm)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={AusPostDamagedFormQuery}
  />
);

export default routes;
