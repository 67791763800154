import Coupon from './Coupon';
import CreditIcon from './Credit';
import CrossHairIcon from './Crosshair';
import FilterIcon from './Filter';
import GiftCardIcon from './GiftCard';
import PointsIcon from './Points';
import PriorityIcon from './Priority';

module.exports = {
  Coupon,
  CreditIcon,
  CrossHairIcon,
  FilterIcon,
  GiftCardIcon,
  PointsIcon,
  PriorityIcon,
};
