import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation LocationReminderMutation {
    getRedirectInfo {
      redirectInfo {
        customerCountry
        website {
          link
          label
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {});
export default commit;