import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Tooltip } from 'antd';
import { AddProductToWishlistMutation, RemoveProductFromWishlistMutation } from './mutations';

class WishlistBtn extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      sellable: PropTypes.bool.isRequired,
      callForPrice: PropTypes.bool.isRequired,
      inStoreOnly: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      inWishlist: PropTypes.bool.isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleOnClick(product, inWishlist) {
    const input = {
      productId: product.id,
    };
    const variables = { input };

    if (this.props.viewer.email == null) {
      message.error('Please login to use wishlist');
      return;
    }

    if (!inWishlist) {
      AddProductToWishlistMutation.commit({
        environment: this.props.relay.environment,
        variables,
        viewer: this.props.viewer,
        parent: product,
        onCompleted: () => {
          message.success('Added to Wishlist');
        },
      });
    } else {
      RemoveProductFromWishlistMutation.commit({
        environment: this.props.relay.environment,
        variables,
        viewer: this.props.viewer,
        parent: product,
        onCompleted: () => {
          message.success('Removed from Wishlist');
        },
      });
    }
  };

  render() {
    const { product } = this.props;
    const inWishlist = get(product, 'inWishlist', false);

    // usedin ProductView, config parent product is not sellable -> no wishlistbutton will be display
    if (!product.sellable && !product.callForPrice && !product.inStoreOnly && !(product.type === 'configurable')) {
      return null;
    }

    return (
      <div style={{width: '12%', float: 'right'}}>
        <Tooltip title={inWishlist ? "Remove from Wishlist" : "Add to Wishlist"}>
          <Button
            style={{color: 'rgb(203, 0, 0)', height: '40px', width: '49px', borderStyle: 'none', boxShadow: 'none'}}
            onClick={() => {this.handleOnClick(product, inWishlist);}}
          >
            <LegacyIcon type="heart" theme={inWishlist ? "filled" : "outlined"} style={{fontSize: '30px', margin: '4px 0 0 -6px'}} />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default createFragmentContainer(WishlistBtn, {
  viewer: graphql`
    fragment WishlistBtn_viewer on Customer {
      id
      email
    }
  `,
  product: graphql`
    fragment WishlistBtn_product on Product {
      id
      name
      type
      callForPrice
      inStoreOnly
      inWishlist
      sellable
    }
  `,
})
