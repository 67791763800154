import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Col, Input, message, Row, Spin } from 'antd';
import moment from 'moment';
import { base64decode, importScript } from '~/helper';

import './style.css';

const { Item: FormItem } = Form;

let merchantId = "SYDTOOLS";
let baseUrl = 'https://test-tyro.mtf.gateway.mastercard.com/form/version';

if (process.env.NODE_ENV === 'production') {
  merchantId = "TYRO_94617";
  baseUrl = 'https://tyro.gateway.mastercard.com/form/version';
}

export const MERCHANT_ID = merchantId;

export const ERROR_MSG = (
  <div style={{display: 'inline'}}>
    <span>There is an error with the payment gateway.</span>
    <br />
    <span>Please refresh your page and try again</span>
    <br />
    <span>or try other payment methods</span>
  </div>
);

export const getTransDetails = (encodedCartId) => {
  const cartId = base64decode(encodedCartId).replace('Cart:', '');
  const unixTime = moment().valueOf();

  return {
    cartId,
    transactionId: `${cartId}_${unixTime}`,
  }
}

export default class CreditCard extends React.Component {
  static importScript = (url, cb = null) => {
    importScript({url}, cb);
  }

  static propTypes = {
    cart: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    getTyroSession: PropTypes.func.isRequired,
    updateCardType: PropTypes.func.isRequired,
    method: PropTypes.shape({
      extra: PropTypes.shape({
        version: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.initPaymentSession = (sessionId) => {
      const { PaymentSession } = window;
      PaymentSession.configure({
        session: sessionId,
        fields: {
          card: {
            number: "#ccNumber",
            securityCode: "#ccCvv",
            expiryMonth: "#ccMonth",
            expiryYear: "#ccYear",
          }
        },
        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: this.initialized,
          formSessionUpdate: this.formSessionUpdate.bind(this),
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
          }
        },
      });

      this.onCardTypeChange();
      this.onValidityChange();
    }

    this.state = {
      loading: true,
      ccNumber: {
        validateStatus: '',
        errorMsg: '',
      },
      ccMonth: {
        validateStatus: '',
        errorMsg: '',
      },
      ccYear: {
        validateStatus: '',
        errorMsg: '',
      },
      ccCvv: {
        validateStatus: '',
        errorMsg: '',
      },
      ccTypeImage: "",
    };

    const { getTyroSession, method } = props;
    const version = get(method, 'extra.version');
    const url = this.getUrl(version);

    if (typeof window.PaymentSession === 'undefined') {
      CreditCard.importScript(url, () => {
        getTyroSession(method, {relay: this.props.relay, cb: this.initPaymentSession.bind(this)});
      });
    } else {
      // HACK to keep the iframe re-render after the script is imported.
      let targetScript = document.querySelectorAll("script[type='text/javascript']");
      targetScript = Array.prototype.slice.call(targetScript);
      targetScript = targetScript.find(({src}) => src === url);
      if (targetScript) {
        targetScript.parentNode.removeChild(targetScript);
        window.PaymentSession = undefined;
        CreditCard.importScript(url, () => {
          getTyroSession(method, {relay: this.props.relay, cb: this.initPaymentSession.bind(this)});
        });
      }
    }
  }

  onCardTypeChange = () => {
    const { PaymentSession } = window;
    PaymentSession.onCardTypeChange((selector, result) => {
      let ccTypeImage = "";

      if (result.status === 'SUPPORTED' && result.brand === "VISA") {
        this.props.updateCardType("VI");
        ccTypeImage = "tyro-cc-base tyro-cc-visa";
      } else if (result.status === 'SUPPORTED' && result.brand === "MASTERCARD") {
        this.props.updateCardType("MC");
        ccTypeImage = "tyro-cc-base tyro-cc-mastercard";
      } else if (result.status === 'SUPPORTED' && result.brand === "AMEX") {
        this.props.updateCardType("AE");
        ccTypeImage = "tyro-cc-base tyro-cc-amex";
      } else {
        this.props.updateCardType(null);
      }

      this.setState({
        ccTypeImage,
      });
    });
  }

  onValidityChange = () => {
    const { PaymentSession } = window;
    const allFields = ['card.number', 'card.expiryMonth', 'card.expiryYear', 'card.securityCode'];

    PaymentSession.onValidityChange(allFields, (selector, result) => {
      const field = selector.slice(1, selector.length);

      let value = { validateStatus: "success", errorMsg: "" };

      if (!result.isValid) {
        value = { validateStatus: "error", errorMsg: `${this.fieldToName(field)} is invalid` };
      }
      if (!result.isValid && result.errorReason === "EMPTY") {
        value = { validateStatus: "error", errorMsg: "Required" };
      }

      this.setState({
        [field]: value,
      });
    });
  }

  getUrl = (version) => {
    return `${baseUrl}/${version}/merchant/${MERCHANT_ID}/session.js`;
  }

  getYears = () => {
    const years = [];

    let curYear = (new Date()).getFullYear();
    const endYear = curYear + 10;

    for (curYear; curYear <= endYear; curYear += 1) {
      years.push(curYear);
    }

    // DEBUG ONLY
    if (process.env.NODE_ENV !== 'production') {
      years.push(2039);
    }

    return years;
  }

  initialized = (response) => {
    if (response.status === "ok") {
      this.setState({
        loading: false
      });
    } else {
      console.error(response.message);
    }
  }

  formSessionUpdate = (response) => {
    const { form, cart } = this.props;
    const hasCvv = get(response, 'sourceOfFunds.provided.card.securityCode');

    if (response.status === "ok" && !hasCvv) {
      const value = {
        validateStatus: "error",
        errorMsg: `${this.fieldToName("ccCvv")} is invalid`,
      };

      this.setState({ ccCvv: value }, () => {
        message.error('Cvv is required.');
      });
    } else if (response.status === "ok") {
      const id = get(cart, 'id', '');
      const { transactionId } = getTransDetails(id);
      const session = get(response, 'session', {});
      const card = get(response, 'sourceOfFunds.provided.card', {});
      const expiry = get(response, 'sourceOfFunds.provided.card.expiry', {});

      form.setFieldsValue({
        tyroData: {
          transactionId,
          sessionId: session.id,
          card: {
            brand: card.brand,
            number: (card.number).slice(-4),
            month: expiry.month,
            year: expiry.year,
          },
        }
      }, () => {
        this.props.submit();
      });
    } else if (response.status === "fields_in_error") {
      Object.keys(response.errors).forEach(errorField => {
        let name = null;

        if (errorField === "cardNumber") {
          name = "Card number";
        } else if (errorField === "expiryYear") {
          name = "Expiry year";
        } else if (errorField === "expiryMonth") {
          name = "Expiry month";
        } else if (errorField === "securityCode") {
          name = "Cvv";
        }

        if (name) {
          let msg = `${name} is invalid.`;

          if (response.errors[errorField] === "missing") {
            msg = `${name} is required.`;
          }

          message.error(msg);
        }
      });
    } else {
      if (response.status === "request_timeout") {
        console.error(`Tyro Session update failed with request timeout: ${response.errors.message}`);
      } else if (response.status === "system_error") {
        console.error(`Tyro Session update failed with system error: ${response.errors.message}`);
      } else {
        const resp = JSON.stringify(response);
        console.error(`Tyro Session update failed: ${resp}`);
      }
      message.error(ERROR_MSG);
    }
  }

  fieldToName = (field) => {
    if (field === "ccNumber") {
      return "Credit Card Number";
    } else if (field === "ccCvv") {
      return "Cvv";
    } else if (field === "ccMonth") {
      return "Expiry Month";
    } else if (field === "ccYear") {
      return "Expiry Year";
    } else if (field === "ccOwner") {
      return "Cardholder name";
    }

    return null;
  }

  render() {
    const { loading, ccNumber, ccTypeImage, ccMonth, ccYear, ccCvv } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin tip="Loading..." spinning={loading}>
        <FormItem
          hasFeedback
          style={{ marginBottom: '0' }}
        >
          {getFieldDecorator('ccOwner', {
            rules: [
              { required: true, message: 'Required' },
            ],
          })(<Input placeholder="Name On Card" />)}
        </FormItem>

        {getFieldDecorator('ccType', {})(<div />)}

        <FormItem
          className="tyro-creditcard"
          validateStatus={ccNumber.validateStatus}
          help={ccNumber.errorMsg}
          style={{ marginBottom: '0' }}
        >
          <Input
            id="ccNumber"
            className={ccTypeImage}
            placeholder="Credit Card Number"
            readOnly
          />
        </FormItem>

        <Row>
          <Col xs={12} md={10}>
            <FormItem
              className="tyro-creditcard select"
              hasFeedback
              validateStatus={ccMonth.validateStatus}
              help={ccMonth.errorMsg}
              style={{ marginBottom: '0', width: '100%' }}
            >
              <select
                id="ccMonth"
                className="ant-select-selection"
                style={{ display: 'inline-block', padding: '4px 11px', width: '100%', background: 'initial' }}
                readOnly
              >
                <option value="">Month</option>
                <option value="01">01 - January</option>
                <option value="02">02 - February</option>
                <option value="03">03 - March</option>
                <option value="04">04 - April</option>
                <option value="05">05 - May</option>
                <option value="06">06 - June</option>
                <option value="07">07 - July</option>
                <option value="08">08 - August</option>
                <option value="09">09 - September</option>
                <option value="10">10 - October</option>
                <option value="11">11 - November</option>
                <option value="12">12 - December</option>
              </select>
            </FormItem>
          </Col>

          <Col xs={12} md={8}>
            <FormItem
              className="tyro-creditcard select"
              hasFeedback
              validateStatus={ccYear.validateStatus}
              help={ccYear.errorMsg}
              style={{ marginBottom: '0' }}
            >
              <select
                id="ccYear"
                className="ant-select-selection"
                style={{ display: 'inline-block', padding: '4px 11px', width: '99%', background: 'initial' }}
                readOnly
              >
                <option value="">Year</option>
                {this.getYears().map(y => <option key={y} value={String(y)}>{y}</option>)}
              </select>
            </FormItem>
          </Col>

          <Col xs={24} md={6}>
            <FormItem
              className="tyro-creditcard"
              hasFeedback
              validateStatus={ccCvv.validateStatus}
              help={ccCvv.errorMsg}
              style={{ marginBottom: '0' }}
            >
              <Input id="ccCvv" placeholder="CVV" readOnly />
            </FormItem>
          </Col>
        </Row>
      </Spin>
    );
  }
}
