import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from 'antd';
import { sanitiseCharacters } from '~/helper';
import { renderOption } from '../product/ShippingQuote';
import { SearchPostcodeSuburbMutation } from '../product/mutations';

const handleSearch = ({query, relay, callback = () => {}}) => {
  if (query.trim().length < 3) return;

  SearchPostcodeSuburbMutation.commit({
    environment: relay.environment,
    variables: { input: { query } },
    onCompleted: resp => {
      const { result } = resp.searchPostcodeSuburb;
      if (result && result.localities) {
        callback(result.localities.locality);
      }
    },
  });
};

const SuburbPostcodeInput = (props) => {
  const { placeholder, relay, onChange } = props;

  const [dataSource, setDataSource] = useState([]);
  const [lastInput, setLastInput] = useState("")

  const onSearch = (query) => {
    query = sanitiseCharacters(query);

    if (lastInput !== query) {
      handleSearch({
        query,
        relay,
        callback: setDataSource,
      });
    }

    setLastInput(query);
  };

  return (
    <AutoComplete
      id={props.id}
      allowClear
      autoComplete="googleignoreautofill"
      options={dataSource.map(renderOption)}
      onSearch={onSearch}
      onSelect={onChange}
      placeholder={placeholder}
    />
  );
};

SuburbPostcodeInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  relay: PropTypes.shape({}).isRequired,
};

SuburbPostcodeInput.defaultProps = {
  placeholder: "Enter a suburb or postcode",
};

export default SuburbPostcodeInput;
