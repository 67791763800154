/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CheckEmailInput = {|
  email: string
|};
export type CheckEmailMutationVariables = {|
  input: CheckEmailInput
|};
export type CheckEmailMutationResponse = {|
  +checkEmail: ?{|
    +result: ?boolean
  |}
|};
export type CheckEmailMutation = {|
  variables: CheckEmailMutationVariables,
  response: CheckEmailMutationResponse,
|};
*/


/*
mutation CheckEmailMutation(
  $input: CheckEmailInput!
) {
  checkEmail(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CheckEmailPayload",
    "kind": "LinkedField",
    "name": "checkEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckEmailMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "813ab18cb1e2dbae4948982c6016e463",
    "id": null,
    "metadata": {},
    "name": "CheckEmailMutation",
    "operationKind": "mutation",
    "text": "mutation CheckEmailMutation(\n  $input: CheckEmailInput!\n) {\n  checkEmail(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ee707fe972d07715a8568256e0b698c';

module.exports = node;
