/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Logout_viewer$ref = any;
type OrderHistory_viewer$ref = any;
type SideNav_viewer$ref = any;
export type routes_OrderHistory_QueryVariables = {||};
export type routes_OrderHistory_QueryResponse = {|
  +viewer: ?{|
    +email: ?string,
    +salesOrders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +verified: ?number,
          +verificationLink: ?string,
          +grandTotal: ?number,
          +insertedAt: ?string,
          +shippingStatus: ?string,
          +status: ?number,
        |}
      |}>
    |},
    +$fragmentRefs: OrderHistory_viewer$ref & SideNav_viewer$ref & Logout_viewer$ref,
  |}
|};
export type routes_OrderHistory_Query = {|
  variables: routes_OrderHistory_QueryVariables,
  response: routes_OrderHistory_QueryResponse,
|};
*/


/*
query routes_OrderHistory_Query {
  viewer {
    ...OrderHistory_viewer
    email
    salesOrders(first: 9999) {
      edges {
        node {
          id
          name
          verified
          verificationLink
          grandTotal
          insertedAt
          shippingStatus
          status
        }
      }
    }
    ...SideNav_viewer
    ...Logout_viewer
    id
  }
}

fragment Logout_viewer on Customer {
  email
}

fragment OrderHistory_viewer on Customer {
  email
  salesOrders(first: 9999) {
    edges {
      node {
        id
        name
        verified
        verificationLink
        grandTotal
        insertedAt
        shippingStatus
        status
      }
    }
  }
}

fragment SideNav_viewer on Customer {
  email
  ...Logout_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 9999
    }
  ],
  "concreteType": "SalesOrderConnection",
  "kind": "LinkedField",
  "name": "salesOrders",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "verified",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "verificationLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grandTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "insertedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shippingStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "salesOrders(first:9999)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_OrderHistory_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrderHistory_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SideNav_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Logout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_OrderHistory_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d3319ab5f8e20439c3186a5c5305d94",
    "id": null,
    "metadata": {},
    "name": "routes_OrderHistory_Query",
    "operationKind": "query",
    "text": "query routes_OrderHistory_Query {\n  viewer {\n    ...OrderHistory_viewer\n    email\n    salesOrders(first: 9999) {\n      edges {\n        node {\n          id\n          name\n          verified\n          verificationLink\n          grandTotal\n          insertedAt\n          shippingStatus\n          status\n        }\n      }\n    }\n    ...SideNav_viewer\n    ...Logout_viewer\n    id\n  }\n}\n\nfragment Logout_viewer on Customer {\n  email\n}\n\nfragment OrderHistory_viewer on Customer {\n  email\n  salesOrders(first: 9999) {\n    edges {\n      node {\n        id\n        name\n        verified\n        verificationLink\n        grandTotal\n        insertedAt\n        shippingStatus\n        status\n      }\n    }\n  }\n}\n\nfragment SideNav_viewer on Customer {\n  email\n  ...Logout_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f94633fdf4ae37e0458318dbdbd73909';

module.exports = node;
