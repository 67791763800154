import { graphql } from 'react-relay';
import { buildMutation, edgeDeleter } from '../../../mutation';

const mutation = graphql`
  mutation RemoveCreditCardMutation($input: RemoveCreditCardInput!) {
    removeCreditCard(input:$input) {
      removedCreditCardId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeDeleter.bind(this, { rootField: 'removeCreditCard', edgeName: 'removedCreditCardId', connectionKey: 'App_viewer_creditCards' }),
});

export default { commit };
