import React from 'react';
import { importScript } from '~/helper';

const buttonContainerId = "__8x8-chat-button-container-script_3624530466086231099c372.33613422";

window.__8x8Chat = {
  uuid: "script_3624530466086231099c372.33613422",
  tenant: "YXJyb3d2b2ljZWFuZGRhdGE5MDYwMQ",
  channel: "ST Support Chat",
  domain: "https://vcc-au1.8x8.com",
  path: "/.",
  align: "right",
  buttonContainerId,
};

export default class Chat extends React.PureComponent {
  componentDidMount() {
    const src = `${window.__8x8Chat.domain + window.__8x8Chat.path  }/CHAT/common/js/chat.js`;
    importScript(src);
  }

  render() {
    return (
      <div style={{position: 'fixed', right: '0.5em', bottom: '1em', zIndex: 10}}>
        <div className="chat-button" id={buttonContainerId} style={{width: '60px', height: '60px'}} />
      </div>
    );
  }
}
