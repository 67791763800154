import { attempt, get } from 'lodash'


// This checks in the catalogues filters, if it got the key imageUrl,
// if true, means it comes from a filtered product list.
const imageUrlExist = function imageUrlExist(filters, key) {
  const { buckets } = filters.find((f) => f.key === key) || {};

  if (Array.isArray(buckets)) {
    return buckets.some(b => b.key && b.imageUrl);
  }
  return false;
}

// Gets the url query filter from location, then check if its exists in the catalogues filters,
// if query key & object length == 1 and if imageUrlExist = true, return the value of the key,
// means we need to append the index to heading / helment / breadcrumb .
const find = function find(allFilters, queryFilters) {
  if (Object.keys(queryFilters).length !== 1) return null;

  const [key] = Object.keys(queryFilters);

  if (queryFilters[key].length !== 1) return null;
  if (!imageUrlExist(allFilters, key)) return null;

  const [value] = queryFilters[key];

  return value;
}

const shouldAppendIndex = function shouldAppendIndex(category, query) {
  const allFilters = get(category, 'catalogues.facets', []);
  const queryFilters = attempt(JSON.parse.bind(null, get(query, 'filters', '{}')));

  return find(allFilters, queryFilters);
}
module.exports = {
  shouldAppendIndex
};
