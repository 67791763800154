/* global ApplePaySession */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { message } from 'antd';

const VERSION = 1;

const performValidation = (valURL) => {
  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({validationURL: valURL})
  };

  return fetch('/api/payments/applepay', request).then((response) => {
    if (!response.ok) {
      return Promise.reject(response);
    }

    return response.json();
  })
}

const canMakePayments = window.ApplePaySession && ApplePaySession.canMakePayments();

export default class ApplePay extends React.Component {
  static canShow = (paymentMethods) => canMakePayments && !!paymentMethods.edges.find(({node}) => node.title === "Apple Pay")

  static propTypes = {
    cart: PropTypes.shape({
      paymentMethods: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      getFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    submit: PropTypes.func.isRequired,
  }

  createSession = (cart) => {
    const paymentRequest = {
      currencyCode: process.env.CURRENCY,
      countryCode: process.env.COUNTRY,
      total: {
        label: 'Sydney Tools',
        amount: cart.grandTotal,
        type: 'pending'
      },
      supportedNetworks: ['amex', 'masterCard', 'visa' ],
      merchantCapabilities: [ 'supports3DS' ]
    };

    const session = new ApplePaySession(VERSION, paymentRequest);

    session.onvalidatemerchant = (event) => {
      const promise = performValidation(event.validationURL);

      promise.then(resp => {
        session.completeMerchantValidation(resp.merchantSession);
      }).catch(err => {
        err.json().then( error => {
          message.error(error.error);
          console.error(error);
          session.abort();
        })
      });
    };

    session.onpaymentauthorized = (event) => {
      const { token } = event.payment;
      const { node: { code } } = get(cart, 'paymentMethods.edges', []).find(({node}) => node.code === 'till_applepay' || node.code === 'tyro_applepay');

      this.props.form.setFieldsValue({
        ccType: token.paymentMethod.network.toLowerCase(),
        applepay: JSON.stringify({
          token
        }),
        paymentMethod: code
      });

      this.props.submit(null, {
        onCompleted: () => {
          const result = {
            status: ApplePaySession.STATUS_SUCCESS
          };
          session.completePayment(result);
        },
        onError: () => {
          session.abort();
        }
      });
    };

    session.begin();
  }

  render() {
    const { cart, cart: { paymentMethods }, form, form: { getFieldDecorator } } = this.props;

    if (ApplePay.canShow(paymentMethods)) {
      return (
        <div id="applepay-container">
          <button
            type="button"
            lang="en"
            onClick={() => {
              form.validateFieldsAndScroll(e => {
                if (!e) {
                  this.createSession(cart)
                }
              });
            }}
            style={{
              cursor: 'pointer',
              WebkitAppearance: '-apple-pay-button',
              ApplePayButtonType: 'plain',
              ApplePayButtonStyle: 'black',
              width: '100%',
              height: '40px',
            }}
          />
          {getFieldDecorator('applepay')(<div />)}
          {getFieldDecorator('ccType')(<div />)}
        </div>
      )
    }
    return null;
  }
}
