import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form } from '@ant-design/compatible';
import { AutoComplete, Input } from 'antd';
import { sanitiseCharacters } from '~/helper';
import { renderOption } from './AddressOption';

const { Item: FormItem } = Form;

class FormItemSuburb extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      validateFields: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    formItemLayout: PropTypes.shape({}),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      telephone: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      postcode: PropTypes.string,
      region: PropTypes.string,
      compnay: PropTypes.string,
      country: PropTypes.shape({
        alpha2: PropTypes.string,
      }),
      fax: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    handleSearch: PropTypes.func,
    handleAddressSelect: PropTypes.func,
    handleAddressTyping: PropTypes.func,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataSourceName: PropTypes.string.isRequired,
  }

  static defaultProps = {
    formItemLayout: {},
    disabled: false,
    type: null,
    address: {},
    placeholder: null,
    handleSearch: () => {},
    handleAddressSelect: () => {},
    handleAddressTyping: () => {},
  }

  renderInput = () => {
    const {
      disabled,
      placeholder,
      form,
      type,
      dataSource,
      dataSourceName,
      handleSearch,
      handleAddressSelect,
      handleAddressTyping
    } = this.props;

    if (process.env.COUNTRY === 'NZ') {
      return (
        <Input disabled={disabled} placeholder={placeholder} />
      )
    }

    return (
      <AutoComplete
        allowClear
        autoComplete="googleignoreautofill"
        options={dataSource.map(renderOption.bind(this, 'location'))}
        onSearch={(value)=>{
          value = sanitiseCharacters(value);
          form.setFieldsValue({
            [`${type}[]city`]: value
          });
          form.validateFields([`${type}[]city`]);

          handleSearch(dataSourceName, value);
        }}
        onSelect={(value, option) => {handleAddressSelect(type, value, option);}}
        onChange={value => handleAddressTyping(type, 'suburb', value)}
        style={{ width: '100%' }}
        disabled={disabled}
        placeholder={placeholder}
      />
    )
  }

  render() {
    const { form, formItemLayout, type, address } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FormItem
        {...formItemLayout}
        label="Suburb"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]city`, {
          rules: [
            { required: true, message: 'Required' },
            { message: 'Please check your suburb.', validator: (rule, value, callback) => {

              if (value && value.includes('@')) {
                callback(null);
              } else if ((value && value.match(/[\d]/g) || []).length > 0) {
                callback(rule.message);
              }

              callback();
            }},
          ],
          initialValue: get(address, 'city', ''),
        })(
          this.renderInput()
        )}
      </FormItem>
    )
  }
}

export default FormItemSuburb;