import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { capitalize, get, groupBy, values } from 'lodash';
import { Link } from 'found';
import { Marker } from 'react-google-maps';
import Helmet from '~/components/page/Helmet';
import { JSONLD, Generic, GenericCollection } from 'react-structured-data';
import { Divider, Row, Col, Card } from 'antd';
import { getSocials } from '~/helper';
import { Map } from './Map';
import Breadcrumb from '../page/Breadcrumb';
import './style.css';
import { renderStore, GOOGLE_MAP_URL, getGoogleMapApiKey } from './StoreList';

export const getMarker = (store) => {
  if (store) {
    const { origin } = window.location;

    return (
      <Marker
        icon={`${origin}/assets/images/google_maps/map_pin.svg`}
        key={store.id}
        position={{ lat: store.lat, lng: store.lng }}
      />
    )
  }

  return null;
}

export const getStoreLoc = (store) => {
  if (store) {
    return { lat: store.lat, lng: store.lng };
  }

  return null;
}

class Store extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  genericOpeningHourList = (store) => {
    let list = Object.keys(store.hours).map(key => {
      return {day: key, hours: store.hours[key]}
    });

    list = groupBy(list, x => `${x.hours.open} ${x.hours.close}`);

    // Object.values() is too new
    // https://caniuse.com/object-values
    return values(list).map(x => {
      const dayOfWeek = x.map(d => capitalize(d.day));
      return (
        <Generic
          key={dayOfWeek.toString()}
          jsonldtype="OpeningHoursSpecification"
          schema={{
            dayOfWeek,
            opens: x[0].hours.open,
            closes: x[0].hours.close
          }}
        />
      )
    });
  }

  renderStoreContent = (store) => {
    if (store.content !== null) {
      return (
        <div>
          <Divider />
          <div dangerouslySetInnerHTML={{ __html: store.content}} />
        </div>
      )
    }
    return null;
  }

  renderMap = (store) => {
    if (store.name === "Repairs") {
      const center = {lat: store.lat, lng: store.lng}
      return (
        <Map
          markers={getMarker(store)}
          center={center}
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          zoom={15}
        />
      )
    }

    const GOOGLE_MAP_API_KEY = getGoogleMapApiKey();

    let countryQuery = 'Australia';
    if (process.env.COUNTRY === 'NZ') {
      countryQuery = 'New+Zealand';
    }

    return (
      <iframe
        title={store.name}
        style={{border: 0, width: '100%', height: 400}}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAP_API_KEY}&q=Sydney+Tools+${store.name}+${countryQuery}&zoom=15`}
      />
    )
  }

  render() {
    const { match: { location }, viewer } = this.props;
    const store = get(viewer, 'stores.edges[0].node', {})
    const socials = Object.values(getSocials());

    if (Object.keys(store).length) {
      return (
        <div>
          <Breadcrumb store={store} location={location} />
          <h1 style={{fontWeight: '700'}}>Sydney Tools {store.name}</h1>
          <Helmet title={store.metaTitle}>
            <meta name="description" content={store.metaDescription} />
          </Helmet>

          <JSONLD>
            <Generic
              type="localBusiness"
              jsonldtype="LocalBusiness"
              schema={{
                name: `Sydney Tools ${store.name}`,
                url: window.location.href,
                telephone: store.phone,
                priceRange: "n/a",
                sameAs: socials,
              }}
            >
              <Generic
                type="address"
                jsonldtype="PostalAddress"
                schema={{
                  streetAddress: store.address,
                  addressLocality: store.city,
                  addressRegion: store.region,
                  postalCode: store.postcode,
                  addressCountry: process.env.COUNTRY,
                }}
              />
              <Generic
                type="geo"
                jsonldtype="GeoCoordinates"
                schema={{
                  latitude: store.lat,
                  longitude: store.lng,
                }}
              />
              <GenericCollection type="openingHoursSpecification">
                {this.genericOpeningHourList(store)}
              </GenericCollection>
            </Generic>
          </JSONLD>

          <Card key={store.id}>
            <Row >
              <Col xs={24} sm={24} md={12} lg={12} xl={12}> {renderStore.call(this, store)} </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {this.renderMap(store)}
              </Col>
            </Row>
            {this.renderStoreContent(store)}
          </Card>

        </div>
      )
    }

    return (
      <div>
        <p style={{padding: '10px'}}>
          We cannot find the store you are looking for.<br />
          Please try again on our <Link to="/stores">store locator</Link> page.
        </p>
      </div>
    );
  }
}

export default createFragmentContainer(Store, {
  viewer: graphql`
    fragment Store_viewer on Customer {
      id
      email
      myStore {
        id
      }
      stores(first: 1, filterBy: $filterBy) {
        edges {
          node {
            id
            name
            address
            city
            postcode
            region
            state
            description
            fax
            phone
            lat
            lng
            metaTitle
            metaDescription
            content
            canPickup
            hours {
              monday {
                open
                close
              }
              tuesday {
                open
                close
              }
              wednesday {
                open
                close
              }
              thursday {
                open
                close
              }
              friday {
                open
                close
              }
              saturday {
                open
                close
              }
              sunday {
                open
                close
              }
            }
          }
        }
      }
    }
  `,
});
