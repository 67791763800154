import React from 'react';
import { HttpError, Route } from 'found';
import { graphql } from 'react-relay';
import { get } from 'lodash';
import SideNav, { routerRender } from './SideNav';

const AccountFormQuery = graphql`
  query routes_AccountForm_Query{
    viewer {
      ...AccountForm_viewer
      ...AccountForm_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const OrderHistoryQuery = graphql`
  query routes_OrderHistory_Query{
    viewer {
      ...OrderHistory_viewer
      ...OrderHistory_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const OrderQuery = graphql`
  query routes_Order_Query($orderNames: [String!]!) {
    viewer {
      ...Order_viewer
      ...Order_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const AddressBookQuery = graphql`
  query routes_AddressBook_Query{
    viewer {
      ...AddressBook_viewer
      ...AddressBook_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const LoyaltyPointQuery = graphql`
  query routes_LoyaltyPoint_Query{
    viewer {
      ...LoyaltyPoint_viewer
      ...LoyaltyPoint_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const RemindPasswordResetQuery = graphql`
  query routes_RemindPasswordReset_Query {
    viewer {
      ...RemindPasswordReset_viewer
    }
  }
`;

const PasswordResetQuery = graphql`
  query routes_PasswordReset_Query($token: String!) {
    viewer {
      ...PasswordReset_viewer
    }
  }
`;

const WishlistQuery = graphql`
  query routes_Wishlist_Query{
    viewer {
      ...Wishlist_viewer
      ...Wishlist_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const CreditCardQuery = graphql`
  query routes_CreditCard_Query{
    viewer {
      ...CreditCard_viewer
      ...CreditCard_viewer @relay(mask: false)
      ...SideNav_viewer
      ...SideNav_viewer @relay(mask: false)
    }
  }
`;

const routes = [
  <Route
    key="/password-reset"
    path="/password-reset/:token"
    getComponent={() => (
      import(/* webpackChunkName: "PasswordReset" */'~/components/account/password/PasswordReset').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={PasswordResetQuery}
    prepareVariables={(_, props) => {
      const { token } = props.params;
      return {
        token
      };
    }}
  />,
  <Route
    key="/remind-password-reset"
    path="/password-reset"
    getComponent={() => (
      import(/* webpackChunkName: "RemindPasswordReset" */'~/components/account/password/RemindPasswordReset').then(module => module.default)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={RemindPasswordResetQuery}
    prepareVariables={params => ({ ...params })}
  />,
  <Route
    key="/account"
    path="/account"
  >
    <Route
      path="/my-account"
      getComponent={() => (
        import(/* webpackChunkName: "AccountForm" */'~/components/account/AccountForm').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={AccountFormQuery}
      render={routerRender}
    />
    <Route
      path="/orders"
      getComponent={() => (
        import(/* webpackChunkName: "OrderHistory" */'~/components/account/OrderHistory').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={OrderHistoryQuery}
      prepareVariables={params => ({ ...params })}
      render={routerRender}
    />
    <Route
      path="/orders/:orderName"
      getComponent={() => (
        import(/* webpackChunkName: "Order" */'~/components/account/Order').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={OrderQuery}
      render={({ Component, props }) => {
        if (Component && props) {
          const { match, viewer } = props;
          const orders = get(props, 'viewer.salesOrders.edges', []);
          if (orders.length > 0) {
            return (
              <SideNav
                match={match}
                viewer={viewer}
              >
                <Component {...props} />
              </SideNav>
            );
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      prepareVariables={(_, props) => {
        let orderName = get(props, 'params.orderName', []);

        if (orderName && typeof orderName === 'string') {
          orderName = [orderName]
        }

        return {
          orderNames: orderName
        }
      }}
    />
    <Route
      path="/credit-cards"
      getComponent={() => (
        import(/* webpackChunkName: "SavedCreditCard" */'~/components/account/CreditCard').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={CreditCardQuery}
      prepareVariables={params => ({ ...params })}
      render={routerRender}
    />
    <Route
      path="wishlist"
      getComponent={() => (
        import(/* webpackChunkName: "Wishlist" */'~/components/account/wishlist/Wishlist').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={WishlistQuery}
      prepareVariables={params => ({ ...params })}
      render={routerRender}
    />
    <Route
      path="addresses"
      getComponent={() => (
        import(/* webpackChunkName: "AddressBook" */'~/components/account/AddressBook').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={AddressBookQuery}
      prepareVariables={params => ({ ...params })}
      render={routerRender}
    />
    <Route
      path="my-points"
      getComponent={() => (
        import(/* webpackChunkName: "LoyaltyPoint" */'~/components/account/LoyaltyPoint').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={LoyaltyPointQuery}
      prepareVariables={params => ({ ...params })}
      render={routerRender}
    />
  </Route>
];

export default routes;
