import React from 'react';
import Icon from '@ant-design/icons';

const filter = function Filter() {
  return (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.000488281 8.84864C0.150157 8.52623 0.407783 8.43545 0.754229 8.43594C5.57061 8.4433 10.387 8.43938 15.2034 8.44527C15.4188 8.44527 15.5268 8.40159 15.6028 8.17782C15.9449 7.16793 16.9121 6.49663 17.9607 6.51037C19.0236 6.5246 19.9648 7.2013 20.2951 8.21463C20.3559 8.4011 20.4354 8.44772 20.6184 8.44478C21.3216 8.43447 22.0253 8.45312 22.728 8.43496C23.071 8.42613 23.311 8.54046 23.4572 8.84814V9.14159C23.3046 9.46105 23.049 9.56017 22.7015 9.55085C21.9905 9.5322 21.279 9.54889 20.5679 9.54251C20.4236 9.54104 20.3584 9.58128 20.3108 9.72653C19.9638 10.7879 19.0167 11.4803 17.9362 11.4774C16.8645 11.474 15.9095 10.784 15.5758 9.74076C15.5125 9.54251 15.4065 9.54447 15.2534 9.54447C10.4213 9.54594 5.58926 9.543 0.757174 9.55134C0.410237 9.55183 0.154083 9.46105 0.00146971 9.14208V8.84864H0.000488281ZM19.2955 9.00763C19.2974 8.23229 18.7096 7.63019 17.9475 7.62675C17.1952 7.62332 16.5852 8.22248 16.5823 8.96739C16.5794 9.75205 17.1775 10.365 17.944 10.3625C18.6953 10.36 19.294 9.7599 19.2955 9.00763Z" />
      <path d="M0.000488281 15.3575C0.151138 15.0361 0.408765 14.9433 0.75472 14.9448C3.05177 14.9546 5.34832 14.9473 7.64537 14.9546C7.84312 14.9551 7.92213 14.8977 7.98592 14.7098C8.32746 13.6974 9.27553 13.0256 10.332 13.0246C11.3969 13.0236 12.3548 13.7062 12.688 14.7299C12.7503 14.9222 12.8401 14.9531 13.0168 14.9531C16.2457 14.9487 19.4741 14.9531 22.703 14.9453C23.048 14.9443 23.3066 15.0366 23.4572 15.358V15.6514C23.3066 15.9719 23.0485 16.0646 22.7035 16.0641C19.4682 16.0563 16.2329 16.0607 12.9976 16.0558C12.8244 16.0558 12.7518 16.1019 12.6919 16.2751C12.3867 17.1589 11.7649 17.709 10.8547 17.9338C10.7698 17.9549 10.6854 17.9779 10.6005 18H10.0607C10.0249 17.9848 9.99001 17.9622 9.95272 17.9554C8.97766 17.7762 8.31519 17.2207 7.98298 16.2933C7.91477 16.1024 7.83086 16.0538 7.63948 16.0543C5.34439 16.0607 3.04931 16.0543 0.754229 16.0636C0.409255 16.0651 0.151138 15.9719 0.000488281 15.6509V15.3575H0.000488281ZM11.6884 15.5106C11.6879 14.7407 11.0902 14.1366 10.3301 14.1381C9.5729 14.1395 8.97521 14.747 8.9757 15.515C8.9757 16.2668 9.57339 16.8694 10.3227 16.8738C11.0868 16.8782 11.6889 16.2771 11.6884 15.5106Z" />
      <path d="M0.000490717 2.33975C0.148687 2.01637 0.407295 1.91823 0.75276 1.92215C1.88092 1.93442 3.00957 1.92215 4.13772 1.93099C4.32812 1.93246 4.414 1.8878 4.47926 1.69103C4.81197 0.681623 5.7669 0.00394282 6.82244 1.7089e-05C7.87699 -0.00390865 8.83487 0.668864 9.1715 1.68023C9.2402 1.88682 9.33491 1.93099 9.53217 1.9305C13.9231 1.92559 18.3136 1.92902 22.7045 1.92215C23.0504 1.92166 23.3081 2.01637 23.4568 2.33926V2.63271C23.3037 2.95168 23.0465 3.04246 22.7006 3.04148C18.3185 3.03363 13.9368 3.03804 9.55475 3.03117C9.33245 3.03117 9.23872 3.08663 9.16364 3.30843C8.82652 4.30508 7.86668 4.97 6.82293 4.96509C5.77279 4.96018 4.83454 4.29379 4.48074 3.30107C4.45129 3.21765 4.42381 3.13373 4.39093 3.03657C3.85311 3.03657 3.32264 3.03657 2.79218 3.03657C2.11352 3.03657 1.43486 3.02332 0.756685 3.04197C0.409749 3.05129 0.153594 2.95119 0 2.63271V2.33926L0.000490717 2.33975ZM8.19546 2.4855C8.19595 1.72881 7.58697 1.11787 6.83078 1.1164C6.07507 1.11542 5.4607 1.72685 5.46021 2.48059C5.45972 3.23384 6.07458 3.85067 6.82636 3.85214C7.5801 3.85362 8.19497 3.23924 8.19546 2.4855Z" />
    </svg>
  )
}

export default function FilterIcon(props) {
  return (
    <Icon component={filter} {...props} />
  )
}