import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import PropTypes from 'prop-types';
import { RightOutlined, TagFilled } from '@ant-design/icons';
import { Menu } from 'antd';
import { get, groupBy } from 'lodash';

import { Link } from 'found';
import EventAlertModal from '~/components/topbar/EventAlertModal';
import GA4 from '~/ga4';

import { BestPrice,
  BonusDeals,
  CatalogueOffers,
  CheapDeals,
  ClickCollect,
  GiftCards,
  PriorityShipping,
  StoreLocations
} from './topbar/NavItems';

const groupStyle = {
  display: 'block',
  padding: '0px 5px',
};

const itemStyle = {
  display: 'flex',
  height: '100%',
  textAlign: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const iconStyle = {
  fontSize: '40px',
  display: 'block',
  margin: '0px',
}

const spanStyle = {
  display: 'block',
  fontSize: '12px',
  lineHeight: "1",
  whiteSpace: 'pre-line',
  margin: '0px',
  fontWeight: '600',
};

const btnStyle = {
  height: '100%',
  width: '100%',
  padding: '0px',
};

/*
 * true = featured, prepend heading `Popular Categories`
 * false = not featured, prepend heading `Categories`
 * it is pointless to return a single heading if there is only one group
 */
const addHeadings = (grouped, featured, menus) => {
  if (Object.keys(grouped).length >= 2) {
    const style={margin: '0px', color: '#cb0000', paddingLeft: '2px'};
    if (featured === 'true') {
      menus.unshift(
        <Menu.Item
          key="head-popular-categories"
          className="topnav"
          style={style}
        >
          <RightOutlined style={{marginRight: '1px'}} />Popular Categories
        </Menu.Item>
      );
    }

    if (featured === 'false') {
      menus.unshift(
        <Menu.Item
          key="head-categories"
          className="topnav"
          style={style}
        >
          <RightOutlined style={{marginRight: '1px'}} />Categories
        </Menu.Item>
      );
    }
  }

  return menus;
}

class Nav extends React.Component {
  static propTypes = {
    position: PropTypes.string,
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: null,
  }

  static defaultProps = {
    position: 'left',
  }

  onNavClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  onSideNavClick = ({ item }) => {
    const event = {
      event: 'side_nav',
      action: 'click',
      label: item.props.name,
    };
    GA4.event(event);
  }

  onTopNavClick = ({ item }) => {
    const event = {
      event: 'top_nav',
      action: 'click',
      label: item.props.name,
    };
    GA4.event(event);
  }

  renderSideMenu(categories) {
    let prepend = [
      <Menu.ItemGroup className="navgroup" key="groupone" style={groupStyle}>
        <Menu.Item key="store" onClick={this.onSideNavClick} name="Stores" style={itemStyle}>
          <StoreLocations btnStyle={{...btnStyle, width: '108%'}} iconStyle={{...iconStyle, color: '#cb0000'}} spanStyle={{...spanStyle, color: 'black'}} />
        </Menu.Item>
        <Menu.Item key="clickcollect" onClick={this.onSideNavClick} name="Click and Collect" style={itemStyle}>
          <ClickCollect btnStyle={btnStyle} iconStyle={{...iconStyle, color: '#cb0000'}} spanStyle={{...spanStyle, color: 'black'}} />
        </Menu.Item>
        <Menu.Item key="bestprice" onClick={this.onSideNavClick} name="Best Price" style={itemStyle}>
          <BestPrice btnStyle={btnStyle} iconStyle={{...iconStyle, color: '#cb0000'}} spanStyle={{...spanStyle, color: 'black'}} />
        </Menu.Item>
        <Menu.Item key="eventalert" onClick={this.onSideNavClick} name="Event Alert" style={itemStyle}>
          <EventAlertModal
            viewer={this.props.viewer}
            btnStyle={btnStyle}
            iconStyle={{...iconStyle, color: '#cb0000'}}
            spanStyle={{...spanStyle, color: 'black'}}
          />
        </Menu.Item>
      </Menu.ItemGroup>,
      <Menu.ItemGroup className="navgroup" key="grouptwo" style={{...groupStyle, backgroundColor: '#cb0000', color: 'white'}}>
        <Menu.Item key="bonusdeals" onClick={this.onSideNavClick} name="Hot Bonus Deals" style={itemStyle}>
          <BonusDeals btnStyle={{...btnStyle, color: 'white'}} iconStyle={iconStyle} spanStyle={spanStyle} />
        </Menu.Item>
        <Menu.Item key="cheapdeals" onClick={this.onSideNavClick} name="Cheap Deals" style={itemStyle}>
          <CheapDeals btnStyle={{...btnStyle, color: 'white'}} iconStyle={iconStyle} spanStyle={spanStyle} />
        </Menu.Item>
        <Menu.Item key="giftcards" onClick={this.onSideNavClick} name="Gift Cards" style={itemStyle}>
          <GiftCards btnStyle={{...btnStyle, color: 'white'}} iconStyle={iconStyle} spanStyle={spanStyle} />
        </Menu.Item>
        <Menu.Item key="catalogueoffers" onClick={this.onSideNavClick} name="Catalogue Offers" style={itemStyle}>
          <CatalogueOffers btnStyle={{...btnStyle, color: 'white'}} iconStyle={iconStyle} spanStyle={spanStyle} />
        </Menu.Item>
      </Menu.ItemGroup>,
    ];

    const priority = (
      <Menu.Item key="priority" style={{ ...groupStyle, backgroundColor: '#0089B6', color: 'white', textAlign: 'center', margin: '0px' }}>
        <PriorityShipping spanStyle={{ fontSize: '16px', color: 'white', fontWeight: '600' }} iconStyle={{ fontSize: '18px', verticalAlign: 'middle' }} />
      </Menu.Item>
    );

    const showPriority = get(this.props, 'viewer.configs.priority', false);
    if (showPriority) {
      prepend = prepend.concat(priority);
    }

    const grouped = groupBy(categories, ({node}) => node.featured);

    return prepend.concat(Object.keys(grouped).map((featured) => {
      const menus = grouped[featured].map((edge) => {
        const c = edge.node;
        const url = `/category/${c.urlSlug}`;
        return (
          <Menu.Item
            key={c.id}
            onClick={this.onSideNavClick}
            name={c.name}
            url={url}
            style={{margin: '0px', height: '30px', lineHeight: '30px'}}
          >
            <Link
              to={url}
              className="nav-text"
              activeClassName="selected"
              style={{ display: 'block', borderBottom: '1px solid #ccc', height: '25px', lineHeight: '25px' }}
            >
              {c.urlSlug === 'clearance' && <TagFilled style={{margin: '0'}} />} {c.name}
            </Link>
          </Menu.Item>
        );
      });

      return addHeadings(grouped, featured, menus);
    }));
  }

  renderTopMenu(categories) {
    const grouped = groupBy(categories, ({node}) => node.featured);

    return Object.keys(grouped).map((featured) => {
      const menus = grouped[featured].map((edge) => {
        const c = edge.node;
        const url = `/category/${c.urlSlug}`;

        return (
          <Menu.Item
            key={c.id}
            className="topnav"
            style={{margin: '0px', height: '30px', lineHeight: '30px'}}
            onClick={this.onTopNavClick}
            name={c.name}
            url={url}
          >
            <Link to={url} style={{ display: 'block', borderBottom: '1px solid #ccc', height: '25px', lineHeight: '25px' }}>
              {c.urlSlug === 'clearance-sale' && <TagFilled style={{margin: '0'}} />} {c.name}
            </Link>
          </Menu.Item>
        );
      });

      return addHeadings(grouped, featured, menus);
    });
  }

  render() {
    const { position, viewer } = this.props;
    const categories = get(viewer, 'categories.edges', []);
    const style = {fontWeight: 'bold', border: 'none'};

    if (position === 'top') {
      style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.15)';
    }

    return (
      <div>
        <Menu onClick={this.onNavClick} style={style}>
          {position === 'left' && this.renderSideMenu(categories)}
          {position === 'top' && this.renderTopMenu(categories)}
        </Menu>
      </div>
    );
  }
}
export default createFragmentContainer(Nav, {
  viewer: graphql`
    fragment Nav_viewer on Customer {
      configs
      ...Account_viewer
      ...EventAlertModal_viewer
      categories(first: 999, inMenu: true) {
        edges {
          node {
            id
            name
            urlSlug
            featured
          }
        }
      }
    }
  `,
});
