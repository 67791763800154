import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { get } from 'lodash';
import { Button, Form, Input, message, Select } from 'antd';
import { PostFeedbackFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

class FeedbackPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    delete values.contactBy;

    PostFeedbackFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success("Thank you very much, we have received your feedback.");
        this.formRef.current.resetFields();
      },
    });
  }

  render() {
    const { viewer, viewer: { cmsPage: page } } = this.props;
    const stores = get(viewer, 'stores.edges', []);

    return (
      <div>
        <Helmet title={page.title} />
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />

        <Form ref={this.formRef} onFinish={this.handleSubmit}>

          <h2>Step 1: Contact Details</h2>
          <FormItem
            name="name"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Name" />
          </FormItem>

          <FormItem
            name="email"
            rules={[
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email' },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>

          <FormItem
            name="telephone"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Telephone" />
          </FormItem>

          <h2>Feedback Details</h2>
          <FormItem
            name="feedback"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <TextArea placeholder="Feedback Details" rows={4} />
          </FormItem>

          <FormItem
            name="desiredOutcome"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <TextArea placeholder="What outcome would you like?" rows={4} />
          </FormItem>

          <h3>Additonal Details(if applicable)</h3>
          <FormItem
            name="refNumber"
          >
            <Input placeholder="Invoice/Sales Number" />
          </FormItem>
          <FormItem
            name="staffName"
          >
            <Input placeholder="Team Member's Name" />
          </FormItem>
          <FormItem
            name="location"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Select
              placeholder="Related Store"
              style={{ width: 200 }}
            >
              <Option value="Online">Online</Option>
              {stores.map((edge) => {
                const store = edge.node;
                return <Option key={store.id} value={store.name}>{store.name}</Option>;
              })}
            </Select>
          </FormItem>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>

        </Form>

      </div>
    );
  }
}

export default createFragmentContainer(FeedbackPage, {
  viewer: graphql`
    fragment FeedbackPage_viewer on Customer {
      stores(first: 100) {
        edges {
          node {
            id
            name
          }
        }
      }
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
      }
    }
  `,
});
