import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { get, uniqBy } from 'lodash';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, message, Popconfirm, Row } from 'antd';

import { RemoveAddressMutation, UpdateDefaultAddressMutation } from './mutations';
import AddressUpdatedSubscription from '../account/subscriptions/AddressUpdatedSubscription'
import { AddressForm } from './AddressForm';

const addrBookBtnStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  boxShadow: 'none',
  padding: '0px',
  width: '32px',
};

const defaultBtnStyle = {
  padding: '0px',
  height: 'inherit',
};

class AddressBook extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      email: PropTypes.string,
      addresses: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      defaultAddress: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    if (props.viewer.email) {
      this.subscribeAddress(props);
    }

    this.state = {
      addressFormVisible: false,
    };
  }

  subscribeAddress = (props) => {
    const { relay: { environment }, viewer } = props;
    const id = get(viewer, 'id');

    if (id && viewer.email) {
      AddressUpdatedSubscription(environment, {id})
    }
  }

  deleteAddress = (address) => {
    RemoveAddressMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: address.id } },
      viewer: this.props.viewer,
    });
  }

  updateAddress = (address) => {
    this.setState({ address });
    this.showAddressFormModal();
  }

  isDefaultAddress = (address) => {
    return get(this.props.viewer.defaultAddress, "id", null) === address.id;
  }

  updateDefaultAddress = (address) => {
    UpdateDefaultAddressMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: address.id } },
      viewer: this.props.viewer,
      onError: (errors) => {
        message.error(errors[0].message);
      }
    });
  }

  showAddressFormModal = () => {
    this.setState({
      addressFormVisible: true,
    });
  }

  hideAddressFormModal = () => {
    this.setState({
      addressFormVisible: false,
    });
  }

  orderByDefaultAddr = (addresses) => {
    const defaultAddressId = get(this.props.viewer.defaultAddress, "id", null);

    if (!defaultAddressId) {
      return addresses;
    }

    const index = addresses.findIndex(({node}) => node.id === defaultAddressId);

    return addresses.splice(index, 1).concat(addresses);
  }

  renderAddresses = addresses => {
    if (addresses.length === 0) {
      return <Empty description="You do not have any saved addresses." image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }

    return (
      <Row type="flex" gutter={[10, 10]} style={{ marginTop: '5px' }}>
        {this.orderByDefaultAddr(addresses).map(({node}) => {
          return (
            <Col key={node.id} xs={24} sm={12}>
              {this.renderAddress(node)}
            </Col>
          );
        })}
      </Row>
    )
  }

  renderSetDefault = (address) => {
    if (!this.isDefaultAddress(address)) {
      return (
        <Button
          type="link"
          onClick={() => { this.updateDefaultAddress(address) }}
          style={defaultBtnStyle}
        >
          <u>Set as Default</u>
        </Button>
      );
    }
    return (
      <div style={{ color: 'green' }}>
        Default
      </div>
    );
  }

  renderAddress = (address) => {
    if (address) {
      return (
        <Card className="address-card" style={{ height: '100%' }}>
          <div style={{ display: 'flex', flexGrow: '1' }}>
            <div style={{ width: '100%' }}>
              <b>{address.firstname} {address.lastname}</b>
              {address.company && (
                <p style={{ marginBottom: '5px' }}>
                  {address.company}
                </p>
              )}

              <p style={{ marginBottom: '5px' }}>
                <span>
                  {address.street} {address.city} {address.postcode}
                </span>
                <br />
                <span>
                  {address.city} {address.region} {address.postcode}
                </span>
                <br />
                <span>
                  {address.country.name}
                </span>
              </p>

              <p style={{ marginBottom: '5px' }}>
                <span>{address.telephone}</span>
                <br />
                {address.fax && (
                  <span>{address.fax}</span>
                )}
              </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button onClick={() => { this.updateAddress(address) }} style={{ ...addrBookBtnStyle, marginBottom: '10px' }} >
                <EditOutlined />
              </Button>

              <Popconfirm
                title="Are you sure delete this address?"
                onConfirm={() => {this.deleteAddress(address);}}
                okText="Yes"
                cancelText="No"
              >
                <Button style={addrBookBtnStyle}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </div>
          <div>
            {this.renderSetDefault(address)}
          </div>
        </Card>
      );
    }
    return null;
  }

  render() {
    const { viewer } = this.props;
    let addresses = get(viewer, 'addresses.edges', []);

    // For precautionary, in case subscription websocket doesnt come first before edgeUpdater.
    addresses = uniqBy(addresses, 'node.id')

    return (
      <div>
        <Helmet title="Addresses" />
        <h1>Addresses</h1>
        <Button type="primary" onClick={() => { this.setState({ address: null }); this.showAddressFormModal(); }}>Add New Address</Button>

        <AddressForm
          viewer={viewer}
          address={this.state.address}
          relay={this.props.relay}
          addressFormVisible={this.state.addressFormVisible}
          onCancel={this.hideAddressFormModal}
        />

        {this.renderAddresses(addresses)}
      </div>
    );
  }
}

export default createFragmentContainer(AddressBook, {
  viewer: graphql`
    fragment AddressBook_viewer on Customer {
      id
      email
      addresses(first: 99) @connection(key: "AddressBook_addresses") {
        edges {
          node {
            id
            firstname
            lastname
            street
            city
            postcode
            region
            country {
              alpha2
              name
            }
            telephone
            company
            fax
          }
        }
      }
      defaultAddress {
        id
      }
    }
  `,
});
