import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ORDER_STATUS, getPaymentFailedUrl } from '~/components/checkout/helper'

function OrderStatusButton({order}) {
  if (order.verified === 0 && order.status === ORDER_STATUS.PROCESSING) {
    return(
      <Button
        type="primary"
        style={{marginLeft: '10px'}}
        href={order.verificationLink}
      >
        Verify
      </Button>
    )
  } else if (order.status === ORDER_STATUS.PAYMENT_FAILED) {
    return (
      <Button
        type="primary"
        style={{marginLeft: '10px'}}
        href={getPaymentFailedUrl(order.id)}
      >
        Retry Payment
      </Button>
    )
  }
  return null;
}

OrderStatusButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    verified: PropTypes.number,
    verificationLink: PropTypes.string,
  }),
}

OrderStatusButton.defaultProps = {
  order: {
    verificationLink: "",
  },
}

export default OrderStatusButton;
