/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductStock_product$ref: FragmentReference;
declare export opaque type ProductStock_product$fragmentType: ProductStock_product$ref;
export type ProductStock_product = {|
  +id: string,
  +ac: ?string,
  +preorderDate: ?string,
  +backInStockDate: ?string,
  +$refType: ProductStock_product$ref,
|};
export type ProductStock_product$data = ProductStock_product;
export type ProductStock_product$key = {
  +$data?: ProductStock_product$data,
  +$fragmentRefs: ProductStock_product$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductStock_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ac",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preorderDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backInStockDate",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '45875f3164cd87aba883bdd4da1957be';

module.exports = node;
