import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation GeocodeMutation($input: GeocodeInput!) {
    geocode(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };

