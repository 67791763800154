import React from 'react';
import PropTypes from 'prop-types';
import EventAlertModal from './EventAlertModal';
import { BestPrice, ClickCollect, StoreLocations, ShippingPromo } from './NavItems';

const iconStyle = {
  color: 'white',
  fontSize: '20px',
  verticalAlign: 'middle',
};

const spanStyle = {
  color: 'white',
  fontSize: '11px',
  fontWeight: 'bold',
};

export default class TopBar extends React.Component {
  static ShippingPromo = (viewer, router) => {
    return (
      <div style={{ backgroundColor: '#212121' }}>
        <div style={{ height: '35px', maxWidth: '1120px', margin: '0px auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ShippingPromo router={router} spanStyle={spanStyle} amount={viewer.freeShippingAmount} />
        </div>
      </div>
    );
  }

  static propTypes = {
    viewer: PropTypes.shape({
      freeShippingAmount: PropTypes.number,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { viewer, router } = this.props;

    return (
      <div style={{ backgroundColor: '#212121' }}>
        <div style={{ height: '35px', maxWidth: '1120px', margin: '0px auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <ShippingPromo router={router} spanStyle={spanStyle} amount={viewer.freeShippingAmount} />
          <StoreLocations router={router} iconStyle={iconStyle} spanStyle={spanStyle} />
          <ClickCollect router={router} iconStyle={iconStyle} spanStyle={spanStyle} />
          <BestPrice router={router} iconStyle={iconStyle} spanStyle={spanStyle} />
          <EventAlertModal
            viewer={viewer}
            iconStyle={iconStyle}
            spanStyle={spanStyle}
          />
        </div>
      </div>
    )
  }
}
