/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateOrderInput = {|
  applepay?: ?string,
  billing?: ?InputCustomerAddress,
  billingAddressId?: ?string,
  ccCvv?: ?string,
  ccMonth?: ?string,
  ccNumber?: ?string,
  ccOwner?: ?string,
  ccSave?: ?boolean,
  ccType?: ?string,
  ccYear?: ?string,
  forklift?: ?boolean,
  googlepay?: ?InputGooglepay,
  grecaptcha?: ?InputGrecaptcha,
  paymentMethod: string,
  paypalNonce?: ?string,
  paypalOrderId?: ?string,
  paypalPayerId?: ?string,
  paypalPaymentId?: ?string,
  pickupStoreId?: ?string,
  postcode?: ?string,
  shipping?: ?InputCustomerAddress,
  shippingAddressId?: ?string,
  shippingMethod: string,
  signupPassword?: ?string,
  stripeToken?: ?string,
  tillData?: ?InputTill,
  tyroData?: ?InputTyro,
  useCredit?: ?boolean,
  useLoyaltyPoints?: ?boolean,
  zipCheckoutId?: ?string,
  zipCheckoutState?: ?string,
|};
export type InputCustomerAddress = {|
  city?: ?string,
  company?: ?string,
  country?: ?string,
  email?: ?string,
  fax?: ?string,
  firstname?: ?string,
  lastname?: ?string,
  postcode?: ?string,
  region?: ?string,
  street?: ?string,
  telephone?: ?string,
|};
export type InputGooglepay = {|
  apiVersion?: ?number,
  apiVersionMinor?: ?number,
  paymentMethodData?: ?InputGooglepayPaymentMethodData,
|};
export type InputGooglepayPaymentMethodData = {|
  description?: ?string,
  info?: ?InputGooglepayPaymentMethodDataInfo,
  tokenizationData?: ?InputGooglepayPaymentMethodDataTokenizationData,
  type?: ?string,
|};
export type InputGooglepayPaymentMethodDataInfo = {|
  assuranceDetails?: ?InputGooglepayPaymentMethodAssuranceDetails,
  cardDetails?: ?string,
  cardNetwork?: ?string,
|};
export type InputGooglepayPaymentMethodAssuranceDetails = {|
  accountVerified?: ?boolean,
  cardHolderAuthenticated?: ?boolean,
|};
export type InputGooglepayPaymentMethodDataTokenizationData = {|
  token?: ?string,
  type?: ?string,
|};
export type InputGrecaptcha = {|
  token?: ?string,
  type?: ?string,
|};
export type InputTill = {|
  cardData?: ?InputCardData,
  extra?: ?string,
  token?: ?string,
|};
export type InputCardData = {|
  binDigits?: ?string,
  cardType?: ?string,
  fingerprint?: ?string,
  firstSixDigits?: ?string,
  fullName?: ?string,
  lastFourDigits?: ?string,
  month?: ?string,
  year?: ?string,
|};
export type InputTyro = {|
  card?: ?InputTyroCard,
  sessionId?: ?string,
  transactionId?: ?string,
|};
export type InputTyroCard = {|
  brand?: ?string,
  month?: ?string,
  number?: ?string,
  year?: ?string,
|};
export type CreateOrderMutationVariables = {|
  input: CreateOrderInput
|};
export type CreateOrderMutationResponse = {|
  +createOrder: ?{|
    +cartId: ?string,
    +token: ?string,
    +salesOrder: ?{|
      +id: string,
      +encryptedId: ?string,
      +name: ?string,
      +verified: ?number,
      +grandTotal: ?number,
      +shippingTotal: ?number,
      +paymentMethod: ?string,
      +status: ?number,
      +discounts: ?$ReadOnlyArray<?{|
        +id: ?string,
        +name: ?string,
      |}>,
      +lines: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +quantity: ?number,
            +unitPrice: ?number,
            +rowTotal: ?number,
            +product: ?{|
              +id: string,
              +sku: ?string,
              +name: ?string,
              +brand: ?{|
                +id: string,
                +name: ?string,
              |},
            |},
            +children: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                  +quantity: ?number,
                  +unitPrice: ?number,
                  +product: ?{|
                    +id: string,
                    +sku: ?string,
                  |},
                |}
              |}>
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type CreateOrderMutation = {|
  variables: CreateOrderMutationVariables,
  response: CreateOrderMutationResponse,
|};
*/


/*
mutation CreateOrderMutation(
  $input: CreateOrderInput!
) {
  createOrder(input: $input) {
    cartId
    token
    salesOrder {
      id
      encryptedId
      name
      verified
      grandTotal
      shippingTotal
      paymentMethod
      status
      discounts {
        id
        name
      }
      lines(first: 99) {
        edges {
          node {
            id
            name
            quantity
            unitPrice
            rowTotal
            product {
              id
              sku
              name
              brand {
                id
                name
              }
            }
            children(first: 99) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  product {
                    id
                    sku
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrderPayload",
    "kind": "LinkedField",
    "name": "createOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cartId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SalesOrder",
        "kind": "LinkedField",
        "name": "salesOrder",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "encryptedId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grandTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shippingTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Discount",
            "kind": "LinkedField",
            "name": "discounts",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "SalesOrderLineConnection",
            "kind": "LinkedField",
            "name": "lines",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SalesOrderLineEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesOrderLine",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowTotal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "product",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v7/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Brand",
                            "kind": "LinkedField",
                            "name": "brand",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v4/*: any*/),
                        "concreteType": "SalesOrderLineConnection",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SalesOrderLineEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SalesOrderLine",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Product",
                                    "kind": "LinkedField",
                                    "name": "product",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "children(first:99)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "lines(first:99)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrderMutation",
    "selections": (v8/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOrderMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "d5d2705d39eda6cbbcde19857032e580",
    "id": null,
    "metadata": {},
    "name": "CreateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOrderMutation(\n  $input: CreateOrderInput!\n) {\n  createOrder(input: $input) {\n    cartId\n    token\n    salesOrder {\n      id\n      encryptedId\n      name\n      verified\n      grandTotal\n      shippingTotal\n      paymentMethod\n      status\n      discounts {\n        id\n        name\n      }\n      lines(first: 99) {\n        edges {\n          node {\n            id\n            name\n            quantity\n            unitPrice\n            rowTotal\n            product {\n              id\n              sku\n              name\n              brand {\n                id\n                name\n              }\n            }\n            children(first: 99) {\n              edges {\n                node {\n                  id\n                  name\n                  quantity\n                  unitPrice\n                  product {\n                    id\n                    sku\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12ad07095a862eb4cb52683bb33017f6';

module.exports = node;
