import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation ChangeFreebiesCheckoutMutation(
    $input: ChangeFreebiesCheckoutInput!,
    $useCredit: Boolean,
    $useLoyaltyPoints: Boolean,
  ) {
    changeFreebiesCheckout(input:$input) {
      cart {
        id
        storeCredits(first: 999) @connection(key: "CartView_storeCredits") {
          edges {
            node {
              id
              name
              creditAmount
            }
          }
        }
        subtotal
        grandTotal
        loyaltyPoints {
          accruing
        }
        discount
        discounts(useCredit: $useCredit, useLoyaltyPoints: $useLoyaltyPoints) {
          id
          name
          amount
          removable
        }
        isOptFreebiesInCart
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              isFreebie
              unitPrice
              quantity
              unitDiscount
              unitSurcharge
              rowTotal
              priorityShipping {
                getByDate
              }
              product {
                id
                mainImage {
                  id
                  thumbnail
                }
                priorityShipping {
                  getByDate
                }
              }
            }
          }
        }
        paymentMethods(first: 20) {
          edges {
            node {
              code
              title
              type
            }
          }
        }
        ...FreebiePopup_cart
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
