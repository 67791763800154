import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Cookies from 'universal-cookie';
import { Button, message } from 'antd';
import { SetMyStoreMutation } from './mutations';

const cookies = new Cookies();

const MY_STORE_COOKIE = 'my_store';

export const getMyStoreId = (props) => {
  const { viewer } = props;
  const { email } = viewer;

  if (!email) {
    return cookies.get(MY_STORE_COOKIE);
  }
  return get(viewer, "myStore.id");
}

const showSuccess = function showSuccess(store) {
  this.setState({
    myStoreId: store.id,
  });
  message.success(`${store.name} is now set as your preferred store.`);
}

const handleOnClick = function handleOnClick (store) {
  const { viewer } = this.props;
  const email = get(viewer, 'email');

  const input = {
    storeId: store.id,
  }
  const variables = { input };

  if (!email) {
    cookies.set(MY_STORE_COOKIE, store.id, { path: '/' });
    showSuccess.call(this, store);
  } else {
    SetMyStoreMutation.commit({
      environment: this.props.relay.environment,
      variables,
      viewer,
      onCompleted: () => {
        showSuccess.call(this, store);
      },
    });
  }
}

export const renderMyStoreBtn = function renderMyStoreBtn(store) {
  const initStoreId = getMyStoreId(this.props);
  const myStoreId = get(this.state, 'myStoreId');

  if (!myStoreId && store.id === initStoreId) {
    return null;
  } else if (store.id === myStoreId) {
    return null;
  }

  return (
    <div>
      <Button
        type="primary"
        onClick={() => { handleOnClick.call(this, store); }}
      >
        Set As My Store
      </Button>
    </div>
  )
}

export const MyStoreBtn = (props) => {
  const { viewer, relay, store, myStoreId, setMyStoreId, style } = props;
  const email = get(viewer, 'email');
  const initStoreId = getMyStoreId(props);

  const onCompleted = () => {
    setMyStoreId(store.id);
    message.success(`${store.name} is now set as your preferred store.`);
  };

  const onClick = () => {
    if (!email) {
      cookies.set(MY_STORE_COOKIE, store.id, { path: '/' });
      onCompleted()
    } else {
      SetMyStoreMutation.commit({
        environment: relay.environment,
        variables: { input: { storeId: store.id }},
        viewer,
        onCompleted,
      });
    }
  };

  if (!myStoreId && store.id === initStoreId) {
    return null;
  } else if (store.id === myStoreId) {
    return null;
  }

  return (
    <Button type="link" style={style} onClick={onClick}>
      Set As My Store
    </Button>
  )
};

MyStoreBtn.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  myStoreId: PropTypes.string,
  setMyStoreId: PropTypes.func,
  style: PropTypes.shape({}),
};

MyStoreBtn.defaultProps = {
  store: {},
  myStoreId: null,
  setMyStoreId: () => {},
  style: {},
};
