import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button } from 'antd';
import Cookies from 'universal-cookie';
import { importScript } from '~/helper';

const cookies = new Cookies();

const getClientId = () => {
  const { COUNTRY } = process.env;

  if (COUNTRY === "AU") {
    return 'au.com.sydneytools.signin';
  } else if (COUNTRY === "NZ") {
    return 'nz.co.sydneytools.signin';
  }
  throw new Error(`recognised tld ${COUNTRY}`);
};
const SCOPE = "name email";

export default class AppleSignin extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    style: PropTypes.shape({
      width: PropTypes.string,
      backgroundColor: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
  }

  static defaultProps = {
    children: null,
    onSuccess: null,
    onFailure: null,
  }

  constructor(props) {
    super(props);

    const redirectURI = `${window.location.origin}/api/apple/sign-in`;
    const state = cookies.get('cart_id');

    importScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js", () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: getClientId(),
          scope: SCOPE,
          redirectURI,
          state,
          usePopup: true,
        });
      }
    });
  }

  onAppleLogin = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      this.success(data)
    } catch ( error ) {
      this.failure(error);
    }
  }

  success = (data) => {
    const payload = JSON.stringify(data);

    if (payload) {
      this.props.onSuccess(payload, "apple");
    }
  }

  failure = (data) => {
    const error = get(data, 'error');
    const excludeError = ["user_cancelled_authorize", "popup_closed_by_user"];

    if (error && !excludeError.includes(error)) {
      this.props.onFailure("Apple login failed.");
    }
  }

  render() {
    const { style } = this.props;

    return (
      <div>
        <Button
          style={style}
          size="large"
          onClick={() => {
            this.onAppleLogin();
          }}
        >
          {this.props.children}
        </Button>
      </div>
    )
  }
}
