import { makeRouteConfig, Redirect, Route } from 'found';
import React from 'react';
import { graphql } from 'react-relay';
import { message } from 'antd';
import { get } from 'lodash';

import App from './components/App';
import Home from './components/home';
import NotFound from './components/error/NotFound';

import ProductRoutes from './components/product/routes';
import StoreRoutes from './components/store/routes';
import CategoryRoutes from './components/category/routes';
import CmsRoutes from './components/cms/routes';
import AccountRoutes from './components/account/routes';
import ContactRoutes from './components/contact/routes';
import JssRoutes from './components/jss/routes';
import SalesRoutes from './components/sales/routes';
import CheckoutRoutes from './components/checkout/routes';
import WarrantyRoutes from './components/warranty/routes';
import ClaimRoutes from './components/claim/routes';
import PosRoutes from './components/pos/routes';
import SparePartsRoutes from './components/spareparts/routes';

export default makeRouteConfig(
  <Route
    path="/"
    Component={App}
    query={graphql`
      query routes_App_Query {
        viewer {
          ...App_viewer
        }
      }
    `}
    render={({ Component, props }) => {
      const pathname = get(props, 'match.location.pathname');
      const errorMsg = get(props, 'match.location.query.error');

      if (pathname === "/" && errorMsg) {
        message.error(errorMsg);
        props.router.replace('/');
      }

      return <Component {...props} />;
    }}
  >
    <Route
      Component={Home}
      query={graphql`
        query routes_Home_Query {
          viewer {
            ...home_viewer
          }
        }
      `}
    />

    <Route
      path="/404"
      getComponent={NotFound}
    />

    {CategoryRoutes}
    {ProductRoutes}
    {StoreRoutes}
    {CmsRoutes}
    {AccountRoutes}
    {ContactRoutes}
    {JssRoutes}
    {SalesRoutes}
    {CheckoutRoutes}
    {WarrantyRoutes}
    {ClaimRoutes}
    {PosRoutes}
    {SparePartsRoutes}

    <Redirect from="/att" to="/category/addicted-to-tools" />
  </Route>
);
