import PostCareerFormMutation from './PostCareerFormMutation';
import PostCustomerOrderFormMutation from './PostCustomerOrderFormMutation';
import PostFeedbackFormMutation from './PostFeedbackFormMutation';
import PostFranchiseFormMutation from './PostFranchiseFormMutation';
import PostMacnaughtFormMutation from './PostMacnaughtFormMutation';
import PostSptoolsFormMutation from './PostSptoolsFormMutation';
import PostSupplierFormMutation from './PostSupplierFormMutation';
import PostThreeMFormMutation from './PostThreeMFormMutation';

module.exports = {
  PostCareerFormMutation,
  PostCustomerOrderFormMutation,
  PostFeedbackFormMutation,
  PostFranchiseFormMutation,
  PostMacnaughtFormMutation,
  PostSptoolsFormMutation,
  PostSupplierFormMutation,
  PostThreeMFormMutation,
};
