import { get } from 'lodash';
import FormItemPostcode from './FormItemPostcode';
import FormItemRegion from './FormItemRegion';
import FormItemStreet from './FormItemStreet';
import FormItemSuburb from './FormItemSuburb';
import SuburbPostcodeInput from './SuburbPostcodeInput';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 24 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 24 },
    lg: { span: 14 },
  },
};

// For Form v4
/* eslint-disable-next-line no-unused-vars */
const confirmPassword = (field, { form, rule, value }) => {
  const fieldValue = form.getFieldValue(field);
  const len = 8;

  if ((value || '').length < len && (fieldValue || '').length < len) {
    return Promise.reject(new Error(`minimum ${len} characters`));
  }

  if ((fieldValue !== undefined && value !== undefined) && fieldValue !== value) {
    form.setFields([{
      name: field,
      value: fieldValue,
      errors: ["password does not match"],
    }]);
    return Promise.reject(new Error("password does not match"));
  }

  form.setFields([{
    name: field,
    value: fieldValue,
    errors: [],
  }]);
  return Promise.resolve();
}

const fileValidator = (mb = 2, rule, files) => {
  if (Array.isArray(files)) {
    const overSizeFile = files.find((file) => {
      const isLt = file.size / 1000 / 1000 < mb;
      return !isLt;
    });

    if (overSizeFile) {
      return Promise.reject(new Error(`file must be smaller than ${mb}MB!`));
    }
  } else if (typeof (files) === "object" && files.file) {
    const isLt = files.file.size / 1000 / 1000 < mb;
    if (!isLt) {
      return Promise.reject(new Error(`file must be smaller than ${mb}MB!`));
    }
  }

  return Promise.resolve();
};

const filesValidator = (mb = 2, _rule, files) => {
  if (Array.isArray(files)) {
    const filesSize = files.reduce((acc, file) => {
      acc += file.size / 1000 / 1000;
      return acc;
    }, 0);

    if (filesSize >= mb) {
      return Promise.reject(new Error(`total file size must be smaller than ${mb}MB!`));
    }
  } else if (typeof (files) === "object" && files.file) {
    const isLt = files.file.size / 1000 / 1000 < mb;
    if (!isLt) {
      return Promise.reject(new Error(`file must be smaller than ${mb}MB!`));
    }
  }

  return Promise.resolve();
}

const mediaValidator = (files) => {
  if (Array.isArray(files)) {
    const hasVideo = files.find(({ type }) => type.includes('video'));
    if (hasVideo || files.length >= 3) {
      return Promise.resolve();
    } else if (files.length < 3) {
      return Promise.reject(new Error("At least 1 video or 3 images"));
    }
  }

  return Promise.resolve();
}

const scrollToFirstError = {
  behavior: (actions) => {
    const header = document.getElementsByTagName('header')[0];
    const height = header ? header.offsetHeight : 0;

    return actions.forEach(({ el, top, left }) => {
      if (el.scrollTop > top) {
        el.scrollTop = top - height;
      } else {
        el.scrollTop = top;
      }
      el.scrollLeft = left;
    });
  }
}

const getCountry = (address) => {
  let defaultCountry = 'AU';

  if (process.env.COUNTRY === 'NZ') {
    defaultCountry = 'NZ';
  }

  return get(address, 'country.alpha2', defaultCountry);
}

const getDataSourceName = (type) => {
  if (type === 'billing') {
    return 'billingDataSource';
  } else if (type === 'shipping') {
    return 'shippingDataSource';
  }

  return 'dataSource';
}

module.exports = {
  confirmPassword,
  fileValidator,
  filesValidator,
  mediaValidator,
  formItemLayout,
  scrollToFirstError,
  getCountry,
  getDataSourceName,
  FormItemPostcode,
  FormItemRegion,
  FormItemStreet,
  FormItemSuburb,
  SuburbPostcodeInput,
};
