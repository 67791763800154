import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Link } from 'found';
import { Alert, AutoComplete, Button, Col, Collapse, Drawer, Input, Row } from 'antd';
import { EditOutlined, RightOutlined } from '@ant-design/icons';
import { sanitiseCharacters, slugify } from '~/helper';
import { SEARCH_RADIUS, formatDistance, foundInRadius, sortingRule } from './helper';
import {
  getDefaultSubrubPostcode,
  handleSearch,
  renderOption,
  saveDefaultSubrubPostcode,
} from '../product/ShippingQuote';
import StockInfo from './StockInfo';
import HoursInfo from './HoursInfo';
import { GeocodeMutation } from '../product/mutations';
import MyLocationBtn from '../store/MyLocationBtn';

const { Panel } = Collapse;

class StoreSelector extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cart: PropTypes.shape({
        stores: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    updateStore: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const dataSource = [];
    const listStores = [];
    const defaultSubrubPostcode = getDefaultSubrubPostcode();
    const { suburb, postcode, latitude, longitude } = defaultSubrubPostcode || {};

    if (defaultSubrubPostcode) {
      const item = {
        postcode,
        latitude,
        longitude,
        id: 'initial',
        location: suburb,
      };
      dataSource.push(item);
    }

    this.state = {
      dataSource,
      visible: false,
      selectedValue: defaultSubrubPostcode ? `${suburb} ${postcode}` : '',
      listStores,
      collapseKey: null,
    };

  }

  componentDidMount() {
    const defaultSubrubPostcode = getDefaultSubrubPostcode();
    const { suburb, postcode, latitude, longitude } = defaultSubrubPostcode || {};

    if (suburb && postcode && latitude && longitude) {
      this.onSelect({ location: suburb, postcode, latitude, longitude });
    }
  }

  onClose = () => {
    this.setState({ visible: false });
  };

  onSearch = (value) => {
    value = this.sanitise(value);
    handleSearch.call(this, 'dataSource', value);
  }

  onSelect = ({ location: suburb, city, postcode, latitude, longitude }) => {
    if (!latitude || !longitude) {
      return null;
    }

    if (suburb && postcode) {
      if (process.env.COUNTRY === 'NZ') {
        saveDefaultSubrubPostcode({ suburb, city, postcode, latitude, longitude });
      } else {
        saveDefaultSubrubPostcode({ suburb, postcode, latitude, longitude });
      }
    }

    const allStores = this.props.viewer.cart.stores.edges.map(({ node }) => node);

    const listStores = foundInRadius(allStores, { latitude, longitude }, SEARCH_RADIUS);

    let shortList = [];
    if (listStores.length > 0) {
      shortList = listStores.sort(sortingRule).slice(0, 20);
    }

    this.setState({ listStores: shortList });

    return null;
  };

  sanitise = (value) => {
    value = sanitiseCharacters(value);

    this.setState({
      selectedValue: value
    });

    return value;
  }

  handleOnClick = (storeId) => {
    const stores = get(this.props.viewer, 'cart.stores.edges', []);

    this.props.updateStore(stores, storeId);
    this.setState({ visible: false });
  }

  renderStores = () => (
    this.state.listStores.length === 0 ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
        <Alert
          description={<div style={{ fontSize: '14px' }}>We haven&apos;t been able to locate a store near this location.</div>}
          type="info"
          showIcon
        />
      </div>
    ) : (
      this.state.listStores.map((store) => (
        <div key={store.name}>
          {this.renderStore(store, true)}
          <hr />
        </div>
      ))
    )
  )

  renderStore(store, headerLink = false) {
    if (!store) {
      return null;
    }

    if (store.node) {
      store = store.node;
    }

    const { collapseKey } = this.state;
    const storeName = store.name.toLowerCase()
      .replace(/\s*\(.*?\)\s*/g, "") // delete all brackets and contents in between; '?' for lazy matching;
      .trim()                        // removes whitespace from both ends of a string.
      .replace(/\s+/g, '-');         // replace space(s) between words with hyphen.

    return (
      <div>
        <div>
          <h2 style={{ display: 'inline-block', marginBottom: '0px' }}>
            <Link
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                textDecoration: 'underline',
                color: 'rgb(33, 33, 33)',
                fontSize: '24px',
              }}
              onClick={(e) => {
                if (!headerLink) {
                  e.preventDefault();
                }
              }}
              to={headerLink ? `/stores/${slugify(store.state)}/${storeName}` : "#"}
            >
              {store.name}
            </Link>
          </h2>
          <span>{store.distance > 0 && (<span style={{ marginLeft: '10px', color: 'rgb(152,152,152)' }}>{formatDistance(store.distance)}</span>)}</span>
          <div className="clearfix" />
        </div>

        <Row>
          {store.stocks && (
            <Col span={24}>
              <StockInfo
                store={store}
                style={{
                  display: 'inline-block',
                  fontSize: '12px',
                  fontWeight: '700',
                  color: 'white',
                  padding: '0px 5px'
                }}
              />
            </Col>
          )}
          <Col span={24}>
            <div style={{ fontSize: '14px' }}>
              {store.address} <br />
              {store.city} {store.postcode} {store.state} <br />
            </div>
            {store.phone && (
              <div style={{ fontSize: '14px' }}>
                <b>Phone</b>: <a href={`tel:${store.phone}`}>{store.phone}</a>
              </div>
            )}
            {store.fax && (<div><b>Fax</b>: <a href={`fax:${store.fax}`}>{store.fax}</a></div>)}
          </Col>
        </Row>

        {store.hours && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Button
                type="link"
                style={{ paddingLeft: '0px', fontSize: '14px' }}
                onClick={() => {
                  if (collapseKey) {
                    this.setState({ collapseKey: null });
                  } else {
                    this.setState({ collapseKey: store.id });
                  }
                }}
              >
                <RightOutlined
                  style={{
                    fontSize: '12px',
                    transition: 'all 0.3s, visibility 0s',
                    transform: collapseKey === store.id ? 'rotate(90deg)' : null,
                  }}
                />
                View Business Hours
              </Button>
            </div>

            <Collapse
              activeKey={collapseKey}
              accordion
              bordered={false}
            >
              <Panel
                className="panel-no-header"
                showArrow={false}
                header={null}
                key={store.id}
                style={{ color: "red" }}
              >
                <HoursInfo store={store} style={{ fontSize: '14px'}} />
              </Panel>
            </Collapse>
          </>
        )}

        {store.canPickup && (
          <Col span={24}>
            <Button
              type='primary'
              onClick={() => this.handleOnClick(store.id)}
              style={{ width: "100%" }}
            >
              Select Store
            </Button>
          </Col>
        )}

      </div>
    );
  }

  renderAutoComplete = () => (
    <Input.Group compact>
      <MyLocationBtn
        onClick={({ latitude, longitude }) => {
          this.setState({ selectedValue: '' });
          this.onSelect({ latitude, longitude });
        }}
        icon
      />
      <AutoComplete
        allowClear
        autoComplete="googleignoreautofill"
        style={{ width: 'calc(100% - 32px)' }}
        options={this.state.dataSource.map(renderOption)}
        onSearch={this.onSearch}
        value={this.state.selectedValue}
        onSelect={(value, option) => {
          this.sanitise(value);

          if (process.env.COUNTRY === 'NZ') {
            GeocodeMutation.commit({
              environment: this.props.relay.environment,
              variables: { input: { query: value } },
              viewer: this.props.viewer,
              onCompleted: (resp) => {
                const result = get(resp, 'geocode.result', {});

                this.onSelect({
                  location: result.suburb,
                  city: result.city,
                  postcode: result.postcode,
                  latitude: result.latitude,
                  longitude: result.longitude,
                })
              }
            });
          } else {
            this.onSelect(get(option, 'item'));
          }
        }}
        dropdownStyle={{ maxHeight: '160px' }}
        placeholder={<small>Enter a Suburb or Postcode</small>}
      />
    </Input.Group>
  )

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button
          type="link"
          style={{ paddingLeft: '0px' }}
          onClick={() => {
            this.setState({ visible: true });
          }}
        >
          <EditOutlined style={{ fontSize: '12px', }} />
          Find a nearest store
        </Button>
        <Drawer
          contentWrapperStyle={{ maxWidth: '378px', width: '100%' }}
          title="Find a nearest store"
          visible={this.state.visible}
          placement="right"
          onClose={this.onClose}
        >
          {this.renderAutoComplete()}
          <hr />
          {this.renderStores()}
        </Drawer>
      </div>
    );
  }
}

export default createFragmentContainer(StoreSelector, {
  viewer: graphql`
    fragment StoreSelector_viewer on Customer {
      id
      email
      myStore {
        id
      }
      cart {
        stores(first: 999) {
          edges {
            node {
              id
              name
              address
              city
              postcode
              state
              description
              phone
              lat
              lng
              canPickup
              excludeBulkyGood
              stocks {
                productId
                stock
              }
              hours {
                monday {
                  open
                  close
                }
                tuesday {
                  open
                  close
                }
                wednesday {
                  open
                  close
                }
                thursday {
                  open
                  close
                }
                friday {
                  open
                  close
                }
                saturday {
                  open
                  close
                }
                sunday {
                  open
                  close
                }
              }
            }
          }
        }
      }
    }
  `,
});
