import React from 'react';
import PropTypes from 'prop-types';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import { Form } from '@ant-design/compatible';
import { AmexRate } from '../CreditCard';

const cards = {
  visa: "VI",
  mastercard: "MC",
  amex: "AE",
  "American Express": "AE",
};

const { Item: FormItem } = Form;

let stripeInstance = null;

class Card extends React.Component {
  static ccReady(ccNumber, ccExpiry, ccCvv) {
    const hasEmpty = [ccNumber, ccExpiry, ccCvv].some(v => v === null);

    if (hasEmpty) {
      return false;
    }

    const hasError = [ccNumber, ccExpiry, ccCvv].some(v => typeof v.error !== 'undefined');
    if (hasError) {
      return false;
    }

    return true;
  }

  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      validateFields: PropTypes.func.isRequired,
    }).isRequired,
    elements: PropTypes.shape({
      getElement: PropTypes.func.isRequired,
    }),
    stripe: PropTypes.shape({
      createPaymentMethod: PropTypes.func.isRequired,
    }),
    setConfirmPayment: PropTypes.func.isRequired,
    updateCardType: PropTypes.func.isRequired,
  }

  static defaultProps = {
    elements: null,
    stripe: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      showAmexRate: false,
      ccNumber: null,
      ccExpiry: null,
      ccCvv: null,
    };

    props.setConfirmPayment(this.confirmPayment);
  }

  componentWillUnmount() {
    this.props.form.setFieldsValue({
      ccNumber: undefined,
      ccExpiry: undefined,
      ccCvv: undefined,
    });
  }

  confirmPayment = (callback) => {
    const { elements, form, stripe } = this.props;
    const { ccNumber, ccExpiry, ccCvv } =  this.state;

    if (stripe && Card.ccReady(ccNumber, ccExpiry, ccCvv)) {
      stripeInstance = stripe;

      stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      })
      .then(({paymentMethod}) => {
        form.setFieldsValue({
          stripeToken: paymentMethod.id,
        });
        callback();
      });
    }
  }

  updateAmexRate(card) {
    let showAmexRate = false;

    if (card.brand === "amex") {
      showAmexRate = true;
    }

    this.setState({ showAmexRate });
  }

  validate(name, rule, value, callback) {
    const field = this.state[name];

    if (!field) {
      callback("Required");
    }
    if (field && field.error) {
      callback(field.error.message);
      return;
    }
    else if (field && field.complete === false) {
      callback("incomplete");
      return;
    }
    callback();
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    }, () => {
      this.props.form.setFieldsValue({
        [name]: value
      }, () => {
        this.props.form.validateFields([name]);
      });
    });
  }

  render() {
    const { showAmexRate } = this.state;
    const { form, form: { getFieldDecorator }, stripe} = this.props;

    if (!stripe) {
      return null;
    }

    return (
      <div>
        <FormItem
          hasFeedback
          extra={showAmexRate ? <AmexRate code="stripe" style={{ marginLeft: '0px' }} /> : null}
        >
          {getFieldDecorator('ccNumber', {
            rules: [
              { validator: this.validate.bind(this, 'ccNumber') },
            ],
            initialValue: this.state.ccNumber
          })(<div />)}
          <CardNumberElement onChange={value => {
            const ccType = cards[value.brand];

            form.setFieldsValue({
              ccType
            });

            this.updateAmexRate(value);
            this.props.updateCardType(ccType, null);
            this.handleChange("ccNumber", value);
          }}
          />
        </FormItem>

        {getFieldDecorator('ccType')(<div />)}

        <FormItem
          hasFeedback
        >
          {getFieldDecorator('ccExpiry', {
            rules: [
              { validator: this.validate.bind(this, 'ccExpiry') },
            ],
            initialValue: this.state.ccExpiry
          })(<div />)}
          <CardExpiryElement onChange={value => this.handleChange("ccExpiry", value)} />
        </FormItem>

        <FormItem
          hasFeedback
        >
          {getFieldDecorator('ccCvv', {
            rules: [
              { validator: this.validate.bind(this, 'ccCvv') },
            ],
            initialValue: this.state.ccCvv
          })(<div />)}
          <CardCvcElement onChange={value => this.handleChange("ccCvv", value)} />
        </FormItem>
      </div>
    );
  }
}

const Injected = (props) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <Card {...props} elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

export const handleNextAction = (options) => {
  return stripeInstance.handleNextAction(options);
}

export const setStripeInstance = (stripe) => {
  stripeInstance = stripe
}

export default Injected;
