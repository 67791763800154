import { useEffect, useState, useRef } from 'react';
import { get, isEqual } from 'lodash';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const EMAIL_CON_REGEX = /^(.*@)(?!.*\.con).*$/i;

// delete characters which not 0-9, a-z, A-Z, space, hyphen, brackets, dot or apostrophe
const sanitiseCharacters = function sanitiseCharacters(value) {
  return value && value.replace(/[^0-9a-z\s-().']/gi, '');
}

const sanitiseState = function sanitiseState(value) {
  return typeof value === 'string' && value.toUpperCase().replace(/-/g, ' ');
}

const slugify = function slugify(value) {
  return typeof value === 'string' && value.trim().toLowerCase().replace(/\s\s+/, ' ').replace(/[^A-Za-z0-9]*$/i, '').replace(/\s/g, '-');
}


const getProductUrl = function getProductUrl(line, urlPrefix = "/product/") {
  const urlSlug = get(line, 'product.urlSlug', '');
  const parentUrlSlug = get(line, 'configParent.urlSlug', '');

  return `${urlPrefix}${parentUrlSlug || urlSlug}`;
}

const getSocials = function getSocials() {
  if (process.env.COUNTRY === 'NZ') {
    return {
      facebook: "https://www.facebook.com/sydneytoolsnz",
      instagram: "https://www.instagram.com/sydneytoolsnz",
      youtube: "https://youtube.com/@SydneyToolsTVNZ",
      tiktok: "https://www.tiktok.com/@sydneytoolsnz",
    };
  }

  return {
    facebook: "https://www.facebook.com/Sydney-Tools-122274834509703",
    instagram: "https://www.instagram.com/sydneytools",
    youtube: "https://www.youtube.com/user/SydneyToolsPtyLtd",
    tiktok: "https://www.tiktok.com/@sydneytools",
  };
}

// FIXME
const getSupportNumber = function getSupportNumber() {
  if (process.env.COUNTRY === 'NZ') {
    return {
      key: '09 871 7633',
      value: '(09) 871 7633',
    };
  }

  return {
    key: '02 9067 6929',
    value: '(02) 9067 6929',
  };
}

const getThumbnail = function getThumbnail(line) {
  if (line.configParent) {
    return get(line, 'configParent.mainImage.thumbnail', '/static/images/placeholder.svg');
  }

  return get(line, 'product.mainImage.thumbnail', '/static/images/placeholder.svg');
}

const checkIOS = function checkIOS(checkIOS11 = false) {
  const ua = window.navigator.userAgent;
  const iOS = /iPad|iPhone|iPod/.test(ua);
  const iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

  if (checkIOS11) {
    return iOS && iOS11;
  }

  return iOS;
};

const base64 = function base64(i) {
  return Buffer.from(i, 'utf8').toString('base64');
  }

const unbase64 = function unbase64(i) {
  return Buffer.from(i, 'base64').toString('utf8');
}

/**
 ** Takes the "global ID" created by toGlobalID, and returns the type name and ID
 ** used to create it.
 * */
const fromGlobalId = function fromGlobalId(globalId) {
  const unbasedGlobalId = unbase64(globalId);
  const delimiterPos = unbasedGlobalId.indexOf(':');
  return {
    type: unbasedGlobalId.substring(0, delimiterPos),
    id: unbasedGlobalId.substring(delimiterPos + 1)
  };
};

/**
 ** Takes a type name and an ID specific to that type name, and returns a
 ** "global ID" that is unique among all types.
 * */
const toGlobalId = function toGlobalId(type, id) {
  return base64([ type, id ].join(':'));
};


/*
 * data can be in the following format
 * a string(a url)
 * an object {
 *   url: string, // required
 *   data?: [key: string, value: any] // optional
 * }
 */
const importScript = function importScript(data, callback) {
  let url = null;


  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;

  if (typeof data === 'string') {
    url = data;
  }

  if (typeof data === 'object') {
    url = get(data, 'url');
    if (data.data) {
      Object.keys(data.data).forEach((key) => {
        script.setAttribute(`data-${key}`, data.data[key])
      });
    }
  }

  if (script.readyState) {
      script.onreadystatechange = function onreadystatechange() {
          if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              if (callback && typeof callback === "function") {
                  callback();
              }
          }
      };
  } else {
      script.onload = function onload() {
          if (callback && typeof callback === "function") {
              callback();
          }
      };
  }
  script.src = url;
  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
};

const showFilters = function showFilters(catalogues, queryFilters) {
  if (!catalogues.subcategory || queryFilters) {
    return [];
  }

  return get(catalogues, 'facets', []).filter(({ buckets }) => buckets.some((t) => t.imageUrl));
}

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const useStickyFilterProps = function useStickyFilterProps() {
  const defaultState = { disable: true, pinStart: 0 };
  const [windowSize, setWindowSize] = useState({ disable: true, pinStart: 0 });
  const prevRef = useRef();

  const handleResize = () => {
    const filterPanel = window.document.getElementById("filter-panel");
    const pinStart =  window.scrollY + (filterPanel && filterPanel.getBoundingClientRect().y);
    const newState = { disable: false, pinStart };

    if (filterPanel && !isEqual(newState, prevRef.current)) {
      setWindowSize(newState);
    } else if (!filterPanel && !isEqual(defaultState, prevRef.current)) {
      setWindowSize(defaultState);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  useEffect(() => {
    prevRef.current = windowSize;
  }, [windowSize.pinStart]);

  return windowSize;
}

module.exports = {
  EMAIL_REGEX,
  EMAIL_CON_REGEX,
  sanitiseCharacters,
  sanitiseState,
  slugify,
  getProductUrl,
  getSocials,
  getSupportNumber,
  getThumbnail,
  checkIOS,
  fromGlobalId,
  toGlobalId,
  base64encode: base64,
  base64decode: unbase64,
  importScript,
  showFilters,
  usePrevious,
  useStickyFilterProps,
}
