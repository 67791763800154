import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PriorityIcon } from '~/components/svg';
import './style.css';

const textStyle = { fontWeight: 800 };
const advTxtStyle = { float: 'right', fontSize: '90%', fontWeight: 'bold' };

const GetByDate = (props) => {
  const { className, date, advertise, showIcon, size } = props;

  if (!date) {
    return null;
  }

  const getByDate = moment(date);
  const ddd = getByDate.format("ddd");
  const monthDay = getByDate.format("MMM DD");

  const today = moment(moment().format('YYYY-MM-DD'));
  const target = moment(getByDate.format('YYYY-MM-DD'));

  const diff = target.diff(today, 'days');

  let msg = (
    <>
      by <b>{ddd}, {monthDay}</b>
    </>
  );

  if (size === "small") {
    msg = (
      <>
        by {ddd}, {monthDay}
      </>
    );
  } else if (advertise) {
    msg = (
      <>
        by <b>{ddd}</b>, {monthDay}
        <br />
        <small style={advTxtStyle}>{advertise}</small>
      </>
    );
  }

  if (diff === 0) {
    if (size === "small") {
      msg = (
        <>
          <span>Tonight, {monthDay}</span>
        </>
      );
    } else if (size === "bold") {
      msg = (
        <>
          Tonight, <b>{monthDay}</b>
        </>
      );
    } else if (advertise) {
      msg = (
        <>
          <span style={textStyle}>TONIGHT</span>, {monthDay}
          <br />
          <small style={advTxtStyle}>{advertise}</small>
        </>
      );
    } else {
      msg = (
        <>
          <span style={textStyle}>TONIGHT</span>, <b>{monthDay}</b>
        </>
      );
    }
  } else if (diff === 1) {
    if (size === "small") {
      msg = (
        <>
          <span>Tomorrow, {monthDay}</span>
        </>
      );
    } else if (size === "bold") {
      msg = (
        <>
          Tomorrow, <b>{monthDay}</b>
        </>
      );
    } else if (advertise) {
      msg = (
        <>
          <span style={textStyle}>TOMORROW</span>, {monthDay}
          <br />
          <small style={advTxtStyle}>{advertise}</small>
        </>
      );
    } else {
      msg = (
        <>
          <span style={textStyle}>TOMORROW</span>, <b>{monthDay}</b>
        </>
      );
    }
  }

  const fontSize = size === "default" ? '12px' : '10px';
  const marginLeft = size === "default" ? '5px' : null;

  return (
    <div className={className} style={{ whiteSpace: 'no-wrap' }}>
      {showIcon && <PriorityIcon style={{ verticalAlign: 'middle', fontSize: '24px' }} />}
      <span style={{ fontSize, marginLeft, overflowX: 'clip', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        Get It {msg}
      </span>
    </div>
  )
}

GetByDate.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  advertise: PropTypes.string,
  showIcon: PropTypes.bool,
  size: PropTypes.string,
}

GetByDate.defaultProps = {
  className: "priority-default",
  date: null,
  advertise: null,
  showIcon: true,
  size: "default",
};


export default GetByDate;
