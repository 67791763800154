import React from 'react';
import PropTypes from 'prop-types';
import { DollarOutlined, FireOutlined, LikeOutlined, ReadOutlined, ShopOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Link }  from 'found';

import { getTnCPath } from '~/components/cms/routes';
import { GiftCardIcon, PriorityIcon } from '~/components/svg';

const BestPrice = function BestPrice(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to={getTnCPath("best-price-guarantee")}>
      <LikeOutlined style={{...iconStyle, transform: 'scaleX(-1)'}} />
      <span style={spanStyle}>Best Prices</span>
    </Link>
  );
}

const BonusDeals = function BonusDeals(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/category/hot-bonus-deals">
      <FireOutlined style={iconStyle} />
      <span style={spanStyle}>Bonus Deals</span>
    </Link>
  );
}

const CatalogueOffers = function CatalogueOffers(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/p/catalogue">
      <ReadOutlined style={iconStyle} />
      <span style={spanStyle}>Catalogue Offers</span>
    </Link>
  );
}

const CheapDeals = function CheapDeals(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/category/cheap-deals">
      <DollarOutlined style={iconStyle} />
      <span style={spanStyle}>Cheap Deals</span>
    </Link>
  );
}

const ClickCollect = function ClickCollect(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/p/click-collect-terms-conditions">
      <ShoppingOutlined style={iconStyle} />
      <span style={spanStyle}>1 Hour Click &amp; Collect</span>
    </Link>
  );
}

const GiftCards = function GiftCards(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/category/by-brand/sydney-tools/gift-card">
      <GiftCardIcon style={iconStyle} />
      <span style={spanStyle}>Gift Cards</span>
    </Link>
  );
}

const PriorityShipping = function PriorityShipping(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/category/priority">
      <PriorityIcon style={iconStyle} />
      <span style={spanStyle}>PRIORITY</span>
    </Link>
  )
}

const StoreLocations = function StoreLocations(props) {
  const { btnStyle, iconStyle, spanStyle } = props;

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to="/stores">
      <ShopOutlined style={iconStyle} />
      <span style={spanStyle}>Store Locations</span>
    </Link>
  );
}

const ShippingPromo = function ShippingPromo(props) {
  const { btnStyle, spanStyle, amount } = props;

  if (!amount) {
    return null;
  }

  return (
    <Link className="ant-btn ant-btn-link" style={btnStyle} to={getTnCPath("shipping")}>
      <span style={{...spanStyle, lineHeight: '30px', paddingRight: '15px'}}>FREE Shipping over ${amount}*</span>
    </Link>
  );
}

const basePropTypes = {
  btnStyle: PropTypes.shape({}),
  iconStyle: PropTypes.shape({}),
  spanStyle: PropTypes.shape({}),
};

const baseDefaultProps = {
  btnStyle: {},
  iconStyle: {},
  spanStyle: {},
};

BestPrice.propTypes = basePropTypes;
BestPrice.defaultProps = baseDefaultProps;

BonusDeals.propTypes = basePropTypes;
BonusDeals.defaultProps = baseDefaultProps;

CatalogueOffers.propTypes = basePropTypes;
CatalogueOffers.defaultProps = baseDefaultProps;

CheapDeals.propTypes = basePropTypes;
CheapDeals.defaultProps = baseDefaultProps;

ClickCollect.propTypes = basePropTypes;
ClickCollect.defaultProps = baseDefaultProps;

GiftCards.propTypes = basePropTypes;
GiftCards.defaultProps = baseDefaultProps;

PriorityShipping.propTypes = basePropTypes;
PriorityShipping.defaultProps = baseDefaultProps;

StoreLocations.propTypes = basePropTypes;
StoreLocations.defaultProps = baseDefaultProps;

ShippingPromo.propTypes = {
  amount: PropTypes.number,
  ...basePropTypes,
};
ShippingPromo.defaultProps = {
  amount: null,
  ...baseDefaultProps
};

module.exports = {
  BestPrice,
  BonusDeals,
  CatalogueOffers,
  CheapDeals,
  ClickCollect,
  GiftCards,
  PriorityShipping,
  StoreLocations,
  ShippingPromo,
}
