import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button, Divider, Form, Input, message, Switch } from 'antd';
import Helmet from '~/components/page/Helmet';
import { EMAIL_CON_REGEX } from '~/helper';
import { confirmPassword } from '~/components/form';

import { UpdateAccountMutation } from './mutations';

const { Item: FormItem } = Form;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
    md: { span: 9 },
    lg: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
    md: { span: 15 },
    lg: { span: 14 },
  },
};

class AccountForm extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      subscription: PropTypes.shape({
        status: PropTypes.number,
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      changePassword: false,
      confirmDirty: false,
    };
  }

  handleSubmit = (values) => {
    delete values.confirmNewPassword;

    const mutation = {
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      onCompleted: () => {
        this.formRef.current.resetFields();
        message.success('Updated');
      }
    };
    UpdateAccountMutation.commit(mutation);
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  render() {
    const { viewer } = this.props;

    const requiredCurrPw = get(viewer, "isPasswordSecure", true);

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit}>
        <Helmet title="Personal Details" />
        <h1>Personal Details</h1>
        <FormItem
          {...formItemLayout}
          label="First Name"
          name="firstname"
          rules={[
            { transform: (value) => value.trim() },
            { required: true, message: 'required' },
          ]}
          initialValue={viewer.firstname ? viewer.firstname.trim() : null}
        >
          <Input placeholder="First Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Last Name"
          name="lastname"
          rules={[
            { transform: (value) => value.trim() },
            { required: true, message: 'required' },
          ]}
          initialValue={viewer.lastname ? viewer.lastname.trim() : null}
        >
          <Input placeholder="Last Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Email"
          name="email"
          rules={[
            { type: 'email', message: 'Invalid Email', transform: (value) => (value || '').trim() },
            { required: false, message: 'Please double check your email', pattern: EMAIL_CON_REGEX },
            { required: true, message: 'required' }
          ]}
          initialValue={viewer.email}
        >
          <Input placeholder="Email Address" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Subscribe to Newsletter"
          name="subscriptionStatus"
          valuePropName="checked"
          rules={[{ required: true, message: 'required' }]}
          initialValue={get(viewer.subscription, 'status') === 1}
        >
          <Switch />
        </FormItem>

        <Divider />

        <FormItem
          {...formItemLayout}
          label="Change Password"
        >
          <Switch
            onChange={(changePassword) => {
             this.setState({changePassword});
           }}
            checked={this.state.changePassword}
          />
        </FormItem>

        {this.state.changePassword && (
          <div>
            {requiredCurrPw && (
              <div>
                <FormItem
                  {...formItemLayout}
                  label="Current Password"
                  name="currentPassword"
                  rules={[
                    { required: true, message: 'required' },
                  ]}
                >
                  <Input.Password placeholder="Current Password" />
                </FormItem>
              </div>
            )}

            <FormItem
              {...formItemLayout}
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: 'required' },
                (form) => ({
                  validator: (rule, value) => confirmPassword("confirmNewPassword", {form, rule, value})
                }),
              ]}
            >
              <Input.Password placeholder="New Password" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Confirm New Password"
              name="confirmNewPassword"
              rules={[
                { required: true, message: 'required' },
                (form) => ({
                  validator: (rule, value) => confirmPassword("newPassword", {form, rule, value})
                }),
              ]}
            >
              <Input.Password placeholder="Confirm New Password" onBlur={this.handleConfirmBlur} />
            </FormItem>
          </div>
        )}

        <Button type="primary" htmlType="submit">Save</Button>
      </Form>
    )
  }
}

export default createFragmentContainer(AccountForm, {
  viewer: graphql`
    fragment AccountForm_viewer on Customer {
      email
      firstname
      lastname
      isPasswordSecure
      subscription {
        status
      }
    }
  `,
});
