import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AutoLoginMutation($input: AutoLoginInput!) {
    autoLogin(input:$input) {
      message
      result
      token
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };

