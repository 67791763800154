import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { get } from 'lodash';
import { Button, Empty, Popconfirm, Table } from 'antd';

import { RemoveCreditCardMutation } from '~/components/checkout/mutations';
import CC from '~/components/checkout/payment/CreditCard';

const { CC_TYPES } = CC;

class CreditCard extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      creditCards: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  getColumns = () => {
    return [
      {
        title: 'Card',
        dataIndex: 'card',
        key: 'card',
        render: (card) => (
          get(CC_TYPES[card], 'logo')
        )
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        render: this.renderCard,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'action',
        render: ({details}) => {
          return (
            <Popconfirm
              title="Are you sure delete this card?"
              onConfirm={() => this.deleteCard(details)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Remove</Button>
            </Popconfirm>
          )
        }
      },
    ]
  }

  getData = (cards) => {
    return cards.map(({node}) => {
      const { ccBrand, ...details } = node;

      return {
        key: details.id,
        card: ccBrand,
        details,
      }
    });
  }

  deleteCard = (card) => {
    RemoveCreditCardMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: card.id } },
      viewer: this.props.viewer,
    });
  }

  renderCard = (card) => (
    <div>
      <span>{card.ccHolder} </span>
      <span style={{ whiteSpace: 'nowrap' }}>
        &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {card.ccLast4}
        <br />
        Exp: {card.ccExpMonth.toString().padStart(2, '0')}/{card.ccExpYear}
      </span>
    </div>
  )

  render() {
    const { viewer } = this.props;
    const cards = get(viewer, 'creditCards.edges', []);

    return (
      <div>
        <Helmet title="Credit Cards" />
        <h1>Credit Cards</h1>

        <Table
          style={{ overflowX: 'auto' }}
          columns={this.getColumns()}
          dataSource={this.getData(cards)}
          locale={{
            emptyText: <Empty description="You do not have any saved credit cards." image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
          pagination={false}
        />
      </div>
    );
  }
}

export default createFragmentContainer(CreditCard, {
  viewer: graphql`
    fragment CreditCard_viewer on Customer {
      id
      creditCards(first: 999) @connection(key: "App_viewer_creditCards") {
        edges {
          node {
            id
            ccBrand
            ccHolder
            ccLast4
            ccExpMonth
            ccExpYear
            ccHolder
          }
        }
      }
    }
  `,
});
