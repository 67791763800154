import React from 'react';

import PropTypes from 'prop-types';
import { Radio, Select } from 'antd';

const { Option } = Select;

const SORTINGS = ['price_asc', 'price_desc'];

export default class Sorter extends React.Component {
  static propTypes = {
    activeFilters: PropTypes.shape({}).isRequired,
    filters: PropTypes.shape({}).isRequired,
    sort: PropTypes.func.isRequired,
    orderBy: PropTypes.shape({
      field: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
    }),
    selectProps: PropTypes.shape({}),
    style: PropTypes.shape({}),
    mobile: PropTypes.bool,
    popover: PropTypes.bool,
  }

  static defaultProps = {
    orderBy: null,
    selectProps: {},
    style: {},
    mobile: false,
    popover: false,
  }

  constructor(props) {
    super(props);

    this.contextRef = React.createRef();

    this.state = {
      orderBy: this.getOrderBy(this.props.orderBy),
    };
  }

  componentWillReceiveProps(prevProps) {
    this.updateSortBy(prevProps);
  }

  getOrderBy = (orderBy) => {
    if (orderBy) {
      const s = `${orderBy.field}_${orderBy.direction}`
      if (SORTINGS.includes(s)) {
        return s;
      }
    }

    return "";
  }

  handleChange = (orderBy) => {
    if (typeof this.props.sort === 'function') {
      this.props.sort(orderBy)
    }

    this.setState({
      orderBy
    });
  }

  updateSortBy = (prevProps) => {
    // update orderBy on navigation change
    if (prevProps.orderBy === null) {
      this.setState({orderBy: ""});
    }
  }

  render() {
    const { selectProps, style, mobile, popover } = this.props;
    const optionStyle = { fontSize: '12px' };

    if (popover) {
      const optionsStyle = { fontSize: '14px', alignItems: 'center', minHeight: '35px' };

      const options = [
        {
          value: "",
          label: "Featured",
          style: optionsStyle,
        },
        {
          value: "price_asc",
          label: "Price: Low To High",
          style: optionsStyle,
        },
        {
          value: "price_desc",
          label: "Price: High To Low",
          style: optionsStyle,
        },
      ];

      return (
        <Radio.Group
          options={options}
          onChange={({ target: { value } }) => { this.handleChange(value); }}
          value={this.state.orderBy}
        />
      )
    }

    return (
      <div id="sorter" className="sorter" style={style}>
        <Select
          value={this.state.orderBy}
          style={{marginLeft: '5px', width: mobile ? '100%' : '156px', ...optionStyle }}
          onChange={this.handleChange}
          getPopupContainer={() => document.getElementById('sorter')}
          {...selectProps}
        >
          <Option style={optionStyle} value="">Featured</Option>
          <Option style={optionStyle} value="price_asc">Price: Low To High</Option>
          <Option style={optionStyle} value="price_desc">Price: High To Low</Option>
        </Select>
      </div>
    );
  }
}
