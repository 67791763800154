/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAddressInput = {|
  city: string,
  company?: ?string,
  country: string,
  fax?: ?string,
  firstname: string,
  id: string,
  lastname: string,
  postcode: string,
  region: string,
  street: string,
  telephone: string,
|};
export type UpdateAddressMutationVariables = {|
  input: UpdateAddressInput
|};
export type UpdateAddressMutationResponse = {|
  +updateAddress: ?{|
    +address: ?{|
      +id: string,
      +firstname: ?string,
      +lastname: ?string,
      +street: ?string,
      +city: ?string,
      +postcode: ?string,
      +region: ?string,
      +country: ?{|
        +name: ?string
      |},
      +telephone: ?string,
      +company: ?string,
      +fax: ?string,
    |}
  |}
|};
export type UpdateAddressMutation = {|
  variables: UpdateAddressMutationVariables,
  response: UpdateAddressMutationResponse,
|};
*/


/*
mutation UpdateAddressMutation(
  $input: UpdateAddressInput!
) {
  updateAddress(input: $input) {
    address {
      id
      firstname
      lastname
      street
      city
      postcode
      region
      country {
        name
      }
      telephone
      company
      fax
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAddressPayload",
    "kind": "LinkedField",
    "name": "updateAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAddress",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postcode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "region",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "telephone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "company",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fax",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAddressMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d187a1f5cd88a4394b57f5df80ba2940",
    "id": null,
    "metadata": {},
    "name": "UpdateAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAddressMutation(\n  $input: UpdateAddressInput!\n) {\n  updateAddress(input: $input) {\n    address {\n      id\n      firstname\n      lastname\n      street\n      city\n      postcode\n      region\n      country {\n        name\n      }\n      telephone\n      company\n      fax\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '013b92f1dcd153f3b5fe8b91fa7306e4';

module.exports = node;
