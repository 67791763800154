import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const VerifyOrderQuery = graphql`
  query routes_VerifyOrder_Query($encryptedIds: [String!]) {
    viewer {
      ...VerifyOrder_viewer
    }
  }
`;

const routes = (
  <Route
    path="/sales-order/verify/:encryptedId"
    query={VerifyOrderQuery}
    getComponent={() => (
      import(/* webpackChunkName: "VerifyOrder" */'~/components/sales/order').then(module => module.VerifyOrder)
      .catch(() => {
        window.location.reload();
      })
    )}
    prepareVariables={params => ({ encryptedIds: [params.encryptedId] })}
  />
);

export default routes;
