import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation PostSptoolsFormMutation($input: PostSptoolsFormInput!) {
    postSptoolsForm(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
