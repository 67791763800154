/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PosOrder_viewer$ref: FragmentReference;
declare export opaque type PosOrder_viewer$fragmentType: PosOrder_viewer$ref;
export type PosOrder_viewer = {|
  +salesPosOrders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +firstname: ?string,
        +lastname: ?string,
        +email: ?string,
        +telephone: ?string,
        +company: ?string,
        +paymentMethod: ?string,
        +surcharges: ?$ReadOnlyArray<?{|
          +name: ?string,
          +amount: ?number,
        |}>,
        +subtotal: ?number,
        +grandTotal: ?number,
        +operator: ?string,
        +store: ?{|
          +id: string,
          +name: ?string,
        |},
        +insertedAt: ?string,
        +lines: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +quantity: ?number,
              +unitPrice: ?number,
              +unitDiscount: ?number,
              +unitSurcharge: ?number,
              +rowTotal: ?number,
              +product: ?{|
                +urlSlug: ?string,
                +status: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                  +thumbnail: ?string,
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: PosOrder_viewer$ref,
|};
export type PosOrder_viewer$data = PosOrder_viewer;
export type PosOrder_viewer$key = {
  +$data?: PosOrder_viewer$data,
  +$fragmentRefs: PosOrder_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    },
    {
      "kind": "RootArgument",
      "name": "storeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PosOrder_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        },
        {
          "kind": "Variable",
          "name": "storeIds",
          "variableName": "storeIds"
        }
      ],
      "concreteType": "SalesPosOrderConnection",
      "kind": "LinkedField",
      "name": "salesPosOrders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesPosOrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesPosOrder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "telephone",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentMethod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderSurcharge",
                  "kind": "LinkedField",
                  "name": "surcharges",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subtotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grandTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "operator",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Store",
                  "kind": "LinkedField",
                  "name": "store",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "insertedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 999
                    }
                  ],
                  "concreteType": "SalesOrderLineConnection",
                  "kind": "LinkedField",
                  "name": "lines",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderLineEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderLine",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "quantity",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "unitPrice",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "unitDiscount",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "unitSurcharge",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rowTotal",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "product",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "urlSlug",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "status",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProductImage",
                                  "kind": "LinkedField",
                                  "name": "mainImage",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "url",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "thumbnail",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "lines(first:999)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d81ab2dd7a5fb0a4563efc432722f29';

module.exports = node;
