import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const FilterDropdown = (props) => {
  const { name, filterKey, style, isFilterApplied, children } = props;

  const [visible, setVisible] = useState(false);

  const hidePopOver = () => {
    setVisible(false);
  }

  useEffect(() => {
    window.addEventListener("resize", hidePopOver);

    return () => {
      window.removeEventListener("resize", hidePopOver);
    }
  }, []);

  const placement = "bottomLeft";
  let content = children;

  if (filterKey === "price_range") {
    content = cloneElement(children, { handleVisibleChange: setVisible });
  }

  return (
    <div style={style}>
      <Popover
        overlayClassName="dropdown-filter"
        title={null}
        content={content}
        trigger="click"
        placement={placement}
        visible={visible}
        onVisibleChange={setVisible}
        overlayStyle={{ width: '100%', maxWidth: '272px' }}
      >
        <Button
          className="dropdown-filter-btn"
          type={isFilterApplied ? "primary" : "default"}
          style={{
            paddingLeft: '10px',
            paddingRight: '30px',
            border: 'none'
          }}
        >
          <span style={{ fontSize: '14px' }}><b>{name}</b></span>
          <DownOutlined
            className="dropdown-filter-arrow"
            style={{
              transform: (visible ? 'rotate(180deg)' : 'none'),
              color: isFilterApplied ? 'white' : 'unset',
            }}
          />
        </Button>
      </Popover>
    </div>
  )
}

FilterDropdown.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  filterKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isFilterApplied: PropTypes.bool,
  style: PropTypes.shape({}),
}

FilterDropdown.defaultProps = {
  filterKey: null,
  isFilterApplied: false,
  style: {},
}

export default FilterDropdown;
