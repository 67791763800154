import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Layout, Menu } from 'antd';
import { Link } from 'found';
import MediaQuery from 'react-responsive';
import { Logout } from '../auth';

import './style.css';

const { Sider } = Layout;

export const routerRender = ({ Component, props }) => {
  if (Component && props) {
    const { match, viewer } = props;
    return (
      <SideNav
        match={match}
        viewer={viewer}
      >
        <Component {...props} />
      </SideNav>
    )
  }
  return undefined;
}

const menuItems = [
  {key: "personalDetails", name: "Personal Details", path: "/account/my-account"},
  {key: "points", name: "Points", path: "/account/my-points"},
  {key: "orders", name: "Orders", path: "/account/orders"},
  {key: "creditCards", name: "Credit Cards", path: "/account/credit-cards"},
  {key: "wishlist", name: "Wishlist", path: "/account/wishlist"},
  {key: "addresses", name: "Addresses", path: "/account/addresses"},
  {key: "creditApplication", name: "Credit Applications", path: "/p/customer-applications"},
];

class SideNav extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      })
    }).isRequired,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
  }

  getSelectedKeys = (location) => {
    const pathname = get(location, 'pathname', "");
    const found = menuItems.find(({ path }) => pathname.includes(path));

    if (found) {
      return [`${found.key}`];
    }
    return [];
  }

  renderMenuItems = (viewer) => {
    const email = get(viewer, 'email');
    const className = "account-nav";
    const itemStyle = { margin: '0px auto' };

    const prepend = menuItems.map((item) => {
      const { key, name, path } = item;
      return [
        <Menu.Item className={className} key={key} style={itemStyle} disabled={!email && key !== "creditApplication"}>
          <Link to={path}>{name}</Link>
        </Menu.Item>,
        <Menu.Divider key={`${key}-divider`} style={{ margin: '0px' }} />
      ]
    });

    if (!email) {
      return prepend;
    }

    return prepend.concat([
      <Menu.Item className={className} key="logout" style={itemStyle}>
        <Logout key="logout" viewer={viewer} />
      </Menu.Item>,
      <Menu.Divider key="logout-divider" style={{ margin: '0px' }} />
    ]);
  }

  render() {
    const { children, match: { location }, viewer } = this.props;

    return (
      <Layout style={{ backgroundColor: 'white' }} >

        <MediaQuery minWidth={768}>
          <Sider
            theme="light"
            zeroWidthTriggerStyle={{ color: 'white', backgroundColor: '#cb0000', top: '5px' }}
            breakpoint="md"
            collapsedWidth="0"
          >
            <h2 style={{ paddingLeft: '5px' }}>My Account</h2>
            <Menu
              onClick={this.handleClick}
              style={{ borderRight: 'none', width: '100%', paddingRight: '12px'}}
              selectedKeys={this.getSelectedKeys(location)}
              mode="inline"
            >
              {this.renderMenuItems(viewer)}
            </Menu>
          </Sider>
        </MediaQuery>

        <div style={{ backgroundColor: 'inherit', margin: '10px 0px 0px 0px', width: '100%' }}>
          {children}
        </div>
      </Layout>
    )
  }
}
export default createFragmentContainer(SideNav, {
  viewer: graphql`
    fragment SideNav_viewer on Customer {
      email
      ...Logout_viewer
    }
  `,
});
