import AddAddressMutation from './AddAddressMutation';
import PasswordResetMutation from './PasswordResetMutation';
import RemoveAddressMutation from './RemoveAddressMutation';
import UpdateAddressMutation from './UpdateAddressMutation';
import UpdateAccountMutation from './UpdateAccountMutation';
import UpdateLoyaltyOptOutMutation from './UpdateLoyaltyOptOutMutation';
import UpdateSalesAddressMutation from './UpdateSalesAddressMutation';
import UpdateDefaultAddressMutation from './UpdateDefaultAddressMutation';

module.exports = {
  AddAddressMutation,
  PasswordResetMutation,
  RemoveAddressMutation,
  UpdateAddressMutation,
  UpdateAccountMutation,
  UpdateLoyaltyOptOutMutation,
  UpdateSalesAddressMutation,
  UpdateDefaultAddressMutation
};
