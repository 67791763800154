import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import MediaQuery from 'react-responsive';
import { capitalize, get } from 'lodash';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { Link } from 'found';
import { Login, Logout } from '../auth';
import { buttonStyle } from '../auth/Login';

class Account extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func,
    router: PropTypes.shape({
      isActive: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
    }).isRequired,
    mobile: PropTypes.bool,
    bodyStyle: PropTypes.shape({}),
  }

  static defaultProps = {
    onClick: null,
    mobile: false,
    bodyStyle: {},
  }

  constructor(props) {
    super(props);

    // This is not needed in antd v5.
    // This is to fix when the Sticky Header re-enters the screen,
    // the Dropdown default onVisibleChange event (on touch screen), does not work properly.
    this.triggerRef = React.createRef();

    this.state = {
      visible: false,
    }
  }

  onClick = () => {
    if (this.triggerRef.current === "hover") {
      return;
    }

    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  getAccBtnStyle = (onClick) => {
    if (onClick) {
      return (
        {
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: '0px',
          color: 'white',
          height: '46px',
          overflow: 'hidden',
          padding: '0px 10px',
          margin: '5px 0px',
          verticalAlign: 'top',
        }
      )
    }

    return buttonStyle;
  }

  handleMenuClick = () => {
    this.setState({ visible: false });
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
    this.triggerRef.current = flag ? "hover" : null;
  }

  renderMyAccount = (viewer, onClick) => {
    const spanStyle = {
      maxWidth: onClick ? '102px' : '50%',
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      verticalAlign: 'bottom',
    };

    return (
      <div>
        Hi, <span style={spanStyle}>{capitalize(get(viewer, 'firstname'))}</span>
        {!onClick && (
          <span>
            <br />
            My Account <CaretDownOutlined />
          </span>
        )}
        {onClick && (
          <span>
            &nbsp;| My Account
          </span>
        )}
      </div>
    );
  }

  render() {
    const { id, viewer, onClick, mobile, bodyStyle } = this.props;

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="myaccount">
          <Link to="/account/my-account" >Personal Details</Link>
        </Menu.Item>
        <Menu.Item key="mypoints">
          <Link to="/account/my-points" >Points</Link>
        </Menu.Item>
        <Menu.Item key="orders">
          <Link to="/account/orders" >Orders</Link>
        </Menu.Item>
        <Menu.Item key="creditcards">
          <Link to="/account/credit-cards" >Credit Cards</Link>
        </Menu.Item>
        <Menu.Item key="wishlist">
          <Link to="/account/wishlist" >Wishlist</Link>
        </Menu.Item>
        <Menu.Item key="addresses">
          <Link to="/account/addresses" >Addresses</Link>
        </Menu.Item>
        <Menu.Item key="customerapplications">
          <Link to="/p/customer-applications">Credit Applications</Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <Logout key="logout" viewer={viewer} />
        </Menu.Item>
      </Menu>
    );

    if (mobile) {
      return [
        <Login id={`${id}-mobile`} key="login" viewer={viewer} router={this.props.router} match={this.props.match} bodyStyle={bodyStyle} mobile />,
        (viewer.email && (
          <div key="account" id="account-dropdown-mobile" style={bodyStyle}>
            <Dropdown
              overlay={menu}
              onClick={this.onClick}
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.visible}
              getPopupContainer={() => document.getElementById('account-dropdown-mobile')}
            >
              <div className="click-state">
                <UserOutlined style={{ display: 'inline-block', fontSize: '25px' }} />
                <br />
                <MediaQuery minWidth={301}>
                  <span style={{fontWeight: '600'}}>Account</span>
                </MediaQuery>
              </div>
            </Dropdown>
          </div>
        )),
      ];
    }

    return [
      <Login id={`${id}-default`} key="login" viewer={viewer} router={this.props.router} match={this.props.match} />,
      (viewer.email && (
      <div key="account" id="account-dropdown" style={{display: 'inline-block', width: '100%'}}>
        <Dropdown
          overlay={menu}
          onClick={this.onClick}
          onVisibleChange={this.handleVisibleChange}
          visible={this.state.visible}
          getPopupContainer={() => document.getElementById('account-dropdown')}
        >
          <Button
            className="account-button"
            style={this.getAccBtnStyle(onClick)}
          >
            <UserOutlined style={{ display: 'inline-block', fontSize: '20px' }} />
            <div style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginLeft: '8px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {this.renderMyAccount(viewer, onClick)}
            </div>
          </Button>
        </Dropdown>
      </div>
     )),
    ];
  }
}

export default createFragmentContainer(Account, {
  viewer: graphql`
    fragment Account_viewer on Customer {
      firstname
      email
      ...Login_viewer
      ...Logout_viewer
    }
  `,
});
