/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SuccessView_viewer$ref = any;
export type routes_SuccessView_QueryVariables = {|
  ids?: ?$ReadOnlyArray<string>
|};
export type routes_SuccessView_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +salesOrders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +status: ?number,
        |}
      |}>
    |},
    +$fragmentRefs: SuccessView_viewer$ref,
  |}
|};
export type routes_SuccessView_Query = {|
  variables: routes_SuccessView_QueryVariables,
  response: routes_SuccessView_QueryResponse,
|};
*/


/*
query routes_SuccessView_Query(
  $ids: [ID!]
) {
  viewer {
    ...SuccessView_viewer
    id
    salesOrders(first: 1, ids: $ids) {
      edges {
        node {
          id
          name
          status
        }
      }
    }
  }
}

fragment SuccessView_viewer on Customer {
  id
  salesOrders(first: 1, ids: $ids) {
    edges {
      node {
        id
        name
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Variable",
      "name": "ids",
      "variableName": "ids"
    }
  ],
  "concreteType": "SalesOrderConnection",
  "kind": "LinkedField",
  "name": "salesOrders",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesOrderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SuccessView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SuccessView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_SuccessView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b50273dd755c6de210b61b40910b730",
    "id": null,
    "metadata": {},
    "name": "routes_SuccessView_Query",
    "operationKind": "query",
    "text": "query routes_SuccessView_Query(\n  $ids: [ID!]\n) {\n  viewer {\n    ...SuccessView_viewer\n    id\n    salesOrders(first: 1, ids: $ids) {\n      edges {\n        node {\n          id\n          name\n          status\n        }\n      }\n    }\n  }\n}\n\nfragment SuccessView_viewer on Customer {\n  id\n  salesOrders(first: 1, ids: $ids) {\n    edges {\n      node {\n        id\n        name\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f84eb1d3a141da175baa449e4ba38f56';

module.exports = node;
