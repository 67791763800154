import AutoLoginMutation from './AutoLoginMutation';
import CreateOrderMutation from './CreateOrderMutation';
import ChangeFreebiesCheckoutMutation from './ChangeFreebiesCheckoutMutation';
import ChangeItemQtyCheckoutMutation from './ChangeItemQtyCheckoutMutation';
import CheckEmailMutation from './CheckEmailMutation';
import CompleteRedirectOrderMutation from './CompleteRedirectOrderMutation';
import GetAddressDetailMutation from './GetAddressDetailMutation';
import GetTyroSessionMutation from './GetTyroSessionMutation';
import PaymentRetryMutation from './PaymentRetryMutation';
import RemoveProductFromCartCheckoutMutation from './RemoveProductFromCartCheckoutMutation';
import ApplyCouponCodeMutation from './ApplyCouponCodeMutation';
import RemoveCouponCodeMutation from './RemoveCouponCodeMutation';
import RemoveCreditCardMutation from './RemoveCreditCardMutation';
import SaveQuoteMutation from './SaveQuoteMutation';
import SearchAddressMutation from './SearchAddressMutation';
import TyroAuthenticatePayerMutation from './TyroAuthenticatePayerMutation';

module.exports = {
  ApplyCouponCodeMutation,
  AutoLoginMutation,
  CreateOrderMutation,
  ChangeFreebiesCheckoutMutation,
  ChangeItemQtyCheckoutMutation,
  CheckEmailMutation,
  CompleteRedirectOrderMutation,
  GetAddressDetailMutation,
  GetTyroSessionMutation,
  PaymentRetryMutation,
  RemoveProductFromCartCheckoutMutation,
  RemoveCouponCodeMutation,
  RemoveCreditCardMutation,
  SaveQuoteMutation,
  SearchAddressMutation,
  TyroAuthenticatePayerMutation,
};
