import React from 'react';

export default class ErroPage extends React.Component {
  render() {
    return (
      <div>
        <img alt="Server Errror" src="/static/images/server_error.svg" />
      </div>
    );
  }
}

