import React from 'react';
import PropTypes from 'prop-types';
import { Dot, DotGroup } from 'pure-react-carousel';

const renderDots = (props) => {
  const {
    totalSlides,
    currentSlide,
  } = props;

  const dots = [];

  for (let i = 0; i < totalSlides; i += 1) {
    dots.push(
      <li key={i} className={currentSlide === i ? 'slick-active' : ''}>
        <Dot
          slide={i}
          style={{
            background: 'rgb(189, 189, 189)',
          }}
        />
      </li>
    );
  }
  return (
    <ul className="slick-dots slick-dots-bottom" style={{zIndex: 2, position: 'relative', height: 'auto'}}>
      {dots}
    </ul>
  );
};

renderDots.propTypes = {
  totalSlides: PropTypes.number.isRequired,
  currentSlide: PropTypes.number.isRequired,
};

export default function Dots() {
  return (
    <DotGroup
      style={{
        position: 'relative',
        bottom: '-20px',
        lineHeight: '15px',
      }}
      renderDots={renderDots}
    />
  );
}
