import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation SetMyStoreMutation($input: SetMyStoreInput!) {
    setMyStore(input:$input) {
      viewer {
        myStore {
          id
        }
      }
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };
