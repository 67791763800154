/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PostReviewInput = {|
  content: string,
  delivery: number,
  price: number,
  productId: string,
  quality: number,
  service: number,
  value: number,
|};
export type PostReviewMutationVariables = {|
  input: PostReviewInput
|};
export type PostReviewMutationResponse = {|
  +postReview: ?{|
    +reviewEdge: ?{|
      +__typename: string,
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +content: ?string,
        +replies: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +content: ?string,
              +customer: ?{|
                +firstname: ?string
              |},
              +insertedAt: ?string,
            |}
          |}>
        |},
        +ratings: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +star: ?number,
              +percentage: ?number,
              +ratingType: ?{|
                +code: ?string
              |},
            |}
          |}>
        |},
        +product: ?{|
          +name: ?string,
          +urlSlug: ?string,
          +mainImage: ?{|
            +id: string,
            +url: ?string,
          |},
        |},
        +customer: ?{|
          +firstname: ?string
        |},
        +insertedAt: ?string,
        +status: ?number,
      |},
    |}
  |}
|};
export type PostReviewMutation = {|
  variables: PostReviewMutationVariables,
  response: PostReviewMutationResponse,
|};
*/


/*
mutation PostReviewMutation(
  $input: PostReviewInput!
) {
  postReview(input: $input) {
    reviewEdge {
      __typename
      cursor
      node {
        id
        content
        replies(first: 100) {
          edges {
            node {
              id
              content
              customer {
                firstname
                id
              }
              insertedAt
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        ratings(first: 10) {
          edges {
            node {
              id
              star
              percentage
              ratingType {
                code
              }
            }
          }
        }
        product {
          name
          urlSlug
          mainImage {
            id
            url
          }
          id
        }
        customer {
          firstname
          id
        }
        insertedAt
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "insertedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10
    }
  ],
  "concreteType": "ReviewRatingConnection",
  "kind": "LinkedField",
  "name": "ratings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReviewRatingEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReviewRating",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "star",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReviewRatingType",
              "kind": "LinkedField",
              "name": "ratingType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "ratings(first:10)"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Customer",
  "kind": "LinkedField",
  "name": "customer",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PostReviewPayload",
        "kind": "LinkedField",
        "name": "postReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewEdge",
            "kind": "LinkedField",
            "name": "reviewEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Review",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": "replies",
                    "args": null,
                    "concreteType": "ReviewReplyConnection",
                    "kind": "LinkedField",
                    "name": "__ReviewList_replies_connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReviewReplyEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReviewReply",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PostReviewPayload",
        "kind": "LinkedField",
        "name": "postReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReviewEdge",
            "kind": "LinkedField",
            "name": "reviewEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Review",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "concreteType": "ReviewReplyConnection",
                    "kind": "LinkedField",
                    "name": "replies",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReviewReplyEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ReviewReply",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v16/*: any*/),
                              (v8/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": "replies(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v15/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "ReviewList_replies",
                    "kind": "LinkedHandle",
                    "name": "replies"
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v8/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c96465dad8029505484effbd4898a578",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "postReview",
            "reviewEdge",
            "node",
            "replies"
          ]
        }
      ]
    },
    "name": "PostReviewMutation",
    "operationKind": "mutation",
    "text": "mutation PostReviewMutation(\n  $input: PostReviewInput!\n) {\n  postReview(input: $input) {\n    reviewEdge {\n      __typename\n      cursor\n      node {\n        id\n        content\n        replies(first: 100) {\n          edges {\n            node {\n              id\n              content\n              customer {\n                firstname\n                id\n              }\n              insertedAt\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        ratings(first: 10) {\n          edges {\n            node {\n              id\n              star\n              percentage\n              ratingType {\n                code\n              }\n            }\n          }\n        }\n        product {\n          name\n          urlSlug\n          mainImage {\n            id\n            url\n          }\n          id\n        }\n        customer {\n          firstname\n          id\n        }\n        insertedAt\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '992aef8fd23fbd8e52b1003529ac208f';

module.exports = node;
