/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddProductToWishlistInput = {|
  productId: string
|};
export type AddProductToWishlistMutationVariables = {|
  input: AddProductToWishlistInput
|};
export type AddProductToWishlistMutationResponse = {|
  +addProductToWishlist: ?{|
    +wishlist: ?{|
      +id: string,
      +products: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +price: ?number,
            +inWishlist: ?boolean,
          |}
        |}>
      |},
    |}
  |}
|};
export type AddProductToWishlistMutation = {|
  variables: AddProductToWishlistMutationVariables,
  response: AddProductToWishlistMutationResponse,
|};
*/


/*
mutation AddProductToWishlistMutation(
  $input: AddProductToWishlistInput!
) {
  addProductToWishlist(input: $input) {
    wishlist {
      id
      products(first: 999) {
        edges {
          node {
            id
            name
            price
            inWishlist
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddProductToWishlistPayload",
    "kind": "LinkedField",
    "name": "addProductToWishlist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Wishlist",
        "kind": "LinkedField",
        "name": "wishlist",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              }
            ],
            "concreteType": "ProductConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "inWishlist",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "products(first:999)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProductToWishlistMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddProductToWishlistMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "85fa9d9745756f6b88df1b351df7368d",
    "id": null,
    "metadata": {},
    "name": "AddProductToWishlistMutation",
    "operationKind": "mutation",
    "text": "mutation AddProductToWishlistMutation(\n  $input: AddProductToWishlistInput!\n) {\n  addProductToWishlist(input: $input) {\n    wishlist {\n      id\n      products(first: 999) {\n        edges {\n          node {\n            id\n            name\n            price\n            inWishlist\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3dfc24a4e1ab3287180eb31d57c7b06b';

module.exports = node;
