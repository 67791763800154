/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FreebiePopup_cart$ref: FragmentReference;
declare export opaque type FreebiePopup_cart$fragmentType: FreebiePopup_cart$ref;
export type FreebiePopup_cart = {|
  +freebies: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +mode: ?number,
        +products: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +urlSlug: ?string,
              +mainImage: ?{|
                +id: string,
                +url: ?string,
                +thumbnail: ?string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: FreebiePopup_cart$ref,
|};
export type FreebiePopup_cart$data = FreebiePopup_cart;
export type FreebiePopup_cart$key = {
  +$data?: FreebiePopup_cart$data,
  +$fragmentRefs: FreebiePopup_cart$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreebiePopup_cart",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "FreebieTierConnection",
      "kind": "LinkedField",
      "name": "freebies",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FreebieTierEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FreebieTier",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v0/*: any*/),
                  "concreteType": "ProductConnection",
                  "kind": "LinkedField",
                  "name": "products",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "urlSlug",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProductImage",
                              "kind": "LinkedField",
                              "name": "mainImage",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "thumbnail",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "products(first:99)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "freebies(first:99)"
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '95c77586e80eab2913b072117f47cc51';

module.exports = node;
