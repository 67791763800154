/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type LoyaltyPoint_viewer$ref: FragmentReference;
declare export opaque type LoyaltyPoint_viewer$fragmentType: LoyaltyPoint_viewer$ref;
export type LoyaltyPoint_viewer = {|
  +id: string,
  +storeCredits: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +remainingPoints: ?number,
        +expiry: ?string,
      |}
    |}>
  |},
  +loyaltyOptOut: ?boolean,
  +loyaltyPoints: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +remainingPoints: ?number,
        +expiry: ?string,
      |}
    |}>
  |},
  +$refType: LoyaltyPoint_viewer$ref,
|};
export type LoyaltyPoint_viewer$data = LoyaltyPoint_viewer;
export type LoyaltyPoint_viewer$key = {
  +$data?: LoyaltyPoint_viewer$data,
  +$fragmentRefs: LoyaltyPoint_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiry",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoyaltyPoint_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "CustomerStoreCreditConnection",
      "kind": "LinkedField",
      "name": "storeCredits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerStoreCreditEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerStoreCredit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": "remainingPoints",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creditRemaining",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "storeCredits(first:99999)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loyaltyOptOut",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "CustomerLoyaltyPointConnection",
      "kind": "LinkedField",
      "name": "loyaltyPoints",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerLoyaltyPointEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerLoyaltyPoint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remainingPoints",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "loyaltyPoints(first:99999)"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '58a9d313cfb55dd30b685d8e59783df1';

module.exports = node;
