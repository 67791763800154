import React, { useState, useRef, useLayoutEffect, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { AutoComplete, Input, Tag, Button } from 'antd';
import { debounce, get } from 'lodash';
import Cookies from 'universal-cookie';
import { ClearOutlined } from '@ant-design/icons';

import './style.css';

const cookies = new Cookies();

const MAX_ENTRIES = 8;

const TAG_BOX_HEIGHT = 54;

const getSuggestions = (props) => (props.viewer.searchAutocomplete || []).map(s => ({ value: s }));

const SEARCH_HISTORY = {
  clear() {
    this.entries = [];
    this.setCookies();
  },
  entries: [],
  length() {
    return this.entries.length;
  },
  isEmpty() {
    return this.entries.length === 0;
  },
  init(query = "") {
    this.entries = cookies.get('searchHistory') || [];

    if (query) {
      this.upsert(query);
    }

    return this;
  },
  set(entries) {
    this.entries = entries.map(item => {
      const query = this.getQuery(item);
      return { [query.toLowerCase()]: item[query] };
    });
    this.setCookies();
  },
  setCookies() {
    cookies.set('searchHistory', this.entries, { path: '/' });
  },

  getQuery(item) {
    return Object.keys(item)[0];
  },
  upsert(query) {
    if (typeof query !== "string" || query.length === 0) {
      return;
    }

    // make search item case insensitive
    query = query.toLowerCase();

    // upsert the query to history
    const existingQuery = this.entries.findIndex(item => this.getQuery(item) === query);

    // remove existing query
    if (existingQuery !== -1) {
      this.entries.splice(existingQuery, 1);
    }

    // prepend query
    this.entries.unshift({ [query]: new Date().toISOString() });

    // at most 8 queries
    this.entries.splice(MAX_ENTRIES);

    this.setCookies();
  }
};

const Header = (props) => {
  const { deleteTagMode, onDelete, toggleDelete, searchHistories, clearAll } = props;

  if (searchHistories.isEmpty()) {
    return null;
  }

  let children = null ;

  if (deleteTagMode) {
    children = (
      <div style={{ display: 'flex', cursor: 'pointer'}}>
        <Button type="text" onClick={clearAll}>
          <span className='black-href' style={{textDecoration: 'underline'}}>Clear All</span>
        </Button>

        <Button type="text" onClick={onDelete}>
          <span className='black-href' style={{textDecoration: 'underline'}}>Done</span>
        </Button>
      </div>
    );
  } else {
    children = <Button icon={<ClearOutlined />} onClick={toggleDelete} />;
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', marginRight: '10px', color: "grey"}}>
      Recent Searches
      {children}
    </div>
  );
}

Header.propTypes = {
  deleteTagMode: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  toggleDelete: PropTypes.func.isRequired,
  searchHistories: PropTypes.shape({
    length: PropTypes.func.isRequired,
    getQuery: PropTypes.func.isRequired,
    isEmpty: PropTypes.func.isRequired,
    entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  clearAll: PropTypes.func.isRequired,
};

// A state for managing Show More and Show Less logic
// 0 => Disabled
// 1 => Show More
// 2 => Show Less
const FoldingState = {
  init() {
    return this;
  },
  state: 0,
  isDisabled() { return this.state === 0; },
  isShowMore() { return this.state === 1; },
  isShowLess() { return this.state === 2; },
  disable() { this.state = 0; },
  showMore() { this.state = 1; },
  showLess() { this.state = 2; },
  toggle() {
    // 0 becomes 1
    // 1 becomes 2
    // 2 becomes 1
    this.state = (this.state % 2) + 1;
  }
};

const reducer = () => { };

const SearchTags = (props) => {
  const { searchHistories, deleteTagMode, onClick, onClose } = props;
  const [tagNum, setTagNum] = useState(MAX_ENTRIES);
  const [maxTagNum, setMaxTagNum] = useState(MAX_ENTRIES);

  // This is to record the first search item from the last rendering
  // so that we can compare the first search item between last rendering and this rendering in the future "Constructor"
  // if there is any change, that means navigation happens and we need to initialise the search bar
  const prevSearchHistoryEntry = useRef(null);

  // Similar to prevSearchHistoryEntry, this is trying to compare the innerWidth between rendering.
  // If changes happenes, that means resize events happens and we need to initialise the search bar.
  const prevWindowInnerWidth = useRef(null);

  // Similar to prevSearchHistoryEntry, this is to record the previous DeleteTag mode
  // so that we can know whether viewer has finished deleting the tag.
  // once tag deletion finished, we need to initialise the search bar.
  const prevDeleteTag = useRef(false);

  const [foldingState] = useReducer(reducer, FoldingState.init());

  const initTagBox = () => {
    setTagNum(0);
    foldingState.disable();
  }

  const expandSearchHis = () => {
    setTagNum(searchHistories.length());
    foldingState.disable();
  }

  useLayoutEffect(() => {
    // Act as the constructor if Resize or Navigation Happenes
    // For navigation and resize we need to initialise the search bar.
    // ---------------------- Start --------------------------------------------------
    const navigation = (searchHistories.entries[0] !== prevSearchHistoryEntry.current);
    const resize = (window.innerWidth !== prevWindowInnerWidth.current);

    if (navigation || resize) {
      initTagBox();

      const [ firstSearchHistoryEntry ] = searchHistories.entries;
      prevSearchHistoryEntry.current = firstSearchHistoryEntry;
      prevWindowInnerWidth.current = window.innerWidth;

      return;
    }
    // ------------------------ End ---------------------------------------------------

    const { height } = document.querySelector('.history-tag').getBoundingClientRect();
    const historyTagRendered = (height > 0);
    const noNeedToFold = (historyTagRendered && tagNum === searchHistories.length() && height <= TAG_BOX_HEIGHT);

    if (noNeedToFold || deleteTagMode) {
      expandSearchHis();
      return;
    }

    // increase tag num by 1 if height is not over and disable the show button
    // if the show more/less is enabled, do not add the tag num.
    if (height <= TAG_BOX_HEIGHT && foldingState.isDisabled() && tagNum < searchHistories.length()) {
      setTagNum(tagNum + 1);
    }

    // decrease tag num by 1 if height is over
    // once the height is over, enable show more button and Tag number should be stable
    // Tag number should not be either added or deleted if the show more button is displayed
    if (height > TAG_BOX_HEIGHT && foldingState.isDisabled()) {
      setTagNum(tagNum - 1);
      foldingState.showMore();
    }

  }, [tagNum, searchHistories.entries[0], window.innerWidth, deleteTagMode]);

  // Initialise the Search Tag box after finishing deleting tags
  useEffect(() => {
    const deleteTagDone = (!deleteTagMode && prevDeleteTag.current);

    if (deleteTagDone){
      initTagBox();
    }

    prevDeleteTag.current = deleteTagMode;
  }, [deleteTagMode]);

  return (
    <div className='history-tag' style={{display: 'flex', flexWrap: 'wrap', lineHeight: '20px', overflow: 'hidden'}}>
      {searchHistories.entries.slice(0, tagNum).map(item => (
        <Tag
          key={`${searchHistories.getQuery(item)}`}
          id={`${searchHistories.getQuery(item)}`}
          closable={deleteTagMode}
          onClose={() => onClose(item)}
          style={{
            marginBottom: '5px',
            cursor: 'pointer',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onClick={() => onClick(searchHistories.getQuery(item))}
          onMouseEnter={e => { e.target.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)'; }}
          onMouseLeave={e => { e.target.style.boxShadow = 'none'; }}
        >
          {searchHistories.getQuery(item)}
        </Tag>
      ))}

      {foldingState.isShowMore() && (
        <Button
          style={{height: "20px", margin: "0"}}
          type="text"
          onClick={()=> {
            setMaxTagNum(tagNum);
            setTagNum(searchHistories.length());
            foldingState.toggle();
          }}
        >
          <span className='black-href' style={{textDecoration: 'underline'}}>Show More</span>
        </Button>
      )}

      {foldingState.isShowLess() && (
        <Button
          style={{height: "20px", margin: "0", textDecoration: "underline"}}
          type="text"
          onClick={()=> {
            setTagNum(maxTagNum);
            foldingState.toggle();
          }}
        >
          <span className='black-href' style={{textDecoration: 'underline'}}>Show Less</span>
        </Button>
      )}
    </div>
  );
};

SearchTags.propTypes = {
  deleteTagMode: PropTypes.bool.isRequired,
  searchHistories: PropTypes.shape({
    length: PropTypes.func.isRequired,
    getQuery: PropTypes.func.isRequired,
    isEmpty: PropTypes.func.isRequired,
    entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

class Search extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      searchAutocomplete: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const query = get(this.props.match.location, 'query.q', '').trim();

    if (query) {
      this.autoCompleteQuery(query);
    }

    this.state = {
      autoCompleteOpen: false,
      query,
      deleteTagMode: false,
      searchHistories: SEARCH_HISTORY.init(query),
    }
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps.match;
    const { location: currLocation } = this.props.match;

    const prevQuery = get(prevLocation, 'query.q', '').trim();
    const currQuery = get(currLocation, 'query.q', '').trim();

    if (prevQuery !== currQuery) {
      if (currQuery) {
        this.autoCompleteQuery(currQuery, () => {
          this.setState({ query: currQuery });
        });
      } else {
        this.setState({ query: currQuery });
      }
    }
  }

  updateAutoCompleteOpen = () => {
    this.setState({ autoCompleteOpen: (getSuggestions(this.props).length > 0) || !this.state.searchHistories.isEmpty()});
  }

  deleteTag = (query) => {
    const { searchHistories } = this.state;

    this.state.searchHistories.set(searchHistories.entries.filter(item => item !== query));

    this.updateAutoCompleteOpen();
  }

  clearAllTags = () => {
    this.state.searchHistories.clear();

    this.updateAutoCompleteOpen();
  }

  history = (options) => {
    const { deleteTagMode, searchHistories } = this.state;

    const searchHisHTML = (
      <div
        className='autocomplete-select-option'
        role="button"
        tabIndex={0}
        onClick={event => event.stopPropagation()}
        onKeyDown={() => {}}
        style={{padding: '5px 12px', border: '0'}}
      >
        {!searchHistories.isEmpty() && (
          <div>
            <Header
              deleteTagMode={deleteTagMode}
              searchHistories={searchHistories}
              toggleDelete={() => this.setState({deleteTagMode: !deleteTagMode})}
              clearAll={this.clearAllTags}
              onDelete={() => {
                this.setState({
                  deleteTagMode: !this.state.deleteTagMode
                });
              }}
            />

            <SearchTags
              deleteTagMode={deleteTagMode}
              searchHistories={searchHistories}
              onClose={this.deleteTag}
              onClick={this.handleSelect}
            />
          </div>
        )}
        {getSuggestions(this.props).length > 0 && <div style={{marginTop: '5px', textAlign: 'left', color: "grey"}}>Suggestions</div>}
      </div>
    )

    return  options.map(option => ({
      value: option.value,
      label: option.value === "Search History" ? searchHisHTML : <div style={{padding: '5px 12px'}}>{option.value}</div>
    }));
  }

  debouncedOnchange = debounce((query, selected) => {
    const qurey = query.trim();

    if (qurey.length > 1 && Object.keys(selected).length === 0) {
      this.autoCompleteQuery(qurey, this.updateAutoCompleteOpen());
    } else if (!qurey) {
      this.autoCompleteQuery(query);
      this.dismissSuggestions();
    }
  }, 100)

  autoCompleteQuery = (query, callback) => {
    const refetchVariables = {
      query,
    };

    this.props.relay.refetch(refetchVariables, null, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  dismissSuggestions = () => {
    this.setState({ autoCompleteOpen: false, deleteTagMode: false});
  }

  handleClick = () => {
    this.updateAutoCompleteOpen();
  }

  handleSearch = (value) => {
    this.handleSelect(value);
  };

  handleSelect = (input) => {
    this.dismissSuggestions();

    // when keyboard enter the search his option, make the input blank and do not redirect
    if (input.trim() === 'Search History') {
      this.setState({query: ''});
      return;
    }

    const query = input.trim();

    this.setState({
      query,
    }, () => {
      if (query) {
        this.state.searchHistories.upsert(query);
        this.props.router.push(`/search?p=1&q=${encodeURIComponent(query)}`);
      }
    });
  }

  render() {
    const {
      autoCompleteOpen,
      query,
    } = this.state;

    const options = getSuggestions(this.props);
    const optionsHistory = [{ value: 'Search History' }, ...options];

    return (
      <AutoComplete
        id="search"
        key="search"
        type="search"
        style={{
          height: '33px',
          display: 'block',
        }}
        value={query}
        open={autoCompleteOpen}
        onBlur={this.dismissSuggestions}
        options={this.history(optionsHistory)}
        onChange={(change, selected) => {
          this.setState({ query: change });
          this.debouncedOnchange(change, selected)
        }}
        onSelect={this.handleSelect}
        dropdownRender={(menu) => (
          <div className='search-bar'>
            {menu}
          </div>
        )}
      >
        <Input.Search
          size="small"
          enterButton
          onSearch={() => this.handleSearch(query)}
          onClick={this.handleClick}
          style={{
            color: 'white',
            display: 'block',
            height: '33px',
          }}
        />
      </AutoComplete>
    );
  }
}

export default createRefetchContainer(Search, {
  viewer: graphql`
    fragment Search_viewer on Customer @argumentDefinitions(
      query: {type: "String", defaultValue: ""}
    ) {
      searchAutocomplete(query: $query)
    }
  `
},
  graphql`
  query SearchRefetchQuery($query: String) {
    viewer {
      ...Search_viewer @arguments(query: $query)
    }
  }
`
);
