const {
  requestSubscription,
  graphql,
} = require('react-relay');

const subscription = graphql`
  subscription CartUpdatedSubscription($id: ID) {
    cartUpdated(id: $id) {
      id
      subtotal
      grandTotal
      storeCredits(first: 999) @connection(key: "CartView_storeCredits") {
        edges {
          node {
            id
            name
            stealthMode
            creditAmount
          }
        }
      }
      lines(first: 999) @connection(key: "CartView_lines") {
        edges {
          node {
            id
            name
            isFreebie
            unitPrice
            quantity
            rowTotal
            unitDiscount
            unitSurcharge
            product {
              id
              urlSlug
              name
              onlineOnly
              mainImage {
                id
                thumbnail
              }
            }
          }
        }
      }
      ...FreebiePopup_cart
    }
  }
`;

export default (environment, variables = {}) => requestSubscription(
    environment,
    {
      subscription,
      variables
    }
  )
