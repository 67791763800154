import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { DatePicker, Table } from 'antd';

import Helmet from '~/components/page/Helmet';
import 'antd/lib/date-picker/style';
import 'antd/lib/table/style';

const urlPrefix = "/product/";

class PriceChange extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      priceChanges: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <a href={`${urlPrefix}${record.urlSlug}`} target="_blank" rel="noopener noreferrer">{text}</a>,
    }, {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    }, {
      title: 'Aden Code',
      dataIndex: 'adenCode',
      key: 'aden_code',
    }];
  }

  onChange = (_, dateString) => {
    const refetchVariables = {
      date: dateString,
    };

    this.props.relay.refetch(refetchVariables);
  }

  render() {
    const { viewer: { priceChanges }} = this.props;

    return (
      <div>
        <Helmet title="Price Changes" />
        <h1>Price Changes</h1>
        <DatePicker onChange={this.onChange} />

        <Table columns={this.columns} dataSource={priceChanges} rowKey={record => record.id} pagination={false} />
      </div>
    );
  }
}

export default createRefetchContainer(
  PriceChange, {
    viewer: graphql`
    fragment PriceChange_viewer on Customer @argumentDefinitions(
      date: {type: "String!", defaultValue: ""},
    ) {
      priceChanges(date: $date) {
        id
        name
        adenCode
        barcode
        price
        urlSlug
      }
    }
  `,
  },
  graphql`
    query PriceChangeRefetchQuery($date: String!) {
      viewer {
        ...PriceChange_viewer @arguments(date: $date)
      }
    }
  `,
);
