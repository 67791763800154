import React from 'react';
import PropTypes from 'prop-types';

import ReCAPTCHA from "react-google-recaptcha";

const GRECAPTCHA_V2_TICKBOX_KEY = "6LdADOgUAAAAALeIbcg90B_XP3B9wCrTQ2KIFqcx";
const GRECAPTCHA_V2_INV_KEY = "6Lc7d3caAAAAAEBjTb8w_pD1BaSJcvCNK2R74eIC";

/*
 * Google Recaptcha V2
 */
export default class Recaptcha extends React.Component {
  static propTypes ={
    recaptchaRef: PropTypes.shape({}).isRequired,
    size: PropTypes.string.isRequired,
    badge: PropTypes.string.isRequired,
  }

  render () {
    const { recaptchaRef, size, badge, ...otherProps } = this.props;

    let sitekey = GRECAPTCHA_V2_TICKBOX_KEY;
    if (size === "invisible") {
      sitekey = GRECAPTCHA_V2_INV_KEY;
    }

    return(
      <ReCAPTCHA
        ref={recaptchaRef}
        size={size}
        badge={badge}
        sitekey={sitekey}
        {...otherProps}
      />
    )
  }
}
