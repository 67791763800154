import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'found';
import { PRODUCT_PER_PAGE } from '~/components/product/SearchView';

import { Pagination as PaginationAntd } from 'antd';
import './index.css';

const getPage = (location) => {
  return parseInt(location.query.p, 10) || 1;
}

export default class Pagination extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({
          q: PropTypes.string,
          p: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          filters: PropTypes.string,
          orderBy: PropTypes.string,
        }).isRequired,
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    name: PropTypes.string,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    totalCount: PropTypes.number.isRequired,
  }

  static defaultProps = {
    name: null,
  }

  constructor(props) {
    super(props);

    const { location } = props.match;

    this.state = {
      current: getPage(location),
      width: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    const { location } = this.props.match;

    // sync paginations
    if (getPage(location) !== this.state.current) {
      this.setState({
        current: getPage(location),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onChange = (page) => {
    const { location } = this.props.match;

    location.query.p = page;

    let shouldUpdateScroll = this.props.name === "bottom";
    const elem = document.getElementById("filter-panel");

    if (shouldUpdateScroll && elem) {
      const x = elem.offsetLeft - elem.scrollLeft;
      const y = elem.offsetTop - elem.scrollTop;

      shouldUpdateScroll = [x, y];
    }

    this.props.router.push({ ...location, shouldUpdateScroll });
    this.setState({ current: page });
  }

  handleResize = () => {
    this.setState({
      width: window.innerWidth,
    });
  }

  itemRender = (current, type, originalElement) => {
    const { location } = this.props.match;

    if (type === 'page') {
      return (
        <Link
          rel="next"
          to={{
            pathname: location.pathname,
            query: {
              ...location.query,
              p: current
            },
          }}
        >
          {current}
        </Link>
      );
    }

    return originalElement;
  }

  render() {
    const { current } = this.state;
    const { totalCount } = this.props;

    const pages = totalCount / PRODUCT_PER_PAGE;

    return (
      <PaginationAntd
        className={pages > 4 ? "long" : ""}
        onChange={this.onChange}
        current={current}
        itemRender={this.itemRender}
        total={totalCount}
        pageSize={PRODUCT_PER_PAGE}
        showSizeChanger={false}
        showLessItems={this.state.width < 365}
        simple={this.state.width < 290}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Products`}
      />
    )
  }
}
