/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QueryProductStockInput = {|
  id: string,
  latitude: number,
  longitude: number,
  range?: ?number,
|};
export type QueryProductStockMutationVariables = {|
  input: QueryProductStockInput
|};
export type QueryProductStockMutationResponse = {|
  +queryProductStock: ?{|
    +result: ?any
  |}
|};
export type QueryProductStockMutation = {|
  variables: QueryProductStockMutationVariables,
  response: QueryProductStockMutationResponse,
|};
*/


/*
mutation QueryProductStockMutation(
  $input: QueryProductStockInput!
) {
  queryProductStock(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "QueryProductStockPayload",
    "kind": "LinkedField",
    "name": "queryProductStock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueryProductStockMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueryProductStockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0f651e94de87ad5c2b2c11c0f28cb973",
    "id": null,
    "metadata": {},
    "name": "QueryProductStockMutation",
    "operationKind": "mutation",
    "text": "mutation QueryProductStockMutation(\n  $input: QueryProductStockInput!\n) {\n  queryProductStock(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46270c5cbcee1046302938d833d7df41';

module.exports = node;
