/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CmsBanner_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CmsPage_viewer$ref: FragmentReference;
declare export opaque type CmsPage_viewer$fragmentType: CmsPage_viewer$ref;
export type CmsPage_viewer = {|
  +cmsPage: ?{|
    +title: ?string,
    +urlSlug: ?string,
    +content: ?string,
    +metaDescription: ?string,
  |},
  +$fragmentRefs: CmsBanner_viewer$ref,
  +$refType: CmsPage_viewer$ref,
|};
export type CmsPage_viewer$data = CmsPage_viewer;
export type CmsPage_viewer$key = {
  +$data?: CmsPage_viewer$data,
  +$fragmentRefs: CmsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "urlSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CmsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "urlSlug",
          "variableName": "urlSlug"
        }
      ],
      "concreteType": "CmsPage",
      "kind": "LinkedField",
      "name": "cmsPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metaDescription",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CmsBanner_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2f6e5ee5459a84505dd13fc75b1610ce';

module.exports = node;
