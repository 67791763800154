import React from 'react';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { get } from 'lodash';
import { Link } from 'found';
import { Form } from '@ant-design/compatible';
import { Affix, Button, Col, Input, message, Row, Select } from 'antd';
import ItemList from '../cart/ItemList';
import { keys } from '../checkout/CheckoutView';
import Total from '../checkout/Total';
import Tyro from '../checkout/payment/Tyro';
import  { AmexRate } from '../checkout/payment/CreditCard';
import { getMaxAge } from '../auth/Login';
import { EmptyCartMutation } from '../cart/mutations';
import {
  GetTyroSessionMutation,
  TyroAuthenticatePayerMutation,
  RemoveCouponCodeMutation,
} from '../checkout/mutations';

import {
  CreatePosOrderMutation,
  DecryptPosStoreMutation,
} from './mutations';

const cookies = new Cookies();

const { Item: FormItem } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

const STORE_COOKIE_NAME = 'pos_store';
const OPERATOR_COOKIE_NAME = 'pos_operator';
const CUSTOMER_COOKIE_NAME = 'pos_customer';

export const getStoreId = () => (
  cookies.get(STORE_COOKIE_NAME)
)

export const setStoreId = (id) => (
  cookies.set(STORE_COOKIE_NAME, id, { path: '/', maxAge: getMaxAge(60) })
)

const clearStore = () => {
  cookies.remove(STORE_COOKIE_NAME);
}

const getOperator = () => (
  cookies.get(OPERATOR_COOKIE_NAME)
)

export const setOperator = (operator) => {
  cookies.set(OPERATOR_COOKIE_NAME, operator, { path: '/', maxAge: getMaxAge(60) });
}

const clearOperator = () => {
  cookies.remove(OPERATOR_COOKIE_NAME);
}

const getCustomer = () => {
  // c is a stringified value but somehow auto parsed by the cookie lib
  const c = cookies.get(CUSTOMER_COOKIE_NAME);

  if (c === undefined || c === null) {
    return {};
  }

  return c;
}

const updateCustomer = (value) => {
  cookies.set(CUSTOMER_COOKIE_NAME, JSON.stringify(value), { path: '/' });
}

export const clearSession = () => {
  cookies.remove(CUSTOMER_COOKIE_NAME, { path: '/' });
}

class Pos extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cart: PropTypes.shape({
        subtotal: PropTypes.number,
        grandTotal: PropTypes.number,
        shippingCost: PropTypes.number,
        discount: PropTypes.number,
        lines: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }),
        discounts: PropTypes.arrayOf(PropTypes.object),
        surcharges: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      validateFieldsAndScroll: PropTypes.func.isRequired,
      resetFields: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    viewer: null,
  };

  constructor(props) {
    super(props);

    const operator = getOperator();

    if (!operator) {
      this.props.router.push("/pos/login");
    }

    this.state = {
      auth: true,
      storeId: null,
      operator,
      customer: getCustomer(),
      loading: false,
    };

    const errorMsg = get(props, 'match.location.query.error');

    if (errorMsg) {
      message.error(errorMsg);
    }
  }

  static getTyroSession = (method, { cb, value, relay }) => {
    GetTyroSessionMutation.commit({
      environment: relay.environment,
      variables: { input: { data: value || null } },
      onCompleted: (resp) => {
        const { sessionId } = resp.getTyroSession;
        const version = get(method, 'extra.version');

        if (sessionId && version) {
          if (cb && typeof cb === 'function') {
            cb(sessionId);
          }
        }
      },
    });
  }

  componentDidMount() {
    this.ensureNoDiscounts();
    this.ensureStore();
  }

  componentDidUpdate() {
    this.ensureNoDiscounts();
  }

  setLoading = (loading) => this.setState({ loading });

  decrypt = (encryptedStoreId) => {
    DecryptPosStoreMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { storeId: encryptedStoreId } },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const storeId = get(resp.decryptPosStore, 'storeId');

        if (storeId) {
          setStoreId(encryptedStoreId);
          this.setState({ storeId });
          this.setState({ auth: true });
        } else {
          clearStore();
          this.setState({ storeId: null });
          this.setState({ auth: false });
        }
      },
      onError: (errors) => {
        message.error(errors[0].message);
        clearStore();
        this.setState({ auth: false });
      }
    });
  }

  signOut = () => {
    clearStore();
    clearOperator();
    clearSession();

    window.location.href = window.location.origin + window.location.pathname;
  }

  clearAll = () => {
    const { form } = this.props;

    form.setFieldsValue({
      ccOwner: undefined,
      ccType: undefined,
      company: undefined,
      email: undefined,
      firstname: undefined,
      lastname: undefined,
      phoneNumber: undefined,
      tyroData: undefined,
    });

    clearSession();
    this.emptyCart();
  }

  ensureNoDiscounts() {
    get(this.props.viewer.cart, 'discounts', [])
    .forEach(d => {
      this.removeDiscount(d);
    });
  }

  ensureStore() {
    let auth = true;

    const encryptedStore = get(this.props, 'match.location.query.storeId') || getStoreId();

    if (encryptedStore) {
      const {encrypted_store_id: storeId} = this.state.operator || {};

      if (storeId && encryptedStore !== storeId) {
        message.error("You do not belong to this store");
        this.props.router.push("/pos/login");
      }
      this.decrypt(encryptedStore);
    }

    if (!encryptedStore) {
      auth = false;
    }

    this.setState({
      auth,
      storeId: encryptedStore,
    });
  }

  emptyCart = () => {
    const { viewer } = this.props;

    if (get(viewer, 'cart')) {
      EmptyCartMutation.commit({
        environment: this.props.relay.environment,
        viewer,
      });
    }
  }

  removeDiscount = (discount) => {
    RemoveCouponCodeMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          id: discount.id,
        },
      },
      viewer: this.props.viewer,
    });
  }

  refetch = (props, cb = null) => {
    this.props.relay.refetch((fragmentVariables) => {
      Object.keys(fragmentVariables).forEach(k => {
        if (!keys.includes(k)) {
          console.error('Please update keys in refetch');
        }
      });
      return props;
    }, null, cb);
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        CreatePosOrderMutation.commit({
          environment: this.props.relay.environment,
          variables: { input: values },
          viewer: this.props.viewer,
          onCompleted: ({ createPosOrder }) => {
            if (createPosOrder.result) {
              const result = JSON.parse(createPosOrder.result);
              if (result) {
                const content = get(result, 'redirect_html');

                if (content) {
                  Tyro.processMethodData(content, () => {
                    this.handleTyroAuthenticatePayer(result);
                  });
                } else {
                  message.error("There was an error, please try again.", 10);
                  this.setState({ loading: false });
                }
              }
            }
          },
          onError: (errors) => {
            message.destroy();
            message.error(errors[0].message);
            this.setState({ loading: false });
          }
        });
      }
    });
  }

  handleTyroAuthenticatePayer = (details) => {
    TyroAuthenticatePayerMutation.commit({
      environment: this.props.relay.environment,
      variables: {
        input: {
          quote_id: get(details, 'order_id'),
          session_id: get(details, 'session_id'),
          transaction_id: get(details, 'transaction_id'),
          extra: Tyro.getExtra({isPos: true}),
        }
      },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const content = get(resp, 'tyroAuthenticatePayer.result.redirect_html');

        if (content) {
          Tyro.injectACSIframe(content);
        } else {
          message.error("There was an error, please try again.", 10);
          this.setState({ loading: false });
        }
      },
      onError: (errors) => {
        message.destroy();
        message.error(errors[0].message);
        this.setState({ loading: false });
      },
    });
  }

  renderStoreSelector = (stores) => (
    <Select
      allowClear
      disabled
      placeholder="Store"
      optionFilterProp="children"
    >
      {stores.map(({node: store}) => (
        <Option key={store.id} value={store.id}>
          {store.name}
        </Option>
      ))}
    </Select>
  )

  render() {
    const { viewer, relay, form, form: { getFieldDecorator } } = this.props;
    const { auth, customer } = this.state;
    const { cart } = viewer;
    const stores = get(viewer, 'stores.edges', []);

    return (
      <div>
        <h1>POS</h1>

        <Row type="flex" justify="space-around" gutter={[10, 10]}>
          <Col xs={24} md={9}>
            <ItemList
              showActionButtons={false}
              viewer={viewer}
            />
            <Affix>
              <Total viewer={viewer} relay={relay} removeDiscount={() => {}} />
            </Affix>
          </Col>

          <Col xs={24} md={13}>
            {!auth && (
              <p>Store is not recognised, please use the correct link for your store.</p>
            )}
            {auth && (
            <Form>
              <Row>
                <Col xs={18} style={{marginBottom: '20px'}}>
                  <Button type="primary" style={{fontWeight: 'bold'}}>
                    <Link to="/pos/list">View All Sales</Link>
                  </Button>
                </Col>
                <Col xs={6} style={{marginBottom: '20px'}}>
                  <Button type="secondary" onClick={this.clearAll}>
                    Clear All Fields
                  </Button>
                  <Button type="secondary" onClick={this.signOut}>
                    Sign Out
                  </Button>
                </Col>
                <Col xs={24}>
                  <FormItem
                    {...formItemLayout}
                    label="Store"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {getFieldDecorator('storeId', {
                      initialValue: this.state.storeId,
                      rules: [
                        { required: true, message: 'Required' },
                      ],
                    })(
                      this.renderStoreSelector(stores)
                    )}
                  </FormItem>
                </Col>
                <Col xs={24}>
                  <FormItem
                    {...formItemLayout}
                    label="Operator"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {getFieldDecorator('operator', {
                      initialValue: get(this.state.operator, 'username'),
                      rules: [
                        { required: true, message: 'Required' },
                      ],
                    })(
                      <Input placeholder="Your Name" disabled />
                    )}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12}>
                  <FormItem
                    {...formItemLayout}
                    label="Customer First Name"
                  >
                    {getFieldDecorator('firstname', {
                      initialValue: customer.firstname,
                      rules: [
                        { required: true, message: 'Required' },
                      ],
                    })(<Input placeholder="First Name" onChange={({target: {value}}) => updateCustomer(Object.assign(customer, {firstname: value}))} />)}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12}>
                  <FormItem
                    {...formItemLayout}
                    label="Customer Last Name"
                  >
                    {getFieldDecorator('lastname', {
                      initialValue: customer.lastname,
                      rules: [
                        { required: true, message: 'Required' },
                      ],
                    })(<Input placeholder="Last Name" onChange={({target: {value}}) => updateCustomer(Object.assign(customer, {lastname: value}))} />)}
                  </FormItem>
                </Col>

                <Col xs={24}>
                  <FormItem
                    {...formItemLayout}
                    label="Customer Email"
                  >
                    {getFieldDecorator('email', {
                      initialValue: customer.email,
                      rules: [
                        { required: true, message: 'Required' },
                        { type: 'email', message: 'Invalid Email' },
                      ],
                    })(<Input placeholder="Email" onChange={({target: {value}}) => updateCustomer(Object.assign(customer, {email: value}))} />)}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12}>
                  <FormItem
                    {...formItemLayout}
                    label="Customer Phone Number"
                  >
                    {getFieldDecorator('phoneNumber', {
                      initialValue: customer.phoneNumber,
                      rules: [
                        { required: true, message: 'Required' },
                      ],
                    })(<Input placeholder="Phone Number" onChange={({target: {value}}) => updateCustomer(Object.assign(customer, {phoneNumber: value}))} />)}
                  </FormItem>
                </Col>

                <Col xs={24} sm={12}>
                  <FormItem
                    {...formItemLayout}
                    label="Company Name"
                  >
                    {getFieldDecorator('company', {
                      initialValue: customer.company,
                    })(<Input placeholder="Company Name" onChange={({target: {value}}) => updateCustomer(Object.assign(customer, {company: value}))} />)}
                  </FormItem>
                </Col>

                {getFieldDecorator('paymentMethod', {
                  initialValue: "tyro",
                })(<div />)}

                <Col xs={24}>
                  {cart && (
                    <div>
                      { form.getFieldValue('ccType') === "AE" && <AmexRate /> }

                      <Tyro.CreditCard
                        cart={cart}
                        form={form}
                        relay={relay}
                        submit={this.handleSubmit}
                        getTyroSession={Pos.getTyroSession}
                        updateCardType={ccType => {
                          form.setFieldsValue({ ccType });
                          this.refetch({ ccType });
                        }}
                        method={{
                          extra: {
                            version: '61'
                          }
                        }}
                      />
                      { form.getFieldValue('ccType') === "AE" && <AmexRate /> }
                    </div>
                  )}
                </Col>
              </Row>
              <Tyro.Threeds form={form} />
              <br />
              <br />
              <br />
              <Button type="primary" size="large" loading={this.state.loading} onClick={()=>{ Tyro.Threeds.init(form, { setLoading: this.setLoading }); }}>Place Order</Button>
            </Form>
            )}
          </Col>
        </Row>

      </div>
    );
  }
}

export default createRefetchContainer(
  Form.create()(Pos), {
    viewer: graphql`
    fragment Pos_viewer on Customer @argumentDefinitions(
      forklift: {type: "Boolean"}
      useCredit: {type: "Boolean"}
      useLoyaltyPoints: {type: "Boolean"}
      street: {type: "String", defaultValue: null}
      suburb: {type: "String", defaultValue: null}
      postcode: {type: "String", defaultValue: null}
      shippingMethod: {type: "String"}
      ccType: {type: "String"}
      stripeToken: {type: "String"}
    ) {
      ...FreebiePopup_viewer
      cart {
        id
        checkoutSnapshot
        subtotal
        shippingCost(shippingMethod: $shippingMethod)
        grandTotal
        surcharges(ccType: $ccType, stripeToken: $stripeToken, forklift: $forklift) {
          name
          amount
        }
        discount
        discounts(useCredit: $useCredit, useLoyaltyPoints: $useLoyaltyPoints) {
          id
          name
          amount
          removable
        }
        isOptFreebiesInCart
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              isFreebie
              unitPrice
              quantity
              unitDiscount
              unitSurcharge
              rowTotal
              product {
                id
                sku
                brand {
                  id
                  name
                }
              }
            }
          }
        }
        ...FreebiePopup_cart
      }
      stores(first: 999) {
        edges {
          node {
            id
            name
            address
            city
            postcode
            state
            description
            phone
            lat
            lng
            canPickup
            excludeBulkyGood
          }
        }
      }
      ...ItemList_viewer
    }
  `,
  },
  graphql`
  query PosRefetchQuery(
    $forklift: Boolean,
    $street: String,
    $suburb: String,
    $postcode: String,
    $useCredit: Boolean,
    $useLoyaltyPoints: Boolean,
    $shippingMethod: String,
    $ccType: String,
    $stripeToken: String,
  ) {
    viewer {
      ...Pos_viewer @arguments(
        forklift: $forklift,
        street: $street,
        suburb: $suburb,
        postcode: $postcode,
        useCredit: $useCredit,
        useLoyaltyPoints: $useLoyaltyPoints,
        shippingMethod: $shippingMethod,
        ccType: $ccType,
        stripeToken: $stripeToken
      )
    }
  }
`,
);
