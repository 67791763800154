import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';

import { onCheckoutCompleted } from './helper';

class BeforeSuccess extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const order = get(this.props.viewer.salesOrders, 'edges[0].node', {});
    onCheckoutCompleted.call(this, {createOrder: {salesOrder: order}}, null);
  }

  render() {
    return null;
  }
}

export default createFragmentContainer(BeforeSuccess, {
  viewer: graphql`
    fragment BeforeSuccess_viewer on Customer {
      salesOrders(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            verified
            grandTotal
            shippingTotal
            paymentMethod
            status
            discounts {
              id
              name
            }
            lines(first: 99) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  rowTotal
                  product {
                    id
                    sku
                    name
                    brand {
                      id
                      name
                    }
                  }
                  children(first: 99) {
                    edges {
                      node {
                        id
                        name
                        quantity
                        unitPrice
                        product {
                          id
                          sku
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
