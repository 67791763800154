import React from 'react';
import PropTypes from 'prop-types';

export default class BonusLabel extends React.Component {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    labels: []
  }

  render() {
    const { labels } = this.props;

    return (
      <div style={{height: '28px'}}>
        {labels.map(label => (
          <img key={label.node.id} src={label.node.imageUrl} alt="" className="bonus-label-list" style={{height: '28px'}} />
        ))}
      </div>
    );
  }
}
