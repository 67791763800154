/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BeforeSuccess_viewer$ref: FragmentReference;
declare export opaque type BeforeSuccess_viewer$fragmentType: BeforeSuccess_viewer$ref;
export type BeforeSuccess_viewer = {|
  +salesOrders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +verified: ?number,
        +grandTotal: ?number,
        +shippingTotal: ?number,
        +paymentMethod: ?string,
        +status: ?number,
        +discounts: ?$ReadOnlyArray<?{|
          +id: ?string,
          +name: ?string,
        |}>,
        +lines: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +quantity: ?number,
              +unitPrice: ?number,
              +rowTotal: ?number,
              +product: ?{|
                +id: string,
                +sku: ?string,
                +name: ?string,
                +brand: ?{|
                  +id: string,
                  +name: ?string,
                |},
              |},
              +children: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                    +quantity: ?number,
                    +unitPrice: ?number,
                    +product: ?{|
                      +id: string,
                      +sku: ?string,
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: BeforeSuccess_viewer$ref,
|};
export type BeforeSuccess_viewer$data = BeforeSuccess_viewer;
export type BeforeSuccess_viewer$key = {
  +$data?: BeforeSuccess_viewer$data,
  +$fragmentRefs: BeforeSuccess_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BeforeSuccess_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "SalesOrderConnection",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verified",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grandTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentMethod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Discount",
                  "kind": "LinkedField",
                  "name": "discounts",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v3/*: any*/),
                  "concreteType": "SalesOrderLineConnection",
                  "kind": "LinkedField",
                  "name": "lines",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderLineEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderLine",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rowTotal",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "product",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v6/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Brand",
                                  "kind": "LinkedField",
                                  "name": "brand",
                                  "plural": false,
                                  "selections": (v2/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": (v3/*: any*/),
                              "concreteType": "SalesOrderLineConnection",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderLineEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderLine",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v1/*: any*/),
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "Product",
                                          "kind": "LinkedField",
                                          "name": "product",
                                          "plural": false,
                                          "selections": [
                                            (v0/*: any*/),
                                            (v6/*: any*/)
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "children(first:99)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "lines(first:99)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '125e96088f4de8c0e896099c65c89426';

module.exports = node;
