import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation PostFeedbackFormMutation($input: PostFeedbackFormInput!) {
    postFeedbackForm(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
