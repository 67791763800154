import React from 'react';
import PropTypes from 'prop-types';

export default class RedemptionLabel extends React.Component {
  static propTypes = {
    label: PropTypes.shape({
      node: PropTypes.shape({
        imageUrl: PropTypes.string,
      })
    }),
  }

  static defaultProps = {
    label: null
  }

  render() {
    const { label } = this.props;

    return (
      <div style={{height: '28px'}}>
        {label && <img src={label.node.imageUrl} alt="" style={{height: '28px'}} />}
      </div>
    );
  }
}
