const {
  requestSubscription,
  graphql,
} = require('react-relay');

const subscription = graphql`
  subscription AddressUpdatedSubscription($id: ID) {
    addressUpdated(id: $id) {
      ...AddressBook_viewer
    }
  }
`;

export default (environment, variables = {}) => requestSubscription(
    environment,
    {
      subscription,
      variables
    }
  )
