import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Anchor, Col, Collapse, Row } from 'antd';
import { get } from 'lodash';
import Helmet from '~/components/page/Helmet';
import Shiitake from 'shiitake';
import CmsContent from './CmsContent';
import './style.css';

const { Link } = Anchor;
const { Panel } = Collapse;

class TocPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        urlSlug: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.urlSlug = props.viewer.cmsPage.urlSlug;
    this.state = {
      tocList: [],
      isInViewport: false,
    }
    this.htmlContent = <CmsContent pageContent={props.viewer.cmsPage.content} initTocList={this.initTocList} />
  }

  componentDidMount() {
    window.addEventListener('scroll', this.setFooterHeight);
    this.setFooterHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setFooterHeight);
  }

  getMaxHeight = (affix, offSetTop) => {
    let height = {
      maxHeight: '100%'
    };

    if (affix) {
      const { cmsPageYPos, footerHeight, isInViewport } = this.state;

      let pageYPos = cmsPageYPos || 0;
      if (pageYPos < 0) {
        pageYPos = 0
      }

      height = {
        maxHeight: `calc(100vh - ${pageYPos}px - ${offSetTop}px)`
      }

      if (isInViewport) {
        height = {
          maxHeight: `${footerHeight - offSetTop}px`
        }
      }
    }

    return height;
  }

  setFooterHeight = () => {
    let footer = document.getElementsByClassName("footer");
    let cmsPage = document.getElementsByClassName("cms-page");

    footer = get(footer, '[0]');
    cmsPage = get(cmsPage, '[0]');

    if (footer && cmsPage) {
      const rect = footer.getBoundingClientRect();
      const cmsRect = cmsPage.getBoundingClientRect();
      const currIsInVP = this.isInViewport(rect);

      this.setState({
        cmsPageYPos: cmsRect.y,
        footerHeight: rect.y,
        isInViewport: currIsInVP,
      })
    }
  }

  initTocList = (newTocList) => {
    this.setState({
      tocList: newTocList
    })
  }

  isInViewport = (rect) => (rect.y <= (window.innerHeight || document.documentElement.clientHeight));

  renderScrollspy = (navList, affix = false) => {
    if (navList.length > 0) {
      let offSetTop = 0;
      if (affix) {
        offSetTop = 30;
      }

      const height = this.getMaxHeight(affix, offSetTop);

      return (
        <div className="toc-affix">
          <Anchor
            className="toc"
            offsetTop={offSetTop}
            affix={affix}
            style={{ marginTop: `${offSetTop}px`, fontSize: '12px', overflow: 'auto', ...height }}
          >
            {navList.map(l => {
              return (
                <button
                  style={{
                    border: 'none',
                    padding: 0,
                    width: '100%',
                    textAlign: 'left',
                  }}
                  onClick={() => l.handleLinkClick()}
                  key={l.href}
                >
                  <Link className={`black-href ${l.tag}`} href={l.href} title={<Shiitake lines={1}>{l.content}</Shiitake>} />
                </button>
              )
            })}
          </Anchor>
        </div>
      )
    }

    return null;
  }

  render() {
    const { viewer: { cmsPage: page } } = this.props;
    const { tocList } = this.state;

    let mdStyle = 24;
    if (tocList.length > 0) {
      mdStyle = 18;
    }

    return (
      <div>
        <Helmet title={page.title} />
        <Row>
          {tocList.length > 0 && [
            <Col key="smallScreen" xs={24} md={0}>
              <Collapse accordion style={{ backgroundColor: 'white', border: 'none', marginBottom: '15px' }}>
                <Panel header="Table of Contents">
                  {this.renderScrollspy(tocList)}
                </Panel>
              </Collapse>
            </Col>,
            <Col key="bigScreen" xs={0} md={24 - mdStyle}>
              <div style={{ paddingRight: '5px' }}>
                {this.renderScrollspy(tocList, true)}
              </div>
            </Col>
          ]}
          <Col xs={24} md={mdStyle} style={{paddingLeft: '1%'}}>
            <h1 style={{ fontSize: 24 }}>{page.title}</h1>
            {this.htmlContent}
          </Col>
        </Row>
      </div>
    );
  }
}

export default createFragmentContainer(TocPage, {
  viewer: graphql`
    fragment TocPage_viewer on Customer {
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
      }
    }
  `,
});
