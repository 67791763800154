import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation GetTyroSessionMutation($input: GetTyroSessionInput!) {
    getTyroSession(input:$input) {
      sessionId
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };
