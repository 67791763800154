import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation CompleteRedirectOrderMutation($input: CompleteRedirectOrderInput!) {
    createOrder: completeRedirectOrder(input:$input) {
      cartId
      token
      salesOrder {
        id
        encryptedId
        name
        verified
        grandTotal
        shippingTotal
        paymentMethod
        status
        lines(first: 99) {
          edges {
            node {
              id
              name
              quantity
              unitPrice
              rowTotal
              product {
                id
                sku
                name
                brand {
                  id
                  name
                }
              }
              children(first: 99) {
                edges {
                  node {
                    id
                    name
                    quantity
                    unitPrice
                    product {
                      id
                      sku
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: (_, store) => {
    const payload = store.getRootField('completeRedirectOrder');
    if (payload) {
      const cartId = payload.getValue('cartId');

      store.delete(cartId);
    }
  },
});

export default { commit };
