import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation AddProductToWishlistMutation($input: AddProductToWishlistInput!) {
    addProductToWishlist(input:$input) {
      wishlist{
        id
        products(first: 999) {
          edges {
            node {
              id
              name
              price
              inWishlist
            }
          }
        }
      }
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };
