import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Cookies from 'universal-cookie';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const cookies = new Cookies();

export default class Preview extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
      router: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.init(props);

    this.state = {
      isAllow: this.isAllow(),
      date: cookies.get('preview'),
    }
  }

  componentDidUpdate() {
    this.reload(this.props);
  }

  init = (props) => {
    const preview = get(props.match.location, 'query.preview');

    if (preview) {
      cookies.set('preview', preview, { path: '/' });
    }
  }

  reload = (props) => {
    const preview = get(props.match.location, 'query.preview');
    const cookie = cookies.get('preview');

    if (preview && !cookie) {
      window.location.reload();
    }
  }

  isAllow = () => {
    return (cookies.get('allow_preview') === "true");
  }

  stopPreview = () => {
    cookies.remove('preview', { path: '/' });

    const { match } = this.props;
    const query = get(match, "location.query");

    if (query.preview) {
      const { preview, ...newQuery } = query;

      match.router.push({
        pathname: match.location.pathname,
        query: newQuery,
      });
    }

    window.location.reload();
  }

  render() {
    return (
      <div>
        {(this.state.isAllow && this.state.date) && (
          <div style={{backgroundColor: 'yellow', textAlign: 'center'}}>
            <span style={{}}>Previewing on date: {this.state.date}</span>
            <Button style={{marginLeft: '10px'}} icon={<CloseCircleOutlined />} shape="circle" onClick={this.stopPreview} />
          </div>
        )}
      </div>
    );
  }
}
