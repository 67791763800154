import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { getTnCPath } from '~/components/cms/routes';
import Helmet from '~/components/page/Helmet';
import { Empty, Switch, Table } from 'antd';
import { Link } from 'found';
import moment from 'moment';
import { PointsIcon } from '~/components/svg';
import 'antd/lib/table/style';
import './style.css';

import { UpdateLoyaltyOptOutMutation } from './mutations';

class LoyaltyPoint extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      loyaltyOptOut: PropTypes.bool,
      storeCredits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      loyaltyPoints: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Points',
        dataIndex: ['node', 'remainingPoints'],
      },
      {
        title: 'Expiry Date',
        dataIndex: ['node', 'expiry'],
        render: (v) => moment(v).format('DD-MM-YYYY'),
      },
    ];

    this.creditColumns = [
      {
        title: 'Dollars',
        dataIndex: ['node', 'remainingPoints'],
        render: (v) => `$${v.toFixed(2)}`,
      },
      {
        title: 'Promotion',
        dataIndex: ['node', 'name'],
      },
      {
        title: 'Expiry Date',
        dataIndex: ['node', 'expiry'],
        render: (v) => moment(v).format('DD-MM-YYYY'),
      },
    ];
  }

  optOut = (checked) => {
    UpdateLoyaltyOptOutMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id: this.props.viewer.id, optOut: checked} },
      viewer: this.props.viewer,
    });
  }

  renderTotalPoints = (loyaltyPoints) => {
    const pts = loyaltyPoints.edges.reduce((acc, {node}) => acc + node.remainingPoints, 0.0);

    return (
      <div style={{ display: 'table' }}>
        <PointsIcon style={{ fontSize: '40px', display: 'table-cell' }} />
        <span style={{ color: 'white', display: 'table-cell', verticalAlign: 'middle', paddingLeft: '10px' }}>
          You have <b>{pts}</b> Bonus Point(s)
        </span>
      </div>
    )
  }

  render() {
    const { loyaltyPoints, loyaltyOptOut, storeCredits } = this.props.viewer;

    return (
      <div>
        <Helmet title="Points" />
        <h1>Points</h1>

        <div style={{
          backgroundColor: '#cb0000',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '10px',
          minHeight: '65px',
        }}
        >
          {this.renderTotalPoints(storeCredits)}
          <Link className="ant-btn" style={{color: '#cb0000'}} to={getTnCPath("points")}>Learn More</Link>
        </div>

        {loyaltyPoints.edges.length > 0 && (
        <Table
          dataSource={loyaltyPoints.edges}
          rowKey={({node}) => node.id}
          columns={this.columns}
          pagination={false}
        />
        )}

        <Table
          style={{margin: '10px 0px'}}
          dataSource={storeCredits.edges}
          rowKey={({node}) => node.id}
          columns={this.creditColumns}
          locale={{
            emptyText: <Empty description="You do not have any bonus points." image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
          pagination={false}
        />

        <div style={{margin: '10px 0px', display: 'flex'}}>
          <Switch defaultChecked={loyaltyOptOut} onChange={this.optOut} />
          <span style={{marginLeft: '10px'}}>I would like to <b>opt out and DO NOT</b> want to earn points.</span>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(LoyaltyPoint, {
  viewer: graphql`
    fragment LoyaltyPoint_viewer on Customer {
      id
      storeCredits(first: 99999) {
        edges {
          node {
            id
            name
            remainingPoints: creditRemaining
            expiry
          }
        }
      }
      loyaltyOptOut
      loyaltyPoints(first: 99999) {
        edges {
          node {
            id
            remainingPoints
            expiry
          }
        }
      }
    }
  `,
});
