import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const renderDate = (date) => {
  let val = null;

  if (date !== '9999-12-31') {
    val = `Ships On: ${moment(date).format('DD/MM/YY')}`;
  }
  else {
    val = 'Pre Order';
  }

  return (
    <div style={{fontSize: '12px', color: 'black', fontWeight: 'bold'}}>
      {val}
    </div>
  )
}

const ShipOn = function ShipOn(props) {
  const { product } = props;

  return (
    <React.Fragment>
      {product.preorderDate && (renderDate(product.preorderDate))}
      {product.backInStockDate && (renderDate(product.backInStockDate))}
    </React.Fragment>
  );
}

ShipOn.propTypes = {
  product: PropTypes.shape({
    preorderDate: PropTypes.string,
    backInStockDate: PropTypes.string,
  }).isRequired,
};

export default ShipOn;
