/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PostSparePartsFormInput = {|
  attachments?: ?$ReadOnlyArray<?any>,
  branch: string,
  customerNumber: string,
  jobNumber?: ?string,
  partsRequired?: ?string,
  staffEmail: string,
  staffName: string,
  unitGp: string,
  unitModel: string,
|};
export type PostSparePartsFormMutationVariables = {|
  input: PostSparePartsFormInput
|};
export type PostSparePartsFormMutationResponse = {|
  +postSparePartsForm: ?{|
    +result: ?boolean
  |}
|};
export type PostSparePartsFormMutation = {|
  variables: PostSparePartsFormMutationVariables,
  response: PostSparePartsFormMutationResponse,
|};
*/


/*
mutation PostSparePartsFormMutation(
  $input: PostSparePartsFormInput!
) {
  postSparePartsForm(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostSparePartsFormPayload",
    "kind": "LinkedField",
    "name": "postSparePartsForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostSparePartsFormMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostSparePartsFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a0e0340ef50236ff0715cd9d7b8bd76c",
    "id": null,
    "metadata": {},
    "name": "PostSparePartsFormMutation",
    "operationKind": "mutation",
    "text": "mutation PostSparePartsFormMutation(\n  $input: PostSparePartsFormInput!\n) {\n  postSparePartsForm(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b943e8cff607de31b97894ef991a509';

module.exports = node;
