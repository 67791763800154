import React from 'react';
import PropTypes from 'prop-types';
import { importScript } from '~/helper';

export class AfterPayWidget extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    amount: PropTypes.number,
    type: PropTypes.string,
  }

  static defaultProps = {
    amount: 0,
    type: 'price-paragraph',
  };

  componentDidMount() {
    if (typeof window.AfterPay === 'undefined') {
      importScript({url: 'https://js.afterpay.com/afterpay-1.x.js'});
    }
  }

  render() {
    const { type, amount } = this.props;

    if (amount <= 3000) {
      return (
        <afterpay-placement
          style={{marginTop: '0'}}
          data-logo-type="compact-badge"
          data-payment-amount-is-bold="true"
          data-type={type}
          data-locale={process.env.LOCALE}
          data-currency={process.env.CURRENCY}
          data-size="xs"
          data-max="3000"
          data-amount={amount}
        />
      );
    }

    return null;
  }
}

export default class AfterPay extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
  }

  render() {
    const { amount, form: { getFieldDecorator } } = this.props;

    return (
      <div>
        <AfterPayWidget amount={amount} type="price-table" />
        {getFieldDecorator('afterPayQuery')(<div />)}
      </div>
    );
  }
}
