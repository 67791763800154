import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import GA4 from '~/ga4';

export const STATUS_STYLES = {
  'processing': {
    color: 'black',
  },
  'allocatingStock': {
    color: '#cb0000',
  },
  'backOrder': {
    color: '#cb0000',
  },
  'beingPicked': {
    color: '#cb0000',
  },
  'partiallyPicked': {
    color: '#cb0000',
  },
  'partiallyShipped': {
    color: 'green',
  },
  'shipped': {
    color: 'green',
  },
  'clickCollect': {
    color: 'green',
  },
  'cancelled': {
    color: 'black',
  },
}

const getShipping = (addresses) => addresses.edges.find(({node}) => node.addressType === "shipping");

const getPickupStore = (order, stores) => {
  if (order.shippingStatus === 'Click & Collect') {
    const {node: { company }} = getShipping(order.addresses);

    if (company && company.includes('Sydney Tools')) {
      const c = company.replace('Sydney Tools ', '');

      return stores.edges.find(({node}) => node.name === c);
    }
  }

  return null;
}

const track = (store, order) => {
  const event = {
    event: "pickup_asst",
    action: store.name,
    label: order.name,
  };
  GA4.event(event);
}

export const getStatus = ({status, shippingStatus}) => {
  if (status === 1 || status === 2) {
    return "Payment Review";
  } else if (status === 3) {
    return "Cancelled";
  }
  return shippingStatus;
}


export default class Status extends React.Component {
  static propTypes = {
    order: PropTypes.shape({
      status: PropTypes.number.isRequired,
      shippingStatus: PropTypes.string.isRequired
    }).isRequired,
    stores: PropTypes.shape({
      edgess: PropTypes.arrayOf(PropTypes.object),
    })
  }

  static defaultProps = {
    stores: {
      edges: []
    },
  }

  render() {
    const { order, order: { shippingStatus: status }, stores } = this.props;

    if (order.status === 3) {
      return <b>Status: Cancelled</b>
    }

    const {node: store} = getPickupStore(order, stores) || {};

    return (
      <div>
        <b style={STATUS_STYLES[camelCase(status)]}>Status: {getStatus(order)}</b>
        {status === 'Shipped' && <span style={{marginLeft: '5px'}}><b><i>(Track and trace is available on each item)</i></b></span>}
        {store && (
          <span style={{marginLeft: '5px'}}>
            (Contact <b>{store.name} store</b> at <a href={`tel:${store.phone}`} onClick={() => track(store, order)}>{store.phone}</a> for further assistance)
          </span>
        )}

      </div>
    )
  }
}
