import React from 'react';
import PropTypes from 'prop-types';

export default class Coupon extends React.Component {
  static propTypes = {
    code: PropTypes.string,
  }

  static defaultProps = {
    code: null,
  }

  constructor(props) {
    super(props);

    this.default = {
      showCode: false,
      width: 57,
      y: 9,
    };

    this.state = this.default;
  }

  getLabel = (code) => {
    const { showCode } = this.state;

    return showCode ? `CODE: ${code}`.toUpperCase() : 'COUPON';
  }

  getCodeLength = (code) => {
    const letters = code.split("").filter(c => Number.isNaN(c)).length;

    const len = letters * 5 + (code.length - letters) * 6;
    return len;
  }

  show = () => {
    const { width, y } = this.default;
    const { code } = this.props;
    const codeLength = this.getCodeLength(code);

    this.setState({
      showCode: true,
      width: width + codeLength,
      y: y - codeLength,
    });
  }

  hide = () => {
    this.setState({
      ...this.default
    });
  }

  render() {
    const { code } = this.props;
    if (!code) {
      return null;
    }

    const { width, y } = this.state;

    return (
      <svg width={width} height="22.8" viewBox={`0 0 ${width} 22.8`} onMouseEnter={this.show} onMouseLeave={this.hide} onTouchStart={this.show} onTouchEnd={this.hide}>
        <g>
          <rect x="0" y="0" width={width} height="4" fill="#1C7E12" />
        </g>

        <g>
          <rect x="0" y="19" width={width} height="4" fill="#1C7E12" />
        </g>

        <g>
          <rect x="47" y={y} transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 66.3864 -43.6154)" width="16" height="4" fill="#1C7E12" />
        </g>

        <g>
          <rect x="-6" y="10" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 13.3967 9.3743)" width="16" height="4" fill="#1C7E12" />
        </g>

        <g>
          <rect x="9" y="4" width="3" height="1" fill="#1C7E12" />
          <rect x="9" y="6.6" width="3" height="2" fill="#1C7E12" />
          <rect x="9" y="10.4" width="3" height="2" fill="#1C7E12" />
          <rect x="9" y="14.2" width="3" height="2" fill="#1C7E12" />
          <rect x="9" y="18" width="3" height="1" fill="#1C7E12" />
        </g>

        <text x="14" y="15" fill="#1C7E12" fontSize="8px" fontWeight="900">{this.getLabel(code)}</text>

      </svg>
    );
  }
}
