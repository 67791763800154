import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Checkbox } from 'antd';
import { PriorityIcon } from '~/components/svg';

export const showPriorityFilter = (match) => {
  const { params: { categoryUrlSlug }} = match;

  if ((categoryUrlSlug || '').includes('priority')) {
    return false;
  }

  return true;
}

export const disableBtn = (filter) => {
  const buckets = get(filter, 'buckets', []);

  return !buckets.find(({ key, docCount }) => key === 'true' && docCount > 0);
}

const PriorityFilter = (props) => {
  const { showPriority, activeFilters, match, filter, setCheckable, style } = props;

  if (!showPriority) {
    return null;
  } else if (showPriorityFilter(match) === false) {
    return null;
  }

  const [checked, setChecked] = useState(false);
  const priorityShipping = get(activeFilters, 'priority_shipping', false);
  const isDisabled = disableBtn(filter);

  useEffect(() => {
    if (typeof priorityShipping === 'boolean') {
      setChecked(priorityShipping);
    } else {
      setChecked(false);
    }
  }, [priorityShipping]);

  const onChange = (e) => {
    setCheckable('priority_shipping', e);
    setChecked(e.target.checked);
  }

  return (
    <div
      className="ant-btn"
      style={{
        ...style,
        background: isDisabled ? '#f5f5f5' : '#0089B6',
        paddingLeft: '10px',
        paddingRight: '0px',
      }}
      disabled={isDisabled}
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
        style={{
          color: isDisabled ? 'rgba(0, 0, 0, 0.25)' : 'white',
          paddingRight: '2px',
        }}
      >
        <PriorityIcon style={{ fontSize: '18px' }} />
        <span style={{ marginLeft: '8px', fontSize: '14px' }}><b>PRIORITY</b></span>
      </Checkbox>
    </div>
  )
}

PriorityFilter.propTypes = {
  showPriority: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      categoryUrlSlug: PropTypes.string,
    }),
  }).isRequired,
  activeFilters: PropTypes.shape({}).isRequired,
  filter: PropTypes.shape({}),
  setCheckable: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
}

PriorityFilter.defaultProps = {
  filter: null,
  style: {},
}

export default PriorityFilter;
