import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';

import {
  Environment,
  Network,
  Observable,
  RecordSource,
  Store,
} from 'relay-runtime';

import { createSubscriber } from "@absinthe/socket-relay";
import * as withAbsintheSocket from "@absinthe/socket";

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';

import { datadogRum } from '@datadog/browser-rum';

// Routing
import { BrowserProtocol, queryMiddleware } from 'farce';
import { createRender, createFarceRouter, ElementsRenderer } from 'found';
import { Resolver } from 'found-relay';
import { ScrollManager } from 'found-scroll';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import StaticContainer from 'react-static-container';

import Cookies from 'universal-cookie';

import browserUpdate from 'browser-update';

// Polyfills
import History from 'html5-history-api';
import raf from 'raf';

import { saveRef }  from '~/refTracker';

import { ErrorPage } from './components/error';

import routes from './routes';
import NotFound from './components/error/NotFound';
import socket from './socket';

// `VERSION` is defined in webpack.prod.babel.js
/* global VERSION */
const phoenixSocket = socket.init(VERSION);

const cookies = new Cookies();
let token = cookies.get('auth_token');
const mountNode = window.document.getElementById('root');

let tagId = 'GTM-W98SKS4';

if (process.env.COUNTRY === 'NZ') {
  tagId = 'GTM-W6KVPFTC';
}

// Google Tag Manager
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event: 'gtm.js'});const f=d.getElementsByTagName(s)[0];
const j=d.createElement(s); const dl=l!=='dataLayer'?`&l=${l}`:'';j.async=true;j.src=
`//www.googletagmanager.com/gtm.js?id=${i}${dl}`;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer',tagId);

if (process.env.COUNTRY === 'AU') {
  ReactPixel.init('1639088596493175');
} else if (process.env.COUNTRY === 'NZ') {
  ReactPixel.init('1316018535947344');
}

const $buoop = {
  required: {i: 10,f: 56,o: -3,s: 8,c: 63},
  insecure: true,
  api: 2018.08,
  reminder: 72,
};
browserUpdate($buoop);


// Polyfill pushState
if (window.history.pushState === 'undefined') {
  window.history = History;
}

// Polyfill requestAnimationFrame
if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = raf;
}

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '12b8938d-22cf-445a-924f-161ffc3496f7',
    clientToken: 'pubf9b157d34764afc052e94cbbd59724ea',
    env: 'production',
    site: 'datadoghq.com',
    service: 'sydney-tools',
    version: VERSION,
    sampleRate: 100,
    trackInteractions: true
  });
}

/* FIXME
 * deathFlag is an indicator for when to show an error page
 * Every route has two requests/queries, for example, /compliant
 * involves *routes_App_Query* and then *routes_ComplaintPage_Query*
 * if both requests failed, deathFalg should be 2 on first visit.
 * In the case of sudden failure on an existing visit, error page should
 * not be displayed, instead `message` from ant design should pop up.
 */
let deathFlag = 0;

function fetchQuery(
  operation,
  variables,
  cacheConfig,
  uploadables,
) {
  const headers = {};
  token = cookies.get('auth_token');

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers,
  };

  if (uploadables) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    formData.append('query', operation.text);
    formData.append('variables', JSON.stringify(variables));

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    request.body = formData;
  } else {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }

  return fetch('/graphql', request).then((response) => {
    if (!response.ok) {
      deathFlag += 1;
      throw Error(response.statusText);
    }
    deathFlag = 0;
    return response;
  }).then(response => response.json());
}

const absintheSocket = withAbsintheSocket.create(phoenixSocket);
const legacySubscribe = createSubscriber(absintheSocket);

const subscribe = (request, variables, cacheConfig) => Observable.create(sink => {
    legacySubscribe(request, variables, cacheConfig, {
      onNext: sink.next,
      onError: sink.error,
      onCompleted: sink.complete
    });
  });

export const environment = new Environment({ // eslint-disable-line import/prefer-default-export
  network: Network.create(fetchQuery, subscribe),
  store: new Store(new RecordSource()),
});

const render = createRender({
  renderPending: (props) => {
    saveRef(props);
    NProgress.start();
  },
  renderReady: (args) => { // eslint-disable-line react/display-name
    const { elements } = args;
    NProgress.done();
    if (deathFlag > 0 && deathFlag <= 2) {
      return <ErrorPage />;
    }
    ReactPixel.pageView();

    return (
      <div>
        <StaticContainer shouldUpdate>
          <ElementsRenderer elements={elements} />
        </StaticContainer>
      </div>
    );
  },
  renderError: (props) => { // eslint-disable-line react/display-name
    NProgress.done();
    const { error } = props; // eslint-disable-line react/prop-types
    // eslint-disable-next-line react/prop-types
    if (error.status === 404) {
      return <NotFound {...props} />;
    }

    return null;
  },
});

const _shouldUpdateScroll = (prevRenderArgs, { location }) => {
  const { shouldUpdateScroll } = location;

  if (typeof shouldUpdateScroll === 'object' && Array.isArray(shouldUpdateScroll)) {
    return shouldUpdateScroll;
  } else if (shouldUpdateScroll === false) {
    return false;
  }

  return true;
};

NProgress.configure({ showSpinner: false });
const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,

  render: (renderArgs) => (// eslint-disable-line react/display-name
    <ScrollManager renderArgs={renderArgs} shouldUpdateScroll={_shouldUpdateScroll}>
      {render(renderArgs)}
    </ScrollManager>
  )
  ,
});

ReactDOM.render(
  <ConfigProvider locale={enUS}>
    <Router resolver={new Resolver(environment)} />
  </ConfigProvider>
  ,
  mountNode,
);
