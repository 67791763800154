import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Button, Form, Input, message, Rate } from 'antd';
import { formItemLayout, scrollToFirstError } from '~/components/form';
import { PostReviewMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;

class ReviewForm extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    PostReviewMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
      parent: this.props.product,
      onCompleted: () => {
        message.success('Thank you for your feedback');
        this.formRef.current.resetFields();
      },
    });
  }

  render() {
    const { product } = this.props;

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit} scrollToFirstError={scrollToFirstError}>

        <h3>You are reviewing <u><b>{product.name}</b></u></h3>
        <FormItem
          name="productId"
          rules={[{ required: true, message: 'required' }]}
          initialValue={product.id}
          hidden
        >
          <Input />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Value"
          name="value"
          rules={[
            { required: true, message: 'required' },
          ]}
        >
          <Rate />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Quality"
          name="quality"
          rules={[{ required: true, message: 'required' }]}
        >
          <Rate />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Service"
          name="service"
          rules={[{ required: true, message: 'required' }]}
        >
          <Rate />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Delivery"
          name="delivery"
          rules={[{ required: true, message: 'required' }]}
        >
          <Rate />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Price"
          name="price"
          rules={[{ required: true, message: 'required' }]}
        >
          <Rate />
        </FormItem>

        <FormItem
          name="content"
          rules={[{ required: true, message: 'required' }]}
        >
          <TextArea rows={4} placeholder="Let us know your thoughts" />
        </FormItem>

        <Button type="primary" htmlType="submit">Submit</Button>

      </Form>
    );
  }
}

export default createFragmentContainer(ReviewForm, {
  product: graphql`
    fragment ReviewForm_product on Product {
      id
      name
    }
  `,
});
