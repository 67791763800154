import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isEqual } from 'lodash';
import { Col, Collapse, Progress, Row } from 'antd';
import { Link } from 'found';

import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/modules/pagination/pagination.min.css';
import './style.css';

const { Panel } = Collapse;

const FREEBIE_MODE = {
  ALL: 0,
  ONE: 1,
};

const getProducts = (offer, lines) => {
  const { products, mode, isMaxTier } = offer;

  const freebieLineProductIds = lines.filter((l) => l.isFreebie).map(({ product }) => product.id);
  const freebieInCart = products.find((p) => freebieLineProductIds.includes(p.id));

  if (isMaxTier && mode === FREEBIE_MODE.ONE && freebieInCart) {
    return [freebieInCart];
  }

  return products;
};

const ProductCard = ({ product }) => {
  const name = get(product, 'name');
  const urlSlug = get(product, 'urlSlug');
  const thumbnail = get(product, 'mainImage.thumbnail');

  const textStyle = {
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '1',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <img alt={name} src={thumbnail} style={{ width: '60px' }} />
      <Link to={`/product/${urlSlug}`} style={textStyle}>
        {name}
      </Link>
    </div>
  )
};

ProductCard.propTypes = {
  product: PropTypes.shape({}).isRequired,
};

const Freebie = ({ offer, lines, hiddenPanels, handleOnChange }) => {
  const { id, name, tierPrice, mode, quantity, amountRequired, percent, isMaxTier } = offer;

  const [products, setProducts] = useState(() => getProducts(offer, lines));

  const defaultActiveKey = useMemo(() => {
    return get(hiddenPanels, 'data', []).includes(id) ? [] : [id];
  }, []);

  useEffect(() => {
    const newProducts = getProducts(offer, lines);

    if (!isEqual(products, newProducts)) {
      setProducts(newProducts);
    }
  }, [offer, lines, products, setProducts]);

  const renderProductList = () => (
    <ul style={{ marginBottom: '0px' }}>
      {products.map((p) => (
        <li key={p.id}>
          {quantity} <b>x</b> <Link to={`/product/${p.urlSlug}`}>{p.name}</Link>
        </li>
      ))}
    </ul>
  );

  const renderProducts = () => {
    if (products.length > 1) {
      return (
        <Swiper
          slidesPerView={1}
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{
            bulletActiveClass: 'offer-pagination-bullet-active',
            horizontalClass: 'offer-pagination-horizontal',
            clickable: true,
          }}
          loop
        >
          {products.map((p) => (
            <SwiperSlide key={p.id}>
              <ProductCard product={p} />
            </SwiperSlide>
          ))}
        </Swiper>
      )
    }

    const product = get(products, '[0]');

    return <ProductCard product={product} />;
  };

  const renderPromoInfo = () => {
    let promoInfo = (
      <>
        You&apos;re <b>${amountRequired}</b> away from receiving {mode === FREEBIE_MODE.ONE ? ' one of the following:' : ' the following:'}
      </>
    );

    if (isMaxTier && mode === FREEBIE_MODE.ONE) {
      const freebieLineProductIds = lines.filter((l) => l.isFreebie).map(({ product }) => product.id);
      const freebieInCart = products.find((p) => freebieLineProductIds.includes(p.id));

      promoInfo = freebieInCart ? (
        <>
          You will receive the following:
        </>
      ) : (
        <>
          You will receive one of the following:
        </>
      )
    } else if (isMaxTier) {
      promoInfo = (
        <>
          You will receive the following:
        </>
      )
    }

    return (
      <div>
        {promoInfo}
        {renderProductList()}
        as part of the <b>{name}</b> promotion.
      </div>
    )
  };

  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      style={{ marginBottom: '10px' }}
      onChange={(key) => handleOnChange(id, isEmpty(key))}
    >
      <Panel key={id} className="offer-panel" header="Freebie Offer!">
        <Row gutter={[10, 10]} style={{ alignItems: 'center' }}>
          <Col xs={24} sm={16} md={24} lg={16}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', columnGap: '5px' }}>
              <b style={{ fontSize: '14px', lineHeight: 1 }}>$0</b>
              <Progress className="offer-progress" percent={percent} strokeColor="#cb0000" strokeLinecap="square" showInfo={false} style={{ color: 'red' }} />
              <b style={{ fontSize: '14px', lineHeight: 1 }}>${tierPrice}</b>
            </div>
          </Col>

          <Col xs={24} sm={8} md={24} lg={8}>
            <div style={{ color: '#cb0000', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {renderProducts()}
            </div>
          </Col>
        </Row>

        {renderPromoInfo()}
      </Panel>
    </Collapse>
  )
};

Freebie.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tierPrice: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      urlSlug: PropTypes.string,
      mainImage: PropTypes.shape({
        id: PropTypes.string,
        thumbnail: PropTypes.string,
      }),
    })),
    mode: PropTypes.number,
    quantity: PropTypes.number,
    amountRequired: PropTypes.number,
    percent: PropTypes.number,
    isMaxTier: PropTypes.bool,
  }).isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  hiddenPanels: PropTypes.shape({}).isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default Freebie;
