/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Logout_viewer$ref = any;
type LoyaltyPoint_viewer$ref = any;
type SideNav_viewer$ref = any;
export type routes_LoyaltyPoint_QueryVariables = {||};
export type routes_LoyaltyPoint_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +storeCredits: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +remainingPoints: ?number,
          +expiry: ?string,
        |}
      |}>
    |},
    +loyaltyOptOut: ?boolean,
    +loyaltyPoints: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +remainingPoints: ?number,
          +expiry: ?string,
        |}
      |}>
    |},
    +email: ?string,
    +$fragmentRefs: LoyaltyPoint_viewer$ref & SideNav_viewer$ref & Logout_viewer$ref,
  |}
|};
export type routes_LoyaltyPoint_Query = {|
  variables: routes_LoyaltyPoint_QueryVariables,
  response: routes_LoyaltyPoint_QueryResponse,
|};
*/


/*
query routes_LoyaltyPoint_Query {
  viewer {
    ...LoyaltyPoint_viewer
    id
    storeCredits(first: 99999) {
      edges {
        node {
          id
          name
          remainingPoints: creditRemaining
          expiry
        }
      }
    }
    loyaltyOptOut
    loyaltyPoints(first: 99999) {
      edges {
        node {
          id
          remainingPoints
          expiry
        }
      }
    }
    ...SideNav_viewer
    email
    ...Logout_viewer
  }
}

fragment Logout_viewer on Customer {
  email
}

fragment LoyaltyPoint_viewer on Customer {
  id
  storeCredits(first: 99999) {
    edges {
      node {
        id
        name
        remainingPoints: creditRemaining
        expiry
      }
    }
  }
  loyaltyOptOut
  loyaltyPoints(first: 99999) {
    edges {
      node {
        id
        remainingPoints
        expiry
      }
    }
  }
}

fragment SideNav_viewer on Customer {
  email
  ...Logout_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99999
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiry",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "CustomerStoreCreditConnection",
  "kind": "LinkedField",
  "name": "storeCredits",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerStoreCreditEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerStoreCredit",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": "remainingPoints",
              "args": null,
              "kind": "ScalarField",
              "name": "creditRemaining",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "storeCredits(first:99999)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loyaltyOptOut",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "CustomerLoyaltyPointConnection",
  "kind": "LinkedField",
  "name": "loyaltyPoints",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerLoyaltyPointEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerLoyaltyPoint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remainingPoints",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "loyaltyPoints(first:99999)"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_LoyaltyPoint_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LoyaltyPoint_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SideNav_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Logout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_LoyaltyPoint_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d518ec0d355633d103a100887bd903e",
    "id": null,
    "metadata": {},
    "name": "routes_LoyaltyPoint_Query",
    "operationKind": "query",
    "text": "query routes_LoyaltyPoint_Query {\n  viewer {\n    ...LoyaltyPoint_viewer\n    id\n    storeCredits(first: 99999) {\n      edges {\n        node {\n          id\n          name\n          remainingPoints: creditRemaining\n          expiry\n        }\n      }\n    }\n    loyaltyOptOut\n    loyaltyPoints(first: 99999) {\n      edges {\n        node {\n          id\n          remainingPoints\n          expiry\n        }\n      }\n    }\n    ...SideNav_viewer\n    email\n    ...Logout_viewer\n  }\n}\n\nfragment Logout_viewer on Customer {\n  email\n}\n\nfragment LoyaltyPoint_viewer on Customer {\n  id\n  storeCredits(first: 99999) {\n    edges {\n      node {\n        id\n        name\n        remainingPoints: creditRemaining\n        expiry\n      }\n    }\n  }\n  loyaltyOptOut\n  loyaltyPoints(first: 99999) {\n    edges {\n      node {\n        id\n        remainingPoints\n        expiry\n      }\n    }\n  }\n}\n\nfragment SideNav_viewer on Customer {\n  email\n  ...Logout_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acda70e24f810e96e303da9b091bbe27';

module.exports = node;
