import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Button, Col, Divider, message, Radio, Spin, Row } from 'antd';
import OrderTotals from '~/components/account/OrderTotals';
import OrderLine from '~/components/account/OrderLine';
import GA4 from '~/ga4';
import CreditCard from './payment/CreditCard';
import { PaymentRetryMutation } from './mutations';
import { onCheckoutCompleted, ORDER_STATUS } from './helper';

const { Group: RadioGroup } = Radio;
const { Item: FormItem } = Form;
const { CC_TYPES } = CreditCard;

const PAYMENT_CODE = "till";

class PaymentRetryView extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      validateFieldsAndScroll: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    }
  }

  componentWillUnmount() {
    this.setState = () => {
      return {
        loading: false
      };
    };
  }

  getSavedCards = (viewer, verification) => {
    if (verification) {
      const last4 = get(verification, 'last4').toString();
      return get(viewer, 'creditCards.edges', []).filter(({node}) => node.ccLast4 === last4);
    }
    return [];
  }

  handleSubmit = (e = null, order) => {
    if (e) {
      e.preventDefault();
    }

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        message.loading('Loading...', 0);
        this.setState({ loading: true });

        // eslint-disable-next-line prefer-const
        let {paymentMethod, ...cc} = values;

        if (Object.keys(cc).length === 0) {
          cc = null
        }

        const input = {
          cc,
          salesOrderId: order.id,
          paymentMethod,
        }

        const onCompleted = onCheckoutCompleted.bind(this);

        PaymentRetryMutation.commit({
          environment: this.props.relay.environment,
          variables: { input },
          viewer: this.props.viewer,
          onCompleted: (resp, errors) => {
            if (!errors && resp.createOrder) {
              const { salesOrder } = resp.createOrder;

              GA4.event({
                event: 'payment_retry',
                action: "click",
                label: salesOrder.name,
              });
            }
            onCompleted(resp, errors);
          },
          onError: (errors) => {
            message.destroy(); // dimiss loading message

            message.error(errors[0].message, 10);
            this.setState({ loading: false });
          }
        });
      }
    })
  }

  pmInitalValue = (paymentMethod, savedCards) => {
    if (paymentMethod === "tyro") {
      return "tyro";
    }

    if (Object.keys(savedCards).length === 0) {
      return PAYMENT_CODE;
    }

    return null;
  }

  renderPaymentFailed = (order) => {
    const { verification } = order;
    const last4 = get(verification, 'last4').toString();

    return (
      <div style={{margin: '0px 10px'}}>
        <h1>#{order.name} <span style={{color: '#cb0000'}}>Payment Failed Please Try Again</span></h1>
        <p>As part of our security measure, we have split your payment into two transactions. Unfortunately we were only able to process one transaction successfully.</p>
        <p>
          Please note only the outstanding balance will be debited.
        </p>
        <p style={{color: '#cb0000'}}>
          {`Please use the same card (ends in ${last4}) and try again to complete the outstanding balance.`}
        </p>
        <p>For further assistance, please <a href="/contacts">contact us</a> for help.</p>
      </div>
    )
  }

  render() {
    const { form, viewer } = this.props;
    const order = get(viewer.salesOrders, 'edges[0].node', {});
    const lines = get(order, 'lines.edges', []);
    const { verification } = order;
    const { getFieldDecorator } = form;
    const last4 = get(verification, 'last4', "").toString();
    const savedCards = this.getSavedCards(viewer, verification);
    const paymentMethod = get(order, 'paymentMethod', "");

    if (order.status === ORDER_STATUS.CANCELLED) {
      return `Order #${order.name} has been cancelled`;
    }

    return (
      <div>
        {this.renderPaymentFailed(order)}

        <Row type="flex" justify="start" gutter={[10, 10]}>
          <Col xs={24} sm={0}>
            <div style={{padding: '5px'}}>
              <div style={{textAlign: 'center'}}>
                <b><u>Your Order:</u></b>
              </div>
              {lines.map((e) => {
                const line = e.node;
                return (
                  <OrderLine key={line.id} line={line} />
                )
              })}

              <Divider />
              <OrderTotals style={{fontWeight: 'bold', marginTop: '10px'}} order={order} />
            </div>
          </Col>

          <Col xs={24} sm={15} md={13}>
            <Form style={{padding: '5px'}}>
              <FormItem>
                {getFieldDecorator('paymentMethod', {
                  initialValue: this.pmInitalValue(paymentMethod, savedCards),
                  rules: [
                    { required: true, message: 'Required' },
                  ],
                })(
                  <RadioGroup
                    style={{width: '100%', padding: '0px 5px'}}
                  >
                    {paymentMethod === "tyro" && (
                      <Radio
                        style={{display: 'block'}}
                        value="tyro"
                        key="tyro"
                      >
                        &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {last4}
                      </Radio>
                    )}
                    {!(paymentMethod === "tyro") && (
                      <div>
                        {savedCards.map(({node}) => {
                          const value = `${PAYMENT_CODE}_${node.id}`;

                          return (
                            <Radio
                              style={{display: 'block'}}
                              value={value}
                              key={value}
                            >
                              {get(CC_TYPES[node.ccBrand], 'logo')} {node.ccHolder} &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {node.ccLast4}
                            </Radio>
                          )
                        })}
                        <Radio
                          style={{display: 'block'}}
                          value={PAYMENT_CODE}
                          key={PAYMENT_CODE}
                        >
                          Credit Card
                          {form.getFieldValue("paymentMethod") === PAYMENT_CODE && (
                            <CreditCard form={form} updateCardType={() => {}} />
                          )}
                        </Radio>
                      </div>
                    )}
                  </RadioGroup>
                )}
              </FormItem>
              <Spin
                size="large"
                spinning={this.state.loading}
              >
                <Button
                  type="primary"
                  size="large"
                  style={{width: '100%'}}
                  onClick={(e) => this.handleSubmit(e, order)}
                >
                  {this.state.loading ? "Processing your payment. . ." : "Complete Order"}
                </Button>
              </Spin>
            </Form>
          </Col>

          <Col xs={0} sm={9} md={11}>
            <div style={{padding: '5px'}}>
              <div style={{textAlign: 'center'}}>
                <b><u>Your Order:</u></b>
              </div>
              {lines.map((e) => {
                const line = e.node;
                return (
                  <OrderLine key={line.id} line={line} />
                )
              })}

              <Divider />
              <OrderTotals style={{fontWeight: 'bold', marginTop: '10px'}} order={order} />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
export default createFragmentContainer(Form.create()(PaymentRetryView), {
  viewer: graphql`
    fragment PaymentRetryView_viewer on Customer {
      id
      firstname
      creditCards(first: 999) @connection(key: "App_viewer_creditCards") {
        edges {
          node {
            id
            ccBrand
            ccHolder
            ccLast4
            ccExpMonth
            ccExpYear
            ccHolder
          }
        }
      }
      salesOrders(first: 1, ids: $ids) {
        edges {
          node {
            id
            name
            status
            shippingTotal
            shippingDescription
            subtotal
            grandTotal
            surcharge
            surcharges {
              name
              amount
            }
            discount
            discounts {
              id
              name
              code
              amount
            }
            paymentMethod
            verification
            lines(first: 99) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  unitDiscount
                  unitSurcharge
                  rowTotal
                  configParent {
                    id
                    urlSlug
                    mainImage {
                      id
                      thumbnail
                    }
                  }
                  product {
                    urlSlug
                    status
                    mainImage {
                      id
                      url
                      thumbnail
                    }
                  }
                  shipments {
                    tracking
                    location
                    status
                    timestamp
                  }
                  children(first: 99) {
                    edges {
                      node {
                        id
                        name
                        quantity
                        unitPrice
                        unitDiscount
                        unitSurcharge
                        rowTotal
                        product {
                          urlSlug
                          status
                          mainImage {
                            id
                            url
                            thumbnail
                          }
                        }
                        shipments {
                          tracking
                          location
                          status
                          timestamp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
