/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Order_viewer$ref: FragmentReference;
declare export opaque type Order_viewer$fragmentType: Order_viewer$ref;
export type Order_viewer = {|
  +email: ?string,
  +stores: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +phone: ?string,
      |}
    |}>
  |},
  +salesOrders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +shippingTotal: ?number,
        +verified: ?number,
        +verificationLink: ?string,
        +shippingDescription: ?string,
        +paymentDescription: ?string,
        +subtotal: ?number,
        +grandTotal: ?number,
        +insertedAt: ?string,
        +surcharge: ?number,
        +surcharges: ?$ReadOnlyArray<?{|
          +name: ?string,
          +amount: ?number,
        |}>,
        +discount: ?number,
        +discounts: ?$ReadOnlyArray<?{|
          +id: ?string,
          +name: ?string,
          +code: ?string,
          +amount: ?number,
        |}>,
        +shipments: ?any,
        +shippingStatus: ?string,
        +status: ?number,
        +addresses: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +addressType: ?string,
              +firstname: ?string,
              +lastname: ?string,
              +street: ?string,
              +city: ?string,
              +postcode: ?string,
              +region: ?string,
              +country: ?{|
                +name: ?string
              |},
              +telephone: ?string,
              +company: ?string,
              +fax: ?string,
              +editable: ?boolean,
            |}
          |}>
        |},
        +lines: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +quantity: ?number,
              +unitPrice: ?number,
              +unitDiscount: ?number,
              +unitSurcharge: ?number,
              +rowTotal: ?number,
              +configParent: ?{|
                +id: string,
                +urlSlug: ?string,
                +mainImage: ?{|
                  +id: string,
                  +thumbnail: ?string,
                |},
              |},
              +priorityShipping: ?{|
                +getByDate: ?string
              |},
              +product: ?{|
                +urlSlug: ?string,
                +status: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                  +thumbnail: ?string,
                |},
              |},
              +shipments: ?$ReadOnlyArray<?{|
                +tracking: ?any,
                +location: ?string,
                +status: ?string,
                +timestamp: ?string,
              |}>,
              +children: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                    +quantity: ?number,
                    +unitPrice: ?number,
                    +unitDiscount: ?number,
                    +unitSurcharge: ?number,
                    +rowTotal: ?number,
                    +product: ?{|
                      +urlSlug: ?string,
                      +status: ?string,
                      +mainImage: ?{|
                        +id: string,
                        +url: ?string,
                        +thumbnail: ?string,
                      |},
                    |},
                    +shipments: ?$ReadOnlyArray<?{|
                      +tracking: ?any,
                      +location: ?string,
                      +status: ?string,
                      +timestamp: ?string,
                    |}>,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: Order_viewer$ref,
|};
export type Order_viewer$data = Order_viewer;
export type Order_viewer$key = {
  +$data?: Order_viewer$data,
  +$fragmentRefs: Order_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitDiscount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSurcharge",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowTotal",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "mainImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        (v11/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "SalesShipmentLine",
  "kind": "LinkedField",
  "name": "shipments",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tracking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "orderNames"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Order_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 999
        }
      ],
      "concreteType": "StoreConnection",
      "kind": "LinkedField",
      "name": "stores",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Store",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phone",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "stores(first:999)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "orderNames",
          "variableName": "orderNames"
        }
      ],
      "concreteType": "SalesOrderConnection",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verified",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verificationLink",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subtotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grandTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "insertedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "surcharge",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderSurcharge",
                  "kind": "LinkedField",
                  "name": "surcharges",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Discount",
                  "kind": "LinkedField",
                  "name": "discounts",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shipments",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingStatus",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 2
                    }
                  ],
                  "concreteType": "SalesAddressConnection",
                  "kind": "LinkedField",
                  "name": "addresses",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesAddressEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesAddress",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "addressType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstname",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastname",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "street",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "city",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "postcode",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "region",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Country",
                              "kind": "LinkedField",
                              "name": "country",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "telephone",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "company",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fax",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "editable",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "addresses(first:2)"
                },
                {
                  "alias": null,
                  "args": (v4/*: any*/),
                  "concreteType": "SalesOrderLineConnection",
                  "kind": "LinkedField",
                  "name": "lines",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderLineEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderLine",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/),
                            (v9/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "configParent",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v10/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProductImage",
                                  "kind": "LinkedField",
                                  "name": "mainImage",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v11/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PriorityShipping",
                              "kind": "LinkedField",
                              "name": "priorityShipping",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "getByDate",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v12/*: any*/),
                            (v13/*: any*/),
                            {
                              "alias": null,
                              "args": (v4/*: any*/),
                              "concreteType": "SalesOrderLineConnection",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderLineEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderLine",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v1/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/),
                                        (v7/*: any*/),
                                        (v8/*: any*/),
                                        (v9/*: any*/),
                                        (v12/*: any*/),
                                        (v13/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "children(first:99)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "lines(first:99)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ae5fb87e3a16b21afc3e3605653f7e5';

module.exports = node;
