/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaymentRetryView_viewer$ref: FragmentReference;
declare export opaque type PaymentRetryView_viewer$fragmentType: PaymentRetryView_viewer$ref;
export type PaymentRetryView_viewer = {|
  +id: string,
  +firstname: ?string,
  +creditCards: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +ccBrand: ?string,
        +ccHolder: ?string,
        +ccLast4: ?string,
        +ccExpMonth: ?number,
        +ccExpYear: ?number,
      |}
    |}>
  |},
  +salesOrders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +status: ?number,
        +shippingTotal: ?number,
        +shippingDescription: ?string,
        +subtotal: ?number,
        +grandTotal: ?number,
        +surcharge: ?number,
        +surcharges: ?$ReadOnlyArray<?{|
          +name: ?string,
          +amount: ?number,
        |}>,
        +discount: ?number,
        +discounts: ?$ReadOnlyArray<?{|
          +id: ?string,
          +name: ?string,
          +code: ?string,
          +amount: ?number,
        |}>,
        +paymentMethod: ?string,
        +verification: ?any,
        +lines: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +quantity: ?number,
              +unitPrice: ?number,
              +unitDiscount: ?number,
              +unitSurcharge: ?number,
              +rowTotal: ?number,
              +configParent: ?{|
                +id: string,
                +urlSlug: ?string,
                +mainImage: ?{|
                  +id: string,
                  +thumbnail: ?string,
                |},
              |},
              +product: ?{|
                +urlSlug: ?string,
                +status: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                  +thumbnail: ?string,
                |},
              |},
              +shipments: ?$ReadOnlyArray<?{|
                +tracking: ?any,
                +location: ?string,
                +status: ?string,
                +timestamp: ?string,
              |}>,
              +children: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                    +quantity: ?number,
                    +unitPrice: ?number,
                    +unitDiscount: ?number,
                    +unitSurcharge: ?number,
                    +rowTotal: ?number,
                    +product: ?{|
                      +urlSlug: ?string,
                      +status: ?string,
                      +mainImage: ?{|
                        +id: string,
                        +url: ?string,
                        +thumbnail: ?string,
                      |},
                    |},
                    +shipments: ?$ReadOnlyArray<?{|
                      +tracking: ?any,
                      +location: ?string,
                      +status: ?string,
                      +timestamp: ?string,
                    |}>,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: PaymentRetryView_viewer$ref,
|};
export type PaymentRetryView_viewer$data = PaymentRetryView_viewer;
export type PaymentRetryView_viewer$key = {
  +$data?: PaymentRetryView_viewer$data,
  +$fragmentRefs: PaymentRetryView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitDiscount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSurcharge",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowTotal",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "mainImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        (v11/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "SalesShipmentLine",
  "kind": "LinkedField",
  "name": "shipments",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tracking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "creditCards"
        ]
      }
    ]
  },
  "name": "PaymentRetryView_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": "creditCards",
      "args": null,
      "concreteType": "CreditCardConnection",
      "kind": "LinkedField",
      "name": "__App_viewer_creditCards_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditCardEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CreditCard",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ccBrand",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ccHolder",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ccLast4",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ccExpMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ccExpYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "SalesOrderConnection",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subtotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grandTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "surcharge",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderSurcharge",
                  "kind": "LinkedField",
                  "name": "surcharges",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Discount",
                  "kind": "LinkedField",
                  "name": "discounts",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    },
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentMethod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verification",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v4/*: any*/),
                  "concreteType": "SalesOrderLineConnection",
                  "kind": "LinkedField",
                  "name": "lines",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderLineEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderLine",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/),
                            (v9/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "configParent",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v10/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProductImage",
                                  "kind": "LinkedField",
                                  "name": "mainImage",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v11/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v12/*: any*/),
                            (v13/*: any*/),
                            {
                              "alias": null,
                              "args": (v4/*: any*/),
                              "concreteType": "SalesOrderLineConnection",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderLineEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderLine",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v1/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/),
                                        (v7/*: any*/),
                                        (v8/*: any*/),
                                        (v9/*: any*/),
                                        (v12/*: any*/),
                                        (v13/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "children(first:99)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "lines(first:99)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ef038da6b01d8f0732518f3c152ba22b';

module.exports = node;
