import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateDefaultAddressMutation($input: UpdateDefaultAddressInput!) {
    updateDefaultAddress(input:$input) {
        viewer {
          defaultAddress {
          id
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
