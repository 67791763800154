import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Button, Form, Input, message, Upload } from 'antd';
import 'antd/lib/date-picker/style';
import 'antd/lib/upload/style';
import { formItemLayout, scrollToFirstError, filesValidator } from '~/components/form';

import { PostSparePartsFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;

const EMAIL_DOMAIN = process.env.COUNTRY === 'NZ' ? '@sydneytools.co.nz' : '@sydneytools.com.au';

const SparePartsForm = (props) => {
  const { viewer, relay } = props;
  const stores = viewer.stores.edges
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    const uploadables = {};
    if (values.attachments) {
      const fileList = values.attachments.map(f => f.originFileObj);

      values.attachments = fileList.map((f, i) => {
        const name = `attachment${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    values.staffEmail += EMAIL_DOMAIN;
    if (values.jobNumber === "") {
      delete values.jobNumber;
    }

    PostSparePartsFormMutation.commit({
      environment: relay.environment,
      variables: { input: values },
      uploadables,
      viewer,
      onCompleted: () => {
        message.success("Thank you very much, we have received your feedback.");
        form.resetFields();
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  return (
    <div>
      <Helmet title="Spare parts" />
      <h1>Spare parts</h1>

      <Form form={form} onFinish={handleSubmit} scrollToFirstError={scrollToFirstError}>

        <p><b>Max. size</b> of the attachments is <b>25MB</b>.</p>
        <p>If you did not receive an <b>email copy</b> of this form, <b>please re-submit or ask for assistance</b>.</p>

        <FormItem
          {...formItemLayout}
          label="Branch"
          name="branch"
          rules={[
            { required: true, message: 'Required' },
          ]}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<SearchOutlined />}
            placeholder="Branch"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={stores.map(({ node }) => ({
              value: node.id,
              label: node.name,
            }))}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Staff Name"
          name="staffName"
          rules={[
            { required: true, message: 'Required' },
          ]}
        >
          <Input placeholder="Staff Name" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Staff Email"
          name="staffEmail"
          rules={[
            { required: true, message: 'Required' },
            { type: 'email', message: 'Invalid Email', transform: val => `${val}${EMAIL_DOMAIN}` },
          ]}
        >
          <Input addonAfter={EMAIL_DOMAIN} style={{ width: '400px' }} placeholder="Staff Email" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Customer Invoice or account number"
          name="customerNumber"
          rules={[
            { required: true, message: 'Required' },
          ]}
        >
          <Input placeholder="Customer Invoice or account number" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Job Number"
          name="jobNumber"
        >
          <Input placeholder="Job Number" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Main unit Model"
          name="unitModel"
          rules={[
            { required: true, message: 'Required' },
          ]}
        >
          <Input placeholder="Main unit Model" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Main unit GP"
          name="unitGP"
          rules={[
            { required: true, message: 'Required' },
          ]}
        >
          <Input placeholder="Main unit GP" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Parts required"
          name="partsRequired"
          help='Provide detailed description of parts required with GPs or part numbers if known'
          rules={[
            { required: true, message: 'Required' },
          ]}
        >
          <TextArea placeholder="Parts required" rows={4} />
        </FormItem>
        <br />

        <FormItem
          {...formItemLayout}
          label="Attachments"
          name="attachments"
          rules={[
            { validator: (_rule, value) => filesValidator(25, _rule, value) }
          ]}
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <Upload
            multiple
            beforeUpload={() => false}
          >
            <Button>
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </FormItem>

        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>

      </Form>
    </div>
  )
}

SparePartsForm.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
    stores: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default createFragmentContainer(SparePartsForm, {
  viewer: graphql`
    fragment SparePartsForm_viewer on Customer {
      id
      stores(first: 999, filterBy: $filterBy) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
