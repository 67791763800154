/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PriceChange_viewer$ref = any;
export type PriceChangeRefetchQueryVariables = {|
  date: string
|};
export type PriceChangeRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PriceChange_viewer$ref
  |}
|};
export type PriceChangeRefetchQuery = {|
  variables: PriceChangeRefetchQueryVariables,
  response: PriceChangeRefetchQueryResponse,
|};
*/


/*
query PriceChangeRefetchQuery(
  $date: String!
) {
  viewer {
    ...PriceChange_viewer_19b1FI
    id
  }
}

fragment PriceChange_viewer_19b1FI on Customer {
  priceChanges(date: $date) {
    id
    name
    adenCode
    barcode
    price
    urlSlug
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PriceChangeRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "PriceChange_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PriceChangeRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "PriceChange",
            "kind": "LinkedField",
            "name": "priceChanges",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adenCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "barcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlSlug",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca5492cf765104e1e4311270d5f060df",
    "id": null,
    "metadata": {},
    "name": "PriceChangeRefetchQuery",
    "operationKind": "query",
    "text": "query PriceChangeRefetchQuery(\n  $date: String!\n) {\n  viewer {\n    ...PriceChange_viewer_19b1FI\n    id\n  }\n}\n\nfragment PriceChange_viewer_19b1FI on Customer {\n  priceChanges(date: $date) {\n    id\n    name\n    adenCode\n    barcode\n    price\n    urlSlug\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2e9a64f9fc496876a3e3a64d704faf60';

module.exports = node;
