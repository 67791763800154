import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateLoyaltyOptOutMutation($input: UpdateLoyaltyOptOutInput!) {
    updateLoyaltyOptOut(input:$input) {
      loyaltyOptOut
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
