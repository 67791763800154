/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PriorityPage_viewer$ref: FragmentReference;
declare export opaque type PriorityPage_viewer$fragmentType: PriorityPage_viewer$ref;
export type PriorityPage_viewer = {|
  +id: string,
  +email: ?string,
  +faqs: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +question: ?string,
        +answer: ?string,
      |}
    |}>
  |},
  +$refType: PriorityPage_viewer$ref,
|};
export type PriorityPage_viewer$data = PriorityPage_viewer;
export type PriorityPage_viewer$key = {
  +$data?: PriorityPage_viewer$data,
  +$fragmentRefs: PriorityPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriorityPage_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filterBy",
          "value": [
            {
              "field": "name",
              "filter": "[PRIORITY-SHIPPING]",
              "filterType": "text",
              "type": "contains"
            }
          ]
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 99
        }
      ],
      "concreteType": "FaqConnection",
      "kind": "LinkedField",
      "name": "faqs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FaqEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Faq",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "question",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "answer",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "faqs(filterBy:[{\"field\":\"name\",\"filter\":\"[PRIORITY-SHIPPING]\",\"filterType\":\"text\",\"type\":\"contains\"}],first:99)"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '61db2f34fd39acc73f79ccc894fd702e';

module.exports = node;
