import React from 'react';
import PropTypes from 'prop-types';

import { message } from 'antd';
import { PayPalButtons, PayPalMessages, PayPalScriptProvider } from "@paypal/react-paypal-js";

let CLIENT_ID = "AcvoNUT1_QIjy2mdHFmMjElbyYwFzZ9Qufvv5-lOW2zIXb0X16458WaGQ9hfmwM0rRSQ2EpHn6TbtjjC";
if (process.env.NODE_ENV === 'production') {
  CLIENT_ID = "ASUcsnWpVOxyohu5Bicctcq0nxGreyfRBcB3kidGBpN4S6dq7PgHlnaSn3uD5WkPjaJaKgC_Dh4PuT22";
}

const setPaypal = ({form, submit}, payload) => {
  form.setFieldsValue({
    paypalPayerId: payload.payerID,
    paypalOrderId: payload.orderID,
  }, () => {
    // attempt to place order on approve
    if (payload && payload.orderID) {
      submit();
    }
  });
};

// eslint-disable-next-line react/no-multi-comp
export class PayPalPayIn4 extends React.Component {
  static propTypes = {
    amount: PropTypes.number,
    style: PropTypes.shape({}),
  }

  static defaultProps = {
    amount: 0,
    style: {},
  };

  componentDidCatch = () => {
  }

  render() {
    const {amount, style} = this.props;

    if (!amount) {
      return null;
    }

    return (
      <PayPalScriptProvider
        options={{
          "components": 'messages',
          "client-id": CLIENT_ID,
        }}
      >
        <PayPalMessages amount={amount} style={style} />
      </PayPalScriptProvider>
    );
  }
};

export const PayPalButton = (props) => {
  const { form, submit, extra } = props;

  if (!extra) {
    return null;
  }

  const { clientId } = extra;

  return (
    <PayPalScriptProvider
      options={{
        "client-id": clientId,
        "currency": process.env.CURRENCY,
        "disable-funding": ['card'],
        "enable-funding": ['paylater'],
      }}
    >
      <PayPalButtons
        style={{
          label: 'paypal',
          shape: 'rect',
        }}
        createOrder={() => {
          return new Promise(((resolve, reject) => {
            form.validateFieldsAndScroll(err => {
              if (!err) {
                const request = {
                  headers: {
                    'content-type': 'application/json'
                  },
                  method: 'POST',
                  credentials: 'same-origin',
                  body: JSON.stringify(form.getFieldsValue())
                };

                fetch('/api/payments/paypal', request)
                .then(res => {
                  if (res.ok) {
                    return res.json()
                  }

                    throw(res);

                }).then(res => {
                  if (res.id) {
                    resolve(res.id);
                  } else {
                    const errorMsg = "There is an error with Paypal. Please try other payment methods";
                    message.error(errorMsg);
                    reject(new Error(errorMsg));
                  }
                }).catch(error => {
                  const msg = 'There was an error';

                  console.error(error);
                  message.error(msg);
                  reject(new Error(msg));
                });
              } else {
                message.error("Please check your details in the form");
                reject(new Error("Incompleted Checkout Form"));
              }
            });
          }));
        }}
        onApprove={(payload) => {
          setPaypal({form, submit}, payload)
        }}
        onCancel={() => {
          setPaypal({form}, {});
        }}
        onError={() => {
          setPaypal({form}, {});
        }}
      />
    </PayPalScriptProvider>
  );
};

PayPalButton.propTypes = {
  extra: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
  }).isRequired,
  submit: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
export default class Paypal extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('paypalPayerId')(<div />)}
        {getFieldDecorator('paypalOrderId')(<div />)}
      </div>
    );
  }
}
