import React from 'react';
import PropTypes from 'prop-types';

import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

import Card, { handleNextAction } from './Card';
import SavedCard from './SavedCard';

let apiKey = 'pk_test_51NnXZmKGjGjqrDkyLemikb6keuaVHrLibR7jBN0pmzoB3MiI9rkQBVpNsm8uMKTk4On3ujZun3MCOeoQnWgF3UnK00QBuGSNM2';

if (process.env.NODE_ENV === 'production') {
  apiKey = 'pk_live_51NnXZmKGjGjqrDkyz07viUha1azSjwHm7S9RRwi3pSA0Z7hu42I1LigV17k2PhvbAUE4e77YUQ8IMjpyeIwZADeo00mLaTBJab';
}

let stripeConfirmPayment = null;

export const load = () => (
  loadStripe(apiKey)
);

export default class Stripe extends React.Component {
  static init = (form, submit) => {
    const callback = () => {
        form.setFieldsValue({
          ccNumber: undefined,
          ccExpiry: undefined,
          ccCvv: undefined,
        });
        submit();
    };

    form.validateFields(err => {
      if (!err) {
        stripeConfirmPayment(callback);
      }
    });
  }

  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    updateCardType: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.stripePromise = load();
  }

  setConfirmPayment = (confirmPayment) => {
    stripeConfirmPayment = confirmPayment;
  }

  static handleNextAction = handleNextAction

  static SavedCard = SavedCard

  render() {
    const { form, updateCardType } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        {getFieldDecorator('stripeToken')(<div />)}
        <Elements stripe={this.stripePromise}>
          <Card
            form={form}
            updateCardType={updateCardType}
            setConfirmPayment={this.setConfirmPayment}
          />
        </Elements>
      </div>
    );
  }
}
