import React from 'react';
import { HttpError, Route } from 'found';
import { get } from 'lodash';
import { graphql } from 'react-relay';
import { ORDER_STATUS, getPaymentFailedUrl, getPaymentSuccessUrl } from './helper';

const CheckoutViewQuery = graphql`
  query routes_CheckoutView_Query(
    $forklift: Boolean,
    $street: String,
    $suburb: String,
    $city: String,
    $postcode: String,
    $useCredit: Boolean,
    $useLoyaltyPoints: Boolean,
    $shippingMethod: String,
    $ccType: String,
    $stripeToken: String
  ) {
    viewer {
      ...CheckoutView_viewer @arguments(
        forklift: $forklift,
        street: $street,
        suburb: $suburb,
        city: $city,
        postcode: $postcode,
        useCredit: $useCredit,
        useLoyaltyPoints: $useLoyaltyPoints,
        shippingMethod: $shippingMethod,
        ccType: $ccType,
        stripeToken: $stripeToken
      )
    }
  }
`;
const SuccessViewQuery = graphql`
  query routes_SuccessView_Query($ids: [ID!]) {
    viewer {
      ...SuccessView_viewer
      ...SuccessView_viewer @relay(mask: false)
    }
  }
`;
const BeforeSuccessQuery = graphql`
  query routes_BeforeSuccess_Query($ids: [ID!]) {
    viewer {
      ...BeforeSuccess_viewer
      ...BeforeSuccess_viewer @relay(mask: false)
    }
  }
`;

const PaymentRetryViewQuery = graphql`
  query routes_PaymentRetryView_Query($ids: [ID!]) {
    viewer {
      ...PaymentRetryView_viewer
      ...PaymentRetryView_viewer @relay(mask: false)
    }
  }
`;

const routes = (
  <Route>
    <Route
      path="/checkout"
      getComponent={() => (
        import(/* webpackChunkName: "CheckoutView" */'~/components/checkout/CheckoutView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={CheckoutViewQuery}
      prepareVariables={() => ({
        // Ref to getPreVars() in CheckoutView.js
        useCredit: true,
        useLoyaltyPoints: true,
      })}
    />
    <Route
      path="/checkout/before-success/:orderId"
      getComponent={() => (
        import(/* webpackChunkName: "BeforeSuccess" */'~/components/checkout/BeforeSuccess').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={BeforeSuccessQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          const order = get(props, 'viewer.salesOrders.edges[0].node', {});

          if (Object.keys(order).length > 0) {
            if (order.status === ORDER_STATUS.PAYMENT_FAILED) {
              props.router.replace(getPaymentFailedUrl(order.id))
            } else {
              return <Component {...props} />;
            }
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      prepareVariables={params => ({ ids: [params.orderId] })}
    />
    <Route
      path="/checkout/success/:orderId"
      getComponent={() => (
        import(/* webpackChunkName: "SuccessView" */'~/components/checkout/SuccessView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={SuccessViewQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          const order = get(props, 'viewer.salesOrders.edges[0].node', {});

          if (Object.keys(order).length > 0) {
            if (order.status === ORDER_STATUS.PAYMENT_FAILED) {
              props.router.replace(getPaymentFailedUrl(order.id))
            } else {
              return <Component {...props} />;
            }
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      prepareVariables={params => ({ ids: [params.orderId] })}
    />
    <Route
      path="/checkout/paymentfailed/:orderId"
      getComponent={() => (
        import(/* webpackChunkName: "PaymentRetryView" */'~/components/checkout/PaymentRetryView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PaymentRetryViewQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          const order = get(props, 'viewer.salesOrders.edges[0].node', {});

          if (Object.keys(order).length > 0) {
            if ([ORDER_STATUS.PAYMENT_FAILED, ORDER_STATUS.CANCELLED].includes(order.status)) {
              return <Component {...props} />;
            }
            props.router.replace(getPaymentSuccessUrl(order.id))
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      prepareVariables={params => ({ ids: [params.orderId] })}
    />
  </Route>
);

export default routes;
