import React from 'react'
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Col, Row } from 'antd';
import { Link } from 'found';
import { getProductUrl, getThumbnail } from '~/helper';
import GetByDate from '../priority/GetByDate';
import Tracking from './order/Tracking';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

export function renderImg(line) {
  const thumbnail = getThumbnail(line);

  const style = {width: '100%', maxWidth: '100px'};
  return (
    thumbnail ?
      <img style={style} alt={line.name} src={thumbnail} />
        : <img style={style} alt="Place Holder" src={IMAGE_PLACEHOLDER} />
  );
}

function OrderLine({line, isChild = false}) {
  const urlPrefix = "/product/";
  const url = getProductUrl(line, urlPrefix);

  const status = get(line, 'product.status', 'disabled');
  const finalPrice = line.unitPrice - line.unitDiscount + line.unitSurcharge;

  const getByDate = get(line.priorityShipping, 'getByDate');

  return (
    <div key={line.id}>
      <Row style={{marginLeft: isChild ? '10%' : '0'}}>
        <Col xs={7} sm={3} md={2}>{renderImg(line)}</Col>
        <Col xs={17} sm={21} md={22} style={{paddingLeft: '10px', fontSize: '14px', fontWeight: isChild ? 'normal' : 'bold'}}>
          { status === 'enabled' ?
            <Link to={url} className="black-href" style={{ textDecoration: 'underline' }}>{line.name}</Link>
            : <span>{line.name}</span>
          }
          <GetByDate date={getByDate} />

          <div style={{marginTop: '5px'}}>
            <span className="price">{line.quantity} x ${(finalPrice).toFixed(2)}</span>
            <Tracking line={line} />
          </div>
        </Col>
      </Row>

      {line.children && line.children.edges.length > 0 && (
        <Row>
          <Col>
            <Row>
              {line.children.edges.map(edge => (
                <OrderLine key={edge.node.id} line={edge.node} isChild />
              ))}
            </Row>
          </Col>
        </Row>
       )}
    </div>
  );
}

OrderLine.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unitPrice: PropTypes.number.isRequired,
    unitDiscount: PropTypes.number,
    unitSurcharge: PropTypes.number,
    quantity: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    priorityShipping: PropTypes.shape({
      getByDate: PropTypes.string
    })
  }).isRequired,
  isChild: PropTypes.bool,
}

OrderLine.defaultProps = {
  isChild: false,
}

export default OrderLine;
