import { graphql } from 'react-relay';
import { edgeUpdater, buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddAddressMutation($input: AddAddressInput!) {
    addAddress(input:$input) {
      addressEdge {
        __typename
        cursor
        node {
          id
          firstname
          lastname
          street
          city
          postcode
          region
          country {
            name
          }
          telephone
          company
          fax
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: edgeUpdater.bind(this, { rootField: 'addAddress', edgeName: 'addressEdge', connectionKey: 'AddressBook_addresses' }),
});

export default { commit };
