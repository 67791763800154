import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { importScript } from '~/helper'
import { CompleteRedirectOrderMutation } from '../mutations';
import { onCheckoutCompleted } from '../helper';

let COUNTRY = 'au';
if (process.env.COUNTRY === 'NZ') {
  COUNTRY = 'nz';
}

export const DisplayZipLogo = ({ price }) => {
  const [init, setInit] = useState(false);

  const click = () => {
      const widget = document.querySelector('[data-zm-asset="productwidget"]');
      if (widget) widget.click();
  }

  const clicker = () => {
    if (init === false) {
      importScript("https://static.zip.co/lib/js/zm-widget-js/dist/zip-widget.min.js", () => {
        // Delay the click as it takes time for the script to load.
        setTimeout(click, 200);
      });
      setInit(true);
    } else {
      click();
    }
  }

  return (
    <div>
      <div
        data-zm-asset="productwidget"
        data-env="production"
        data-zm-merchant="4441aa8d-aa9a-41ea-9d5f-19241c069a0d"
        data-zm-price={price}
        data-zm-region={COUNTRY}
        data-zm-widget="popup"
        zm-widget="inline"
        style={{ height: '0', padding: '0', visibility: 'hidden', width: '0' }}
      />

      <div
        onClick={clicker}
        onKeyPress={clicker}
        tabIndex="0"
        role="button"
      >
        <img
          src="/assets/images/zip_logo.svg"
          height="30"
          alt="Zip Pay"
          title="Zip Pay"
        />
      </div>
    </div>
  );
};

DisplayZipLogo.propTypes = {
  price: PropTypes.number.isRequired,
};

export default class Zippay extends React.Component {
  static redirect = function redirect(loc) {
    if (['checkoutId', 'result'].every(k => k in loc.query)) {
      if (['approved'].includes(loc.query.result)) {
        const values = {
          zipCheckoutId: loc.query.checkoutId,
          zipCheckoutState: loc.query.result,
          PaymentMethod: 'zip',
        };

        const onCompleted = onCheckoutCompleted.bind(this);

        CompleteRedirectOrderMutation.commit({
          // eslint-disable-next-line react/prop-types
          environment: this.props.relay.environment,
          variables: { input: values },
          // eslint-disable-next-line react/prop-types
          viewer: this.props.viewer,
          onCompleted,
          onError: (errors) => {
            message.destroy(); // dimiss loading message
            message.error(errors[0].message, 10);
            // eslint-disable-next-line react/no-unused-state
            this.setState({ loading: false });
          },
        });
      }
    }
  }

  static init = (form, submit) => {

    const fields = form.getFieldsValue();

    /*
     * When re-setting a password is present, the above guard is not enough to prevent order placing
     * as `checkoutPassword` field is removed.
     */
    if (fields.billing && Object.keys(fields.billing).length === 1 && fields.billing.email) {
      message.error('Please login to place your order')
      return;
    }

    // Form wasn't scrolling when theres a missing field
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        submit();
      }
    })
  }

  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('zipCheckoutId')(<div />)}
        {getFieldDecorator('zipCheckoutState')(<div />)}
      </div>
    );
  }
}
