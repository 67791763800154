import React from 'react';
import PropTypes from 'prop-types';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ButtonBack, ButtonNext, Dot, WithStore } from 'pure-react-carousel';

class Thumbnail extends React.Component {
  static propTypes = {
    thumbnails: PropTypes.arrayOf(PropTypes.shape({
    })).isRequired,
    currentSlide: PropTypes.number.isRequired,
    naturalSlideHeight: PropTypes.number.isRequired,
    totalSlides: PropTypes.number.isRequired,
    product: PropTypes.shape({
      model: PropTypes.string.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      slideHeight: props.naturalSlideHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setSlideSize);
    this.setSlideSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSlideSize);
  }

  getTop = (currentSlide) => {
    let top = '0px';

    const thumb = document.querySelector(`#image-thumbnails button.carousel__dot:nth-child(${currentSlide+1})`);

    if (thumb) {
      const move = currentSlide - 1;
      if (move > 0) {
        const wrapper = document.querySelector("#image-thumbnails");
        const height = thumb.clientHeight;
        const halfThumb = height / 2;
        const halfWindow = wrapper.clientHeight / 2;
        const { offsetTop } = thumb;

        // move clicked thumb to center vertically
        top = `${-offsetTop + halfWindow - halfThumb}px`;
      }
    }

    return top;
  }

  setSlideSize = () => {
    const elem = document.getElementById('image-slider');
    const upBtn = document.querySelector('#image-thumbnails .carousel__back-button') || 0;
    const downBtn = document.querySelector('#image-thumbnails .carousel__next-button') || 0;


    if (elem) {
      let slideHeight = elem.clientHeight;

      if (upBtn) {
        slideHeight -= upBtn.clientHeight;
      }

      if (downBtn) {
        slideHeight -= downBtn.clientHeight;
      }

      this.setState({
        slideHeight
      });
    }
  }

  getSlideHeight = () => `${this.state.slideHeight}px`

  render() {
    const { currentSlide, product, thumbnails, totalSlides } = this.props;

    return (
      <div style={{textAlign: 'center'}}>
        {thumbnails.length > 3 && (
        <ButtonBack style={{background: 'none', border: 'none'}}>
          <CaretUpOutlined />
        </ButtonBack>
        )}

        <div style={{ overflow: 'hidden' }}>
          <div style={{
            top: this.getTop(currentSlide),
            transition: 'top .5s',
            transitionTimingFunction: 'cubic-bezier(.645,.045,.355,1)',
            position: 'relative',
            maxHeight: this.getSlideHeight(),
          }}
          >
            {thumbnails.map((slide, index) => (
              <Dot
                key={slide.id || slide.src}
                slide={index}
                style={{
                  margin: '5px 0px',
                  background: 'none',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '0px',
                  boxSizing: 'border-box',
                }}
              >
                {slide.thumbnail && React.isValidElement(slide.thumbnail) &&
                  slide.thumbnail
                }
                {slide.thumbnail && !React.isValidElement(slide.thumbnail) &&
                  <img alt={product.model} className="img-fluid" src={slide.thumbnail} />
                }
              </Dot>
            ))}
          </div>
        </div>

        {thumbnails.length > 3 && (currentSlide + 1 !== totalSlides) && (
        <ButtonNext style={{background: 'none', border: 'none'}}>
          <CaretDownOutlined />
        </ButtonNext>
        )}
      </div>
    );
  }
}

export default WithStore(Thumbnail, state => ({
  currentSlide: state.currentSlide,
  naturalSlideHeight: state.naturalSlideHeight,
  totalSlides: state.totalSlides,
}))
