import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation PostCustomerOrderFormMutation($input: PostCustomerOrderFormInput!) {
    postCustomerOrderForm(input:$input) {
      result
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
