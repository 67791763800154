/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAddressInput = {|
  city: string,
  company?: ?string,
  country: string,
  fax?: ?string,
  firstname: string,
  lastname: string,
  postcode: string,
  region: string,
  street: string,
  telephone: string,
|};
export type AddAddressMutationVariables = {|
  input: AddAddressInput
|};
export type AddAddressMutationResponse = {|
  +addAddress: ?{|
    +addressEdge: ?{|
      +__typename: string,
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +firstname: ?string,
        +lastname: ?string,
        +street: ?string,
        +city: ?string,
        +postcode: ?string,
        +region: ?string,
        +country: ?{|
          +name: ?string
        |},
        +telephone: ?string,
        +company: ?string,
        +fax: ?string,
      |},
    |}
  |}
|};
export type AddAddressMutation = {|
  variables: AddAddressMutationVariables,
  response: AddAddressMutationResponse,
|};
*/


/*
mutation AddAddressMutation(
  $input: AddAddressInput!
) {
  addAddress(input: $input) {
    addressEdge {
      __typename
      cursor
      node {
        id
        firstname
        lastname
        street
        city
        postcode
        region
        country {
          name
        }
        telephone
        company
        fax
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAddressPayload",
    "kind": "LinkedField",
    "name": "addAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAddressEdge",
        "kind": "LinkedField",
        "name": "addressEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAddress",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "region",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "telephone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "company",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fax",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAddressMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "781666aee6cecc9629f15e2a3700e6bd",
    "id": null,
    "metadata": {},
    "name": "AddAddressMutation",
    "operationKind": "mutation",
    "text": "mutation AddAddressMutation(\n  $input: AddAddressInput!\n) {\n  addAddress(input: $input) {\n    addressEdge {\n      __typename\n      cursor\n      node {\n        id\n        firstname\n        lastname\n        street\n        city\n        postcode\n        region\n        country {\n          name\n        }\n        telephone\n        company\n        fax\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e190055c9ac3800d085a566951d8ef4';

module.exports = node;
