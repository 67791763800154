import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'found';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { get } from 'lodash';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, List, message, Pagination, Popconfirm, Row } from 'antd';
import Shiitake from 'shiitake';
import { AddProductToCartMutation } from '~/components/cart/mutations';
import { ProductLabel } from '~/components/product/ProductLabel';
import { ProductId } from '~/components/product/ProductId';
import ViewMode, { getViewMode } from '~/components/product/ViewMode';
import GAhelper from '~/GAhelper';
import GA4 from '~/ga4';

import { RemoveProductFromWishlistMutation } from './mutations';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';
const PAGE_SIZE = 36;
const urlPrefix = '/product/';
const { listingName } = GAhelper;

const recordGA = (product) => {
  const category = listingName(window.location.pathname);

  GA4.selectItem(product, category);
}

const removeProduct = (productId, { viewer, relay }) => {
  RemoveProductFromWishlistMutation.commit({
    environment: relay.environment,
    variables: { input: { productId } },
    viewer,
  });
}

const moveToCart = (product, props) => {
  const { viewer, relay } = props;
  const input = {
    id: product.id,
    quantity: 1,
  };

  const variables = { input };

  AddProductToCartMutation.commit({
    environment: relay.environment,
    variables,
    viewer,
    parent: product,
    onCompleted: () => {
      ReactPixel.track(
        'AddToCart',
        {
          contents: [
            { id: product.sku, quantity: 1 }
          ],
          content_name: product.name,
          content_type: 'product',
          value: product.price || null,
          currency: process.env.CURRENCY,
        }
      );
      message.success('Added to Cart');
      removeProduct(product.id, props);
    },
  });
}

const AddToCartBtn = (props) => {
  const { product } = props;
  const btnStyle = { fontSize: 'small', margin: '5px 0 5px 0' };

  let onClick = () => {moveToCart(product, props)};
  if (!product.sellable || product.nonStock) {
    onClick = null;
  }

  let addBtn = (
    <Button type="primary" onClick={onClick} style={{ fontSize: 'small', margin: '5px 0 5px 0' }} block>
      Add To Cart
    </Button>
  );

  if (!product.sellable && product.type === 'configurable') {
    addBtn = (
      <Button
        type="primary"
        href={product.fullUrl}
        style={{ fontSize: 'small', margin: '5px 0 5px 0' }}
        block
      >
        View Product
      </Button>
    )
  } else if (!product.sellable) {
    if (product.callForPrice) {
    addBtn = (
      <a title="Call Now" href={`tel:${props.viewer.salesNumber}`}>
        <Button type="primary" style={btnStyle} block>Call For Price</Button>
      </a>
    )
    } else if (product.inStoreOnly) {
      addBtn = (
        <Button type="primary" style={btnStyle} block>In Store Only</Button>
      );
    } else {
      addBtn = (
        <Popconfirm
          title="This product is not available online anymore. Remove it from wishlist?"
          onConfirm={() => {removeProduct(product.id, props);}}
          okText="Yes"
          cancelText="No"
        >
          {addBtn}
        </Popconfirm>
      )
    }
  } else if (product.nonStock) {
    addBtn = (
      <Popconfirm
        overlayClassName="stock-popover"
        overlayStyle={{maxWidth: '500px'}}
        title="Non-stock items are not in stock but can be sourced typically within 24 hours."
        onConfirm={() => {moveToCart(product, props);}}
        okText="OK"
      >
        {addBtn}
      </Popconfirm>
    )
  }


  return addBtn;
}

AddToCartBtn.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    callForPrice: PropTypes.bool,
    sellable: PropTypes.bool,
    nonStock: PropTypes.bool,
    inStoreOnly: PropTypes.bool,
  }).isRequired,
}

const RemoveBtn = (props) => {
  const { product, isList } = props;

  return (
    <Popconfirm
      title="Remove it from wishlist?"
      onConfirm={() => {removeProduct(product.id, props);}}
      okText="Yes"
      cancelText="No"
    >
      <Button
        style={{ fontSize: 'small', borderStyle: 'none', ...isList ? { padding: '0 6px'} : null }}
        block={!isList}
        type={isList ? 'link' : null}
      >
        <DeleteOutlined /> {isList ? null : 'Remove'}
      </Button>
    </Popconfirm>
  )
}

RemoveBtn.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  isList: PropTypes.bool,
}

RemoveBtn.defaultProps = {
  isList: false,
}

const ProductImage = (props) => {
  const { product: p, style, url } = props;
  const image = get(p, 'mainImage', {});

  return (
    <div className="product-image" style={style}>
      <ProductLabel product={p} type="thumbnail" />
      <Link to={url} onClick={() => { recordGA(p); }} target="_blank" rel="noopener noreferrer">
        <div className="click-state">
          {image &&
            <img className="img-fluid" title={p.name} alt={p.name} src={image.thumbnail} />
          }
          {!image &&
            <img className="img-fluid" title={p.name} alt={p.name} src={IMAGE_PLACEHOLDER} />
          }
        </div>
      </Link>
    </div>
  )
}

ProductImage.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
}

const ProductPrice = ({ product }) => {
  if (product.type === 'configurable') {
    product = get(product, 'configurables.edges[0].node', {});
  }

  return (
    <div className="price" style={{ display: 'inline-block', fontSize: '16px', verticalAlign: 'text-top' }} >
      <div>{product.callForPrice ? 'Call For Price' : `$${product.price.toFixed(2)}`}</div>
    </div>
  )
}

ProductPrice.propTypes = {
  product: PropTypes.shape({
    type: PropTypes.string,
    price: PropTypes.number,
    callForPrice: PropTypes.bool,
  }).isRequired,
}

const ListView = (props) => {
  const { viewer } = props;

  const products = get(viewer, 'wishlist.products.edges', []);

  const getSlice = (lists, page) => {
    return lists.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }

  const [current, setCurrent] = useState(1);
  const [list, setList] = useState(products.slice(0, PAGE_SIZE));

  useEffect(() => {
    const l = getSlice(products, current);
    setList(l)
  }, [products]);


  const onChange = (page, pageSize, scroll = false) => {
    const l = getSlice(products, page);
    setList(l);
    setCurrent(page);

    if (scroll) {
      window.scrollTo(0, 0);
    }
  }

  return [
    (products.length > 0 ? (
      <Pagination
        key="topPagination"
        size="small"
        style={{ textAlign: 'right' }}
        onChange={(page, pageSize) => onChange(page, pageSize)}
        current={current}
        total={products.length}
        pageSize={PAGE_SIZE}
        showSizeChanger={false}
      />
    ) : null),
    <List
      key="wishlistList"
      className="wishlist-list"
      itemLayout="vertical"
      dataSource={list}
      renderItem={({node: p}) => {
        const url = `${urlPrefix}${p.urlSlug}`;

        return (
          <List.Item key={p.id}>
            <Row style={{ flexWrap: 'nowrap'}} >
              <Col xs={8} style={{ minWidth: '100px' }}>
                <ProductImage
                  product={p}
                  url={url}
                  style={{ position: 'relative', maxWidth: '200px' }}
                />
              </Col>
              <Col xs={16} style={{ paddingLeft: '10px' }}>
                <Row style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                  <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="product-info-list">
                      <h2 title={p.name}>
                        <Link className="black-href" to={url} onClick={() => { recordGA(p); }} target="_blank" rel="noopener noreferrer">
                          {p.name}
                        </Link>
                      </h2>
                      <div style={{fontSize: '12px'}}>
                        <ProductId sku={p.sku} model={p.model} secondModel={p.secondModel} />
                      </div>
                    </div>
                    <RemoveBtn {...props} product={p} isList />
                  </Col>

                  <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ProductPrice product={p} />
                    <div>
                      <AddToCartBtn {...props} product={p} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </List.Item>
        )
      }}
    />,
    (products.length > 0 ? (
      <Pagination
        key="bottomPagination"
        size="small"
        style={{ textAlign: 'right' }}
        onChange={(page, pageSize) => onChange(page, pageSize, true)}
        current={current}
        total={products.length}
        pageSize={PAGE_SIZE}
        showSizeChanger={false}
      />
    ) : null)
  ]
}

ListView.propTypes = {
  viewer: PropTypes.shape({
    salesNumber: PropTypes.string,
    wishlist: PropTypes.shape({
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
}

class Wishlist extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesNumber: PropTypes.string,
      wishlist: PropTypes.shape({
        products: PropTypes.shape({
          edges: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      view: getViewMode('wishlist'),
    }
  }

  handleViewMode = (view, cb = null) => {
    this.setState({ view }, () => {
      if (cb && typeof cb === 'function') {
        cb();
      }
    });
  }

  renderProducts(products) {
    return (
      <Row>
        {products.map((edge) => {
          const product = edge.node;
          return (
            <Col key={product.id} xs={12} sm={8} md={6} style={{ marginBottom: '5px', paddingLeft: '5px', paddingRight: '5px' }}>
              {this.renderProductCard(product)}
            </Col>
          )
        })}
      </Row>
    )
  }

  renderProductCard = (n) => {
    const url = `${urlPrefix}${n.urlSlug}`;

    return (
      <Card key={n.id} className="product-card" bodyStyle={{ padding: '5px' }}>
        <ProductImage
          product={n}
          url={url}
          style={{ position: 'relative' }}
        />
        <div className="product-info">
          <h2 title={n.name}>
            <Link className="black-href" to={url} onClick={() => { recordGA(n); }} target="_blank" rel="noopener noreferrer">
              <Shiitake lines={2}>{n.name}</Shiitake>
            </Link>
          </h2>
          <div style={{fontSize: '12px'}}>
            <ProductId sku={n.sku} model={n.model} secondModel={n.secondModel} />
          </div>

          <div className="clearfix">
            <ProductPrice product={n} />
            <div>
              <Row justify='center'>
                <Col span={24}>
                  <AddToCartBtn {...this.props} product={n} />
                </Col>
                <Col span={24}>
                  <RemoveBtn {...this.props} product={n} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    );
  }

  render() {
    const { viewer } = this.props;
    const { view } = this.state;
    const products = get(viewer, 'wishlist.products.edges', []);

    return (
      <div>
        <Helmet title="Wishlist" />
        <h1>Wishlist</h1>
        <ViewMode name="wishlist" view={view} handleClick={this.handleViewMode} />
        {!products.length && (
          <Empty description="You wishlist is empty." image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {!!products.length && (view === 'list') ? (
          <ListView {...this.props} />
        ) : (
          this.renderProducts(products)
        )}
      </div>
    );
  }
}

export default createFragmentContainer(Wishlist, {
  viewer: graphql`
    fragment Wishlist_viewer on Customer {
      salesNumber
      wishlist {
        id
        products(first: 999) @connection(key: "Wishlist_products") {
          edges {
            node {
              id
              name
              price
              sku
              brand {
                id
                name
              }
              mainImage {
                id
                url
                thumbnail
              }
              type
              urlSlug
              fullUrl
              sellable
              nonStock
              freeShipping
              inStoreOnly
              preorderDate
              backInStockDate
              model
              clearance
              callForPrice
              configurables(first: 99){
                edges {
                  node {
                    id
                    name
                    price
                  }
                }
              }
              labels(first: 999) {
                edges {
                  node {
                    id
                    imageUrl
                    placement
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
