import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/*
 * This is just a slimed version of
 * https://github.com/captivationsoftware/react-sticky/blob/master/src/Sticky.js
 * The only difference is this component only required `isSticky` prop
 * to determine a background for its children.
 */
export default class Sticky extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    topOffset: PropTypes.number,
    bottomOffset: PropTypes.number,
    relative: PropTypes.bool,
    banner: PropTypes.shape({
      node: PropTypes.shape({
        bg2: PropTypes.string,
      }),
    }),
    disableCompensation: PropTypes.bool,
  };

  static contextTypes = {
    subscribe: PropTypes.func,
    unsubscribe: PropTypes.func,
    getParent: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    banner: null,
    relative: false,
    topOffset: 0,
    bottomOffset: 0,
    disableCompensation: false,
  };

 state = {
    isSticky: false,
  };

  componentWillMount() {
    if (!this.context.subscribe) {
      throw new TypeError('Expected Sticky to be mounted within StickyContainer');
    }

    this.context.subscribe(this.handleContainerEvent);
  }

  componentDidUpdate() {
    this.placeholder.style.paddingBottom = this.props.disableCompensation
      ? 0
      : `${this.state.isSticky ? this.state.calculatedHeight : 0}px`;
  }

  componentWillUnmount() {
    this.context.unsubscribe(this.handleContainerEvent);
  }

  handleContainerEvent = ({
    distanceFromTop,
    distanceFromBottom,
    eventSource,
  }) => {
    const parent = this.context.getParent();

    let preventingStickyStateChanges = false;
    if (this.props.relative) {
      preventingStickyStateChanges = eventSource !== parent;
      distanceFromTop =
        -(eventSource.scrollTop + eventSource.offsetTop) +
        this.placeholder.offsetTop;
    }

    const contentClientRect = { height: 0 };
    const calculatedHeight = contentClientRect.height;

    const wasSticky = !!this.state.isSticky;
    const isSticky = preventingStickyStateChanges
      ? wasSticky
      : distanceFromTop <= -this.props.topOffset &&
        distanceFromBottom > -this.props.bottomOffset;

    distanceFromBottom =
      (this.props.relative
        ? parent.scrollHeight - parent.scrollTop
        : distanceFromBottom) - calculatedHeight;

    this.setState({
      isSticky,
      calculatedHeight,
    });
  };

  render() {
    const { isSticky } = this.state;
    const { banner } = this.props;
    const fixed = isSticky ? 'fixed' : '';

    const style = {
      background: `url(${get(banner, 'node.bg2', '')}) no-repeat center top ${fixed}`,
      transition: 'background 0.5s ease',
    };

    return (
      <div>
        <div ref={(placeholder) => { this.placeholder = placeholder; }} />
        <div className="sticky-background" style={style} >
          {this.props.children}
        </div>
      </div>
    );
  }
}
