export default class GAhelper {
  static listingName = (pathname, listMode = 'Product Listing') => {
    let list = null;
    if (pathname.includes("/search")) {
      list = "Product Search Listing";
    } else if (pathname.includes("/wishlist")) {
      list = "Wishlist";
    } else if (pathname === "/") {
      list = "Home Page"
    } else {
      list = listMode === 'suggestions' ? 'Suggestions' : listMode;
    }

    return list;
  }
}
