import React from 'react';
import PropTypes from 'prop-types';
import Shiitake from 'shiitake';
import { Link } from 'found';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Collapse, Row } from 'antd';

import { getUrl } from './helper';
import { renderAddToCart, recordGA, renderPrice } from './List';

const { Panel } = Collapse;

class Related extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string,
      related: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  render() {
    const { product: { related }, relay, viewer } = this.props;

    if (!related || !related.edges.length) {
      return null;
    }

    return (
      <Collapse
        bordered={false}
        defaultActiveKey={["optional_accessories"]}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        style={{marginBottom: '20px', border: 'none', maxHeight: '245px', overflow: 'auto'}}
      >
        <Panel header={<div style={{fontWeight: '700', textAlign: 'center'}}>Optional Accessories</div>} key="optional_accessories">
          {related.edges.map(({node: p}, index) => (
            <div key={p.id}>
              <Row>
                <Col xs={4} style={{textAlign: 'center'}}>
                  <img src={p.mainImage.thumbnail} width="50" alt={p.name} />
                </Col>
                <Col xs={20}>
                  <Link className="black-href" title={p.name} to={getUrl(p.urlSlug)} sku={p.sku} onClick={() => recordGA(p, index, { listMode: 'optional' })}>
                    <Shiitake lines={2} tagName="span">
                      {p.name}
                    </Shiitake>
                  </Link>

                  <div style={{float: 'left'}}>
                    {renderPrice(p, {showNormally: false})}
                  </div>

                  <div style={{float: 'right'}}>
                    {renderAddToCart(p, 'optional', {relay, viewer})}
                  </div>

                  <div className="clearfix" />
                </Col>
              </Row>
              <hr />
            </div>
        ))}
        </Panel>
      </Collapse>
    );
  }
}

export default createFragmentContainer(Related, {
  viewer: graphql`
    fragment Related_viewer on Customer {
      id
    }
  `,
  product: graphql`
    fragment Related_product on Product {
      related(first: 99) {
        edges {
          node {
            id
            brand {
              id
              name
            }
            name
            type
            nonStock
            sku
            price
            urlSlug
            callForPrice
            mainImage {
              id
              thumbnail
            }
            configurables(first: 99) {
              edges {
                node {
                  id
                  name
                  type
                  nonStock
                  sku
                  price
                  urlSlug
                  callForPrice
                  mainImage {
                    id
                    thumbnail
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
