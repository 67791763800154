import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { getTnCPath } from '~/components/cms/routes';
import Helmet from '~/components/page/Helmet';
import { camelCase, get } from 'lodash';
import { Alert, Table } from 'antd';
import { Link } from 'found';
import moment from 'moment';
import { getStatus, STATUS_STYLES } from './order/Status';
import OrderStatusButton from './OrderStatusButton';

const showDelay = (order) => {
  const now = moment();

  return ([null, 1].includes(order.verified) &&
          now.diff(moment(order.insertedAt), 'days') <= 30 &&
          now.diff(moment(order.insertedAt), 'days') >= 3 &&
          ['Partially Shipped', 'Shipped', 'Cancelled', 'Click & Collect'].includes(order.shippingStatus) === false);
}

export const getDelayMsg = (order) => {

  const shouldShow = showDelay(order);

  if (shouldShow) {
    return (
      <Alert
        message={
          <p>
            <span style={{color: '#cb0000', textDecoration: 'underline', fontWeight: 'bold'}}>Your order is still on its way</span>
            <br />
            We are experiencing some slight delays and hope to be back on track soon.
            <br />
            Please feel free to contact us at any time for an update.
            <br />
            We apologise for any inconvenience caused by the delay and appreciate your patience.
          </p>
        }
        type="warning"
      />
    );
  }
    return null;

}

class OrderHistory extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      })
    }).isRequired,
  }

  renderNewTable = (orders) => {
    const columns = [
      {
        title: 'Order #',
        dataIndex: 'order',
        key: 'order',
        width: '30%',
        render: (order) => {
          const { pathname } = this.props.match.location;
          const url = `${pathname}/${order.name}`;

          return (
            <div>
              <Link to={url}>
                {order.name}
              </Link>
              <OrderStatusButton order={order} />
            </div>
          )
        },
      },
      {
        title: 'Order Date',
        dataIndex: 'orderDate',
        key: 'orderDate',
        render: date => <span style={{fontSize: '14px'}}>{date}</span>
      },
      {
        title: 'Grand Total',
        dataIndex: 'grandTotal',
        key: 'grandTotal',
        render: price => <span className="price" style={{fontSize: '14px'}}>${(price).toFixed(2)}</span>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, {order}) => {
          const verify = {
            0: "Verification Needed",
            2: "Verification Voided",
            3: "Verification Failed",
          };

          if (order.status === 0 && verify[status]) {
            return (
              <span style={{color: '#cb0000'}}>{verify[status]}</span>
            )
          }

          return (
            <span style={STATUS_STYLES[camelCase(order.shippingStatus)]}>
              {getStatus(order)}
            </span>
          )
        }
      }
    ];

    const data = orders.map((edge) => {
      const order = edge.node;
      const { id, grandTotal } = order;
      const orderDate = moment(order.insertedAt).format('DD/MM/YYYY');
      const status = order.verified;

      return (
        {
          key: id,
          order,
          orderDate,
          grandTotal,
          status
        }
      )
    });

    return (
      <div>
        <Table columns={columns} dataSource={data} scroll={{ x: true }} locale={{emptyText: "No Orders Found"}} />
      </div>
    )
  }

  render() {
    const { viewer } = this.props;
    const orders = get(viewer, 'salesOrders.edges', []);

    const delayed = orders.find(({node}) => showDelay(node));

    return (
      <div>
        <Helmet title="Orders" />
        <h1 className="myOrders">Orders</h1>

        <p style={{ padding: '10px 10px 10px 10px'}}>
          Please find below all of your orders.
          For Returns & Refunds or Warranty Claims please <Link to={getTnCPath("returns-refunds-repairs")}>click here</Link>.
        </p>

        {delayed && getDelayMsg(delayed.node)}

        {this.renderNewTable(orders)}
      </div>
    );
  }
}

export default createFragmentContainer(OrderHistory, {
  viewer: graphql`
    fragment OrderHistory_viewer on Customer {
      email
      salesOrders(first: 9999) {
        edges {
          node {
            id
            name
            verified
            verificationLink
            grandTotal
            insertedAt
            shippingStatus
            status
          }
        }
      }
    }
  `,
});
