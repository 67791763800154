import { graphql } from 'react-relay';
import { buildMutation } from '~/mutation';

const mutation = graphql`
  mutation RemoveProductFromWishlistMutation($input: RemoveProductFromWishlistInput!) {
    removeProductFromWishlist(input:$input) {
      wishlist{
        id
        products(first: 999) @connection(key: "Wishlist_products") {
          edges {
            node {
              id
              name
              price
              sku
              images {
                thumbnail
              }
            }
          }
        }
      }
      product {
        id
        inWishlist
      }
    }
  }
`;

const commit = buildMutation(mutation);

export default { commit };
