/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetMyStoreInput = {|
  storeId: string
|};
export type SetMyStoreMutationVariables = {|
  input: SetMyStoreInput
|};
export type SetMyStoreMutationResponse = {|
  +setMyStore: ?{|
    +viewer: ?{|
      +myStore: ?{|
        +id: string
      |}
    |}
  |}
|};
export type SetMyStoreMutation = {|
  variables: SetMyStoreMutationVariables,
  response: SetMyStoreMutationResponse,
|};
*/


/*
mutation SetMyStoreMutation(
  $input: SetMyStoreInput!
) {
  setMyStore(input: $input) {
    viewer {
      myStore {
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Store",
  "kind": "LinkedField",
  "name": "myStore",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetMyStoreMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetMyStorePayload",
        "kind": "LinkedField",
        "name": "setMyStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetMyStoreMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetMyStorePayload",
        "kind": "LinkedField",
        "name": "setMyStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4cce695d5e6dad0c22730f7b8633e70",
    "id": null,
    "metadata": {},
    "name": "SetMyStoreMutation",
    "operationKind": "mutation",
    "text": "mutation SetMyStoreMutation(\n  $input: SetMyStoreInput!\n) {\n  setMyStore(input: $input) {\n    viewer {\n      myStore {\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5eec595779125b9be5f03d32c32b429';

module.exports = node;
