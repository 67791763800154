/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Contact_viewer$ref = any;
export type routes_Contact_QueryVariables = {||};
export type routes_Contact_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Contact_viewer$ref
  |}
|};
export type routes_Contact_Query = {|
  variables: routes_Contact_QueryVariables,
  response: routes_Contact_QueryResponse,
|};
*/


/*
query routes_Contact_Query {
  viewer {
    ...Contact_viewer
    id
  }
}

fragment Contact_viewer on Customer {
  id
  faqs(first: 99) {
    edges {
      node {
        id
        question
        answer
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Contact_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Contact_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Contact_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 99
              }
            ],
            "concreteType": "FaqConnection",
            "kind": "LinkedField",
            "name": "faqs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FaqEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Faq",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "question",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answer",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "faqs(first:99)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28f3a86169cd590c463c2bd05c7d6433",
    "id": null,
    "metadata": {},
    "name": "routes_Contact_Query",
    "operationKind": "query",
    "text": "query routes_Contact_Query {\n  viewer {\n    ...Contact_viewer\n    id\n  }\n}\n\nfragment Contact_viewer on Customer {\n  id\n  faqs(first: 99) {\n    edges {\n      node {\n        id\n        question\n        answer\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a568f2a78fa7f890106c973b9e2d116a';

module.exports = node;
