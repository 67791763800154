/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAccountInput = {|
  currentPassword?: ?string,
  email: string,
  firstname: string,
  lastname: string,
  newPassword?: ?string,
  subscriptionStatus: boolean,
|};
export type UpdateAccountMutationVariables = {|
  input: UpdateAccountInput
|};
export type UpdateAccountMutationResponse = {|
  +updateAccount: ?{|
    +customer: ?{|
      +firstname: ?string,
      +lastname: ?string,
      +email: ?string,
      +isPasswordSecure: ?boolean,
      +subscription: ?{|
        +status: ?number
      |},
    |}
  |}
|};
export type UpdateAccountMutation = {|
  variables: UpdateAccountMutationVariables,
  response: UpdateAccountMutationResponse,
|};
*/


/*
mutation UpdateAccountMutation(
  $input: UpdateAccountInput!
) {
  updateAccount(input: $input) {
    customer {
      firstname
      lastname
      email
      isPasswordSecure
      subscription {
        status
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPasswordSecure",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAccountPayload",
        "kind": "LinkedField",
        "name": "updateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAccountPayload",
        "kind": "LinkedField",
        "name": "updateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "706c3d6bd5da56cca179bf77d88ec8d1",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountMutation(\n  $input: UpdateAccountInput!\n) {\n  updateAccount(input: $input) {\n    customer {\n      firstname\n      lastname\n      email\n      isPasswordSecure\n      subscription {\n        status\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eab323e4b7624a2d715b13f5a0fd3a94';

module.exports = node;
