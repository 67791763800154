import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { importScript } from '~/helper';

// For facebook oauth account, ask chung
const getClientId = () => {
  const { COUNTRY } = process.env;

  if (COUNTRY === "AU") {
    return '1373453456173774';
  } else if (COUNTRY === "NZ") {
    return '192844673539073';
  }
  throw new Error(`recognised tld ${COUNTRY}`);
};

export default class FacebookSignin extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    style: PropTypes.shape({
      width: PropTypes.string,
      backgroundColor: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
  }

  static defaultProps = {
    children: null,
    onSuccess: null,
    onFailure: null,
  }

  constructor(props) {
    super(props);

    const fbUrl = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v6.0&appId=${getClientId()}&cookie=1`;
    importScript(fbUrl);
  }

  onFacebookLogin = (onSuccess, onFailure) => {
    if (window.FB) {
      window.FB.login((response) => {
        if (response.authResponse) {
          const { grantedScopes, accessToken } = response.authResponse;
          if (grantedScopes.includes("email")) {
            onSuccess(accessToken, "facebook");
          } else {
            onFailure("You have not authorised your facebook account to log in.");
          }
        }
      }, {scope: 'public_profile,email', return_scopes: true, auth_type: 'rerequest'});
    }
  }

  render() {
    const { style, onSuccess, onFailure } = this.props;

    return(
      <Button
        style={style}
        size="large"
        onClick={() => {
          this.onFacebookLogin(onSuccess, onFailure);
        }}
      >
        {this.props.children}
      </Button>
    )
  }
}
