import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, message, Upload } from 'antd';
import 'antd/lib/date-picker/style';
import 'antd/lib/upload/style';
import { fileValidator, formItemLayout } from '~/components/form';

import { PostAusPostDamagedFormMutation } from './mutations';

const { Item: FormItem } = Form;

class AusPostDamagedForm extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      loading: false,
    }
  }

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const uploadables = {};
    if (values.attachments) {
      const fileList = values.attachments.map(f => f.originFileObj);

      values.attachments = fileList.map((f, i) => {
        const name = `attachment${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    values.receivedDate = values.receivedDate.format('LL');
    values.purchaseDate = values.purchaseDate.format('LL');

    PostAusPostDamagedFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      uploadables,
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success("Thank you very much, we have received your claim.");
        this.formRef.current.resetFields();
        this.setState({ loading: false });
      },
      onError: () => {
        this.setState({ loading: false });
      },
    });
  }

  render() {
    return (
      <div>
        <Helmet title="AusPost Damaged Form" />
        <h1>AusPost Damaged Form</h1>

        <Form ref={this.formRef} onFinish={this.handleSubmit}>

          <p><b>Max. size</b> of the attachments is <b>20MB</b>.</p>

          <FormItem
            {...formItemLayout}
            label="Invoice Number"
            name="invoiceNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Invoice Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Received Date"
            name="receivedDate"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Purchase Date"
            name="purchaseDate"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Tracking Number"
            name="trackingNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Tracking Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Case Number"
            name="caseNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Case Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Cost Of Goods"
            name="costOfGoods"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Cost Of Goods" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Attachments"
            name="attachments"
            rules={[
              { required: true, message: 'Required' },
              { required: true, message: 'File cannot be over 20MB', validator: fileValidator.bind(this, 20)}
            ]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              multiple
              accept="image/*"
              listType="picture"
              customRequest={() => {}}
              beforeUpload={() => false}
            >
              <Button>
                <UploadOutlined /> Click to Upload
              </Button>
            </Upload>
          </FormItem>

          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Submit
          </Button>

        </Form>
      </div>
    );
  }
}

export default createFragmentContainer(AusPostDamagedForm, {
  viewer: graphql`
    fragment AusPostDamagedForm_viewer on Customer {
      id
    }
  `,
});
