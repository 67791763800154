/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RegisterCustomerInput = {|
  email: string,
  firstname: string,
  lastname: string,
  password: string,
|};
export type RegisterCustomerMutationVariables = {|
  input: RegisterCustomerInput
|};
export type RegisterCustomerMutationResponse = {|
  +registerCustomer: ?{|
    +token: ?string
  |}
|};
export type RegisterCustomerMutation = {|
  variables: RegisterCustomerMutationVariables,
  response: RegisterCustomerMutationResponse,
|};
*/


/*
mutation RegisterCustomerMutation(
  $input: RegisterCustomerInput!
) {
  registerCustomer(input: $input) {
    token
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RegisterCustomerPayload",
    "kind": "LinkedField",
    "name": "registerCustomer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterCustomerMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterCustomerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a334a0fe937cfd06bc87eb6a448ec3e6",
    "id": null,
    "metadata": {},
    "name": "RegisterCustomerMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterCustomerMutation(\n  $input: RegisterCustomerInput!\n) {\n  registerCustomer(input: $input) {\n    token\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '153ec4477fe7025d8cf30a9c2fe18d32';

module.exports = node;
