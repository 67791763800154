/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FreebiePopup_cart$ref = any;
type FreebiePopup_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ItemList_viewer$ref: FragmentReference;
declare export opaque type ItemList_viewer$fragmentType: ItemList_viewer$ref;
export type ItemList_viewer = {|
  +configs: ?any,
  +cart: ?{|
    +id: string,
    +subtotal: ?number,
    +grandTotal: ?number,
    +loyaltyPoints: ?{|
      +accruing: ?number
    |},
    +storeCredits: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +stealthMode: ?boolean,
          +creditAmount: ?number,
        |}
      |}>
    |},
    +isOptFreebiesInCart: ?boolean,
    +lines: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +isFreebie: ?boolean,
          +unitPrice: ?number,
          +quantity: ?number,
          +rowTotal: ?number,
          +unitDiscount: ?number,
          +unitSurcharge: ?number,
          +configParent: ?{|
            +id: string,
            +urlSlug: ?string,
            +mainImage: ?{|
              +id: string,
              +thumbnail: ?string,
            |},
          |},
          +priorityShipping: ?{|
            +getByDate: ?string
          |},
          +product: ?{|
            +id: string,
            +sku: ?string,
            +name: ?string,
            +urlSlug: ?string,
            +nonStock: ?boolean,
            +onlineOnly: ?boolean,
            +preorderDate: ?string,
            +backInStockDate: ?string,
            +stockAvailable: ?number,
            +clearance: ?boolean,
            +limitedStock: ?boolean,
            +priorityShipping: ?{|
              +getByDate: ?string
            |},
            +brand: ?{|
              +id: string,
              +name: ?string,
            |},
            +mainImage: ?{|
              +id: string,
              +thumbnail: ?string,
            |},
          |},
        |}
      |}>
    |},
    +freebies: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +mode: ?number,
          +products: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +urlSlug: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                  +thumbnail: ?string,
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +stores: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +stocks: ?$ReadOnlyArray<?{|
            +productId: ?string,
            +stock: ?string,
          |}>,
        |}
      |}>
    |},
    +$fragmentRefs: FreebiePopup_cart$ref,
  |},
  +$fragmentRefs: FreebiePopup_viewer$ref,
  +$refType: ItemList_viewer$ref,
|};
export type ItemList_viewer$data = ItemList_viewer;
export type ItemList_viewer$key = {
  +$data?: ItemList_viewer$data,
  +$fragmentRefs: ItemList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "mainImage",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PriorityShipping",
  "kind": "LinkedField",
  "name": "priorityShipping",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "getByDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemList_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grandTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LoyaltyPoints",
          "kind": "LinkedField",
          "name": "loyaltyPoints",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accruing",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "storeCredits",
          "args": null,
          "concreteType": "StoreCreditConnection",
          "kind": "LinkedField",
          "name": "__CartView_storeCredits_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreCreditEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreCredit",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stealthMode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "creditAmount",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOptFreebiesInCart",
          "storageKey": null
        },
        {
          "alias": "lines",
          "args": null,
          "concreteType": "QuoteLineConnection",
          "kind": "LinkedField",
          "name": "__CartView_lines_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuoteLineEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuoteLine",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isFreebie",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rowTotal",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitDiscount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitSurcharge",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Product",
                      "kind": "LinkedField",
                      "name": "configParent",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v5/*: any*/),
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Product",
                      "kind": "LinkedField",
                      "name": "product",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sku",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonStock",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "onlineOnly",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "preorderDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "backInStockDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "stockAvailable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "clearance",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "limitedStock",
                          "storageKey": null
                        },
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Brand",
                          "kind": "LinkedField",
                          "name": "brand",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v9/*: any*/),
          "concreteType": "FreebieTierConnection",
          "kind": "LinkedField",
          "name": "freebies",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FreebieTierEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FreebieTier",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mode",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": (v9/*: any*/),
                      "concreteType": "ProductConnection",
                      "kind": "LinkedField",
                      "name": "products",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                (v5/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProductImage",
                                  "kind": "LinkedField",
                                  "name": "mainImage",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "url",
                                      "storageKey": null
                                    },
                                    (v6/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "products(first:99)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "freebies(first:99)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 999
            }
          ],
          "concreteType": "StoreConnection",
          "kind": "LinkedField",
          "name": "stores",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Store",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StockLevel",
                      "kind": "LinkedField",
                      "name": "stocks",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "stock",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "stores(first:999)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FreebiePopup_cart"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreebiePopup_viewer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '47b951a31a34c599d536678601e31616';

module.exports = node;
