import React from 'react';
import PropTypes from 'prop-types';
import { get, round } from 'lodash';
import { Button, Checkbox, Col, InputNumber, Row } from 'antd';

import FilterLabel from './filter/FilterLabel';
import './style.css';

export const parseRange = (str) => {
  if (typeof str !== 'string') {
    return [null, null];
  }

  const range = str.split('-');

  return range.length === 2 ? range.map(s => round(s, 2) || null) : [null, null]
};

export const getLabel = (to, from) => {
  if (to && from) {
    return `$${from} to $${to}`;
  } else if (to) {
    return `Up to $${to}`;
  } else if (from) {
    return `$${from} & above`;
  }

  return "";
};

export const isPriceFilterApplied = (props) => {
  const activeFilters = get(props, 'activeFilters', {});

  return !!(activeFilters.price_range || activeFilters.price_min || activeFilters.price_max);
}

/*
 * Need to support the old query price_min and price_max.
 */
export default class PriceFilter extends React.Component {
  static propTypes = {
    activeFilters: PropTypes.shape({}).isRequired,
    filter: PropTypes.shape({}).isRequired,
    updatePriceRange: PropTypes.func.isRequired,
    style: PropTypes.shape({}),
    handleVisibleChange: PropTypes.func,
  }

  static defaultProps = {
    style: {},
    handleVisibleChange: () => {},
  }

  constructor(props) {
    super(props);

    this.contextRef = React.createRef();

    this.state = {
      filterRange: this.getFilter(props),
    };
  }

  getPriceRange = (props) => {
    const { filter } = props;
    const max = get(filter, 'max', 0);
    const min = get(filter, 'min', 0);

    return [Math.floor(min), Math.ceil(max)];
  }

  getFilter = (props) => {
    const { activeFilters, filters } = props;
    let max = get(activeFilters, 'price_max', null);
    let min = get(activeFilters, 'price_min', null);

    max = max ? round(max, 2) || null : max;
    min = min ? round(min, 2) || null : min;

    const priceRange = get(activeFilters, 'price_range', []);
    if (priceRange.length === 1 && this.isCheckboxKey(filters, priceRange)) {
      [min, max] = parseRange(priceRange[0]);
    }

    return [min, max];
  }

  getRangeOptions = ({ filter }) => {
    const ranges = get(filter, 'buckets', []);

    return ranges.map(({ key, to, from, docCount }) => {
      const label = getLabel(to, from);

      return  {
        value: key,
        label: <FilterLabel label={label} docCount={docCount} />
      }
    });
  }

  isCheckboxKey = (filters, priceRange) => {
    return !get(filters, 'buckets', []).find(({ key }) => {
      return key === priceRange[0];
    });
  }

  checkboxOnChange = (priceRange) => {
    if (Array.isArray(priceRange) && priceRange.length > 0) {
      this.updatePriceRange({ price_range: priceRange });
    } else {
      this.updatePriceRange({ price_range: null });
    }
  }

  updatePriceRange = (filter) => {
    this.props.updatePriceRange({
      ...filter,
      price_min: null,
      price_max: null,
    });
  }

  inputOnChange = (name, value) => {
    const { filterRange } = this.state;

    if (name === "min") {
      filterRange[0] = value;
    } else if (name === "max") {
      filterRange[1] = value;
    }

    this.setState({ filterRange });
  }

  handleOnClick = () => {
    const { handleVisibleChange } = this.props;
    const { filterRange } = this.state;
    const selectedRange = get(this.props, 'activeFilters.price_range', []);

    if (filterRange[0] && filterRange[1] && filterRange[0] > filterRange[1]) {
      filterRange.reverse();
    }

    if (filterRange[0] || filterRange[1]) {
      const range = filterRange.map(v => v || '*').join("-");
      this.updatePriceRange({ price_range: [range] });
    } else if (filterRange[0] === null && filterRange[1] === null && selectedRange.length === 0) {
      this.updatePriceRange({ price_range: null });
    }

    handleVisibleChange(false);
  }

  renderInputNumber = (value, type) => {
    const priceRange = this.getPriceRange(this.props);
    const max = priceRange[1];

    return (
      <InputNumber
        min={0}
        max={max}
        style={{ width: '100px', marginRight: '10px', flex: '1' }}
        value={value}
        placeholder={type === "min" ? "Min" : "Max"}
        prefix="$"
        onChange={(v) => { this.inputOnChange(type, v); }}
      />
    )
  }

  render() {
    const rangeOptions = this.getRangeOptions(this.props);
    const selectedRange = get(this.props, 'activeFilters.price_range', []);

    const { filterRange } = this.state;

    return (
      <div id="price-slider-container" ref={this.contextRef}>
        <Row type="flex" justify="space-between" style={{ minWidth: '260px' }}>
          <Col span={24}>
            <Checkbox.Group
              className="price-filter-checkbox"
              options={rangeOptions}
              value={selectedRange}
              onChange={this.checkboxOnChange}
            />
          </Col>
          <Col span={24} style={{ marginTop: '5px', display: 'inline-flex', flexDirection: 'row' }}>
            <div style={{ width: '100%', display: 'inline-flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
              {this.renderInputNumber(filterRange[0], "min")}
              {this.renderInputNumber(filterRange[1], "max")}
            </div>
            <Button
              type="link"
              size="small"
              style={{ verticalAlign: 'middle', fontSize: '14px' }}
              onClick={this.handleOnClick}
            >
              Go
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
