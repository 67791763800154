import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';

import { CompleteRedirectOrderMutation } from '../mutations';
import { onCheckoutCompleted } from '../helper';

export const DisplayHummLogo = () => {
  const [visible, setVisible] = useState(false);

  const clicker = () => {
    setVisible(true);
  }

  const cancel = () => {
    setVisible(false);
  }

  return (
    <div
      style={{
        fontSize: '12px',
        display: 'inline-block',
        paddingTop: '5px',
        paddingBottom: '5px',
        cursor: 'pointer',
      }}
    >
      <div
        onClick={clicker}
        onKeyPress={clicker}
        tabIndex="0"
        role="button"
      >
        <img
          src="/static/images/payments/humm.svg"
          height="30"
          alt="Humm"
          title="Humm"
        />
      </div>

      <Modal
        visible={visible}
        onCancel={cancel}
        footer={null}
      >
        <iframe
          title="Humm"
          src="https://widgets.shophumm.com.au/content/html/priceInfoV2.html"
          style={{width: '100%', border: 'none', height: '700px'}}
        />
      </Modal>

    </div>
  );
}

export default class Humm extends React.Component {
  static redirect = function redirect(loc) {
    const keys =[
      'x_account_id',
      'x_reference',
      'x_currency',
      'x_test',
      'x_amount',
      'x_timestamp',
      'x_result',
      'x_gateway_reference',
      'x_purchase_number',
      'x_signature',
    ];
    if (keys.every(k => k in loc.query)) {
      if (loc.query.x_result === 'completed') {
        const values = {
          hummQuery: loc.search.replace('?', ''),
          paymentMethod: 'humm',
        };

        const onCompleted = onCheckoutCompleted.bind(this);

        CompleteRedirectOrderMutation.commit({
          // eslint-disable-next-line react/prop-types
          environment: this.props.relay.environment,
          variables: { input: values },
          // eslint-disable-next-line react/prop-types
          viewer: this.props.viewer,
          onCompleted,
          onError: (errors) => {
            message.destroy(); // dimiss loading message
            message.error(errors[0].message, 10);
            // eslint-disable-next-line react/no-unused-state
            this.setState({ loading: false });
          },
        });
      } else if (loc.query.x_result === 'failed') {
        message.error('Unsuccessful Payment');
      }
      else {
        message.error('Invalid payment info');
      }
    }
  }

  static init = (form, submit) => {

    const fields = form.getFieldsValue();

    /*
     * When re-setting a password is present, the above guard is not enough to prevent order placing
     * as `checkoutPassword` field is removed.
     */
    if (fields.billing && Object.keys(fields.billing).length === 1 && fields.billing.email) {
      message.error('Please login to place your order')
      return;
    }

    // Form wasn't scrolling when theres a missing field
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        submit();
      }
    })
  }

  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('hummQuery')(<div />)}
      </div>
    );
  }
}
