/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CmsBanner_viewer$ref = any;
type List_viewer$ref = any;
type PriorityPage_viewer$ref = any;
type ProductCard_product$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Catalogue_viewer$ref: FragmentReference;
declare export opaque type Catalogue_viewer$fragmentType: Catalogue_viewer$ref;
export type Catalogue_viewer = {|
  +configs: ?any,
  +categories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +faqs: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +question: ?string,
              +answer: ?string,
            |}
          |}>
        |},
        +id: string,
        +name: ?string,
        +urlSlug: ?string,
        +description: ?string,
        +description2: ?string,
        +metaTitle: ?string,
        +metaDescription: ?string,
        +previewDescription: ?string,
        +jsonLd: ?string,
        +featuredBrandsNew: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +brand: ?{|
                +id: string,
                +name: ?string,
                +imageUrl: ?string,
                +urlSlug: ?string,
              |},
              +subcategory: ?{|
                +id: string,
                +name: ?string,
              |},
            |}
          |}>
        |},
        +catalogues: ?{|
          +totalCount: ?number,
          +subcategory: ?{|
            +urlSlug: ?string,
            +name: ?string,
            +canonicalUrl: ?string,
            +metaTitle: ?string,
            +metaDescription: ?string,
            +description: ?string,
            +description2: ?string,
            +faqs: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +question: ?string,
                  +answer: ?string,
                |}
              |}>
            |},
          |},
          +brand: ?{|
            +urlSlug: ?string,
            +metaTitle: ?string,
            +description: ?string,
            +description2: ?string,
            +name: ?string,
          |},
          +facets: ?$ReadOnlyArray<?{|
            +key?: string,
            +name?: string,
            +position?: number,
            +buckets?: $ReadOnlyArray<?{|
              +key: string,
              +docCount: number,
              +imageUrl: ?string,
              +to?: ?number,
              +from?: ?number,
            |}>,
            +max?: number,
            +min?: number,
          |}>,
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id?: string,
              +imageUrl?: ?string,
              +imageMode?: ?number,
              +link?: ?string,
              +displayMode?: ?string,
              +name?: ?string,
              +position?: ?number,
              +urlSlug?: ?string,
              +docCount?: ?number,
              +metaTitle?: ?string,
              +metaDescription?: ?string,
              +adjacentBanners?: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                    +placement: ?string,
                    +imageUrl: ?string,
                    +link: ?string,
                  |}
                |}>
              |},
              +$fragmentRefs: ProductCard_product$ref,
            |}
          |}>,
        |},
      |}
    |}>
  |},
  +$fragmentRefs: List_viewer$ref & CmsBanner_viewer$ref & PriorityPage_viewer$ref,
  +$refType: Catalogue_viewer$ref,
|};
export type Catalogue_viewer$data = Catalogue_viewer;
export type Catalogue_viewer$key = {
  +$data?: Catalogue_viewer$data,
  +$fragmentRefs: Catalogue_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": (v0/*: any*/),
  "concreteType": "FaqConnection",
  "kind": "LinkedField",
  "name": "faqs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FaqEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Faq",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "question",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "faqs(first:99)"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlSlug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description2",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaTitle",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metaDescription",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docCount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "brandUrlSlug"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoryUrlSlug"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "page"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "priorityPage"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "subcategoryUrlSlug"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "subcategoryUrlSlug2"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": null
      }
    ]
  },
  "name": "Catalogue_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "urlSlug",
          "variableName": "categoryUrlSlug"
        }
      ],
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "previewDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "jsonLd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 999
                    }
                  ],
                  "concreteType": "FeaturedBrandConnection",
                  "kind": "LinkedField",
                  "name": "featuredBrandsNew",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FeaturedBrandEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FeaturedBrand",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Brand",
                              "kind": "LinkedField",
                              "name": "brand",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v3/*: any*/),
                                (v9/*: any*/),
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Subcategory",
                              "kind": "LinkedField",
                              "name": "subcategory",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v3/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "featuredBrandsNew(first:999)"
                },
                {
                  "alias": "catalogues",
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "brandUrlSlug",
                      "variableName": "brandUrlSlug"
                    },
                    {
                      "kind": "Variable",
                      "name": "filters",
                      "variableName": "filters"
                    },
                    {
                      "kind": "Variable",
                      "name": "orderBy",
                      "variableName": "orderBy"
                    },
                    {
                      "kind": "Variable",
                      "name": "page",
                      "variableName": "page"
                    },
                    {
                      "kind": "Variable",
                      "name": "subcategoryUrlSlug",
                      "variableName": "subcategoryUrlSlug"
                    },
                    {
                      "kind": "Variable",
                      "name": "subcategoryUrlSlug2",
                      "variableName": "subcategoryUrlSlug2"
                    }
                  ],
                  "concreteType": "CatalogueConnection",
                  "kind": "LinkedField",
                  "name": "__Catalogue_catalogues_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Subcategory",
                      "kind": "LinkedField",
                      "name": "subcategory",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canonicalUrl",
                          "storageKey": null
                        },
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Brand",
                      "kind": "LinkedField",
                      "name": "brand",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v7/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "facets",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v10/*: any*/),
                            (v3/*: any*/),
                            (v11/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Bucket",
                              "kind": "LinkedField",
                              "name": "buckets",
                              "plural": true,
                              "selections": [
                                (v10/*: any*/),
                                (v12/*: any*/),
                                (v9/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "Facet",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v10/*: any*/),
                            (v3/*: any*/),
                            (v11/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Bucket",
                              "kind": "LinkedField",
                              "name": "buckets",
                              "plural": true,
                              "selections": [
                                (v10/*: any*/),
                                (v12/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "to",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "from",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "max",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "min",
                              "storageKey": null
                            }
                          ],
                          "type": "FacetStats",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CatalogueEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                (v9/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "imageMode",
                                  "storageKey": null
                                },
                                (v13/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "displayMode",
                                  "storageKey": null
                                },
                                (v3/*: any*/),
                                (v11/*: any*/),
                                (v4/*: any*/),
                                (v12/*: any*/),
                                (v7/*: any*/),
                                (v8/*: any*/)
                              ],
                              "type": "Subcategory",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": (v0/*: any*/),
                                  "concreteType": "AdjacentBannerConnection",
                                  "kind": "LinkedField",
                                  "name": "adjacentBanners",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "AdjacentBannerEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "AdjacentBanner",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v1/*: any*/),
                                            (v3/*: any*/),
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "placement",
                                              "storageKey": null
                                            },
                                            (v9/*: any*/),
                                            (v13/*: any*/)
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": "adjacentBanners(first:99)"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "ProductCard_product"
                                }
                              ],
                              "type": "Product",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "List_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CmsBanner_viewer"
    },
    {
      "condition": "priorityPage",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PriorityPage_viewer"
        }
      ]
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e97d4b2b216fef88d7e811a20aeeb28e';

module.exports = node;
