/* global VERSION */
import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { Link } from 'found';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { BarsOutlined, DollarOutlined, FireOutlined, ReadOutlined } from '@ant-design/icons';
import { BackTop, Col, Drawer, Dropdown, Layout, notification, Row } from 'antd';
import { get } from 'lodash';

import '@ant-design/compatible/assets/index.css';
import 'antd/lib/affix/style';
import 'antd/lib/alert/style';
import 'antd/lib/back-top/style';
import 'antd/lib/auto-complete/style';
import 'antd/lib/breadcrumb/style';
import 'antd/lib/button/style';
import 'antd/lib/card/style';
import 'antd/lib/checkbox/style';
import 'antd/lib/col/style';
import 'antd/lib/collapse/style';
import 'antd/lib/divider/style';
import 'antd/lib/drawer/style';
import 'antd/lib/dropdown/style';
import 'antd/lib/form/style';
import 'antd/lib/input/style';
import 'antd/lib/input-number/style';
import 'antd/lib/icon/style';
import 'antd/lib/layout/style';
import 'antd/lib/list/style';
import 'antd/lib/menu/style';
import 'antd/lib/message/style';
import 'antd/lib/modal/style';
import 'antd/lib/notification/style';
import 'antd/lib/popconfirm/style';
import 'antd/lib/radio/style';
import 'antd/lib/rate/style';
import 'antd/lib/row/style';
import 'antd/lib/select/style';
import 'antd/lib/spin/style';
import 'antd/lib/switch/style';
import 'antd/lib/tabs/style';
import 'antd/lib/upload/style';
import 'antd/lib/style';

import { StickyContainer } from 'react-sticky';
import moment from 'moment';
import MediaQuery from 'react-responsive';

import socket from '~/socket';
import Nav from './Nav';
import Banner from './home/Banner';
import CartView from './cart/CartView';
import { Footer } from './footer';
import { GiftCardIcon, PriorityIcon } from './svg';
import Account from './account/Account';
import TopBar from './topbar/TopBar';
import ErrorBoundary from './error/ErrorBoundary';
import Preview from './Preview';
import Sticky from './Sticky';
import StickyHeader from './StickyHeader';
import '../../public/css/base.css';
import Chat from './Chat';
import LocationReminder from './location/LocationReminder';
import Search from './search/Search';

moment.locale('en');

const openNotification = (props) => {
  notification.open(props);
};

const iconStyles = {
  fontSize: '18px',
  verticalAlign: 'middle',
};

const headStyles = {
  color: 'white',
  display: 'table-cell',
  verticalAlign: 'middle',
  lineHeight: '20px',
  textAlign: 'center',
  cursor: 'pointer'
};

const navStyles = {
  textAlign: 'center',
};

const navMenu = (showPriority) => ({
  categories: showPriority ? { xs: 0, md: 3 } : { xs: 0, md: 4, lg: 3 },
  bonusDeals: showPriority ? { xs: 0, md: 4 } : { xs: 0, md: 4, lg: 4 },
  cheapDeals: showPriority ? { xs: 0, md: 4 } : { xs: 0, md: 4, lg: 4 },
  giftCards: showPriority ? { xs: 0, md: 3 } : { xs: 0, md: 4 },
  catalogue: showPriority ? { xs: 0, md: 3 } : { xs: 0, md: 4 },
  account: showPriority ? { xs: 0, md: 4 } : { xs: 0, md: 4, lg: 5 },
});

// A first call must be made to prevent an error from happening
notification.open({ key: 'dummy', duration: 0.1, style: { display: 'none' } });

let logos = {
  compact: "/static/images/logo.svg",
  flat: "/static/images/logo_flat.svg",
};

if (process.env.COUNTRY === 'NZ') {
  logos = {
    compact: "/static/images/logo_nz.svg",
    flat: "/static/images/logo_flat_nz.svg",
  };
}

class App extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({
      storeCredits: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      email: PropTypes.string,
    }).isRequired,
    router: PropTypes.shape({
      addNavigationListener: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
      isActive: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    this.desktopCartRef = React.createRef();
    this.mobileCartRef = React.createRef();

    const { innerWidth } = window;

    const md = 768; // in px

    const defaultSideOpen = !props.viewer.email && props.router.isActive(props.match, { pathname: '/account'}) && innerWidth < md;

    // This is not needed in antd v5.
    this.triggerRef = React.createRef();

    this.state = {
      sideOpen: defaultSideOpen,
      /*
       * forceRendering Drawer will cause the hidden and rendered content to be searchable during CTRL+F which
       * hidden content should not be searchable by browser
       */
      forceRenderDrawerVisible: defaultSideOpen,
      bannerIndex: 0,
      banners: [],
      visible: false,
    };

    const credits = this.props.viewer.storeCredits.edges.reduce((acc, edge) => {
      const c = edge.node;

      return acc + c.creditRemaining;
    }, 0);

    if (credits > 0) {
      notification.close('Loyalty Points');
      openNotification({
        key: 'Loyalty Points',
        message: 'Loyalty Points',
        description: `You have $${credits} points available.`,
        duration: 10,
      });
    }
  }

  componentDidMount() {
    const { router } = this.props;

    socket.onNewVersion.call(this, VERSION);

    router.addNavigationListener((location) => {
      // location.query appears on product filters
      const { pathname, search, query } = location;

      if (socket.isNewVersion() && !query && pathname) {
        window.location.href = pathname + search;
        return false;
      }

      return true;
    });
  }

  onTouchEnd = () => {
    this.setState({
      sideOpen: false,
    });
  }

  onClick = () => {
    if (this.triggerRef.current === "hover") {
      return;
    }

    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
    this.triggerRef.current = flag ? "hover" : null;
  }

  toggleSideNav = () => {
    const { forceRenderDrawerVisible, sideOpen } = this.state;

    this.setState({
      sideOpen: !sideOpen,
      forceRenderDrawerVisible: !forceRenderDrawerVisible,
    });
  }

  changeBannerBg = (index) => {
    this.setState({ bannerIndex: index });
  }

  initBanners = (banners) => {
    this.setState({
      banners,
    });
  }

  hideCart = () => {
    const target = 'current.handleMenuClick';
    const hide = get(this.desktopCartRef, target) || get(this.mobileCartRef, target);

    if (hide && typeof hide === 'function') {
      hide();
    }
  }

  render() {
    const { viewer, children, match, relay } = this.props;
    const { banners, forceRenderDrawerVisible, sideOpen } = this.state;
    const error = get(this.props, 'error');
    const showPriority = get(viewer, 'configs.priority', false);

    return (
      <div className="cart-wrapper">
        {/* Cart toast container. Without it, cart toast won't display properly */}
        <div id="cart-toast" />

        <Helmet title="Sydney Tools" error={error}>
          <meta property="og:locale" content={process.env.LOCALE} />
          <meta property="og:site_name" content="Sydney Tools" />
        </Helmet>

        <Preview match={match} />
        <Chat />
        <LocationReminder relay={relay} />

        <Layout>
          <Drawer
            title={
              <Account id="account-drawer" viewer={viewer} onClick={this.toggleSideNav} router={this.props.router} match={this.props.match} />
            }
            afterVisibleChange={(visible) => {
              if (!visible) {
                this.setState({
                  forceRenderDrawerVisible: false,
                })
              }
            }}
            className="left-drawer"
            visible={sideOpen}
            onClose={this.onTouchEnd}
            placement="left"
            headerStyle={{ padding: '0px', display: 'flex', justifyContent: 'center' }}
            bodyStyle={{padding: '0px', display: forceRenderDrawerVisible ? 'block' : 'none'}}
            forceRender
          >
            <Nav viewer={viewer} onClick={this.onTouchEnd} position="left" forceRender />
          </Drawer>

          <Layout style={{ backgroundColor: 'white', overflow: 'hidden', minHeight: '100vh' }}>
            <StickyHeader
              style={{ background: '#cb0000', padding: 0 }}
              match={match}
              onUnpin={() => { this.hideCart() }}
            >
              <MediaQuery minWidth={768}>
                <TopBar viewer={viewer} router={this.props.router} />
              </MediaQuery>
              <MediaQuery maxWidth={767}>
                {TopBar.ShippingPromo(viewer, this.props.router)}
              </MediaQuery>
              <div style={{ maxWidth: '1120px', margin: '0 auto', lineHeight: '75px' }} >
                <div className="xs-logo" style={{ display: 'table', width: '100%'}}>
                  <MediaQuery maxWidth={767}>
                    <LegacyIcon
                      className="trigger"
                      style={{
                        cursor: 'pointer',
                        color: 'white',
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        minWidth: '30px'
                      }}
                      type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.toggleSideNav}
                    />
                  </MediaQuery>

                  <div
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Link
                      to="/"
                      className="logo-link click-state"
                      style={{
                        display: 'block',
                        width: '150px',
                      }}
                    >
                      <MediaQuery maxWidth={767}>
                        <img alt="Logo" className="img-fluid" src={logos.compact} />
                      </MediaQuery>
                      <MediaQuery minWidth={768}>
                        <img alt="Logo" className="img-fluid" style={{maxHeight: '30px'}} src={logos.flat} />
                      </MediaQuery>
                    </Link>
                  </div>

                  <MediaQuery maxWidth={575}>
                    <div style={{ display: 'table-cell' }}>
                      <div style={{
                        height: '64px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                      }}
                      >
                        <Account
                          id="account-sm"
                          viewer={viewer}
                          router={this.props.router}
                          match={this.props.match}
                          bodyStyle={headStyles}
                          mobile
                        />
                        <CartView
                          ref={this.mobileCartRef}
                          key="cart"
                          bodyStyle={{
                            ...headStyles,
                            position: 'relative',
                          }}
                          viewer={viewer}
                        />
                      </div>
                    </div>
                  </MediaQuery>

                </div>

                <div
                  className="search-form"
                  style={{ padding: '0px 10px 5px' }}
                >
                  <Search router={this.props.router} match={this.props.match} viewer={this.props.viewer} />
                </div>

                <MediaQuery minWidth={576} maxWidth={767}>
                  <Account
                    id="account-md"
                    viewer={viewer}
                    router={this.props.router}
                    match={this.props.match}
                    bodyStyle={{ ...headStyles, paddingRight: '10px' }}
                    mobile
                  />
                </MediaQuery>

                <MediaQuery minWidth={576}>
                  <CartView
                    ref={this.desktopCartRef}
                    key="cart"
                    bodyStyle={{
                      ...headStyles,
                      paddingRight: '15px',
                    }}
                    viewer={viewer}
                  />
                </MediaQuery>
              </div>

              <MediaQuery minWidth={768}>
                <div style={{ background: '#212121', color: 'white' }}>
                  <div className="navMenu" style={{ maxWidth: '1120px', margin: '0 auto', fontWeight: 'bold', lineHeight: '50px' }} >
                    <Row>
                      <Col {...navMenu(showPriority).categories} style={navStyles}>
                        <Dropdown
                          onClick={this.onClick}
                          onVisibleChange={this.handleVisibleChange}
                          visible={this.state.visible}
                          forceRender
                          overlay={<Nav viewer={viewer} position="top" onClick={() => { this.handleVisibleChange(false); }} />}
                        >
                          <span role="button" className="ant-dropdown-link click-state inverse navLink" >
                            <BarsOutlined style={iconStyles} /> Categories
                          </span>
                        </Dropdown>
                      </Col>
                      <Col {...navMenu(showPriority).bonusDeals} style={navStyles}>
                        <Link className="click-state inverse navLink" to="/category/hot-bonus-deals" >
                          <FireOutlined style={iconStyles} /> Bonus Deals
                        </Link>
                      </Col>
                      <Col {...navMenu(showPriority).cheapDeals} style={navStyles}>
                        <Link className="click-state inverse navLink" to="/category/cheap-deals" >
                          <DollarOutlined style={iconStyles} /> Cheap Deals
                        </Link>
                      </Col>
                      <Col {...navMenu(showPriority).giftCards} style={navStyles}>
                        <Link className="click-state inverse navLink" to="/category/by-brand/sydney-tools/gift-card" >
                          <GiftCardIcon style={{ ...iconStyles, marginRight: '4px' }} />
                          Gift Cards
                        </Link>
                      </Col>
                      <Col {...navMenu(showPriority).catalogue} style={navStyles}>
                        <Link className="click-state inverse navLink" to="/p/catalogue" >
                          <ReadOutlined style={iconStyles} /> Catalogue
                        </Link>
                      </Col>
                      {showPriority && (
                        <Col xs={0} md={3} style={navStyles}>
                          <Link className="click-state priority navLink" to="/category/priority" >
                            <PriorityIcon style={iconStyles} /> PRIORITY
                          </Link>
                        </Col>
                      )}
                      <Col {...navMenu(showPriority).account} style={{ textAlign: 'center', float: 'right' }}>
                        <Account id="account-lg" viewer={viewer} router={this.props.router} match={this.props.match} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </MediaQuery>
            </StickyHeader>

            {this.props.match.location && this.props.match.location.pathname === '/' && (
            <div>
              <Banner banners={banners} changeBannerBg={this.changeBannerBg} />
              <StickyContainer>
                <Sticky banner={banners[this.state.bannerIndex]}>
                  <div style={{padding: '5px 0', background: 'transparent'}}>
                    <h1
                      style={{
                       backgroundColor: 'black',
                       color: 'white',
                       fontWeight: 700,
                       margin: '0',
                       textAlign: 'center',
                     }}
                    >
                      The Biggest Range Of The Best Brands At The Best Prices
                    </h1>
                  </div>

                  <Row>
                    <Col
                      xs={24}
                      style={{
                        maxWidth: '1120px',
                        width: '100%',
                        margin: '0 auto',
                        float: 'none',
                      }}
                    >
                      <ErrorBoundary pathname={get(this.props.match.location, 'pathname')}>
                        {
                          React.cloneElement(children, {
                            initBanners: this.initBanners,
                          })
                        }
                      </ErrorBoundary>
                    </Col>
                  </Row>
                </Sticky>
              </StickyContainer>
            </div>
            )}

            {(!this.props.match.location || this.props.match.location.pathname !== '/') && (
            <Row style={{flexGrow: 1}}>
              <Col
                xs={24}
                style={{
                  maxWidth: '1120px',
                  width: '100%',
                  margin: '0 auto 15px auto',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  float: 'none',
                }}
              >
                <ErrorBoundary pathname={get(this.props.match.location, 'pathname')}>
                  {children}
                </ErrorBoundary>
              </Col>
            </Row>
            )}

            <Footer viewer={viewer} router={this.props.router} />
            <BackTop style={{ right: '20px', bottom: '90px', zIndex: '3'}} />
          </Layout>

        </Layout>

      </div>
    );
  }
}
export default createFragmentContainer(App, {
  viewer: graphql`
    fragment App_viewer on Customer {
      id
      firstname
      lastname
      email
      configs
      freeShippingAmount
      storeCredits(first: 999) @connection(key: "App_viewer_storeCredits") {
        edges {
          node {
            id
            name
            creditAmount
            creditRemaining
            brands
            expiry
          }
        }
      }
      ...Search_viewer
      ...Logout_viewer
      ...Footer_viewer
      ...Account_viewer
      ...Nav_viewer
      ...CartView_viewer
      ...EventAlertModal_viewer
    }
  `,
});
