/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReviewForm_product$ref: FragmentReference;
declare export opaque type ReviewForm_product$fragmentType: ReviewForm_product$ref;
export type ReviewForm_product = {|
  +id: string,
  +name: ?string,
  +$refType: ReviewForm_product$ref,
|};
export type ReviewForm_product$data = ReviewForm_product;
export type ReviewForm_product$key = {
  +$data?: ReviewForm_product$data,
  +$fragmentRefs: ReviewForm_product$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewForm_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ccda0cbb03d8377f0a2f1336c4dee41b';

module.exports = node;
