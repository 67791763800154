import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

const ProductId = (props) => {
  const { sku, model, secondModel, mode } = props;

  if (mode === "horizontal") {
    return (
      <Row style={{fontSize: '12px', color: 'black', fontWeight: 'bold'}}>
        <Col xs={24}>Item ID: {sku}</Col>
        <Col xs={12}>Model: {model}</Col>
        {secondModel && (<Col xs={12}>Second Model: {secondModel}</Col>)}
      </Row>
    );
  }

  return (
    <div style={{ color: '#919191', height: '66px', overflow: 'hidden' }}>
      <div><b>Item ID:</b> <span>{sku}</span> <span style={{float: 'right'}}>{secondModel}</span></div>
      <div style={{ wordWrap: 'break-word' }}><b>Model:</b> {model}</div>
    </div>
  )
};

ProductId.propTypes = {
  sku: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  secondModel: PropTypes.string,
  mode: PropTypes.string,
};

ProductId.defaultProps = {
  secondModel: null,
  mode: null,
};

module.exports = {
  ProductId,
};
