/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FreebiePopup_viewer$ref: FragmentReference;
declare export opaque type FreebiePopup_viewer$fragmentType: FreebiePopup_viewer$ref;
export type FreebiePopup_viewer = {|
  +id: string,
  +$refType: FreebiePopup_viewer$ref,
|};
export type FreebiePopup_viewer$data = FreebiePopup_viewer;
export type FreebiePopup_viewer$key = {
  +$data?: FreebiePopup_viewer$data,
  +$fragmentRefs: FreebiePopup_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreebiePopup_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c294a9d24ddd2f4132dbe6f6bb1f545e';

module.exports = node;
