import React from 'react';
import { HttpError, Route, RedirectException } from 'found';
import { graphql } from 'react-relay';
import { attempt, get } from 'lodash';
import { base64encode } from '~/helper';
import { getOrderBy, PRODUCT_PER_PAGE } from './SearchView';

const ProductViewQuery = graphql`
  query routes_ProductView_Query($urlSlugs: [String]) {
    viewer {
      ...ProductView_viewer @relay(mask: false)
      ...ProductView_viewer
    }
  }
`;

const PriceChangeQuery = graphql`
  query routes_PriceChange_Query {
    viewer {
      ...PriceChange_viewer
    }
  }
`;

const PriceTagQuery = graphql`
  query routes_PriceTag_Query($filterBy: [FilterBy]) {
    viewer {
      ...PriceTag_viewer
    }
  }
`;

const StockReportQuery = graphql`
  query routes_StockReport_Query {
    viewer {
      ...StockReport_viewer
    }
  }
`;

export const SearchViewQuery = graphql`
  query routes_SearchView_Query($count: Int!, $cursor: String, $query: String!, $filters: String, $orderBy: OrderBy, $page: Int) {
    viewer {
      ...SearchView_viewer @arguments(count: $count, cursor: $cursor, query: $query, filters: $filters, orderBy: $orderBy, page: $page)
    }
  }
`;


const routes = (
  <Route>
    <Route
      path="/product/:productUrlSlug"
      getComponent={() => (
        import(/* webpackChunkName: "ProductView" */'~/components/product/ProductView').then(module => module.default)
      )}
      query={ProductViewQuery}
      render={({ resolving, Component, props }) => {
        if (resolving && Component && props) {
          const products = get(props, 'viewer.products.edges', []);
          if (products.length > 0) {
            return <Component {...props} key={products[0].node.id} />;
          }

          throw new HttpError(404, props);
        }
        return undefined;
      }}
      prepareVariables={params => ({ urlSlugs: [params.productUrlSlug] })}
    />

    <Route
      path="/price-changes"
      getComponent={() => (
        import(/* webpackChunkName: "PriceChange" */'~/components/product/PriceChange').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PriceChangeQuery}
    />

    <Route
      path="/price-tag"
      getComponent={() => (
        import(/* webpackChunkName: "PriceTag" */'~/components/product/PriceTag').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PriceTagQuery}
      prepareVariables={() => {
        const filterBy = [{
          field: "status",
          filter: "false,true",
          filterType: "boolean",
          type: "in",
        }];

        return { filterBy };
      }}
    />

    <Route
      path="/internal"
    >
      <Route
        path="/stock-report"
        getComponent={() => (
          import(/* webpackChunkName: "StockReport" */'~/components/product/StockReport').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
        )}
        query={StockReportQuery}
      />
    </Route>

    <Route
      path="/new-arrivals"
      getComponent={() => (
        import(/* webpackChunkName: "NewArrivals" */'~/components/product/SearchView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={SearchViewQuery}
      prepareVariables={(_, props) => {
        const page = parseInt(get(props.location.query, 'p', 1), 10);
        const count = PRODUCT_PER_PAGE;
        const after = base64encode(`arrayconnection:${count - 1}`);
        let { filters } = props.location;

        if (!filters) {
          filters = props.location.query.filters; // eslint-disable-line prefer-destructuring
          filters = filters ? attempt(JSON.parse.bind(null, filters)) : null;
        }

        filters = filters ? JSON.stringify(filters) : null;

        return {
          count,
          after,
          page,
          query: "new-arrivals",
          filters,
        };
      }}
    />

    <Route
      path="/search"
      getComponent={() => (
        import(/* webpackChunkName: "SearchView" */'~/components/product/SearchView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={SearchViewQuery}
      prepareVariables={(_, props) => {
        const page = parseInt(get(props.location.query, 'p', 1), 10);
        const count = PRODUCT_PER_PAGE;
        const after = base64encode(`arrayconnection:${count - 1}`);
        const query = get(props.location.query, "q", "").slice(0, 200);
        const orderBy = getOrderBy(props.location);
        let { filters } = props.location;

        if (query === "new-arrivals") {
          throw new RedirectException('/new-arrivals', 301);
        }

        if (!filters) {
          filters = props.location.query.filters; // eslint-disable-line prefer-destructuring
          filters = filters ? attempt(JSON.parse.bind(null, filters)) : null;
        }

        filters = filters ? JSON.stringify(filters) : null;

        return {
          count,
          after,
          page,
          query,
          filters,
          orderBy
        };
      }}
    />

  </Route>
);

export default routes;
