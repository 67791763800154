import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { CompleteRedirectOrderMutation } from '../mutations';
import { onCheckoutCompleted } from '../helper';

let rendered = false;

export default class LatitudePay extends React.Component {
  static displayBreakdown() {
    if (rendered) {
      return;
    }
    const elms = document.querySelectorAll("img[src*='https://images.latitudepayapps.com/v2/snippet.svg']," +
        " img[src*='https://images.latitudepayapps.com/api/banner?brand=lpay']");
    [].forEach.call(elms, (elm) => {
        const imgLink = new URL(elm.src);
        rendered = true;
        elm.style.cursor = "pointer";
        elm.addEventListener("click", () => {
          if (document.getElementsByClassName("lpay-modal-wrapper").length === 0) {
            const t = new XMLHttpRequest;
            t.onreadystatechange = () => {
              if (t.readyState === 4 && t.status === 200 && t.responseText !== null) {
                document.body.insertAdjacentHTML("beforeend", t.responseText);
                document.querySelector(".lpay-modal-wrapper").style.display = "block";
              }
            };
            t.open("GET", `https://images.latitudepayapps.com/v2/modal.html${  imgLink.search}`, !0);
            t.send(null);
          } else {
            document.querySelector(".lpay-modal-wrapper").style.display = "block";
          }
        });
    });
  }

  static displayLogo() {
    return (
      <img
        src="/assets/images/latitude_logo.svg"
        height="30"
        alt="Latitude Pay"
        title="Latitude Pay"
      />
    );
  }

  static redirect = function redirect(loc) {
    const keys =[
      'token',
      'reference',
      'message',
      'result',
      'signature'
    ];
    if (keys.every(k => k in loc.query)) {
      if (loc.query.result === 'COMPLETED') {
        const values = {
          latitudePayQuery: loc.search.replace('?', ''),
          paymentMethod: 'latitude_pay',
        };

        const onCompleted = onCheckoutCompleted.bind(this);

        CompleteRedirectOrderMutation.commit({
          // eslint-disable-next-line react/prop-types
          environment: this.props.relay.environment,
          variables: { input: values },
          // eslint-disable-next-line react/prop-types
          viewer: this.props.viewer,
          onCompleted,
          onError: (errors) => {
            message.destroy(); // dimiss loading message
            message.error(errors[0].message, 10);
            // eslint-disable-next-line react/no-unused-state
            this.setState({ loading: false });
          },
        });
      } else if (loc.query.x_result === 'failed') {
        message.error('Unsuccessful Payment');
      }
      else {
        message.error('Invalid payment info');
      }
    }
  }

  static init = (form, submit) => {

    const fields = form.getFieldsValue();

    /*
     * When re-setting a password is present, the above guard is not enough to prevent order placing
     * as `checkoutPassword` field is removed.
     */
    if (fields.billing && Object.keys(fields.billing).length === 1 && fields.billing.email) {
      message.error('Please login to place your order')
      return;
    }

    // Form wasn't scrolling when theres a missing field
    form.validateFieldsAndScroll((err) => {
      if (!err) {
        submit();
      }
    })
  }

  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('latitudePayQuery')(<div />)}
      </div>
    );
  }
}
