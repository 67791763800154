import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';

import { get, debounce } from 'lodash';
import Cookies from 'universal-cookie';
import { AutoComplete, Card, Modal, Spin } from 'antd';
import GetByDate from '../priority/GetByDate';

import { GeocodeMutation, SearchPostcodeSuburbMutation } from './mutations';

const cookies = new Cookies();

export const deliveryTime = {
  normal: '4 - 10',
  priority: '1 - 3',
  nonStock: '8 - 16',
};

export const ShippingTime = (props) => {
  const { rate: { extra }, type } = props;

  const days = deliveryTime[type];
  const advertise = get(extra, 'advertise');

  return (
    <div>
      {extra && extra.promo && (
        <span>
          <span style={{color: '#0089B6', float: 'left', fontWeight: 'bold'}}>
            Delivery time
          </span>
          <span style={{float: 'right'}}>
            <GetByDate date={extra.promo} advertise={advertise} />
          </span>
        </span>
      )}
      {!extra && <><span style={{float: 'left', fontWeight: 'bold'}}>Delivery time</span><span style={{float: 'right'}}>{days} working days</span></>}
      <div style={{clear: 'both'}} />
    </div>
  );
}

ShippingTime.propTypes = {
  rate: PropTypes.shape({
    extra: PropTypes.shape({
      promo: PropTypes.string
    })
  }).isRequired,
  type: PropTypes.string,
};

ShippingTime.defaultProps = {
  type: 'normal',
};

export const renderOption = function renderOption(item) {
  const value = [item.location, item.postcode].join(" ").trim();

  return {
    key: item.id,
    item,
    value,
  }
};

export const handleSearch = function handleSearch(dataSource = 'dataSource', value, {onComplete} = {}) {
  if (value.trim().length < 3) return;

  SearchPostcodeSuburbMutation.commit({
    environment: this.props.relay.environment,
    variables: { input: { query: value } },
    viewer: this.props.viewer,
    onCompleted: (resp) => {
      const { result } = resp.searchPostcodeSuburb;
      if (result && result.localities) {
        this.setState({ [dataSource]: result.localities.locality });
        if (typeof(onComplete) === 'function') {
          onComplete(result.localities.locality);
        }
      } else {
        this.setState({ [dataSource]: [] });
      }
    },
  });
};

export const useHandleSearch = (relay, dataSource = 'dataSource') => {
  const [state, setState] = useState({ [dataSource]: [] });

  const handleSearchFn = (_, value, { onComplete } = {}) => {
    if (value.trim().length < 3) return;

    SearchPostcodeSuburbMutation.commit({
      environment: relay.environment,
      variables: { input: { query: value } },
      onCompleted: (resp) => {
        const { result } = resp.searchPostcodeSuburb;
        if (result && result.localities) {
          setState({ [dataSource]: result.localities.locality });
          if (typeof (onComplete) === 'function') {
            onComplete(result.localities.locality);
          }
        } else {
          setState({ [dataSource]: [] });
        }
      },
    });
  };

  return [state[dataSource], handleSearchFn];
};

export const getDefaultSubrubPostcode = () => {
  const data = cookies.get('suburbPostcode');
  if (data && (!data.latitude || !data.longitude)) {
    cookies.remove('suburbPostcode', { path: '/' });
    return null;
  }
  return data;
};
export const saveDefaultSubrubPostcode = (data) => {
  const { suburb, postcode, latitude, longitude } = data || {};
  if (suburb && postcode && latitude && longitude) {
    cookies.set('suburbPostcode', data, { path: '/' });
  }
};
export const getDefaultDataSource = (defaultSubrubPostcode) => {
  const { suburb, city, postcode, latitude, longitude } = defaultSubrubPostcode || {};

  if (process.env.COUNTRY === 'NZ') {
    return {
      postcode,
      latitude,
      longitude,
      suburb,
      location: city,
      id: 'initial',
    };
  }

  return {
    postcode,
    latitude,
    longitude,
    location: suburb,
    id: 'initial',
  };
}

const FreeShipping = (props) => {
  const { product, freeShippingAmount, pipe, style } = props;

  return (product.globalFreeShipping && freeShippingAmount) ? (
    <div style={style}>
      <b> {pipe ? '| ' : null}FREE Shipping over <span style={{color: '#c70d13'}}>${freeShippingAmount}*</span></b>
    </div>
  ) : null;
}

FreeShipping.propTypes = {
  product: PropTypes.shape({
    globalFreeShipping: PropTypes.bool,
  }).isRequired,
  freeShippingAmount: PropTypes.number,
  pipe: PropTypes.bool,
  style: PropTypes.shape({}),
}

FreeShipping.defaultProps = {
  freeShippingAmount: null,
  pipe: false,
  style: {},
}


class ShippingQuote extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      freeShippingAmount: PropTypes.number,
      shippingMethods: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      globalFreeShipping: PropTypes.bool,
    }).isRequired,
    handleGetByDate: PropTypes.func,
  }

  static defaultProps = {
    handleGetByDate: null,
  }

  constructor(props) {
    super(props);

    /*
     * Gets default postcode and suburb from cookie
     */
    const dataSource = [];
    const defaultSubrubPostcode = getDefaultSubrubPostcode() || {};
    const { latitude, longitude } = defaultSubrubPostcode;

    if (defaultSubrubPostcode) {
      const item = getDefaultDataSource(defaultSubrubPostcode);
      dataSource.push(item);
    }

    this.state = {
      modalVisible: false,
      suburb: get(defaultSubrubPostcode, 'suburb'),
      city: get(defaultSubrubPostcode, 'city'),
      postcode: get(defaultSubrubPostcode, 'postcode'),
      latitude,
      longitude,
      dataSource,
      loading: false,
      quoteInput: get(defaultSubrubPostcode, 'selectedValue'),
    };

    this.lastInput = ''; // The last sanitised user input, which helps to avoid unnecessary search request
  }

  componentDidMount = () => {
    this.getRates();
  }

  componentDidUpdate(prevProps) {
    const { handleGetByDate } = this.props;

    const prevSMs = get(prevProps, 'viewer.shippingMethods.edges', []);
    const currSMs = get(this.props, 'viewer.shippingMethods.edges', []);

    const prev = this.getPromo(prevSMs);
    const curr = this.getPromo(currSMs);

    if ((prev !== curr) && handleGetByDate) {
      handleGetByDate(curr);
    }
  }

  onSearch = debounce((value) => {
    const cleanValue = this.sanitiseCharacters(value).trim();

    if (this.shouldSearch(cleanValue)) {
      handleSearch.call(this, 'dataSource', cleanValue)
    }
    this.lastInput = cleanValue;
  }, 100);

  getPromo = (methods) => get(methods.find(m => get(m, 'node.extra.promo')), 'node.extra.promo')

  getRates = (callback) => {
    const { suburb, city, postcode } = this.state;

    if (suburb && postcode) {
      const refetchVariables = {
        suburb,
        city,
        postcode,
        productId: this.props.product.id,
        priority: true,
      };

      this.setState({ loading: true });

      this.props.relay.refetch(refetchVariables, null, () => {
        this.setState({ loading: false });
        if (callback instanceof Function) {
          callback();
        }
      });
    }
  }

  getDefaultLoc = (suburb, postcode) => {
    if (suburb && postcode) {
      return `${suburb} ${postcode}`;
    }

    return null;
  }

  openModal = () => {
    this.getRates(() => this.setState({ modalVisible: true }));
  }

  /* Avoid unnecessary search requests:
     i.e. Search requests for user input 123! and 123@ (they are all 123 after sanitising)
          should not be sent because request for 123 should have already been sent out */
  shouldSearch = cleanValue => this.lastInput !== cleanValue

  handleOk = () => {
    this.setState({
      modalVisible: false,
    });
  }

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  }

  handleSelect = (value, option) => {
    if (process.env.COUNTRY === 'NZ') {
      GeocodeMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { query: value }},
        viewer: this.props.viewer,
        onCompleted: (resp) => {
          const result = get(resp, 'geocode.result', {});

          this.handleGetRate({
            suburb: result.suburb,
            city: result.city,
            postcode: result.postcode,
            latitude: result.latitude,
            longitude: result.longitude,
          });
        },
      });
    } else {
      this.handleGetRate({
        suburb: option.item.location,
        postcode: option.item.postcode,
        latitude: option.item.latitude,
        longitude: option.item.longitude,
      });
    }
  }

  handleGetRate = (item) => {
    this.setState(item, () => {
      this.openModal();
      const { suburb, city, postcode, latitude, longitude, quoteInput } = this.state;

      if (process.env.COUNTRY === 'NZ') {
        saveDefaultSubrubPostcode({ suburb, city, postcode, latitude, longitude, selectedValue: quoteInput })
      } else {
        saveDefaultSubrubPostcode({ suburb, postcode, latitude, longitude, selectedValue: quoteInput })
      }
    })
  }

  // delete characters which not 0-9, a-z, A-Z, space, hyphen, brackets, dot or apostrophe
  sanitiseCharacters = value => value && value.replace(/[^0-9a-z\s-().']/gi, '')

  handleChange = (value) => {
    this.setState({quoteInput: this.sanitiseCharacters(value)});
  }

  renderTitle = (node) => {
    const code = get(node, 'code', '');
    const title = get(node, 'title', '');

    if (code.includes('priority')) {
      return title.replace(/\(.*\)\s*/g, '')
    }
    return title;
  }

  renderShippingMethods = (sm, p) => {
    const type = p.nonStock ? 'nonStock' : 'normal';

    const methods = {
      standard: [],
      priority: [],
      storepickup: [],
    };

    sm.forEach(({node}) => {
      const code = node.code.replace('_insurance', '');
      if (code === 'storepickup') {
        methods.storepickup.push(node);
      }
      else if (code === 'priority') {
        methods.priority.push(node);
      }
      else {
        methods.standard.push(node);
      }
    });


    return (
      <div style={{marginTop: '5px', fontSize: '14px'}}>
        {Object.keys(methods).map(key => {
          if (methods[key].length === 0) {
            return null;
          }

          if (key === 'storepickup') {
            const n = methods[key].find(node => node);

            if (n) {
              return (
                <Card key={key} size="small">
                  <span style={{float: 'left', maxWidth: '70%'}}>{n.title}</span><span style={{float: 'right'}}>{n.priceFormatted}</span>
                </Card>
              );
            }
          }

          return (
            <Card key={key} title={<ShippingTime type={key === 'priority' ? key : type} rate={methods[key][0]} />} size="small" style={{marginBottom: '5px'}}>
              {methods[key].map(node => (
                <div key={node.code}>
                  <span style={{float: 'left', maxWidth: '70%'}}>
                    {this.renderTitle(node)}
                  </span>
                  <span style={{float: 'right'}}>
                    {node.priceFormatted}
                  </span>
                  <div style={{clear: 'both'}} />
                </div>
            ))}
            </Card>
          );
        })}
      </div>
    )
  }

  renderAutoComplete = () => (
    <AutoComplete
      allowClear
      autoComplete="googleignoreautofill"
      options={this.state.dataSource.map(renderOption)}
      onSearch={this.onSearch}
      onSelect={this.handleSelect}
      onChange={this.handleChange}
      style={{ width: '100%' }}
      placeholder={<small>Enter a Suburb or Postcode</small>}
      value={this.state.quoteInput}
    />
  )

  render() {
    const shippingMethods = get(this.props.viewer.shippingMethods, 'edges', []);

    const { suburb, postcode, loading } = this.state;

    const { product } = this.props;
    const { freeShippingAmount } = this.props.viewer;

    return (
      <section className="shipping-quote">
        <h3 style={{paddingTop: '10px', paddingLeft: '15px', fontSize: '14px'}}>
          <img src="/static/images/shipping.svg" alt="Shipping" width="20" style={{marginRight: '5px'}} />
          <span style={{color: "#cb0000", fontWeight: 700}}>Delivery</span>
          <FreeShipping
            product={product}
            freeShippingAmount={freeShippingAmount}
            style={{ display: 'contents' }}
            pipe
          />
        </h3>

        <div style={{backgroundColor: '#f3f3f3', padding: '5px 15px 15px 15px'}}>
          <div style={{fontSize: '14px', fontWeight: 700, margin: '0'}}>
            Shipping cost and delivery time
            <GetByDate date={this.getPromo(shippingMethods)} />
          </div>
          <Modal
            title="Shipping Estimate"
            visible={this.state.modalVisible}
            footer=""
            onOK={this.handleOk}
            onCancel={this.handleCancel}
            bodyStyle={{paddingTop: '0'}}
          >
            {this.renderAutoComplete()}

            <Spin spinning={this.state.loading} />
            <FreeShipping product={product} freeShippingAmount={freeShippingAmount} style={{ marginTop: '5px', textAlign: 'center' }} />

            {this.renderShippingMethods(shippingMethods, product)}
            { (loading === false && suburb && postcode && shippingMethods.length === 0) && (
              <div>We are unable to determine a rate for your address, please make sure that the address is correct.</div>
            )}
          </Modal>

          {this.renderAutoComplete()}

        </div>
      </section>
    );
  }
}
export default createRefetchContainer(
  ShippingQuote, {
    viewer: graphql`
    fragment ShippingQuote_viewer on Customer @argumentDefinitions(
      suburb: {type: "String!", defaultValue: ""},
      city: {type: "String", defaultValue: ""},
      postcode: {type: "String!", defaultValue: ""},
      productId: {type: "ID!", defaultValue: ""},
    ) {
      freeShippingAmount
      shippingMethods(first: 20, productId: $productId, suburb: $suburb, city: $city, postcode: $postcode) {
        edges {
          node {
            code
            title
            price
            priceFormatted
            extra
          }
        }
      }
    }
  `,
  },
  graphql`
  query ShippingQuoteRefetchQuery($productId: ID!, $suburb: String!, $city: String, $postcode: String!) {
    viewer {
      ...ShippingQuote_viewer @arguments(productId: $productId, suburb: $suburb, city: $city, postcode: $postcode)
    }
  }
`,
);
