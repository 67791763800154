/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddressList_viewer$ref: FragmentReference;
declare export opaque type AddressList_viewer$fragmentType: AddressList_viewer$ref;
export type AddressList_viewer = {|
  +id: string,
  +email: ?string,
  +addresses: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +firstname: ?string,
        +lastname: ?string,
        +street: ?string,
        +city: ?string,
        +postcode: ?string,
        +region: ?string,
        +telephone: ?string,
        +company: ?string,
        +fax: ?string,
        +country: ?{|
          +name: ?string
        |},
      |}
    |}>
  |},
  +defaultBillingAddress: ?{|
    +id: string,
    +firstname: ?string,
    +lastname: ?string,
    +street: ?string,
    +city: ?string,
    +postcode: ?string,
    +region: ?string,
    +telephone: ?string,
    +company: ?string,
    +fax: ?string,
    +country: ?{|
      +name: ?string
    |},
  |},
  +defaultShippingAddress: ?{|
    +id: string,
    +firstname: ?string,
    +lastname: ?string,
    +street: ?string,
    +city: ?string,
    +postcode: ?string,
    +region: ?string,
    +telephone: ?string,
    +company: ?string,
    +fax: ?string,
    +country: ?{|
      +name: ?string
    |},
  |},
  +defaultAddress: ?{|
    +id: string
  |},
  +$refType: AddressList_viewer$ref,
|};
export type AddressList_viewer$data = AddressList_viewer;
export type AddressList_viewer$key = {
  +$data?: AddressList_viewer$data,
  +$fragmentRefs: AddressList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "region",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telephone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fax",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "addresses"
        ]
      }
    ]
  },
  "name": "AddressList_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": "addresses",
      "args": null,
      "concreteType": "CustomerAddressConnection",
      "kind": "LinkedField",
      "name": "__AddressBook_addresses_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerAddressEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerAddress",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAddress",
      "kind": "LinkedField",
      "name": "defaultBillingAddress",
      "plural": false,
      "selections": (v11/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAddress",
      "kind": "LinkedField",
      "name": "defaultShippingAddress",
      "plural": false,
      "selections": (v11/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAddress",
      "kind": "LinkedField",
      "name": "defaultAddress",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc456e96886bb9e9ca45ad71d38aca2e';

module.exports = node;
