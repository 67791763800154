/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PosSuccessView_viewer$ref = any;
export type routes_PosSuccessView_QueryVariables = {|
  ids: $ReadOnlyArray<string>,
  storeIds: $ReadOnlyArray<string>,
|};
export type routes_PosSuccessView_QueryResponse = {|
  +viewer: ?{|
    +salesPosOrders: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +$fragmentRefs: PosSuccessView_viewer$ref,
  |}
|};
export type routes_PosSuccessView_Query = {|
  variables: routes_PosSuccessView_QueryVariables,
  response: routes_PosSuccessView_QueryResponse,
|};
*/


/*
query routes_PosSuccessView_Query(
  $ids: [ID!]!
  $storeIds: [String!]!
) {
  viewer {
    ...PosSuccessView_viewer
    salesPosOrders(first: 1, ids: $ids, storeIds: $storeIds) {
      edges {
        node {
          id
          name
        }
      }
    }
    id
  }
}

fragment PosSuccessView_viewer on Customer {
  salesPosOrders(first: 1, ids: $ids, storeIds: $storeIds) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "storeIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Variable",
      "name": "ids",
      "variableName": "ids"
    },
    {
      "kind": "Variable",
      "name": "storeIds",
      "variableName": "storeIds"
    }
  ],
  "concreteType": "SalesPosOrderConnection",
  "kind": "LinkedField",
  "name": "salesPosOrders",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SalesPosOrderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesPosOrder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PosSuccessView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PosSuccessView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_PosSuccessView_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0433d33d256eac338bac343e6da1c9c7",
    "id": null,
    "metadata": {},
    "name": "routes_PosSuccessView_Query",
    "operationKind": "query",
    "text": "query routes_PosSuccessView_Query(\n  $ids: [ID!]!\n  $storeIds: [String!]!\n) {\n  viewer {\n    ...PosSuccessView_viewer\n    salesPosOrders(first: 1, ids: $ids, storeIds: $storeIds) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment PosSuccessView_viewer on Customer {\n  salesPosOrders(first: 1, ids: $ids, storeIds: $storeIds) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68053203f6ae96c9e048298b26f47a8d';

module.exports = node;
