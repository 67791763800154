import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useRouter } from 'found';

import FilterLabel from './FilterLabel';

const FilterCheckbox = (props) => {
  const {
    filterKey,
    buckets,
    updateFilters,
    showSearch,
    style,
    checkboxContainerStyle,
    checkboxInnerStyle,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const { match } = useRouter();

  const pathname = get(match, 'location.pathname');
  const search = get(match, 'location.search');

  useEffect(() => {
    if (searchValue) {
      setSearchValue("");
    }
  }, [pathname, search]);

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value.trim());
  }

  const filteredBuckets = buckets.filter(({key}) => key.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1);

  return (
    <>
      {showSearch && (
        <Input
          type="search"
          key={`${filterKey}_search`}
          allowClear
          value={searchValue}
          onChange={handleSearch}
          size="small"
          style={{ height: '27px' }}
          suffix={!searchValue ? <SearchOutlined /> : null}
        />
      )}
      <div style={style}>
        <div key={filterKey} style={checkboxContainerStyle}>
          {filteredBuckets.map(option => (
            <Checkbox
              className="filter-checkbox"
              checked={option.checked}
              onChange={(e) => { updateFilters(filterKey, option.key, e); }}
              style={checkboxInnerStyle}
              key={option.key}
            >
              <FilterLabel label={option.key} docCount={option.docCount} />
            </Checkbox>
          ))}
        </div>
      </div>
    </>
  )
};

FilterCheckbox.propTypes = {
  filterKey: PropTypes.string.isRequired,
  buckets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateFilters: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
  style: PropTypes.shape({}),
  checkboxContainerStyle: PropTypes.shape({}),
  checkboxInnerStyle: PropTypes.shape({}),
}

FilterCheckbox.defaultProps = {
  showSearch: false,
  style: {},
  checkboxContainerStyle: {},
  checkboxInnerStyle: {},
}

export default FilterCheckbox;
