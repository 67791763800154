import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

export default class BundleContent extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      ac: PropTypes.string,
      type: PropTypes.string.isRequired,
      bundles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      })
    }).isRequired,
  }

  render() {
    const { product } = this.props;

    return (
      <div style={{fontSize: '13px'}}>
        {product.type === 'bundle' && product.ac === null && (
          <div>
            {product.bundles.edges.map(edge => {
             const b = edge.node;

             return (
               <Row key={b.id}>
                 <Col span={3}>
                   {b.quantity} <b>X</b>
                 </Col>
                 <Col span={17}>
                   {b.name}
                 </Col>
                 <Col span={4}>
                   <b>{b.ac || b.sku}</b>
                 </Col>
               </Row>
             );
            })}
          </div>
        )}
      </div>
    );
  }
}
