import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import ProductList from '../product/ProductList';

export const PRODUCT_PER_PAGE = 40;

export const getOrderBy = (location) => {
  let { orderBy } = location;

  if (!orderBy) {
    const orderByQuery = get(location.query, 'orderBy');
    const [field, direction] = orderByQuery ? orderByQuery.split("_") : [null, null];

    if (field && direction) {
      orderBy = {
        field,
        direction
      };
    }
  }


  return orderBy;
};


export default createPaginationContainer(
  ProductList,
  {
    viewer: graphql`
    fragment SearchView_viewer on Customer @argumentDefinitions(
      count: {type: "Int!"}
      cursor: {type: "String"}
      query: {type: "String!"}
      filters: {type: "String"}
      orderBy: {type: "OrderBy"}
      page: {type: "Int"}
    ) {
      ...List_viewer
      ...CmsBanner_viewer
      configs
      products(first: $count, orderBy: $orderBy, after: $cursor, query: $query, filters: $filters, page: $page) @connection(key: "SearchView_products") {
        totalCount
        facets {
          ...FacetsFragment_facet @relay(mask: false)
        }
        canonicalLink
        redirect
        edges {
          node {
            id
            ...ProductCard_product
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.products;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        filters: fragmentVariables.filters,
        query: fragmentVariables.query,
        orderBy: fragmentVariables.orderBy,
      };
    },
    query: graphql`
    query SearchViewPaginationQuery (
      $count: Int!
      $cursor: String
      $query: String!
      $filters: String
      $orderBy: OrderBy
      $page: Int
    ) {
      viewer {
        ...SearchView_viewer @arguments(count: $count, orderBy: $orderBy, cursor: $cursor, query: $query, filters: $filters, page: $page)
      }
    }
  `,
  },
);
