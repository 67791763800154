import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { message } from 'antd';
import NProgress from 'nprogress';

function buildMutation(mutation, config = {}) {
  const commit = function commit({
    environment, variables = {}, viewer, uploadables, parent, ...rest
  }) {
    NProgress.start();
    const c = {
      mutation,
      onCompleted: (response, errors) => {
        let e = errors;
        if (errors) {
          // convert server side error string to array
          if (!Array.isArray(errors)) {
            e = [{ message: 'Server Error' }];
          }
          if (rest.onError) {
            rest.onError(e);
          } else if (config.onError) {
            config.onError(e);
          } else {
            message.error(e[0].message);
          }
        } else if (rest.onCompleted) {
          rest.onCompleted(response, e);
        } else if (config.onCompleted) {
          config.onCompleted(response, e);
        }
        NProgress.done();
      },
      onError: (errors) => {
        let e = errors;
        // convert server side error string to array
        if (!Array.isArray(errors)) {
          e = [{ message: 'Server Error' }];
        }

        if (rest.onError) {
          rest.onError(e);
        } else if (config.onError) {
          config.onError(e);
        } else {
          console.error(e);
          message.error('Server Error');
        }
        NProgress.done();
      },
      variables: {
        ...variables,
      },
    };

    if (config.optimisticResponse) {
      c.optimisticResponse = config.optimisticResponse;
    }
    if (config.updater) {
      c.updater = config.updater.bind(this, { viewer, parent });
    }

    if (config.configs) {
      c.configs = config.configs;
    }

    if (uploadables) {
      c.uploadables = uploadables;
    }

    return commitMutation(
      environment,
      c,
    );
  };

  return commit;
}

const getConnection = function getConnection(store, { viewer, parent }, connectionKey) {
  let parentProxy = null;
  if (parent) {
    parentProxy = store.get(parent.id);
  } else {
    parentProxy = store.get(viewer.id);
  }

  return ConnectionHandler.getConnection(
    parentProxy,
    connectionKey,
  );
};

// prevent duplicate id being inserted when,
// edgeUpdater for add address and addresss subscription insert edge
const exist = function exist(connection, newEdge) {
  let id = null;
  const node = newEdge.getLinkedRecord('node');

  if (node) {
    id = node.getDataID();
  }

  const edges = connection.getLinkedRecords('edges');

  const ids = (edges || []).map(edge => {
    const n = edge.getLinkedRecord('node');

    if (n) {
      return n.getDataID();
    }
    return null;
  }).filter(i => i);

  return ids.includes(id);
}

const edgeDeleter = ({ rootField, edgeName, connectionKey }, { viewer, parent }, store) => {
  const deletedID = store.getRootField(rootField).getValue(edgeName);

  const conn = getConnection(store, { viewer, parent }, connectionKey);

  ConnectionHandler.deleteNode(
    conn,
    deletedID,
  );
};

const edgeUpdater = ({
  rootField, edgeName, connectionKey, insertPosition = 'after',
}, { viewer, parent }, store) => {
  const payload = store.getRootField(rootField);

  if (!payload) return;

  const newEdge = payload.getLinkedRecord(edgeName);

  const conn = getConnection(store, { viewer, parent }, connectionKey);

  if (!exist(conn, newEdge)) {
    if (insertPosition === 'after') {
      ConnectionHandler.insertEdgeAfter(conn, newEdge);
    } else {
      ConnectionHandler.insertEdgeBefore(conn, newEdge);
    }
  }
};

module.exports = {
  buildMutation,
  edgeDeleter,
  edgeUpdater,
};
