/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveProductFromWishlistInput = {|
  productId: string
|};
export type RemoveProductFromWishlistMutationVariables = {|
  input: RemoveProductFromWishlistInput
|};
export type RemoveProductFromWishlistMutationResponse = {|
  +removeProductFromWishlist: ?{|
    +wishlist: ?{|
      +id: string,
      +products: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +price: ?number,
            +sku: ?string,
            +images: ?$ReadOnlyArray<?{|
              +thumbnail: ?string
            |}>,
          |}
        |}>
      |},
    |},
    +product: ?{|
      +id: string,
      +inWishlist: ?boolean,
    |},
  |}
|};
export type RemoveProductFromWishlistMutation = {|
  variables: RemoveProductFromWishlistMutationVariables,
  response: RemoveProductFromWishlistMutationResponse,
|};
*/


/*
mutation RemoveProductFromWishlistMutation(
  $input: RemoveProductFromWishlistInput!
) {
  removeProductFromWishlist(input: $input) {
    wishlist {
      id
      products(first: 999) {
        edges {
          node {
            id
            name
            price
            sku
            images {
              thumbnail
              id
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    product {
      id
      inWishlist
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sku",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inWishlist",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveProductFromWishlistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveProductFromWishlistPayload",
        "kind": "LinkedField",
        "name": "removeProductFromWishlist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Wishlist",
            "kind": "LinkedField",
            "name": "wishlist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "products",
                "args": null,
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "__Wishlist_products_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductImage",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveProductFromWishlistMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveProductFromWishlistPayload",
        "kind": "LinkedField",
        "name": "removeProductFromWishlist",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Wishlist",
            "kind": "LinkedField",
            "name": "wishlist",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "ProductConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductImage",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": "products(first:999)"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Wishlist_products",
                "kind": "LinkedHandle",
                "name": "products"
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca8c7b312f67114386f149abf37120bb",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "removeProductFromWishlist",
            "wishlist",
            "products"
          ]
        }
      ]
    },
    "name": "RemoveProductFromWishlistMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveProductFromWishlistMutation(\n  $input: RemoveProductFromWishlistInput!\n) {\n  removeProductFromWishlist(input: $input) {\n    wishlist {\n      id\n      products(first: 999) {\n        edges {\n          node {\n            id\n            name\n            price\n            sku\n            images {\n              thumbnail\n              id\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    product {\n      id\n      inWishlist\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66081817fc0140df0a0e9e10d51dd0d0';

module.exports = node;
