import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, Col, Modal, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Link } from 'found';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

import { SignUpForm } from '~/components/auth/Login';
import { getTnCPath } from '~/components/cms/routes';
import { PriorityIcon } from '~/components/svg';
import { renderFaqs } from './Covid19Page';

import './style.css';

const ORANGE = '#FD8A2D';
const GREEN = '#65B526';
const BLUE = '#0089B6';

const baseBtnStyle = {
  height: '44px',
  width: '100%',
  fontWeight: '700',
  color: 'white',
  backgroundColor: '#D9D9D9',
}

const sliderImages = {
  header: { name: 'Header', file: 'header.png' },
  sameDay: { name: 'Same Day Delivery', file: 'same_day.png' },
  nextDay: { name: 'Next Day Delivery', file: 'next_day.png' },
  exclusive: { name: 'Exclusive Offer', file: 'exclusive.png' },
  membershipPerks: { name: 'Membership Perks', file: 'membership_perks.png' },
};

const Highlight = (props) => {
  const { color, children } = props;

  return (
    <b style={{ color }}>
      {children}
    </b>
  )
}

Highlight.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired
}

const SliderContent = (props) => {
  const { title, titleStyle, content, image } = props;

  return (
    <>
      <Row style={{ padding: '0px 10px', height: '100%' }}>
        <Col
          xs={24}
          sm={11}
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            className="priority-shipping-slider-image img-fluid"
            alt={image.name}
            src={`/static/images/priority/${image.file}`}
          />
        </Col>
        <Col xs={24} sm={13}>
          <div style={{ ...titleStyle, height: '44px' }}>
            <span style={{ color: 'white', 'fontSize': '16px', 'fontWeight': '700',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            >
              {title}
            </span>
          </div>
          {content}
        </Col>
      </Row>
    </>
  )
}

SliderContent.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.shape({}).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  image: PropTypes.shape({
    name: PropTypes.string,
    file: PropTypes.string,
  }).isRequired,
};

const SignUpModal = (props) => {
  const { viewer, visible, handleModal } = props;

  return (
    <Modal
      visible={visible}
      footer={null}
      width="420px"
      onCancel={() => { handleModal(false); }}
    >
      <SignUpForm viewer={viewer} />
    </Modal>
  )
}

SignUpModal.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  visible: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
}

class PriorityPage extends React.Component {
  static propTypes = {
    viewer: PropTypes.oneOfType([
      PropTypes.object,
    ]).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      swiper: null,
      sliderIndex: 0,
    };
  }

  activeBtn = (position) => {
    const { sliderIndex } = this.state;

    if (typeof position === 'number' && sliderIndex === position) {
      return { backgroundColor: BLUE };
    } else if (Array.isArray(position) && position.includes(sliderIndex)) {
      return { backgroundColor: BLUE };
    }

    return null;
  }

  handleClick = (realIndex) => {
    const { swiper } = this.state

    if (!swiper) {
      return;
    }

    const activeIndex = realIndex + 1;

    swiper.slideTo(activeIndex);
  }

  handleModal = (visible) => {
    this.setState({ visible });
  }

  renderIntro = () => {
    return (
      <div style={{ marginTop: '20px' }}>
        <h4 id="what-is-sydney-tools-priority">
          <b>What Is Sydney Tools Priority</b>
        </h4>

        <span>Sydney Tools <Highlight color={BLUE}>PRIORITY</Highlight> offers you the fastest delivery services in order to get the tools you want in your hands quickly. </span>
        <Highlight color={BLUE}>PRIORITY</Highlight> service includes <Highlight color={ORANGE}>Same Day</Highlight> and <Highlight color={ORANGE}>Next Day Delivery</Highlight>, as well as exclusive offers for <Highlight color={BLUE}>PRIORITY</Highlight> Members and much more.
        <br />
        Not a Member yet? Simply create a <Highlight color={ORANGE}>Free</Highlight> Membership Account and enjoy the benefits of Sydney Tools <Highlight color={BLUE}>PRIORITY</Highlight> today!
      </div>
    )
  }

  renderDeliveryContent = (type) => {
    return (
      <div>
        <span>
          {type === "Same Day Delivery" && (
            <>
              <Highlight color={BLUE}>PRIORITY</Highlight> Members who placed an order before 1:30pm on eligible products will be delivered by 9pm that evening.
            </>
          )}
          {type === "Next Day Delivery" && (
            <>
              <Highlight color={BLUE}>PRIORITY</Highlight> Members who placed an order after 1:30pm on eligible products will recieve Next Day Delivery.
            </>
          )}
        </span>
        <br />
        <br />
        <b>Benefits:</b>
        <ul style={{ margin: '0px' }}>
          <li>Get your hands on tools faster than ever before</li>
          <li>Real time track and trace</li>
          <li>Saves you time and money</li>
        </ul>
        <br />
        Look out for the <PriorityIcon style={{ fontSize: '24px' }} /> <Highlight color={BLUE}>PRIORITY</Highlight> logo while shopping for eligible products.
      </div>
    )
  }

  renderPriorityBenefits = (email) => {
    return (
      <div style={{ marginTop: '55px' }}>
        <h4 id="priority-benefits">
          <b>Priority Benefits</b>
        </h4>

        <Row gutter={[{xs: 5, sm: 10, md: 16}, 0]}>
          <Col xs={8}>
            <Button
              style={{ ...baseBtnStyle, ...this.activeBtn([0, 1]) }}
              onClick={() => { this.handleClick(0) }}
            >
              Shipping
            </Button>
          </Col>
          <Col xs={8}>
            <Button
              style={{ ...baseBtnStyle, ...this.activeBtn(2) }}
              onClick={() => { this.handleClick(2) }}
            >
              Shopping
            </Button>
          </Col>
          <Col xs={8}>
            <Button
              style={{ ...baseBtnStyle, ...this.activeBtn(3) }}
              onClick={() => { this.handleClick(3) }}
            >
              And More!
            </Button>
          </Col>
        </Row>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
        >
          <div
            className="swiper-button-prev"
            style={{ position: 'relative', left: 'unset', margin: '0px 10px 0px 5px', '--swiper-navigation-size': '50px' }}
          />
          <Swiper
            className="priority-shipping-swiper"
            style={{ marginTop: '20px' }}
            modules={[Navigation]}
            loop
            onSwiper={(swiper) => { this.setState({ swiper });}}
            onSlideChange={(s) => {
              if (s.realIndex !== null) {
                this.setState({ sliderIndex: s.realIndex });
              }
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            <SwiperSlide key={1}>
              <SliderContent
                title="Same Day Delivery"
                titleStyle={{ backgroundColor: ORANGE }}
                image={sliderImages.sameDay}
                content={this.renderDeliveryContent("Same Day Delivery")}
              />
            </SwiperSlide>
            <SwiperSlide key={2}>
              <SliderContent
                title="Next Day Delivery"
                titleStyle={{ backgroundColor: GREEN }}
                image={sliderImages.nextDay}
                content={this.renderDeliveryContent("Next Day Delivery")}
              />
            </SwiperSlide>
            <SwiperSlide key={3}>
              <SliderContent
                title="Exclusive Offer"
                titleStyle={{ backgroundColor: ORANGE }}
                image={sliderImages.exclusive}
                content={(
                  <div>
                    <Highlight color={BLUE}>PRIORITY</Highlight> Members will have access to exclusive offers and deals.
                    <br />
                    <b>Benefits:</b>
                    <ul>
                      <li>Exclusive discounts, offers and more</li>
                      <li>Access to Same and Next Day Delivery to receive your tools faster</li>
                    </ul>
                    <Button
                      type="link"
                      style={{ padding: '0px', textAlign: 'start', whiteSpace: 'pre-wrap' }}
                      onClick={() => {
                        const elem = document.getElementById("filter-panel");

                        if (elem) {
                          const x = elem.offsetLeft - elem.scrollLeft;
                          const y = elem.offsetTop - elem.scrollTop;

                          window.scroll(x, y);
                        }
                      }}
                    >
                      <div>Explore the latest PRIORITY Deals <CaretRightOutlined /></div>
                    </Button>
                  </div>
                )}
              />
            </SwiperSlide>
            <SwiperSlide key={4}>
              <SliderContent
                title="Membership Perks"
                titleStyle={{ backgroundColor: ORANGE }}
                image={sliderImages.membershipPerks}
                content={(
                  <div>
                    Sydney Tools <Highlight color={BLUE}>PRIORITY</Highlight> Members will be the first to hear about the latest sales and deals.
                    <br />
                    {!email && (
                      <>
                        <span>Not a <Highlight color={BLUE}>PRIORITY</Highlight> Member yet? </span>
                        <Button type="link" style={{ padding: '0px' }} onClick={() => { this.handleModal(true); }}>
                          Click here
                        </Button>
                        <span> to sign up today!</span>
                      </>
                    )}
                  </div>
                )}
              />
            </SwiperSlide>
          </Swiper>
          <div
            className="swiper-button-next"
            style={{ position: 'relative', right: 'unset', margin: '0px 5px 0px 10px', '--swiper-navigation-size': '50px' }}
          />
        </div>
      </div>
    )
  }

  renderSignUpToday = () => {
    return (
      <Row style={{ marginTop: '55px' }}>
        <Col xs={24} sm={11} style={{ paddingRight: '10px' }}>
          <h4 id="sign-up-today">
            <b>Sign Up Today</b>
          </h4>
          For access to the Fastest Tool Delivery in Australia, sign up today for Sydney Tools <Highlight color={BLUE}>PRIORITY</Highlight>! Check out the latest offers from the Best Brands at the Best Prices.
        </Col>
        <Col xs={24} sm={13} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{
            maxWidth: '450px',
            width: '100%',
            height: '140px',
            padding: '10px',
            backgroundColor: BLUE,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
          >
            <div>
              <PriorityIcon style={{ fontSize: '34px' }} />
              <span style={{ color: 'white', fontSize: '32px', fontWeight: '900', marginLeft: '10px' }}>PRIORITY</span>
            </div>
            <Button
              style={{ width: '100%', height: '53px', fontSize: '130%', fontWeight: '700' }}
              onClick={() => { this.handleModal(true); }}
            >
              Click Here To Sign Up
            </Button>
          </div>
        </Col>
      </Row>
    )
  }

  renderFaqs = (faqs) => {
    if (faqs.length === 0) {
      return null;
    }

    return (
      <div style={{ marginTop: '55px' }}>
        <h4 id="frequently-asked-questions" ><b>Frequently Asked Questions</b></h4>
        {renderFaqs(faqs)}
      </div>
    )
  }

  renderTermsAndConditions = () => {
    return (
      <div style={{ marginTop: '55px' }}>
        <h4 id="terms-and-conditions" ><b>Terms & Conditions</b></h4>
        PRIORITY services is available on selected products and locations only. Orders that include non-PRIORITY products or exceeds 22kg will be split up. For full shipping terms and conditions <Link to={getTnCPath("shipping")}>click here.</Link>
      </div>
    )
  }

  render() {
    const { viewer } = this.props;
    const { header: headerImage } = sliderImages;

    const email = get(viewer, 'email');
    const faqs = get(viewer, 'faqs.edges', []);

    return (
      <div>
        <Row style={{ marginTop: '27px' }} gutter={[21, 0]}>
          <Col>
            <img className="img-fluid" alt={headerImage.name} src={`/static/images/priority/${headerImage.file}`} />

            {this.renderIntro()}
            {this.renderPriorityBenefits(email)}
            {!email && (
              this.renderSignUpToday()
            )}
            {this.renderFaqs(faqs)}
            {this.renderTermsAndConditions()}
            {!email && (
              <SignUpModal viewer={viewer} visible={this.state.visible} handleModal={this.handleModal} />
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default createFragmentContainer(PriorityPage, {
  viewer: graphql`
    fragment PriorityPage_viewer on Customer {
      id
      email
      faqs(first: 99, filterBy: [{ field: "name", filter: "[PRIORITY-SHIPPING]", filterType: "text", type: "contains" }]) {
        edges {
          node {
            id
            question
            answer
          }
        }
      }
    }
  `,
});
