import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Helmet from '~/components/page/Helmet';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const renderFaqs = (faqs) => (
  (faqs.length !== 0) && (
  <div>
    <Collapse accordion style={{backgroundColor: 'white', border: 'none'}}>
      {
      faqs.map(({node: faq}) => (
        <Panel header={faq.question} key={faq.id}>
          <div dangerouslySetInnerHTML={{__html: faq.answer}} />
        </Panel>
      ))
    }
    </Collapse>
  </div>
))

class Covid19Page extends React.Component {
  static propTypes = {
    viewer: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  };

  static defaultProps = {
    viewer: null,
  };

  render() {
    const { viewer: { cmsPage: page } } = this.props;
    const faqs  = get(this.props.viewer, 'faqs.edges', []);

    return (
      <div>
        <Helmet title="COVID-19" />
        <div dangerouslySetInnerHTML={{ __html: page.content }} />
        {renderFaqs(faqs)}
      </div>
    );
  }
}

export default createFragmentContainer(Covid19Page, {
  viewer: graphql`
    fragment Covid19Page_viewer on Customer {
      id
      cmsPage(urlSlug: "covid-19") {
        title
        urlSlug
        content
      }
      faqs(first: 99, filterBy: $filterBy) {
        edges {
          node {
            id
            question
            answer
          }
        }
      }
    }
  `,
});
