import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import Helmet from '~/components/page/Helmet';
import { Button, Form, Input, InputNumber } from 'antd';
import { get } from 'lodash';
import { VerifyOrderMutation } from './mutations';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
  },
};

class VerifyOrder extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    VerifyOrderMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      viewer: this.props.viewer,
    });
  }

  render() {
    const { viewer } = this.props;
    const order = get(viewer, 'salesOrders.edges[0].node', {});

    return (
      <div>
        <Helmet title="Order Verification" />
        <h1>Order Verification</h1>

        {!order.id &&
          <p>Order cannot be found. Please make sure the url is correct.</p>
        }

        {(order.verified === null || order.verified === 1) &&
          <p>Order #{order.name} has been verified, thank you very much.</p>
        }

        {order.verified === 3 && (
        <p>
          You have had 5 unsuccessful attempts for <b>order #{order.name}</b>.
          Please contact us for assistance.
        </p>
        )}

        {order.verified === 0 && (
        <div>
          <p>
            We have split your payment for <b>order #{order.name}</b> into two random amounts.
            You will need to check your bank statement or card holder to confirm the two amounts,
            and then add the two amounts in the form.
          </p>

          <Form ref={this.formRef} onFinish={this.handleSubmit}>
            <FormItem
              name="id"
              rules={[
                { required: true, message: 'Required' },
              ]}
              initialValue={order.id}
              hidden
            >
              <Input />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="First Amount"
              name="firstAmount"
              rules={[
                { required: true, message: 'Required' },
              ]}
            >
              <InputNumber style={{width: '100%'}} min={0.01} step={0.01} placeholder="First Amount" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Second Amount"
              name="secondAmount"
              rules={[
                { required: true, message: 'Required' },
              ]}
            >
              <InputNumber style={{width: '100%'}} min={0.01} step={0.01} placeholder="Second Amount" />
            </FormItem>

            <Button type="primary" htmlType="submit">
              Submit
            </Button>

          </Form>

          <br />

          <h2>Support Team</h2>
          <p>
            Please contact our support team if you encounter any problems.
          </p>

          <h2>One-off Security Check</h2>
          <p>
            You will no longer need to verify future orders after
            completing your first transaction.
          </p>

          <br />
          <h2>Instructions</h2>
          <img src="/assets/images/security_check/instructions.png" alt="Security Check Instructions" />
        </div>
        )}

      </div>
    );
  }
}

export default createFragmentContainer(VerifyOrder, {
  viewer: graphql`
    fragment VerifyOrder_viewer on Customer {
      salesOrders(first: 1, encryptedIds: $encryptedIds) {
        edges {
          node {
            id
            name
            verified
          }
        }
      }
    }
  `,
});
