import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Button, Col, Divider, Form, Modal, Radio, Row } from 'antd';
import { get } from 'lodash';

import { ChangeFreebiesMutation } from '../cart/mutations';
import { checkoutEnv } from '../checkout/helper';

const urlPrefix = "/product/";

const { Group: RadioGroup } = Radio;
const { Item: FormItem } = Form;

const radioStyle = {
      display: 'block',
    };

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

class FreebiePopup extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    cart: PropTypes.shape({
      freebies: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    handleChangeFreebies: PropTypes.func,
    visible: PropTypes.bool,
    line: PropTypes.shape({
      id: PropTypes.string,
      isFreebie: PropTypes.bool,
    }),
    selectedValue: PropTypes.string,
    onCancel: PropTypes.func,
    closable: PropTypes.bool,
  }

  static defaultProps = {
    cart: null,
    handleChangeFreebies: null,
    visible: null,
    line: null,
    selectedValue: null,
    onCancel: null,
    closable: true,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    const { visible } = this.props;

    this.state = {
      visible: (visible || false),
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.visible !== null) && this.props.visible !== prevProps.visible) {
      this.handleVisible(this.props.visible);
    }
  }

  getCorrespondingTier = (optionalFreebies, line) => {
    const freebiePId = line.product.id;

    const optfreebies = optionalFreebies.filter(({node}) => {
      const products = get(node.products, "edges", []);
      const productIds = products.map(x => x.node.id);

      return productIds.includes(freebiePId);
    });

    return optfreebies;
  }

  setSelectedValue = (item, selectedValue) => {
    const products = get(item.products, "edges", []);

    if (selectedValue) {
      const productIds = products.map(x => x.node.id);
      if (productIds.includes(selectedValue)) {
        return selectedValue;
      }
    }

    return products[0].node.id;
  }

  handleVisible = (visible) => {
    this.setState({
      visible
    });
  }

  handleOnclick = () => {
    this.setState({
      visible: true
    })
  }

  handleChangeFreebies = (freebieIds) => {
    const handleChangeFreebies = checkoutEnv.getFn('handleChangeFreebies');

    if (!Array.isArray(freebieIds)) {
      freebieIds = [freebieIds];
    }

    if (this.props.handleChangeFreebies) {
      this.props.handleChangeFreebies(freebieIds);
    } else if (handleChangeFreebies) {
      handleChangeFreebies(freebieIds);
    } else {
      ChangeFreebiesMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { freebieIds } },
        viewer: this.props.viewer,
      });
    }
  }

  handleOk = () => {
    const { current: form } = this.formRef;
    const formValues = form.getFieldValue("freebiePopup");

    this.handleChangeFreebies(formValues);
    form.resetFields();

    this.handleCancel();
  }

  handleCancel = () => {
    if (this.props.onCancel !== null) {
      this.props.onCancel();
    }

    this.setState({
      visible: false
    });
  }

  renderThumbnail = (product) => {
    const thumbnail = get(product, 'mainImage.url');
    const style = {width: '100%', maxWidth: '100px'};

    return (
      thumbnail ?
        <img style={style} alt={product.name} src={thumbnail} />
          : <img style={style} alt="Place Holder" src={IMAGE_PLACEHOLDER} />
    );
  }

  renderDivider = (i) => {
    if (i > 0) {
      return (
        <Divider style={{margin: '10px 0px'}} />
      )
    }
    return null;
  }

  renderModal = (optionalFreebies, closable, selectedValue) => {
    if (optionalFreebies && optionalFreebies.length > 0) {
      return (
        <div>
          <Modal
            title="Choose your Freebies"
            zIndex={1051}
            closable={closable}
            maskClosable={closable}
            keyboard={closable}
            visible={this.state.visible}
            footer={(
              <div style={{marginRight: '50px'}}>
                <Button key="ok" type="primary" onClick={this.handleOk}>
                  Confirm
                </Button>
                <Button key="cancel" disabled={!closable} onClick={this.handleCancel}>
                  Cancel
                </Button>
              </div>
            )}
          >
            <Form ref={this.formRef}>
              {optionalFreebies.map((item, i) => {
                const tier = item.node;
                const products = get(tier.products, "edges", []);

                return (
                  <div key={tier.id}>
                    {this.renderDivider(i)}
                    <h3>Freebie #{i+1} :</h3>
                    <FormItem
                      name={["freebiePopup", i]}
                      rules={[
                        { required: false, message: 'Required' },
                      ]}
                      initialValue={this.setSelectedValue(tier, selectedValue)}
                      style={{margin: '0px'}}
                    >
                      <RadioGroup
                        key={tier.id}
                        style={{display: 'block'}}
                      >
                        {products.map(({node}) => {
                          const url = `${urlPrefix}${node.urlSlug}`;
                          const { id } = node;

                          return (
                            <Radio key={id} value={id} style={radioStyle}>
                              <Row>
                                <Col xs={8} sm={6} >
                                  {this.renderThumbnail(node)}
                                </Col>
                                <Col xs={16} sm={18} >
                                  <a href={url} target="_blank" rel="noopener noreferrer">{node.name}</a>
                                </Col>
                              </Row>
                            </Radio>
                          )
                        })}
                      </RadioGroup>
                    </FormItem>
                  </div>
                )
              })}
            </Form>
          </Modal>
        </div>
      )
    }

    return null;
  }

  // default cart dropdown zindex is 1050
  render() {
    const { cart, line, selectedValue, closable } = this.props;
    const optFreebiesTiers = get(cart, 'freebies.edges', []);

    if (line && line.isFreebie) {
      const optFreebiesTier = this.getCorrespondingTier(optFreebiesTiers, line);
      const products = get(optFreebiesTier[0], "node.products.edges", []);

      return (
        <div>
          {closable && optFreebiesTier.length > 0 && products.length > 1 && (
            <div style={{fontSize: '12px', paddingLeft: '5px', lineHeight: '1' }}>
              <Button
                type="link"
                size="small"
                style={{fontSize: '12px'}}
                onClick={() => {
                  this.handleOnclick();
                }}
              >
                Change Freebie
              </Button>
            </div>
          )}

          {this.renderModal(optFreebiesTier, closable, selectedValue)}
        </div>
      )
    }

    return (
      <div>
        {this.renderModal(optFreebiesTiers, closable, selectedValue)}
      </div>
    )
  }
}

export default createFragmentContainer(FreebiePopup, {
  viewer: graphql`
    fragment FreebiePopup_viewer on Customer {
      id
    }
  `,
  cart: graphql`
    fragment FreebiePopup_cart on Cart {
      freebies(first: 99) {
        edges {
          node {
            id
            mode
            products(first: 99) {
              edges {
                node {
                  id
                  name
                  urlSlug
                  mainImage {
                    id
                    url
                    thumbnail
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
