import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation RemoveProductFromCartMutation($input: RemoveProductFromCartInput!) {
    removeProductFromCart(input:$input) {
      cart{
        ...CartFragment_cart @relay(mask: false)
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
