/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PriceChange_viewer$ref = any;
export type routes_PriceChange_QueryVariables = {||};
export type routes_PriceChange_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PriceChange_viewer$ref
  |}
|};
export type routes_PriceChange_Query = {|
  variables: routes_PriceChange_QueryVariables,
  response: routes_PriceChange_QueryResponse,
|};
*/


/*
query routes_PriceChange_Query {
  viewer {
    ...PriceChange_viewer
    id
  }
}

fragment PriceChange_viewer on Customer {
  priceChanges(date: "") {
    id
    name
    adenCode
    barcode
    price
    urlSlug
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PriceChange_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PriceChange_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_PriceChange_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "date",
                "value": ""
              }
            ],
            "concreteType": "PriceChange",
            "kind": "LinkedField",
            "name": "priceChanges",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adenCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "barcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlSlug",
                "storageKey": null
              }
            ],
            "storageKey": "priceChanges(date:\"\")"
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e8e46611506f25afe45bcd737efb301",
    "id": null,
    "metadata": {},
    "name": "routes_PriceChange_Query",
    "operationKind": "query",
    "text": "query routes_PriceChange_Query {\n  viewer {\n    ...PriceChange_viewer\n    id\n  }\n}\n\nfragment PriceChange_viewer on Customer {\n  priceChanges(date: \"\") {\n    id\n    name\n    adenCode\n    barcode\n    price\n    urlSlug\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '202007eedca540ac72a0b2862bdbc408';

module.exports = node;
