import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Alert, AutoComplete, Button, Col, message, Modal, Row } from 'antd';
import { sanitiseCharacters } from '~/helper';
import { GeocodeMutation, QueryProductStockMutation } from './mutations';
import {
  getDefaultSubrubPostcode,
  handleSearch,
  renderOption,
  saveDefaultSubrubPostcode,
  getDefaultDataSource,
} from './ShippingQuote';
import { renderStore } from '../store/StoreList';

class ProductStock extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      ac: PropTypes.string,
      backInStockDate: PropTypes.string,
      preorderDate: PropTypes.string,

    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      router: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const dataSource = [];
    const defaultSubrubPostcode = getDefaultSubrubPostcode() || {};

    const { latitude, longitude, selectedValue } = defaultSubrubPostcode || {};

    const { product } = props;

    let badStock = null;

    // AU requires Aden Code
    if (process.env.COUNTRY === 'AU' && !product.ac) {
      badStock = "this item";
    }

    if (defaultSubrubPostcode) {
      const item = getDefaultDataSource(defaultSubrubPostcode);
      dataSource.push(item);
    }

    this.state = {
      badStock,
      suburb: get(defaultSubrubPostcode, 'suburb'),
      city: get(defaultSubrubPostcode, 'city'),
      postcode: get(defaultSubrubPostcode, 'postcode'),
      latitude,
      longitude,
      dataSource,
      loading: false,
      stores: [],
      visible: false,
      notfound: false,
      selectedValue,
    };
  }

  onSearch = (value) => {
    value = this.sanitise(value);
    handleSearch.call(this, 'dataSource', value);
  };

  onSelect = (id, value, option, geocode = true) => {
    const { location, postcode, latitude, longitude } = option;

    if (process.env.COUNTRY === 'NZ' && geocode) {
      GeocodeMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { query: value }},
        viewer: this.props.viewer,
        onCompleted: (resp) => {
          const result = get(resp, 'geocode.result', {});

          this.handleQueryStock(id, value, {
            suburb: result.suburb,
            city: result.city,
            postcode: result.postcode,
            latitude: result.latitude,
            longitude: result.longitude,
          });
        },
      });
    } else if (process.env.COUNTRY === 'NZ') {
      this.handleQueryStock(id, value, { suburb: option.suburb, city: option.city, postcode, latitude, longitude });
    } else {
      this.handleQueryStock(id, value, { suburb: location, postcode, latitude, longitude });
    }
  };

  handleQueryStock = (id, value, item) => {
    this.setState({
      ...item,
      loading: true,
      notfound: false,
    }, () => {
      const { suburb, city, postcode, latitude, longitude } = this.state;
      if (process.env.COUNTRY === 'NZ') {
        saveDefaultSubrubPostcode({ suburb, city, postcode, latitude, longitude, selectedValue: value });
      } else {
        saveDefaultSubrubPostcode({ suburb, postcode, latitude, longitude, selectedValue: value });
      }
      this.queryProductStock(id);
    });
  }

  queryProductStock = (id) => {
    const { latitude, longitude } = this.state;

    if (!latitude || !longitude) {
      return;
    }

    QueryProductStockMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: { id, latitude, longitude, range: 250, } },
      viewer: this.props.viewer,
      onCompleted: (resp) => {
        const {result: stores} = resp.queryProductStock;
        if (stores) {
          this.setState({stores});
        }
        if (stores.length === 0) {
          this.setState({ notfound: true });
        }
        this.setState({ loading: false, visible: true });
      },
      onError: (errors) => {
        if (get(errors, '[0].code')) {
          message.error('Please slow down and try again later');
        }
        else if (get(errors, '[0].message')) {
          message.error(get(errors, '[0].message'));
        }

        this.setState({ loading: false });
      },
    });
  }

  sanitise = (value) => {
    value = sanitiseCharacters(value);

    this.setState({
      selectedValue: value
    });

    return value;
  }

  handleOk = () => {
    const { product } = this.props;
    const { selectedValue } = this.state;
    const data = {
      location: this.state.suburb,
      ...this.state,
    };

    this.onSelect(product.id, selectedValue, data, false);
  };

  handleCancel = ()  => {
    this.setState({
      visible: false,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });

    this.handleOk();
  };

  renderStore = (store) => (
    <div key={store.name}>
      {renderStore.call(this, store, true)}
      <hr />
    </div>
    )

  renderAutoComplete = (product) => (
    <AutoComplete
      allowClear
      autoComplete="googleignoreautofill"
      style={{ width: '100%' }}
      options={this.state.dataSource.map(renderOption)}
      onSearch={this.onSearch}
      value={this.state.selectedValue}
      onSelect={(value, option) => {
        value = this.sanitise(value);
        this.onSelect(product.id, value, get(option, 'item'));
      }}
      dropdownStyle={{ maxHeight: '160px' }}
      placeholder={<small>Enter a Suburb or Postcode</small>}
    />
  )

  render() {
    const { badStock, loading, notfound, stores, suburb } = this.state;
    const { product } = this.props;

    return (
      <div>
        <div style={{border: '1px solid rgba(0, 0, 0, 0.1)'}} >
          <Row style={{backgroundColor: '#f3f3f3', padding: '5px 15px'}}>
            <Col span={24}>
              <div style={{color: "#cb0000", fontWeight: 700, marginTop: '7px', fontSize: '14px'}}>
                <img src="/static/images/clickncollect.svg" alt="Check Stock" width="20" style={{marginRight: '5px'}} />
                Check Stock Availability
              </div>
            </Col>
            <Col span={24}>
              {this.renderAutoComplete(product)}
            </Col>
          </Row>
        </div>

        <Modal
          title="Find In Store"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk} disabled={badStock !== null}>
              Check
            </Button>,
          ]}
        >
          {badStock !== null && (
            <div>
              Stock check is not availabile for {badStock}
            </div>
          )}

          {badStock === null && (
            <div style={{maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden'}}>
              {this.renderAutoComplete(product)}
              <Alert type="warning" message="Please call ahead to confirm stock availability" />
              {stores.map(s => this.renderStore(s))}
              {notfound && <p>We are unable to locate stock near {suburb}. Please contact us for further assistance.</p>}
            </div>
          )}

        </Modal>
      </div>
    );
  }
}

export default createFragmentContainer(ProductStock, {
  viewer: graphql`
    fragment ProductStock_viewer on Customer {
      id
      email
      myStore {
        id
      }
    }
  `,
  product: graphql`
    fragment ProductStock_product on Product {
      id
      ac
      preorderDate
      backInStockDate
    }
  `,
});
