import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import { Form } from '@ant-design/compatible';
import { Button, Input, Modal, Select } from 'antd';
import { formItemLayout, getCountry, getDataSourceName, FormItemPostcode, FormItemRegion, FormItemStreet, FormItemSuburb } from '~/components/form';
import { getLabel } from '~/components/form/FormItemRegion';
import { handleSearch } from '~/components/product/ShippingQuote';
import { AddAddressMutation, UpdateAddressMutation } from './mutations';

const { Option } = Select;
const { Item: FormItem } = Form;

// FIXME this function is way too big and complicated due to shared by account and checkout
const renderAddressInputs = function renderAddressInputs({
  form, address, type
}) {
  const { relay } = this.props;
  const { getFieldDecorator } = form;

  const dataSourceName = getDataSourceName(type);
  const dataSource = this.state[dataSourceName];

  return (
    <div>
      <FormItem
        {...formItemLayout}
        label="First Name"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]firstname`, {
          rules: [
            { required: true, message: 'Required' },
          ],
          initialValue: get(address, 'firstname', ''),
        })(<Input placeholder="First Name" />)}
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Last Name"
        key="lastname"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]lastname`, {
          rules: [
            { required: true, message: 'Required' },
          ],
          initialValue: get(address, 'lastname', ''),
        })(<Input placeholder="Last Name" />)}
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Telephone"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]telephone`, {
          rules: [
            { type: "string", required: true, pattern: /^\+?[()+0-9 ext.]+$/, message: 'Phone Number Only' },
            { required: true, message: 'Required' },
          ],
          initialValue: get(address, 'telephone', ''),
        })(<Input placeholder="Telephone" />)}
      </FormItem>

      <FormItemStreet
        form={form}
        formItemLayout={formItemLayout}
        relay={relay}
        type={type}
        address={address}
        placeholder="Street No & Name"
        handleAddressSelect={(addressType, value, option) => {
          const { suburb, postcode } = option;

          this.setState({ suburb, postcode });
        }}
      />

      <FormItemSuburb
        form={form}
        formItemLayout={formItemLayout}
        type={type}
        address={address}
        dataSource={dataSource}
        dataSourceName={dataSourceName}
        handleSearch={this.handleSearch}
        handleAddressSelect={this.handleAddressSelect}
        handleAddressTyping={this.handleAddressTyping}
        placeholder="Suburb"
      />

      <FormItemPostcode
        form={form}
        formItemLayout={formItemLayout}
        type={type}
        address={address}
        dataSource={dataSource}
        dataSourceName={dataSourceName}
        handleSearch={this.handleSearch}
        handleAddressSelect={this.handleAddressSelect}
        handleAddressTyping={this.handleAddressTyping}
        placeholder="Postcode"
      />

      <FormItemRegion
        type={type}
        address={address}
        form={form}
        formItemLayout={formItemLayout}
        placeholder={getLabel()}
      />

      <FormItem
        {...formItemLayout}
        label="Country"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]country`, {
          rules: [
            { required: true, message: 'Required' },
          ],
          initialValue: getCountry(address),
        })(
          <Select placeholder="Country" disabled>
            <Option value="AU">Australia</Option>
            <Option value="NZ">New Zealand</Option>
          </Select>
        )}
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Company"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]company`, {
          rules: [
            { required: false, message: 'Required' },
          ],
          initialValue: get(address, 'company', ''),
        })(<Input placeholder="Company" />)}
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="Fax"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]fax`, {
          rules: [
            { required: false, message: 'Required' },
          ],
          initialValue: get(address, 'fax', ''),
        })(<Input placeholder="Fax" />)}
      </FormItem>

    </div>
  );
};

renderAddressInputs.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  address: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    telephone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    region: PropTypes.string,
    compnay: PropTypes.string,
    country: PropTypes.shape({
      alpha2: PropTypes.string,
    }),
    fax: PropTypes.string,
  }),
};
renderAddressInputs.defaultProps = {
  address: {},
}

const AddressForm = Form.create()(class extends React.Component {
  static displayName = 'AddressForm';
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      resetFields: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    updateMutation: PropTypes.shape({
      commit: PropTypes.func.isRequired,
    }),
    addressFormVisible: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      telephone: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      postcode: PropTypes.string,
      region: PropTypes.string,
      compnay: PropTypes.string,
      country: PropTypes.shape({
        alpha2: PropTypes.string,
      }),
      fax: PropTypes.string,
    }),
  };

  static defaultProps = {
    address: {},
    updateMutation: UpdateAddressMutation,
  };

  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      dataSource: [],
      suburb: null,
      postcode: null,
    };
    this.handleSearch = handleSearch.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    /* reset all fields upoon rendering,
     * otherwise all fields will use initial values
     * regardless what address it is showing
     */
    if (!nextProps.address || !this.props.address || (this.props.address && nextProps.address && nextProps.address.id !== this.props.address.id)) {
      if (!(nextProps.address === null && this.props.address === null)) {
        this.setState({
          suburb: get(nextProps.address, 'city'),
          postcode: get(nextProps.address, 'postcode'),
        });
        this.props.form.resetFields();
      }
    } else if (this.state.suburb === null && this.state.postcode === null) {
      this.setState({
        suburb: get(nextProps.address, 'city'),
        postcode: get(nextProps.address, 'postcode'),
      });
      this.props.form.resetFields();
    }
  }

  onCancel = (e) => {
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel(e);
    }
    this.props.form.resetFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.suburb) {
          values.address.city = this.state.suburb;
        }

        if (this.state.postcode) {
          values.address.postcode = this.state.postcode;
        }

        if (!this.props.address) {
          AddAddressMutation.commit({
            environment: this.props.relay.environment,
            variables: { input: values.address },
            viewer: this.props.viewer,
            onCompleted: this.props.onCancel,
          });
        } else {
          this.props.updateMutation.commit({
            environment: this.props.relay.environment,
            variables: { input: { ...values.address, id: this.props.address.id } },
            viewer: this.props.viewer,
            onCompleted: this.props.onCancel,
          });
        }
      }
    });
  }

  // handle suburb/postcode selection
  handleAddressSelect = (addressType, value, option) => {
    const selection = option.item;
    const { location: suburb, postcode, state } = selection;

    this.props.form.setFieldsValue({
      [`${addressType}[]city`]: suburb,
      [`${addressType}[]postcode`]: postcode,
      [`${addressType}[]region`]: state,
    });
    this.setState({
      suburb,
      postcode,
    });
  }

  render() {
    const { form } = this.props;

    return (
      <div>
        <Modal
          visible={this.props.addressFormVisible}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Form onSubmit={this.handleSubmit}>
            {renderAddressInputs.call(this, { form, address: this.props.address, type: 'address' })}
            <Button size="large" onClick={this.onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" size="large">Submit</Button>
          </Form>
        </Modal>
      </div>
    );
  }
});


module.exports = {
  AddressForm,
  renderAddressInputs,
};
