import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, Form, message } from 'antd';

import { ResetPasswordMutation } from './mutations';

const FormItem = Form.Item;

export default class ResetPassword extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    formRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({}),
    ]).isRequired,
    field: PropTypes.arrayOf(PropTypes.string).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    size: PropTypes.string,
  }

  static defaultProps = {
    size: "large",
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { formRef, field } = this.props;
    const { current: form } = formRef;

    form.validateFields([field]).then((values) => {
      const email = get(values, field);

      ResetPasswordMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { email } },
        viewer: this.props.viewer,
        onCompleted: (resp) => {
          this.setState({loading: false});
          if (resp.resetPassword.result) {
            message.success('Check your email for a link to reset your password. If it does not appear within a few minutes, check your spam folder.', 10);
          }
        },
        onError: (errors) => {
          message.error(errors[0].message);
          this.setState({loading: false});
        }
      });
    }).catch(() => {});
  }

  render() {
    const { size } = this.props;
    const { loading } = this.state;

    return (
      <FormItem>
        <Button
          style={{ width: '100%' }}
          type="primary"
          htmlType="submit"
          loading={loading}
          size={size}
          onClick={this.handleSubmit}
        >
          Reset Password
        </Button>
      </FormItem>
    );
  }
}
