/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AusPostDamagedForm_viewer$ref = any;
export type routes_AusPostDamagedForm_QueryVariables = {||};
export type routes_AusPostDamagedForm_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AusPostDamagedForm_viewer$ref
  |}
|};
export type routes_AusPostDamagedForm_Query = {|
  variables: routes_AusPostDamagedForm_QueryVariables,
  response: routes_AusPostDamagedForm_QueryResponse,
|};
*/


/*
query routes_AusPostDamagedForm_Query {
  viewer {
    ...AusPostDamagedForm_viewer
    id
  }
}

fragment AusPostDamagedForm_viewer on Customer {
  id
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AusPostDamagedForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AusPostDamagedForm_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_AusPostDamagedForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cca7308616dcb751c1a5fadcd62362c",
    "id": null,
    "metadata": {},
    "name": "routes_AusPostDamagedForm_Query",
    "operationKind": "query",
    "text": "query routes_AusPostDamagedForm_Query {\n  viewer {\n    ...AusPostDamagedForm_viewer\n    id\n  }\n}\n\nfragment AusPostDamagedForm_viewer on Customer {\n  id\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '84dbd0c339392842a8d3ae888b45fc95';

module.exports = node;
