/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Covid19Page_viewer$ref: FragmentReference;
declare export opaque type Covid19Page_viewer$fragmentType: Covid19Page_viewer$ref;
export type Covid19Page_viewer = {|
  +id: string,
  +cmsPage: ?{|
    +title: ?string,
    +urlSlug: ?string,
    +content: ?string,
  |},
  +faqs: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +question: ?string,
        +answer: ?string,
      |}
    |}>
  |},
  +$refType: Covid19Page_viewer$ref,
|};
export type Covid19Page_viewer$data = Covid19Page_viewer;
export type Covid19Page_viewer$key = {
  +$data?: Covid19Page_viewer$data,
  +$fragmentRefs: Covid19Page_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterBy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Covid19Page_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "urlSlug",
          "value": "covid-19"
        }
      ],
      "concreteType": "CmsPage",
      "kind": "LinkedField",
      "name": "cmsPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": "cmsPage(urlSlug:\"covid-19\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 99
        }
      ],
      "concreteType": "FaqConnection",
      "kind": "LinkedField",
      "name": "faqs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FaqEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Faq",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "question",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "answer",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4eb1da1bcb90e850b7d4ff3d8c7e8b9';

module.exports = node;
