import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { Button, Input, message } from 'antd';

import { setOperator } from './Pos';

import {
  PosLoginMutation,
} from './mutations';

const { Item: FormItem } = Form;

class PosLogin extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      validateFieldsAndScroll: PropTypes.func.isRequired,
      resetFields: PropTypes.func.isRequired,
      getFieldValue: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        PosLoginMutation.commit({
          environment: this.props.relay.environment,
          variables: { input: values },
          viewer: this.props.viewer,
          onCompleted: ({posLogin}) => {
            const { operator } = posLogin;
            setOperator(operator);

            this.setState({loading: false});

            this.props.router.push('/pos');
          },
          onError: (errors) => {
            message.error(errors[0].message);
            this.setState({loading: false});
          }
        });
      }
    });
  }

  render() {
    const { form: { getFieldDecorator } } = this.props;

    return (
      <div>
        <h1>POS</h1>

        <Form onSubmit={this.handleSubmit} style={{textAlign: "center"}} layout="inline">
          <FormItem>
            {getFieldDecorator('username', {
               rules: [
                 { required: true, message: 'Required' },
               ],
             })(<Input placeholder="Aden Username" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password', {
               rules: [
                 { required: true, message: 'Required' },
               ],
             })(<Input placeholder="Aden Password" type="password" />)}
          </FormItem>
          <Button type="primary" size="large" loading={this.state.loading} htmlType="submit">Login</Button>
        </Form>
      </div>
    );
  }
}

export default createFragmentContainer(Form.create()(PosLogin), {
  viewer: graphql`
    fragment PosLogin_viewer on Customer {
      id
    }
  `,
});
