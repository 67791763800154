import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';

const urlPrefix = "/product/";

class Freebie extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      price: PropTypes.number,
      freebies: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
  }

  // mode all = 0, one = 1
  freebieList = (freebies, price) => freebies.map(({node: f}) => {
    const tier =  f.tiers.edges.find(({node: t}) => price >= t.price);

      const mode = get(tier, 'node.mode');

      if (tier) {
        if (mode === 0 || mode === null) {
          return tier.node.products.edges.map(({node: p}) => {
            const url = `${urlPrefix}${p.urlSlug}`;

            return (
              <div key={p.id}>
                {tier.node.quantity} x <a href={url} target="_blank" rel="noopener noreferrer">{p.name}</a>
              </div>
            )
          })
        } else if (mode === 1) {
          return (
            <div key={f.id}>
              <h3>
                Optional
                <span style={{fontSize: '12px', whiteSpace: 'nowrap'}}> (Select One of the following at Checkout)</span>
              </h3>

              {tier.node.products.edges.map(({node: p}) => {
                const url = `${urlPrefix}${p.urlSlug}`;

                return (
                  <div key={p.id}>
                    {tier.node.quantity} x <a href={url} target="_blank" rel="noopener noreferrer">{p.name}</a>
                  </div>
                )
              })}
            </div>
          )
        }
      }
      return null;
    }).filter(i => i)

  render() {
    const { product } = this.props;
    const { price } = product;
    const freebies = get(product, 'freebies.edges', []);

    const list = this.freebieList(freebies, price);

    if (!product) {
      return null;
    }

    return (
      <div>
        {list.length > 0 && (
          <div>
            <h2>Free</h2>
            {list}
          </div>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(Freebie, {
  product: graphql`
    fragment Freebie_product on Product {
      price
      freebies(first: 99) {
        edges {
          node {
            id
            tiers(first: 99) {
              edges{
                node {
                  id
                  price
                  quantity
                  mode
                  products(first: 99) {
                    edges {
                      node {
                        id
                        name
                        urlSlug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
