import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, startCase } from 'lodash';
import { showFiltedCategory } from './Breadcrumb';
import { shouldAppendIndex } from './helper';

export default class HeadingTitle extends React.Component {
  static propTypes = {
    category: PropTypes.shape({
      urlSlug: PropTypes.string,
    }).isRequired,
    subcategory: PropTypes.shape({
      urlSlug: PropTypes.string,
    }),
    brand: PropTypes.shape({
      name: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }

  static defaultProps = {
    subcategory: {},
    brand: {},
    location: {},
  }

  getHeadingTitle = (category, subcategory, brand, location) => {
    const { pathname, query } = location;
    let title = [];

    if (!isEmpty(brand)) {
      title.push(brand.name);
    }

    if (get(subcategory, 'urlSlug')) {
      const paths = pathname.split('/').filter(p => p);
      if (!pathname.includes('by-brand') && paths.length === 4) {
        title.push(startCase(paths[2]));
      } else if (pathname.includes('by-brand') && paths.length === 5) {
        title.push(startCase(paths[3]));
      }
      title.push(subcategory ? subcategory.name : startCase(subcategory.urlSlug));
    }

    // get subcategory from filters
    const f = showFiltedCategory(subcategory, location);
    if (f) {
      title.push(f);
    }

    if (title.length > 0 && category.urlSlug !== 'by-brand') {
      title.unshift(category.name);
    }
    else if (title.length === 0) {
      title = [category.name];
    }

    const key = shouldAppendIndex(category, query);
    if (key) {
      const name = `${key} ${get(subcategory, 'name', '')}`;
      title.push(name);
    }
    return title.join(' / ');
  }

  render() {
    const {
      category,
      subcategory,
      brand,
      location,
    } = this.props;

    return (
      <h1>{this.getHeadingTitle(category, subcategory, brand, location)}</h1>
    );
  }
}
