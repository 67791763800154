import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';

import { Alert, Form, Input, message } from 'antd';
import 'antd/lib/alert/style';
import { formItemLayout } from '~/components/form';

import { ResetPassword } from '~/components/auth';
import { PasswordResetMutation } from '../mutations';
import PasswordForm from './PasswordForm';

const FormItem = Form.Item;

class PasswordReset extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      canResetPassword: PropTypes.bool,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    const { token } = props.match.params;

    this.token = token;
  }

  handleSubmit = (values) => {
    const input = {
      token: this.token,
      password: values.newPassword,
    };
    PasswordResetMutation.commit({
      environment: this.props.relay.environment,
      variables: { input },
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success("Your password has been reset.")
        this.props.router.push('/');
      }
    });
  }

  render() {
    const { relay, viewer } = this.props;

    return (
      <div>
        <Helmet title="Forgot your password? - Sydney Tools" />
        <h1>Reset your password</h1>

        {viewer.canResetPassword && (
          <PasswordForm formRef={this.formRef} onSubmit={this.handleSubmit} />
        )}

        {!viewer.canResetPassword && (
        <Form ref={this.formRef}>
          <Alert message="It looks like you clicked on an invalid password reset link. Please try again" type="error" />
          <p>
            Please enter your email address and we will send you a password reset link.
          </p>
          <FormItem
            {...formItemLayout}
            name={["resetPassword", "email"]}
            rules={[
              { transform: (value) => value.trim() },
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email' },
            ]}
          >
            <Input autoComplete="username" placeholder="Email" />
          </FormItem>
          <ResetPassword formRef={this.formRef} field={["resetPassword", "email"]} viewer={viewer} relay={relay} />
        </Form>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(PasswordReset, {
  viewer: graphql`
    fragment PasswordReset_viewer on Customer @argumentDefinitions(
      passwordResetToken: {type: "String"},
    ) {
      canResetPassword(passwordResetToken: $token)
    }
  `,
});
