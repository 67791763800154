import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import moment from 'moment';
import { Link } from 'found';
import { Table } from 'antd';

const columns = [
  {
    title: 'Name',
    dataIndex: ['node', 'name'],
    width: '30%',
    render: function OrderName(name, {node}) { return <Link to={`/pos/order/${node.id}`}>{name}</Link>; }
  },
  {
    title: 'Email',
    dataIndex: ['node', 'email'],
  },

  {
    title: 'Firstname',
    dataIndex: ['node', 'firstname'],
  },
  {
    title: 'Lastname',
    dataIndex: ['node', 'lastname'],
  },
  {
    title: 'Grand Total',
    dataIndex: ['node', 'grandTotal'],
    render: (v) => `$${v}`
  },
  {
    title: 'Operator',
    dataIndex: ['node', 'operator'],
  },
  {
    title: 'Store',
    dataIndex: ['node', 'store', 'name'],
  },
  {
    title: 'Placed At',
    dataIndex: ['node', 'insertedAt'],
    render: (v) => moment(v).format('DD/MM/YYYY hh:mm:ss'),
  },
];

class PosOrderList extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesPosOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
  }

  render() {
    const { salesPosOrders: { edges } } = this.props.viewer;

    return (
      <div>
        <h1>All POS Orders</h1>

        <Table
          columns={columns}
          dataSource={edges}
          rowKey={({ node })=> node.id}
          pagination={false}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <b>Total Orders: {edges.length}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
    );
  }
}

export default createFragmentContainer(PosOrderList, {
  viewer: graphql`
    fragment PosOrderList_viewer on Customer {
      salesPosOrders(first: 9999, storeIds: $storeIds) {
        edges {
          node {
            id
            name
            firstname
            lastname
            email
            grandTotal
            operator
            store {
              id
              name
            }
            insertedAt
          }
        }
      }
    }
  `,
});
