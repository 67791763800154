import React from 'react';
import PropTypes from 'prop-types';

const YouTubeVideo = (props) => {
  const { link } = props;

  return (
    <div className="embed-responsive embed-responsive-16by9" style={{ marginBottom: '10px' }}>
      <iframe
        title={link}
        className="embed-responsive-item"
        width="560"
        height="315"
        src={`//www.youtube.com/embed/${link}`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  )
}

YouTubeVideo.propTypes = {
  link: PropTypes.string.isRequired,
}

export default YouTubeVideo;
