import React from 'react';

import PropTypes from 'prop-types';
import { flattenDeep, get, uniqWith} from 'lodash';

import { PhotoSwipe } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import MediaQuery from 'react-responsive';

import { Col, Row } from 'antd';
import 'antd/lib/carousel/style';

import { ProductLabel } from './ProductLabel';
import Thumbnail from './media/Thumbnail';
import Dots from './media/Dots';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

export default class Media extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.object),
      bundles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      model: PropTypes.string.isRequired,
    }).isRequired,
    selectedProduct: PropTypes.shape({
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.object),
    }),
  }

  static defaultProps = {
    selectedProduct: null,
  }

  constructor(props) {
    super(props);

    this.mousePosition = [];

    this.params = {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      getSwiper: this.getGallerySwiper
    };

    this.paramsThumb = {
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      slideToClickedSlide: true,
      containerModifierClass: "swiper-container-thumb-",
      getSwiper: this.getThumbnailSwiper
    };

    this.state = {
      media: this.initImages(props.product),
      gallerySwiper: null,
      thumbnailSwiper: null,
      isOpen: false,
      options: {
        closeOnScroll: false,
        shareEl: false,
        history: false,
        galleryPIDs: false
      },
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
      const { gallerySwiper, thumbnailSwiper } = nextState

      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }

  getBundleImages = (product) => {
    if (product.type === 'bundle' && product.bundles) {
      const childrenImages = product.bundles.edges.map((child) => {
        if (!child.node.images) return null;
        return child.node.images.map(image => ({
          thumbnail: image.thumbnail,
          src: image.url,
          w: 1000,
          h: 1000,
        }));
      }).filter(i => i);
      return childrenImages;
    }

    return [];
  }

  getVideos = (product) => {
    const videos =  (get(product, 'videos', []) || []).map((i) => ({
        id: i,
        thumbnail: (
          <div style={{ position: 'relative', marginTop: '5px', marginBottom: '5px' }}>
            <img alt="Play" className="absolute-center" src="/static/images/yt_play_btn.png" />
            <img alt="Poster" className="img-fluid" src={`//img.youtube.com/vi/${i}/hqdefault.jpg`} />
          </div>
        ),
        src: (
          <div className="embed-responsive" style={{ paddingBottom: '100%' }}>
            <iframe title={i} style={{ zIndex: 1 }} width="100%" height="100%" src={`//www.youtube.com/embed/${i}`} frameBorder="0" allowFullScreen />
          </div>
        ),
        w: 1000,
        h: 1000,
      }));

    return videos;
  }

  getGallerySwiper = (gallerySwiper) => {
    this.setState({ gallerySwiper });
  }

  getThumbnailSwiper = (thumbnailSwiper) => {
    this.setState({ thumbnailSwiper });
  }

  initImages = (product) => {
    let mainImage = get(product, 'mainImage');
    const images = get(product, 'images', []) || [];

    this.images = images.map(i => ({
      thumbnail: i.thumbnail,
      src: i.url,
      w: 1000,
      h: 1000,
    }));

    if (mainImage) {
      mainImage = {
        thumbnail: mainImage.thumbnail,
        src: mainImage.url,
        w: 1000,
        h: 1000,
      };
      this.images = this.images.filter(i => i.src !== mainImage.src);
      this.images.unshift(mainImage);
    }

    const childrenImages = this.getBundleImages(product);
    this.images = this.images.concat(flattenDeep(childrenImages));
    this.images = this.removeDuplicateImages(this.images);

    // clone images to media which holds videos as well
    const media = this.images.slice();

    this.getVideos(product).forEach((v) => {
      media.splice(1, 0, v);
    });

    return media;
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  }

  removeDuplicateImages = (imagesList) => uniqWith(imagesList, (a, b) => {
    if (a.id && b.id) {
      return a.id === b.id;
    } else if (a.thumbnail && b.thumbnail) {
      return a.thumbnail === b.thumbnail;
    }
    return false;
  })

  render() {
    const { product, selectedProduct } = this.props;

    let { media } = this.state;

    if (selectedProduct && selectedProduct.images.length > 0) {
      media = this.initImages(selectedProduct).concat(media);

      media = this.removeDuplicateImages(media);
    }

    return (
      <div>
        <PhotoSwipe
          isOpen={this.state.isOpen}
          items={media}
          options={this.state.options}
          onClose={this.handleClose}
        />

        <CarouselProvider
          naturalSlideWidth={400}
          naturalSlideHeight={400}
          totalSlides={media.length}
          className="ant-carousel"
        >
          <Row gutter={12}>
            <Col xs={0} sm={0} md={5} id="image-thumbnails">
              <Thumbnail product={product} thumbnails={media} />
            </Col>

            <Col xs={24} sm={24} md={17} id="image-slider">
              <div style={{position: 'relative'}}>
                <ProductLabel product={product} selectedProduct={selectedProduct} />

                <Slider classNameAnimation="none">
                  {media.map((i, index) => (
                    <Slide index={index} key={i.id || i.src}>
                      {i.src && React.isValidElement(i.src) &&
                       i.src
                      }

                      {i.src && !React.isValidElement(i.src) && (
                        <div
                          role="presentation"
                          onMouseUp={e => {
                            const [clientX, clientY] = this.mousePosition;
                            if (clientX === e.clientX && clientY === e.clientY) {
                              const { options } = this.state;
                              options.index = index;
                              this.setState({
                                isOpen: true,
                                options,
                              });
                            }
                          }}
                          onMouseDown={e => {
                            this.mousePosition = [e.clientX, e.clientY];
                          }}
                        >
                          <img alt={product.model} className="img-fluid" src={i.src} />
                        </div>
                      )}
                    </Slide>
                  ))}
                </Slider>
              </div>

              <MediaQuery maxWidth={767}>
                <Dots />
              </MediaQuery>

            </Col>
          </Row>
        </CarouselProvider>

        {!this.images.length &&
          <img alt="Place Holder" src={IMAGE_PLACEHOLDER} />
        }

      </div>
    );
  }
}
