/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type WarrantyForm_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type routes_WarrantyForm_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>
|};
export type routes_WarrantyForm_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: WarrantyForm_viewer$ref
  |}
|};
export type routes_WarrantyForm_Query = {|
  variables: routes_WarrantyForm_QueryVariables,
  response: routes_WarrantyForm_QueryResponse,
|};
*/


/*
query routes_WarrantyForm_Query(
  $filterBy: [FilterBy]
) {
  viewer {
    ...WarrantyForm_viewer
    id
  }
}

fragment WarrantyForm_viewer on Customer {
  id
  stores(first: 999, filterBy: $filterBy) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_WarrantyForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WarrantyForm_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_WarrantyForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 999
              }
            ],
            "concreteType": "StoreConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "298e916a21d8fd25e6dc325369ee3581",
    "id": null,
    "metadata": {},
    "name": "routes_WarrantyForm_Query",
    "operationKind": "query",
    "text": "query routes_WarrantyForm_Query(\n  $filterBy: [FilterBy]\n) {\n  viewer {\n    ...WarrantyForm_viewer\n    id\n  }\n}\n\nfragment WarrantyForm_viewer on Customer {\n  id\n  stores(first: 999, filterBy: $filterBy) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5aa98dfad1cad279437875adf6a70de';

module.exports = node;
