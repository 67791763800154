import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';

const { Option } = Select;
const { Item: FormItem } = Form;

export const getLabel = () => {
  if (process.env.COUNTRY === 'NZ') {
    return "Town / City";
  }

  return "State";
}

class FormItemRegion extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    formItemLayout: PropTypes.shape({}),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      telephone: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      postcode: PropTypes.string,
      region: PropTypes.string,
      compnay: PropTypes.string,
      country: PropTypes.shape({
        alpha2: PropTypes.string,
      }),
      fax: PropTypes.string,
    }),
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    formItemLayout: {},
    disabled: false,
    type: null,
    address: {},
    placeholder: null,
  }

  renderInput = () => {
    const { disabled, placeholder } = this.props;

    if (process.env.COUNTRY === 'NZ') {
      return (
        <Input placeholder={placeholder} />
      )
    }

    return (
      <Select
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        placeholder={placeholder}
        disabled={disabled}
      >
        <Option value="ACT">ACT</Option>
        <Option value="NSW">NSW</Option>
        <Option value="NT">NT</Option>
        <Option value="QLD">QLD</Option>
        <Option value="SA">SA</Option>
        <Option value="TAS">TAS</Option>
        <Option value="VIC">VIC</Option>
        <Option value="WA">WA</Option>
      </Select>
    )
  }

  render() {
    const { form, formItemLayout, type, address } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FormItem
        {...formItemLayout}
        label={getLabel()}
        hasFeedback
      >
        {getFieldDecorator(`${type}[]region`, {
          rules: [
            { required: true, message: 'Required' },
          ],
          initialValue: get(address, 'region', undefined),
        })(
          this.renderInput()
        )}
      </FormItem>
    )
  }
}

export default FormItemRegion;
