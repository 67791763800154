import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row } from 'antd';
import { get } from 'lodash';

import { getStoreId } from '../pos/Pos';

import './style.css';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

const openCartToast = (product, { router } = {}) => {
  notification.destroy();

  const imageSrc = get(product, 'mainImage.thumbnail', IMAGE_PLACEHOLDER);
  const key = product.id;

  const toCheckout = () => {
    if (router) {
      notification.destroy();
      const path = getStoreId() ? '/pos' : '/checkout';
      router.push(path);
    }
  };

  const description = (
    <div style={{ marginTop: '22px' }}>
      <Row type="flex" align="middle">
        <Col span={10}>
          <img
            src={imageSrc}
            style={{ maxWidth: "90px" }}
            className="img-fluid"
            title={product.name}
            alt={product.name}
          />
        </Col>
        <Col span={14}>
          <div style={{ marginLeft: "10px" }}>
            <p style={{ fontSize: "11px", fontWeight: "700", height: "45px", overflow: "hidden", lineHeight: "1.5" }}>
              {product.name}
            </p>
            <div style={{ textAlign: "center", color: "green", fontSize: "13px", fontWeight: "700" }}>
              <span>
                <CheckCircleOutlined /> ADDED!
              </span>
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }} >
              {router && (
              <Button type="primary" style={{ fontWeight: "800" }} onClick={toCheckout} >
                Checkout
              </Button>
            )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  notification.open({
    description,
    key,
    style: { padding: "0 12px 12px 12px", width: 'auto' },
    getContainer: () => document.getElementById("cart-toast") || document.body,
    duration: 2.5,
  });
}

module.exports = {
  openCartToast
};
