/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PostCareerFormInput = {|
  address: string,
  availability: string,
  comments?: ?string,
  email: string,
  employment: string,
  location: string,
  name: string,
  resume: $ReadOnlyArray<?any>,
  role: string,
  telephone: string,
|};
export type PostCareerFormMutationVariables = {|
  input: PostCareerFormInput
|};
export type PostCareerFormMutationResponse = {|
  +postCareerForm: ?{|
    +result: ?boolean
  |}
|};
export type PostCareerFormMutation = {|
  variables: PostCareerFormMutationVariables,
  response: PostCareerFormMutationResponse,
|};
*/


/*
mutation PostCareerFormMutation(
  $input: PostCareerFormInput!
) {
  postCareerForm(input: $input) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PostCareerFormPayload",
    "kind": "LinkedField",
    "name": "postCareerForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostCareerFormMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostCareerFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3bb3e9c753eed760b79c841fff08f3f0",
    "id": null,
    "metadata": {},
    "name": "PostCareerFormMutation",
    "operationKind": "mutation",
    "text": "mutation PostCareerFormMutation(\n  $input: PostCareerFormInput!\n) {\n  postCareerForm(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ef0c8f1e751a02146df7d6a6b3178be';

module.exports = node;
