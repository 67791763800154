import React from 'react';
import PropTypes from 'prop-types';

import { get, uniq, values } from 'lodash';
import { Form, Select } from 'antd';

const { Option } = Select;

export default class SelectConfigProduct extends React.Component {
  static propTypes = {
    selectedProduct: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    updater: PropTypes.func,
  }

  static defaultProps = {
    updater: null,
  }

  constructor(props) {
    super(props);

    const { selectedProduct, product: parent } = this.props;

    this.formRef = React.createRef();

    this.state = {
      select: this.getSelect(selectedProduct, parent),
      selectOptions: this.getSelectOptions(parent),
      selectValid: !!selectedProduct,
    };
  }

  getSelect = (selectedProduct, product) => {
    const configAttr = get(product, 'configurableAttributes', []);
    const initProdOpts = {};

    configAttr.forEach((attr) => {
      initProdOpts[attr.code] = this.getAttribute(selectedProduct.attributes[attr.code]);
    })

    return initProdOpts;
  }

  getSelectOptions = (product) => {
    const configAttr = get(product, 'configurableAttributes', []);
    const childProducts = get(product, 'configurables.edges', []);

    const selectOptions = {};
    configAttr.forEach((attr) => {
      const opts = uniq(childProducts.filter(({node}) =>
          Object.prototype.hasOwnProperty.call(node.attributes, `${attr.code}`)
        ).map(({node}) =>
          this.getAttribute(node.attributes[attr.code])
        )
      );
      selectOptions[attr.code] = opts;
    });

    return selectOptions;
  }

  // If the child product attribute are in an array,
  // get the 1st element, return as string.
  getAttribute = (attr) => {
    if (Array.isArray(attr)) {
      return get(attr, '[0]', '');
    }
    return attr;
  }

  updateProdView = (updater, product, valid) => {
    this.setState({selectValid: valid})
    updater(product, valid);
  }

  updateProduct = (product, updater, found) => {
    const configAttr = get(product, 'configurableAttributes', []);
    const { select } = this.state;
    const selectLength = Object.keys(select).length;

    // make sure all select options are selected
    if (found.length === 1 && selectLength === configAttr.length && typeof updater === 'function') {
      this.updateProdView(updater, found[0].node, true);
    } else {
      this.updateProdView(updater, null, false);
    }
  }

  filterByProd = (products, key, value) => products.filter(({node}) => {
    const { attributes } = node;
    return (this.getAttribute(attributes[key]) === value);
  })

  findProducts = (products, conds) => {
    if (Object.keys(conds).length === 0) {
      return products;
    }

    const k = Object.keys(conds)[0];
    // Object.values() is too new
    // https://caniuse.com/object-values
    const v = values(conds)[0];

    const nps = this.filterByProd(products, k, v);

    const newConds = Object.assign({}, conds);
    delete newConds[k];

    return this.findProducts(nps, newConds);
  }

  handleOnChange = (product, updater, attr, value) => {
    const configs = get(product, 'configurables.edges', []);

    const { select } = this.state;
    select[attr.code] = value;

    const found = this.findProducts(configs, select);

    if (found.length === 0) {
      delete select[attr.code];
    } else {
      select[attr.code] = value;
    }

    this.setState({select}, () => {
      this.updateProduct(product, updater, found);
    });
  }

  render() {
    const { product, updater } = this.props;

    if (product.type === 'configurable') {
      const configAttr = get(product, 'configurableAttributes', []);

      return (
        <Form ref={this.formRef}>
          <Form.Item
            validateStatus={this.state.selectValid ? 'success' : 'error'}
            help={this.state.selectValid ? null : "Sorry, no product is currently available with this selection."}
          >
            { configAttr.map((attr) => {
              const options = this.state.selectOptions[attr.code];

              return (
                <div key={attr.name}>
                  <Form.Item
                    name={attr.name}
                    label={attr.name}
                    initialValue={this.state.select[attr.code]}
                    style={{margin: '0px'}}
                  >
                    <Select
                      placeholder={`Pick a ${attr.name}`}
                      style={{width: '100%'}}
                      onChange={(value) => {
                        this.handleOnChange(product, updater, attr, value);
                      }}
                    >
                      {
                        options.map(item => (
                          <Option key={item} value={item}>{item}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              )
            }) }
          </Form.Item>
        </Form>
      )
    }
    return null;
  }
}
