/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountForm_viewer$ref: FragmentReference;
declare export opaque type AccountForm_viewer$fragmentType: AccountForm_viewer$ref;
export type AccountForm_viewer = {|
  +email: ?string,
  +firstname: ?string,
  +lastname: ?string,
  +isPasswordSecure: ?boolean,
  +subscription: ?{|
    +status: ?number
  |},
  +$refType: AccountForm_viewer$ref,
|};
export type AccountForm_viewer$data = AccountForm_viewer;
export type AccountForm_viewer$key = {
  +$data?: AccountForm_viewer$data,
  +$fragmentRefs: AccountForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPasswordSecure",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7ddceccae98370c9416403d5e4d5bbba';

module.exports = node;
