import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Helmet from '../page/Helmet';
import Breadcrumb from '../page/Breadcrumb';
import HeadingTitle from '../page/HeadingTitle';

export default class PreviewDescription extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
      }).isRequired,
    }).isRequired,
  }

  render() {
    const { viewer, match: {location} } = this.props;
    const category = get(viewer, 'categories.edges[0].node', {});
    const subcategory = get(category, 'catalogues.subcategory', {});
    const brand = get(category, 'catalogues.brand', {});
    const previewDescription = get(category, 'previewDescription');

    return (
      <div>
        <Helmet category={category} subcategory={subcategory} brand={brand} location={location} />
        <Breadcrumb category={category} subcategory={subcategory} location={location} brand={brand} />
        <HeadingTitle category={category} subcategory={subcategory} brand={brand} location={location} />

        <div dangerouslySetInnerHTML={{ __html: previewDescription }} />
      </div>
    )
  }
}
