/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedbackPage_viewer$ref: FragmentReference;
declare export opaque type FeedbackPage_viewer$fragmentType: FeedbackPage_viewer$ref;
export type FeedbackPage_viewer = {|
  +stores: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +cmsPage: ?{|
    +title: ?string,
    +urlSlug: ?string,
    +content: ?string,
  |},
  +$refType: FeedbackPage_viewer$ref,
|};
export type FeedbackPage_viewer$data = FeedbackPage_viewer;
export type FeedbackPage_viewer$key = {
  +$data?: FeedbackPage_viewer$data,
  +$fragmentRefs: FeedbackPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "urlSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "StoreConnection",
      "kind": "LinkedField",
      "name": "stores",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Store",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "stores(first:100)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "urlSlug",
          "variableName": "urlSlug"
        }
      ],
      "concreteType": "CmsPage",
      "kind": "LinkedField",
      "name": "cmsPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f87a6545428e9d2f2a3d1402847a6fde';

module.exports = node;
