import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation DecryptPosStoreMutation($input: DecryptPosStoreInput!) {
    decryptPosStore(input:$input) {
      storeId
    }
  }
`;

const commit = buildMutation(mutation, {});

export default { commit };
