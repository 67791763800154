import { message } from 'antd';
import { trackOrder }  from '~/refTracker';
import GA4 from '~/ga4';
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'universal-cookie';

const DISTANCE_UNIT = 'km';
const EARTH_RADIUS = 6371e3; // 1e3 === 1km
const SEARCH_RADIUS = 40e3; // unit: 1e3 === 1KM
const cookies = new Cookies();
/*
 * The main purpose of this object is to share functions like `handleQtyChange()`
 * and `handleRemove()` with ItemList at the header. On checkout page, functions
 * at header will be replaced by the those that are in checkout page.
 * Checkout page has a bag of variables, for example, `useCredit`, `suburb`
 * and `postcode` are required to refetch the whole cart object, without those varirables,
 * a lot of properities returned, e.g. AE surcharge and shipping cost would not correct anymore.
 */
const holder = {};
const checkoutEnv = {
  setFn(name, fn) {
    holder[name] = fn;
  },
  getFn(name) {
    return holder[name] ? holder[name] : null;
  },
};

const ORDER_STATUS = {
  PROCESSING: 0,
  PAYMENT_REVIEW: 1,
  PAYMENT_FAILED: 2,
  CANCELLED: 3
}

const decodeSuburbPostcode = function decodeSuburbPostcode(address) {
  const city = address.city.includes("--") ? address.city.split("--")[0] : address.city;
  const postcode = address.postcode.includes("--") ? address.postcode.split("--")[1] : address.postcode;

  return { city, postcode };
}

const getPaymentFailedUrl = function getPaymentFailedUrl(orderId) {
  return `/checkout/paymentfailed/${orderId}`;
}

const getPaymentSuccessUrl = function getPaymentSuccessUrl(orderId) {
  return `/checkout/success/${orderId}`;
}

// do not use arrow function
const onCheckoutCompleted = function onCheckoutCompleted(resp, errors, {onCompleted} = {}) {
  message.destroy(); // dimiss loading message
  if (!errors) {
    if (!resp.createOrder) {
      window.location.reload();
    }

    const { salesOrder } = resp.createOrder

    if (salesOrder.status === 0) {
      trackOrder(resp.createOrder);

      ReactPixel.track(
        'Purchase',
        {
          contents: salesOrder.lines.edges.flatMap((edge) => {
            const { node } = edge;
            if (node.children) {
              return [edge].concat(node.children.edges);
            }
            return edge;
          }).map(({node}) => ({ id: node.product.sku, quantity: node.quantity })),
          content_type: 'product',
          value: salesOrder.grandTotal,
          currency: process.env.CURRENCY,
        }
      );
    }

    this.setState({
      loading: false,
      success: true,
    });
    window.scrollTo(0, 0);

    if (typeof onCompleted === 'function') {
      onCompleted(resp, errors);
    }

    let returnUrl = null;
    if (salesOrder.status === ORDER_STATUS.PROCESSING && salesOrder.verified === 0) {
      returnUrl = `/sales-order/verify/${encodeURIComponent(salesOrder.encryptedId)}`;
    } else if (salesOrder.status === ORDER_STATUS.PAYMENT_FAILED) {
      returnUrl = getPaymentFailedUrl(salesOrder.id);
    } else {
      returnUrl = getPaymentSuccessUrl(salesOrder.id);
    }

    if (resp.createOrder.token) {
      cookies.set('auth_token', resp.createOrder.token, { path: '/' });
      GA4.purchase(resp, () => {
        window.location.href = returnUrl;
      });
    } else {
      GA4.purchase(resp, () => {
        this.props.router.push(returnUrl);
      });
    }
  }
};

const recordGA = function recordGA(action, label) {
  const event = {
    event: 'checkout',
    action,
    label,
  };
  GA4.event(event);
}

const degreeToRadians = function degreeToRadians(degree) {
  return degree * Math.PI / 180;
}

const foundInRadius = function foundInRadius(allStores, loc, rad) {
  return allStores.map((store) => {
    // Haversine formula, for reference: https://www.movable-type.co.uk/scripts/latlong.html
    // searched location (loc) must have lat/lng from austpost api, otherwise cannot find its distance
    const { latitude, longitude } = loc;
    const { lat, lng } = store;

    const phi1 = degreeToRadians(latitude);
    const phi2 = degreeToRadians(lat);
    const deltaPhi = degreeToRadians(lat - latitude);
    const deltaLambda = degreeToRadians(lng - longitude);

    const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = EARTH_RADIUS * c;

    if (distance <= rad) {
      return Object.assign({}, store, {
        storeName: store.name,
        address: `${store.address}`,
        phone: store.phone,
        distance: Math.round(distance / 1000), // in km
        lat: store.lat,
        lng: store.lng,
      });
    }
    return null;
  }).filter(s => s);
}

const sortingRule = function sortingRule(a, b) {
  return a.distance - b.distance;
}

const formatDistance = function formatDistance(distance) {
  return Math.round(distance) + DISTANCE_UNIT;
}

module.exports = {
  EARTH_RADIUS,
  ORDER_STATUS,
  SEARCH_RADIUS,
  checkoutEnv,
  decodeSuburbPostcode,
  formatDistance,
  foundInRadius,
  getPaymentFailedUrl,
  getPaymentSuccessUrl,
  onCheckoutCompleted,
  recordGA,
  sortingRule,
};
