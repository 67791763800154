import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import { getStoreId } from './Pos';

const PosQuery = graphql`
  query routes_Pos_Query(
    $forklift: Boolean,
    $street: String,
    $suburb: String,
    $postcode: String,
    $useCredit: Boolean,
    $useLoyaltyPoints: Boolean,
    $shippingMethod: String,
    $ccType: String,
    $stripeToken: String,
  ) {
    viewer {
      ...Pos_viewer @arguments(
        forklift: $forklift,
        street: $street,
        suburb: $suburb,
        postcode: $postcode,
        useCredit: $useCredit,
        useLoyaltyPoints: $useLoyaltyPoints,
        shippingMethod: $shippingMethod,
        ccType: $ccType,
        stripeToken: $stripeToken
      )
    }
  }
`;

const PosSuccessViewQuery = graphql`
  query routes_PosSuccessView_Query($ids: [ID!]!, $storeIds: [String!]!) {
    viewer {
      ...PosSuccessView_viewer
      ...PosSuccessView_viewer @relay(mask: false)
    }
  }
`;

const PosOrderListQuery = graphql`
  query routes_PosOrderList_Query($storeIds: [String!]!) {
    viewer {
      ...PosOrderList_viewer
      ...PosOrderList_viewer @relay(mask: false)
    }
  }
`;

const PosOrderQuery = graphql`
  query routes_PosOrder_Query($ids: [ID!]!, $storeIds: [String!]!) {
    viewer {
      ...PosOrder_viewer
      ...PosOrder_viewer @relay(mask: false)
    }
  }
`;

const PosLoginQuery = graphql`
  query routes_PosLogin_Query {
    viewer {
      ...PosLogin_viewer
      ...PosLogin_viewer @relay(mask: false)
    }
  }
`;

const routes = (
  <Route
    path="/pos"
  >
    <Route
      path="/"
      getComponent={() => (
        import(/* webpackChunkName: "Pos" */'~/components/pos').then(module => module.Pos)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PosQuery}
    />
    <Route
      path="/login"
      getComponent={() => (
        import(/* webpackChunkName: "PosLogin" */'~/components/pos/PosLogin').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PosLoginQuery}
    />
    <Route
      path="/success/:id"
      getComponent={() => (
        import(/* webpackChunkName: "PosSuccessView" */'~/components/pos/PosSuccessView').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PosSuccessViewQuery}
      prepareVariables={(_, props) => {
        const storeIds = getStoreId();

        if (storeIds) {
          return {
            ids: [props.params.id],
            storeIds: [storeIds]
          }
        }

        return {
          ids: [props.params.id],
          storeIds: []
        };
      }}
    />
    <Route
      path="/list"
      getComponent={() => (
        import(/* webpackChunkName: "PosOrderList" */'~/components/pos/PosOrderList').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PosOrderListQuery}
      prepareVariables={() => {
        const storeIds = getStoreId();

        if (storeIds) {
          return {
            storeIds: [storeIds]
          }
        }

        return {storeIds: []};
      }}
    />
    <Route
      path="/order/:id"
      getComponent={() => (
        import(/* webpackChunkName: "PosOrder" */'~/components/pos/PosOrder').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={PosOrderQuery}
      prepareVariables={(_, props) => {
        const storeIds = getStoreId();

        if (storeIds) {
          return {
            ids: [props.params.id],
            storeIds: [storeIds]
          }
        }

        return {
          ids: [props.params.id],
          storeIds: []
        };
      }}
    />
  </Route>
);

export default routes;
