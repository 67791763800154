import Cookies from 'universal-cookie';
import { importScript } from '~/helper';

const cookies = new Cookies();

const saveRef = (props) => {
  const { location: loc } = props;
  const { psrid } = loc.query;

  if (psrid) {
    cookies.set('psrid', psrid, { path: '/' });
  }
};

const clearRef = () => {
  cookies.remove('psrid', { path: '/' });
};

const trackOrder = ({ salesOrder }) => {
  const psrid = cookies.get('psrid');

  importScript("//cdn.pricespider.com/pathinsights/ps-pi-ps.min.js", () => {
    const { pswtb } = window;

    if (pswtb && psrid && salesOrder) {
      const order = pswtb.purchaseTracking.createOrder();

      let purchaseTotal = 0;

      const productList = salesOrder.lines.edges
        .map(({node}) => {
          purchaseTotal += (node.unitPrice * node.quantity);

          return (
            {
              sku: node.product.sku,
              manufacturer: node.product.brand.name,
              productName: node.name,
              unitPrice: node.unitPrice,
              quantity: node.quantity,
            }
          )
        });

      if (productList.length > 0) {
        order.redirectToken = psrid;
        order.details = productList;
        order.purchaseTotal = purchaseTotal.toFixed(2);
        order.currencyCode = process.env.CURRENCY;
        order.submit();
      }

      clearRef();
    }
  });
};

module.exports = {
  saveRef,
  trackOrder,
};
