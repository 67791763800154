import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { message } from 'antd';
import { getTransDetails } from './CreditCard';

export const getExtra = (obj = {}) => {
  return JSON.stringify({
    timeZone: (new Date()).getTimezoneOffset(),
    screenHeight: window.innerHeight,
    screenWidth: window.innerWidth,
    colorDepth: window.screen.colorDepth,
    ...obj
  });
}

const sleepFor = (duration) => {
  return new Promise(resolve => setTimeout(resolve, duration));
}

/*
 * iframe injection for initiate authentication
 * Copied from https://test-tyro.mtf.gateway.mastercard.com/static/threeDS/1.3.0/three-ds.js
 */
export const processMethodData = (simpleDivData, callback = () => {}) => {
  // Add the iframe with a unique name
  const iframe = document.createElement('iframe');
  const uniqueString = 'methodIFrame';
  iframe.style.display = 'none';
  iframe.id = 'iframe';
  document.body.appendChild(iframe);
  iframe.contentWindow.name = uniqueString;
  const doc = document.getElementById('iframe').contentWindow.document;
  doc.open();
  doc.write(simpleDivData);
  doc.close();
  sleepFor(1500).then(() => { callback() });
}

/*
 * Full screen redirect function for tyro 3ds
 * Copied from https://test-tyro.mtf.gateway.mastercard.com/static/threeDS/1.3.0/three-ds.js
 */
export const injectACSIframe = (content) => {
  document.body.innerHTML = content;
  const contentScript = document.body.getElementsByTagName('script')[0];

  if (contentScript !== undefined) {
    const redirectForm = document.getElementById('threedsChallengeRedirectForm') || document.getElementById('threedsFrictionLessRedirectForm') || document.getElementById('redirectTo3ds1Form');

    if (redirectForm) {
      redirectForm.target = '_parent';
    }

    const script = document.createElement('script');
    script.innerText = contentScript.innerText;
    document.body.appendChild(script);
    const acsIframe = document.getElementById('challengeFrame') || document.getElementById('redirectTo3ds1Frame');

    if (acsIframe) {
      acsIframe.style.position = 'absolute';
      acsIframe.style.width = '100%';
      acsIframe.style.height = '100%';
    }
  }
}

export default class Threeds extends React.Component {
  /*
   * When submit and cart are supplied, its from tyro saved cc.
   * Otherwise checkout using tyro PaymentSession function.
   */
  static init = (form, { cart, setLoading, submit }) => {
    const fields = form.getFieldsValue();
    const { PaymentSession } = window;

    /*
     * When re-setting a password is present, the above guard is not enough to prevent order placing
     * as `checkoutPassword` field is removed.
     */
    if (fields.billing && Object.keys(fields.billing).length === 1 && fields.billing.email) {
      message.error('Please login to place your order')
      return;
    }

    form.validateFieldsAndScroll((err) => {
      if (!err) {
        setLoading(true);


        if (submit && typeof submit === "function") {
          const { transactionId } = getTransDetails(get(cart, 'id'));
          const tyroData = form.getFieldValue('tyroData');

          form.setFieldsValue({
            tyroData: {
              ...tyroData,
              transactionId,
            }
          }, () => {
            submit();
          });
        } else if (PaymentSession) {
          // This trigger formSessionUpdate callback in PaymentSession configure
          PaymentSession.updateSessionFromForm('card');
        } else {
          console.error("PaymentSession not found.")
        }

        setLoading(false);
      }
    })
  }

  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('tyroData')(<div />)}
      </div>
    );
  }
}
