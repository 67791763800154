import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get, isEqual } from 'lodash';

import Credit from './Credit';
import Freebie from './Freebie';

const HIDDEN_PANELS_KEY = 'offer_hidden_panels';

const getHiddenPanels = () => {
  const item = localStorage.getItem(HIDDEN_PANELS_KEY);
  return item ? JSON.parse(item) : { data: [], _expires: null };
};

const setHiddenPanels = (list) => {
  const now = new Date();
  const _expires = new Date(now);
  _expires.setDate(now.getDate() + 1);

  const result = { data: list, _expires: _expires.valueOf() };
  localStorage.setItem(HIDDEN_PANELS_KEY, JSON.stringify(result));

  return result;
};

const Offer = (props) => {
  const { viewer } = props;

  const offers = get(viewer, 'cart.offers', []);

  const hiddenPanels = useMemo(() => {
    const now = Date.now();
    const offerIds = offers.map(({ id }) => id );

    const _hiddenPanels = getHiddenPanels();
    const expires = get(_hiddenPanels, '_expires');

    const currHiddenIds = get(_hiddenPanels, 'data', []);
    const hiddenIds = currHiddenIds.filter((id) => offerIds.includes(id));

    if (expires && now > expires) {
      setHiddenPanels([]);
    } else if (offers.length === 0 && currHiddenIds.length !== 0) {
      setHiddenPanels([]);
    } else if (!isEqual(currHiddenIds, hiddenIds)) {
      setHiddenPanels(hiddenIds);
    }

    return getHiddenPanels();
  }, [offers]);

  const handleOnChange = (id, isCollapsed) => {
    const set = new Set(get(getHiddenPanels(), 'data', []));

    if (isCollapsed) {
      set.add(id);
    } else {
      set.delete(id);
    }

    const list = Array.from(set);

    setHiddenPanels(list);
  };

  return offers.length > 0 ? (
    <div>
      {offers.map((offer) => {
        const type = get(offer, '__typename');
        const sharedProps = { hiddenPanels, handleOnChange };

        if (type === 'OfferCredit') {
          return <Credit key={offer.id} offer={offer} {...sharedProps} />;
        } else if (type === 'OfferFreebie') {
          const lines = get(viewer, 'cart.lines.edges', []).map(({ node }) => node);

          return <Freebie key={offer.id} offer={offer} lines={lines} {...sharedProps} />;
        }

        return null;
      })}
    </div>
  ) : null
};

Offer.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
};

export default createFragmentContainer(Offer, {
  viewer: graphql`
    fragment Offer_viewer on Customer {
      id
      cart {
        id
        offers {
          __typename
          ... on OfferCredit {
            id
            name
            tierPrice
            creditAmount
            amountRequired
            percent
            isMaxTier
          }
          ... on OfferFreebie {
            id
            name
            tierPrice
            products {
              id
              name
              urlSlug
              mainImage {
                id
                thumbnail
              }
            }
            mode
            quantity
            amountRequired
            percent
            isMaxTier
          }
        }
        lines(first: 999) @connection(key: "CartView_lines") {
          edges {
            node {
              id
              name
              isFreebie
              product {
                id
              }
            }
          }
        }
      }
    }
  `
});
