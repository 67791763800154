import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

const WarrantyFormQuery = graphql`
  query routes_WarrantyForm_Query($filterBy: [FilterBy]) {
    viewer {
      ...WarrantyForm_viewer
    }
  }
`;

const routes = (
  <Route
    path="/warranty"
    getComponent={() => (
      import(/* webpackChunkName: "WarrantyForm" */'~/components/warranty').then(module => module.WarrantyForm)
      .catch(() => {
        window.location.reload();
      })
    )}
    query={WarrantyFormQuery}
    prepareVariables={() => {
      const filterBy = [{
        field: "status",
        filter: "false,true",
        filterType: "boolean",
        type: "in",
      }];

      return { filterBy };
    }}
  />
);

export default routes;
