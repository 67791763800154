import React from 'react';
import PropTypes from 'prop-types';

export default class SavedCard extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    method: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }).isRequired,
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      ccBrand: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const { form, node } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div style={{display: 'none'}}>
        {getFieldDecorator('ccType', {
          initialValue: node.ccBrand,
        })(<div />)}
      </div>
    );
  }
}
