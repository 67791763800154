import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation OauthLoginMutation($input: OauthLoginInput!) {
    oauthLogin(input: $input) {
      token
      viewer {
        id
        defaultAddress {
          city
          region
          postcode
          lat
          lng
        }
      }
    }
  }
`;

const commit = buildMutation(mutation);
export default { commit };
