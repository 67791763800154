/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SupplierForm_viewer$ref: FragmentReference;
declare export opaque type SupplierForm_viewer$fragmentType: SupplierForm_viewer$ref;
export type SupplierForm_viewer = {|
  +cmsPage: ?{|
    +title: ?string,
    +urlSlug: ?string,
    +content: ?string,
  |},
  +$refType: SupplierForm_viewer$ref,
|};
export type SupplierForm_viewer$data = SupplierForm_viewer;
export type SupplierForm_viewer$key = {
  +$data?: SupplierForm_viewer$data,
  +$fragmentRefs: SupplierForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "urlSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SupplierForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "urlSlug",
          "variableName": "urlSlug"
        }
      ],
      "concreteType": "CmsPage",
      "kind": "LinkedField",
      "name": "cmsPage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '86c8625114f65cc1b06e3b5428e0402c';

module.exports = node;
