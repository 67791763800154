import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Checkbox, Tabs } from 'antd';
import { get } from 'lodash';

import AddressList from './AddressList';
import { decodeSuburbPostcode } from './helper';

const { TabPane } = Tabs;

class AddressView extends React.Component {
  static propTypes = {
    sameAddress: PropTypes.bool.isRequired,
    setSameAddress: PropTypes.func.isRequired,
    newAddressTabKey: PropTypes.string.isRequired,
    changeAddressTab: PropTypes.func.isRequired,
    viewer: PropTypes.shape({
      addresses: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    form: PropTypes.shape({
      getFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  onTabChange = (e) => {
    this.props.changeAddressTab(e);
  }

  addressCheckbox = (e) => {
    const { form, viewer, setSameAddress } = this.props;
    const addresses = get(viewer, 'addresses.edges', []);
    const sameAddress = e.target.checked;

    setSameAddress(sameAddress);

    if (sameAddress) {
      this.props.changeAddressTab('shipping');
    } else {
      this.props.changeAddressTab('billing');
    }

    const { shippingAddressId, shipping } = form.getFieldsValue(['shippingAddressId', 'shipping[]street', 'shipping[]city', 'shipping[]region', 'shipping[]postcode']);
    const { node: address } = addresses.find(({ node }) => node.id === shippingAddressId) || {};

    let dest = { street: '', suburb: '', city: '', postcode: ''};

    if (address) {
      dest = { street: address.street, suburb: address.city, city: address.region, postcode: address.postcode };
    } else if (!!shipping.city && !!shipping.postcode) {
      const { city: suburb, postcode } =  decodeSuburbPostcode(shipping);
      dest = { street: get(shipping, 'street', ''), suburb, city: get(shipping, 'region', ''), postcode };
    }

    this.props.onChange({ dest });
  }

  renderTabs = ({ form, viewer, disabled, onChange }) => {
    const { sameAddress } = this.props;

    return (
      <div>
        <Checkbox
          onChange={this.addressCheckbox}
          checked={sameAddress}
          disabled={disabled}
        >
          Bill to same address
        </Checkbox>

        <Tabs defaultActiveKey="shipping" activeKey={this.props.newAddressTabKey} onTabClick={this.onTabChange}>
          <TabPane tab="Shipping Address" key="shipping">
            <AddressList
              type="shipping"
              form={form}
              viewer={viewer}
              disabled={disabled}
              onChange={onChange}
            />
          </TabPane>

          {!sameAddress && (
            <TabPane tab="Billing Address" key="billing" forceRender>
              <AddressList
                type="billing"
                form={form}
                viewer={viewer}
                disabled={disabled}
                onChange={onChange}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    )
  }

  render() {
    const { form, viewer, disabled, onChange } = this.props;

    return (
      <div>
        {this.renderTabs({ form, viewer, disabled, onChange })}
      </div>
    );
  }
}

export default createFragmentContainer(AddressView, {
  viewer: graphql`
    fragment AddressView_viewer on Customer {
      ...AddressList_viewer
      id
      addresses(first: 999) @connection(key: "AddressBook_addresses") {
        edges {
          node {
            id
            firstname
            lastname
            street
            city
            postcode
            region
            telephone
            company
            fax
            country {
              name
            }
          }
        }
      }
    }
  `,
});
