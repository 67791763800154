import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Button, DatePicker, Form, Input, message, Upload } from 'antd';
import 'antd/lib/date-picker/style';
import 'antd/lib/upload/style';
import { formItemLayout, scrollToFirstError, mediaValidator, filesValidator } from '~/components/form';

import { PostWarrantyFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;

const EMAIL_DOMAIN = process.env.COUNTRY === 'NZ' ? '@sydneytools.co.nz' : '@sydneytools.com.au';

class WarrantyForm extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      loading: false,
    }
  }

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const uploadables = {};
    if (values.attachments) {
      const fileList = values.attachments.map(f => f.originFileObj);

      values.attachments = fileList.map((f, i) => {
        const name = `attachment${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    values.staffEmail += EMAIL_DOMAIN;
    values.bookedDate = values.bookedDate.format('LL');
    values.invoiceDate = values.invoiceDate.format('LL');

    PostWarrantyFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      uploadables,
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success("Thank you very much, we have received your feedback.");
        this.formRef.current.resetFields();
        this.setState({ loading: false });
      },
      onError: () => {
        this.setState({ loading: false });
      },
    });
  }

  render() {
    const stores = this.props.viewer.stores.edges;

    return (
      <div>
        <Helmet title="Warranty" />
        <h1>Warranty</h1>

        <Form ref={this.formRef} onFinish={this.handleSubmit} scrollToFirstError={scrollToFirstError}>

          <p><b>Max. size</b> of the attachments is <b>25MB</b>.</p>
          <p>If you did not receive an <b>email copy</b> of this form, <b>please re-submit or ask for assistance</b>.</p>

          <FormItem
            {...formItemLayout}
            label="Branch"
            name="branch"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Select
              allowClear
              showSearch
              suffixIcon={<SearchOutlined />}
              placeholder="Branch"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={stores.map(({ node }) => ({
                value: node.id,
                label: node.name,
              }))}
            />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Staff Name"
            name="staffName"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Staff Name" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Staff Email"
            name="staffEmail"
            rules={[
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email', transform: val => `${val}${EMAIL_DOMAIN}` },
            ]}
          >
            <Input addonAfter={EMAIL_DOMAIN} style={{ width: '400px' }} placeholder="Staff Email" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Invoice Number"
            name="invoiceNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Invoice Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Invoice Date"
            name="invoiceDate"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Booked Date"
            name="bookedDate"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Job Number"
            name="jobNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Job Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Model Number"
            name="modelNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Model Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="GP Number"
            name="GPNumber"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="GP Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Serial Number"
            name="serialNumber"
          >
            <Input placeholder="Serial Number" />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Customer Complaint"
            name="complaint"
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <TextArea placeholder="Customers Complaint" rows={4} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Fault Report"
            name="fault"
            help='Please provide detailed failure description, testing done to diagnose fault, parts required for repair, etc.'
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <TextArea placeholder="Fault Report" rows={4} />
          </FormItem>
          <br />

          <FormItem
            {...formItemLayout}
            label="Attachments"
            name="attachments"
            rules={[
              { required: true, message: 'Required' },
              { validator: (_rule, value) => mediaValidator(value) },
              { validator: (_rule, value) => filesValidator(25, _rule, value) }
            ]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              multiple
              beforeUpload={() => false}
            >
              <Button>
                <UploadOutlined /> Click to Upload
              </Button>
            </Upload>
          </FormItem>

          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Submit
          </Button>

        </Form>
      </div>
    );
  }
}

export default createFragmentContainer(WarrantyForm, {
  viewer: graphql`
    fragment WarrantyForm_viewer on Customer {
      id
      stores(first: 999, filterBy: $filterBy) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});
