import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import Helmet from '~/components/page/Helmet';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Upload } from 'antd';
import { filesValidator, scrollToFirstError, SuburbPostcodeInput } from '~/components/form';

import { PostCustomerOrderFormMutation } from './mutations';

const { Item: FormItem } = Form;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 5 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 14 },
    lg: { span: 14 },
  },
};

class CustomerOrderForm extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleSubmit = (values) => {
    const uploadables = {};

    if (values.attachments) {
      const fileList = values.attachments.map(f => f.originFileObj);

      values.attachments = fileList.map((f, i) => {
        const name = `attachments${i}`;

        uploadables[name] = f;
        return name;
      });
    }

    PostCustomerOrderFormMutation.commit({
      environment: this.props.relay.environment,
      variables: { input: values },
      uploadables,
      viewer: this.props.viewer,
      onCompleted: () => {
        message.success('Thank you very much, we have received your order.');
        this.formRef.current.resetFields();
      },
    });
  }

  render() {
    const { relay, viewer: { cmsPage: page } } = this.props;

    return (
      <div>
        <Helmet title={page.title} />
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.content }} />

        <Form ref={this.formRef} onFinish={this.handleSubmit} scrollToFirstError={scrollToFirstError}>
          <FormItem
            label="Company"
            name="company"
            {...formItemLayout}
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Company" />
          </FormItem>

          <FormItem
            label="Name"
            name="name"
            {...formItemLayout}
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Name" />
          </FormItem>

          <FormItem
            label="Email"
            name="email"
            {...formItemLayout}
            rules={[
              { required: true, message: 'Required' },
              { type: 'email', message: 'Invalid Email' },
            ]}
          >
            <Input placeholder="Email" />
          </FormItem>

          <FormItem
            label="Telephone"
            name="telephone"
            {...formItemLayout}
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <Input placeholder="Telephone" />
          </FormItem>

          <FormItem
            label="Suburb/Postcode"
            name="location"
            {...formItemLayout}
          >
            <SuburbPostcodeInput relay={relay} />
          </FormItem>

          <FormItem
            label="Message"
            name="message"
            {...formItemLayout}
            rules={[
              { required: true, message: 'Required' },
            ]}
          >
            <TextArea placeholder="Message" rows={4} />
          </FormItem>

          <FormItem
            name="attachments"
            {...formItemLayout}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
            rules={[
              { required: true, message: 'Files cannot be over 25MB', validator: filesValidator.bind(this, 25)}
            ]}
          >
            <Upload
              customRequest={() => {}}
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              listType="picture"
              multiple
              beforeUpload={() => false}
            >
              <p>Files can be up to 25MB for file types .pdf .doc .docx .xls .xlsx</p>
              <Button>
                <UploadOutlined /> Files
              </Button>
            </Upload>
          </FormItem>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

export default createFragmentContainer(CustomerOrderForm, {
  viewer: graphql`
    fragment CustomerOrderForm_viewer on Customer {
      cmsPage(urlSlug: $urlSlug) {
        title
        urlSlug
        content
      }
    }
  `,
});

