/* global google */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { camelCase, capitalize, get } from 'lodash';
import { Link } from 'found';
import { CloseOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Card, Col, Collapse, Row } from 'antd';
import { Circle, Marker } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import Helmet from '~/components/page/Helmet';
import { JSONLD, Generic, GenericCollection } from 'react-structured-data';
import { sanitiseCharacters, sanitiseState, slugify } from '~/helper';
import Breadcrumb from '../page/Breadcrumb';
import { getCenter, getZoom, Map } from './Map';
import { MyStoreBtn, renderMyStoreBtn } from './MyStoreBtn';
import './style.css';
import { renderOption, handleSearch } from '../product/ShippingQuote';
import { GeocodeMutation } from '../product/mutations';
import { foundInRadius, sortingRule, SEARCH_RADIUS, formatDistance } from '../checkout/helper';
import HoursInfo from '../checkout/HoursInfo';
import MyLocationBtn from './MyLocationBtn';

const { Panel } = Collapse;
const CENTER = getCenter();
const ZOOM = getZoom();

export const getGoogleMapApiKey = () => {
  if (process.env.COUNTRY === 'NZ') {
    return 'AIzaSyAoKIO0Q4bkqHTzNiFQfpdK5NcXbskqSqM';
  }
  return'AIzaSyB2Fihe3Kdj2eUTFwl2WerhJBXYmW8h03k';
}

const GOOGLE_MAP_API_KEY = getGoogleMapApiKey();

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.32&key=${GOOGLE_MAP_API_KEY}&libraries=geometry,drawing,places`;
export const SEARCH_QUERY = '?query';

export const getStateName = (key) => {
  if (process.env.COUNTRY === 'NZ') {
    return sanitiseState(key).split(" ").map((s) => capitalize(s)).join(" ");
  }

  return key.toUpperCase();
}

const renderAddress = function renderAddress(classname, store) {
  if (!store.address) {
    return null;
  }

  const region = get(store, 'region');

  if (classname === "checkout") {
    return (
      <div className={classname}>
        {store.address} {store.city} {store.postcode} {region}
      </div>
    )
  }

  return (
    <div>
      {store.address} <br />
      {store.city} {store.postcode} {region} <br />
    </div>
  )
}

export const StoreInfo = (props) => {
  const { viewer, relay, match, store, headerLink, className } = props;
  const { router } = match || {};

  if (!store) {
    return null;
  }

  const [myStoreId, setMyStoreId] = useState(null);

  const storeName = store.name.toLowerCase()
    .replace(/\s*\(.*?\)\s*/g, "") // delete all brackets and contents in between; '?' for lazy matching;
    .trim()                        // removes whitespace from both ends of a string.
    .replace(/\s+/g, '-');         // replace space(s) between words with hyphen.

  return (
    <div>
      <div>
        <h2 style={{ display: 'inline-block', marginBottom: '0px' }}>
          <Link
            router={router}
            match={match}
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: 'rgb(33, 33, 33)',
            }}
            onClick={(e) => {
              if (!headerLink) {
                e.preventDefault();
              }
            }}
            to={headerLink ? `/stores/${slugify(store.state)}/${storeName}` : "#"}
          >
            {store.name}
          </Link>
        </h2>
        <span>{store.distance > 0 && (<span style={{ marginLeft: '10px', color: 'rgb(152,152,152)' }}>{formatDistance(store.distance)}</span>)}</span>
        {store.stock && (
          <div
            className={camelCase(store.stock)}
            style={{
              float: 'right',
              marginRight: '15px',
              display: 'inline-block',
              fontWeight: 700,
            }}
          >
            <span>
              {store.stock}
            </span>
          </div>
        )}
        <div className="clearfix" />
      </div>

      <Row>
        <Col span={24}>
          {renderAddress(className, store)}
          {store.phone && (
            <div className={className}>
              <b>Phone</b>: <a href={`tel:${store.phone}`}>{store.phone}</a>
            </div>
          )}

          {store.fax && (<div className={className}><b>Fax</b>: <a href={`fax:${store.fax}`}>{store.fax}</a></div>)}
        </Col>

        {store.canPickup && (
          <Col span={24}>
            <MyStoreBtn viewer={viewer} relay={relay} store={store} myStoreId={myStoreId} setMyStoreId={setMyStoreId} />
          </Col>
        )}
      </Row>

      {store.hours && (
        <div className={className}>
          <HoursInfo store={store} />
        </div>
      )}

      {store.description && (
        <div className={className}>
          <div dangerouslySetInnerHTML={{ __html: store.description }} />
        </div>
      )}
    </div>
  )
};

StoreInfo.propTypes = {
  viewer: PropTypes.shape({}).isRequired,
  relay: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    router: PropTypes.shape({}),
  }),
  store: PropTypes.shape({
    name: PropTypes.string,
    state: PropTypes.string,
    distance: PropTypes.string,
    stock: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    canPickup: PropTypes.bool,
    hours: PropTypes.shape({}),
    description: PropTypes.string,
  }),
  headerLink: PropTypes.bool,
  className: PropTypes.string,
};

StoreInfo.defaultProps = {
  match: {},
  store: null,
  headerLink: false,
  className: '',
};

// rendering is shared to checkout page, use renderStore.call(this) when using this function from another component
// When <Link/> used inside the Google Map components, it lost its ref.
// Therefore it required router and match to work properly.
export const renderStore = function renderStore(store, headerLink = false, classname = '') {
  if (!store) {
    return null;
  }

  if (store.node) {
    store = store.node;
  }

  const storeName = store.name.toLowerCase()
    .replace(/\s*\(.*?\)\s*/g, "") // delete all brackets and contents in between; '?' for lazy matching;
    .trim()                        // removes whitespace from both ends of a string.
    .replace(/\s+/g, '-');         // replace space(s) between words with hyphen.

  const { match } = this.props;
  const { router } = match || {};

  return (
    <div>
      <div>
        <h2 style={{ display: 'inline-block', marginBottom: '0px' }}>
          <Link
            router={router}
            match={match}
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              textDecoration: 'underline',
              color: 'rgb(33, 33, 33)',
            }}
            onClick={(e) => {
              if (!headerLink) {
                e.preventDefault();
              }
            }}
            to={headerLink ? `/stores/${slugify(store.state)}/${storeName}` : "#"}
          >
            {store.name}
          </Link>
        </h2>
        <span>{store.distance > 0 && (<span style={{ marginLeft: '10px', color: 'rgb(152,152,152)' }}>{formatDistance(store.distance)}</span>)}</span>
        {store.stock && (
          <div
            className={camelCase(store.stock)}
            style={{
              float: 'right',
              marginRight: '15px',
              display: 'inline-block',
              fontWeight: 700,
            }}
          >
            <span>
              {store.stock}
            </span>
          </div>
        )}
        <div className="clearfix" />
      </div>

      <Row>
        <Col span={24}>
          {renderAddress(classname, store)}
          {store.phone && (
            <div className={classname}>
              <b>Phone</b>: <a href={`tel:${store.phone}`}>{store.phone}</a>
            </div>
          )}

          {store.fax && (<div className={classname}><b>Fax</b>: <a href={`fax:${store.fax}`}>{store.fax}</a></div>)}
        </Col>
        {store.canPickup && (
          <Col span={24}>
            {renderMyStoreBtn.call(this, store)}
          </Col>
        )}
      </Row>

      {store.hours && (
        <div className={classname}>
          <HoursInfo store={store} />
        </div>
      )}

      {store.description && (
        <div className={classname}>
          <div dangerouslySetInnerHTML={{ __html: store.description }} />
        </div>
      )}
    </div>
  );
}

export const groupByState = function groupByState(stores) {
  const s = {};

  stores.forEach((edge) => {
    const store = edge.node;

    const state = s[store.state] ? s[store.state] : [];

    state.push(store);
    s[store.state] = state;
  });

  return s;
}

class StoreList extends React.Component {
  static propTypes = {
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    viewer: PropTypes.shape({
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        query: PropTypes.shape({}),
        search: PropTypes.string,
      }),
      params: PropTypes.shape({
        state: PropTypes.string,
      }).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { location, params } = props.match;
    const stores = get(props.viewer, 'stores.edges', []);

    this.isNz = process.env.COUNTRY === 'NZ';

    this.states = groupByState(stores);

    this.allMarkers = this.getMarkers(stores);

    this.defaultBounds = null;

    const iconStyle = {
      anchorText: [-3, 0],
      textColor: 'white',
      textSize: 12,
      width: 34,
      height: 43
    };

    this.markerCluster =
      Object.keys(this.states).map(state => (
        <MarkerClusterer
          key={state}
          gridSize={35}
          styles={[
            {
              url: "/assets/images/google_maps/m1.png",
              ...iconStyle
            },
            {
              url: "/assets/images/google_maps/m2.png",
              ...iconStyle
            },
            {
              url: "/assets/images/google_maps/m3.png",
              ...iconStyle
            },
            {
              url: "/assets/images/google_maps/m4.png",
              ...iconStyle
            },
            {
              url: "/assets/images/google_maps/m5.png",
              ...iconStyle
            }
          ]}
        >
          {this.getMarkers(this.states[state])}
        </MarkerClusterer>
      ));

    this.onSearch = handleSearch.bind(this, 'dataSource');
    const dataSource = [];
    this.columns = [{
      title: 'Store Name',
      dataIndex: 'storeName',
    }, {
      title: 'Address',
      dataIndex: 'address',
    }, {
      title: 'Phone',
      dataIndex: 'phone',
      render: (text) => <a href={`tel:${text}`} style={{ cursor: 'pointer' }}>{text}</a>
    }, {
      title: 'Distance',
      dataIndex: 'distance',
    }];

    this.initMap = {
      infoBox: [],
      dataSource,
      foundStore: null,// null: init; true: found result(s) through search bar; false: no result from search
      listData: [],
      circle: null,
      markerCluster: this.markerCluster,
      searchLocation: {},
      collapse: this.getCollapse(this.states, params.state),
    };

    this.resetMapBtn = (
      <Button type="primary" onClick={this.resetMap} style={{ margin: '35px', height: '60px', fontSize: 'large', }}>
        {this.isNz ? "Browse other stores" : "Browse stores by states"}
      </Button>
    );

    this.isSearchInit = false;

    const searchStoreInput = get(location.query, 'query', null);

    this.state = {
      ...this.initMap,
      ...{ searchStoreInput },
    };
  }

  // Update this component, when the url query is changed
  componentDidUpdate = (prevProps) => {
    const oldSearch = get(prevProps.match.location, 'query.query');
    const newSearch = get(this.props.match.location, 'query.query');

    const oldState = get(prevProps.match.params, 'state');
    let newState = get(this.props.match.params, 'state');

    const searchStoreInput = get(this.props.match.location.query, 'query', null);

    if (oldSearch !== newSearch && !newState) {
      // If the url search query is different from the previous query,
      // update the searched location.
      // Else return the map to default position.
      if (this.props.match.location.search !== "") {
        this.setState({
          ...{ searchStoreInput },
        });

        this.searchStore(newSearch);
      } else {
        this.clearState(searchStoreInput);

        if (this.allMarkers.length === 1) {
          this.setState({ zoom: ZOOM, center: CENTER });
        } else {
          // reset map position
          this.map.fitBounds(this.defaultBounds);
        }
      }
    } else if (oldState !== newState) {
      // If current "state" e.g nsw, vic... etc, in the Url path is different
      // from the previous one, Update the Collapse component.
      // If no state is supplied, return the map to default position.
      this.clearState(searchStoreInput);

      if (newState) {
        newState = sanitiseState(newState);

        this.handleChange(newState, false);
      } else if (this.allMarkers.length === 1) {
        this.setState({ zoom: ZOOM, center: CENTER });
      } else {
        // reset map position when Store button is pressed
        this.map.fitBounds(this.defaultBounds);
      }
    }
  }

  // set map zoom by the distance between from customer input to store.
  setZoomByDistance = (distance) => {
    if (distance > 25) {
      // greater than 25km
      return 9;
    } else if (distance > 15) {
      // 15km -- 25km
      return 10;
    } else if (distance > 8) {
      // 8km -- 15km
      return 11;
    } else if (distance > 3) {
      // 3km -- 8km
      return 12;
    }

    // 0km -- 3km
    return 13;
  }

  setWindowHistory = (query = null, state = false) => {
    const baseurl = "/stores";
    let q = null;
    // In page /stores/nsw, if collapse panel changes
    // Update url to reflect the changes
    if (state && typeof (query) === "string") {
      q = `/${query}`;

      this.props.router.push(`${baseurl}${q}`);
      return;
    }

    // Update url when a suburb/postcode is searched
    if (query && query.location && query.postcode) {
      // If query object is direcrly from Autocomplete selection,
      // set query url contain both location & postcode
      q = `${SEARCH_QUERY}=${query.location} ${query.postcode}`;
    } else if (typeof (query) === "string") {
      // If query is a direct input (without selection from Autocomplete),
      // set query url equal to the input
      q = `${SEARCH_QUERY}=${query}`;
    } else {
      // If there are no query / state supplied, go back to page /stores
      this.props.router.push(`${baseurl}`);
      return;
    }
    this.props.router.push(`${baseurl}${q}`);
  }

  getCollapse = (states, state) => {
    if (state) {
      const s = sanitiseState(state);
      if (Object.keys(states).includes(s)) {
        return s;
      }
    }
    return null;
  }

  getMarkers = stores => stores.map((edge) => {
    const store = edge.node || edge;
    const { origin } = window.location;

    return (
      <Marker
        onClick={() => { this.handleMarkerClick(store); }}
        icon={`${origin}/assets/images/google_maps/map_pin.svg`}
        key={store.id}
        position={{ lat: store.lat, lng: store.lng }}
      />
    );
  })

  getTitle = (isMetaTitle = false) => {
    const { pathname, search } = window.location;

    if (pathname.includes("/stores/")) {
      const storeName = sanitiseState(pathname.slice(8));
      const states = Object.keys(this.states);

      if (states.includes(storeName)) {
        return `Sydney Tools Stores in ${getStateName(storeName)}`;
      }
    } else if (pathname.includes("/stores") && search.includes("?query=")) {
      if (isMetaTitle === true) {
        const query = new URLSearchParams(search);

        return `Stores Search - ${query.get("query")} | Sydney Tools`;
      }
    }

    return "Sydney Tools Stores";
  }

  handleFound = (found) => {
    if (found) {
      const value = found.id;
      const option = { item: found };

      this.handleSelect(value, option);
    } else {
      this.missSearchHanler({});
    }
  }

  // when theres a search query from the url, search the locations that match the query.
  // if its not found, set the 1st element of the locations result.
  // lastly, update the map to the correct position.
  searchStore = (query) => {
    if (query) {
      this.onSearch(query, {
        onComplete: (locations) => {
          let found = null;
          if (locations.length > 0) {
            found = locations.find(x => {
              if (this.isNz) {
                return x.location === query;
              }
              return `${x.location} ${x.postcode}` === query;
            });

            if (!found) {
              found = get(locations, '0');
            }
          }

          this.handleNzGeocode(query, (r) => {
            this.handleFound(r);
          }, () => {
            this.handleFound(found);
          })
        }
      });
    }
  }

  // When NZ site query locality / postcode it does not have coordinates,
  // it require google geocode to get back the coordinate.
  // onCompletedCB is for NZ site, when the coordinates are return then run the subsequence fn inside the callback.
  // defaultCb is the original function for AUD site.
  handleNzGeocode = (query, onCompletedCb = () => {}, defaultCb = () => {}) => {
    if (this.isNz) {
      GeocodeMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { query }},
        viewer: this.props.viewer,
        onCompleted: (resp) => {
          const result = get(resp, 'geocode.result');
          onCompletedCb(result);
        },
      });
    } else {
      defaultCb();
    }
  }

  clearState = (searchStoreInput) => {
    this.setState({
      ...this.initMap,
      collapse: undefined,
      ...{ searchStoreInput },
    });
  }

  resetMap = () => { // display <Collapse> to show full list of states, and hide other search results
    const { dataSource, ...reset } = this.initMap;

    this.setState({
      ...reset,
    }, function cb() {
      this.handleMapMounted(this.map);
      window.scrollTo(0, 0);
      this.setWindowHistory();
    });
  }

  handleMarkerClick = (store) => {
    const infoBox = (
      <InfoBox
        position={new google.maps.LatLng(store.lat, store.lng)}
        options={{ closeBoxURL: '', enableEventPropagation: true }}
      >
        <div
          style={{
            width: '300px',
            backgroundColor: 'white',
            padding: '12px',
            fontSize: '16px',
            fontColor: '#08233B',
            position: 'relative',
          }}
        >
          <CloseOutlined
            style={{ cursor: 'pointer', top: '10px', right: '10px', position: 'absolute' }}
            onClick={() => {
              this.setState({
                infoBox: null,
              });
            }}
          />
          {this.renderStore(store)}
        </div>
      </InfoBox>
    );

    this.setState({
      infoBox,
    });
  }

  handleChange = (e, setHistory = true) => {
    this.setState({
      collapse: e
    });

    if (!e) {
      this.map.fitBounds(this.defaultBounds);
      if (setHistory) {
        this.setWindowHistory();
      }
      return;
    }

    if (setHistory) {
      this.setWindowHistory(slugify(e), true);
    }

    const bounds = new google.maps.LatLngBounds();

    if (this.states[e].length > 1) {
      this.states[e].forEach((store) => {
        const point = new google.maps.LatLng({ lat: store.lat, lng: store.lng });
        bounds.extend(point);
      });
      this.setState({
        infoBox: null, // clear all opened info boxes when change to new states.
        listData: this.states[e]
      });
      this.map.fitBounds(bounds);
    } else {
      this.centerStore(this.states[e][0]);
    }
  }

  handleMapMounted = (map) => {
    if (map) {
      this.map = map;

      this.defaultBounds = new google.maps.LatLngBounds();

      this.allMarkers.forEach((marker) => {
        const point = new google.maps.LatLng(marker.props.position);
        this.defaultBounds.extend(point);
      });

      if (this.state.collapse) {
        this.handleChange(this.state.collapse, false);
      } else if (this.allMarkers.length === 1) {
        this.setState({ zoom: ZOOM, center: CENTER });
      } else {
        this.map.fitBounds(this.defaultBounds);
      }

      if (!this.isSearchInit) {
        this.searchStore(this.state.searchStoreInput);
        this.isSearchInit = true;
      }
    }
  }

  centerStore = (store) => {
    this.setState({
      zoom: 15,
      center: { lat: store.lat, lng: store.lng },
    });
  }

  /*
   * reset Map if no store is found
   */
  missSearchHanler = (item) => {
    this.setState({
      center: CENTER,
      circle: null,
      foundStore: false,
      infoBox: null,
      markerCluster: this.markerCluster,
      searchLocation: item,
      zoom: ZOOM,
    }, function cb() {
      this.map.fitBounds(this.defaultBounds);
    });
  }

  handleSelect = (value, option) => {
    let allStores = [];

    if (this.isNz) {
      allStores = get(this.props, 'viewer.stores.edges', []).map(({ node }) => node);
    } else {
      const itemState = get(option, 'item.state');
      allStores = get(this.states, itemState, []);
    }

    if (allStores.length === 0) {
      this.missSearchHanler(option.item);
      return;
    }

    const stores = foundInRadius(allStores, option.item, SEARCH_RADIUS)

    this.focusMap(stores, option.item);
  }

  drawCircle = (shortList, userLocation) => (
    <Circle
      center={{
        lat: userLocation.latitude,
        lng: userLocation.longitude
      }}
      radius={(shortList[shortList.length - 1].distance + 2) * 1e3} // extra 2km makes circle looks more nature
      options={{
        strokeColor: "#1a75ff",
        strokeWeight: 1,
        visible: true,
        fillOpacity: .1,
      }}
    />
  )

  focusMap = (stores, userLocation) => {
    if (stores.length > 0) {
      const shortList = stores.sort(sortingRule).slice(0, 5);
      const circle = this.drawCircle(shortList, userLocation);

      if (stores.length > 1 && window.google) {
        const bounds = new google.maps.LatLngBounds();
        stores.map(store => {
          const point = new google.maps.LatLng({ lat: store.lat, lng: store.lng });
          bounds.extend(point);
          return null;
        });
        const point = new google.maps.LatLng({ lat: userLocation.latitude, lng: userLocation.longitude });
        bounds.extend(point);
        // where 'zoom' of GoogleMap doesn't take effect
        this.map.fitBounds(bounds);
      } else {
        // only found 1 store in target vicinity.
        this.setState({
          center: { lat: userLocation.latitude, lng: userLocation.longitude },
          zoom: this.setZoomByDistance(shortList[0].distance),
        });
      }

      this.setState({
        foundStore: true,
        listData: shortList,
        circle,
        infoBox: null,
        markerCluster: this.markerCluster,
        searchLocation: userLocation,
      });
    } else {
      this.missSearchHanler(userLocation);
    }
  }

  useCurrentLocation = (coords) => {
    const { viewer } = this.props;

    const stores = get(viewer, 'stores.edges', []).map(({ node }) => node);
    const storesInRadius = foundInRadius(stores, coords, SEARCH_RADIUS);
    const userPosition = { "latitude": coords.latitude, "longitude": coords.longitude };

    this.setState({ searchStoreInput: '' });
    this.focusMap(storesInRadius, userPosition)
  }

  renderStore = (store) => {
    store = Object.assign({}, store);

    store.onClick = this.centerStore;

    return renderStore.call(this, store, true, '');
  }

  renderStoreNearYou = (searchLocation) => {
    let msg = "you";

    if (this.isNz && searchLocation.city) {
      const { suburb, city } = searchLocation;
      msg = [suburb, city].filter(i => i).join(", ");
    }

    if (searchLocation.location) {
      msg = `${searchLocation.location}, ${searchLocation.postcode}`
    }

    return `Stores near ${msg}`
  }

  render() {
    const { match: { location } } = this.props;
    const { center, circle, dataSource, foundStore, infoBox, listData, markerCluster, searchLocation, zoom } = this.state;
    const { origin } = window.location;

    const breadcrumb = Object.keys(this.states).map((s) => getStateName(s));

    return (
      <div>
        <Helmet title={this.getTitle(true)}>
          <meta name="description" content="Welcome to Sydney Tools. Use our store locator to find your nearest Sydney Tools store. Store information, Business Hours and more. Come visit us today!" />
        </Helmet>

        <JSONLD>
          <Generic
            type="breadcrumbList"
            jsonldtype="BreadcrumbList"
          >
            <GenericCollection type="itemListElement">
              <Generic
                jsonldtype="ListItem"
                schema={{
                  position: 1,
                  name: "Store",
                  item: origin
                }}
              />
              <Generic
                jsonldtype="ListItem"
                schema={{
                  position: 2,
                  name: "Store Locations",
                  item: `${origin}/stores`
                }}
              />
            </GenericCollection>
          </Generic>
        </JSONLD>

        <Breadcrumb states={breadcrumb} location={location} />
        <h1 style={{ fontWeight: '700' }}>{this.getTitle()}</h1>
        <div>
          <MyLocationBtn
            type="primary"
            disabled={!window.google}
            onClick={this.useCurrentLocation}
            style={{ margin: '0 5px', fontSize: 'small', float: 'left' }}
          >
            Use My Location
          </MyLocationBtn>
          <div>
            <div style={{ overflow: 'hidden' }}>
              <AutoComplete
                allowClear
                options={dataSource.map(renderOption)}
                value={this.state.searchStoreInput}
                onSearch={(v) => {
                  v = sanitiseCharacters(v);
                  this.onSearch(v);
                  this.setState({
                    searchStoreInput: v
                  })
                }}
                onSelect={(v, o) => {
                  v = sanitiseCharacters(v);

                  this.handleNzGeocode(v, (r) => {
                    const option = { item: r };

                    this.handleSelect(v, option);
                    this.setState({ searchStoreInput: v });
                    this.setWindowHistory(option.item);
                  }, () => {
                    this.handleSelect(v, o);
                    this.setState({ searchStoreInput: v });
                    this.setWindowHistory(o.item);
                  });
                }}
                style={{
                  marginBottom: '5px',
                  width: '99%'
                }}
                placeholder={<small>Enter a suburb or postcode</small>}
              />
            </div>
          </div>
        </div>
        <Map
          markers={markerCluster}
          center={center}
          circle={circle}
          infoBox={infoBox}
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          onMapMounted={this.handleMapMounted}
          onZoomChanged={() => {
            if (this.map.getZoom() !== zoom) {
              this.setState({ zoom: this.map.getZoom() });
            }
          }}
          zoom={zoom}
        />

        {(foundStore !== null) && ( // only shows when showing results from search bar
          <h2 style={{ marginTop: "5px", marginLeft: '20px' }}>
            {this.renderStoreNearYou(searchLocation)}
          </h2>
        )}

        {(foundStore === false) && (
          <div>
            <div style={{ color: "#e60000", margin: '35px', position: "relative", top: "5px" }}>
              We cannot find store information for this postcode, try browsing or check your postcode.
            </div>
            {this.resetMapBtn}
          </div>
        )}

        {(foundStore === true) && (
          <div>
            {
              listData.map((store) => (
                <Card key={store.id}>
                  {this.renderStore(store)}
                </Card>
              ))
            }
            {this.resetMapBtn}
          </div>
        )
        }

        {(foundStore === null) && (
          <Collapse
            accordion
            bordered={false}
            activeKey={this.state.collapse}
            onChange={this.handleChange}
          >
            {Object.keys(this.states).sort().map(key => (
              <Panel header={getStateName(key)} key={key} forceRender>
                {this.states[key].map(store => (
                  <Card key={store.id}>
                    {this.renderStore(store)}
                  </Card>
                ))}
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    );
  }
}

export default createFragmentContainer(StoreList, {
  viewer: graphql`
    fragment StoreList_viewer on Customer {
      id
      email
      myStore {
        id
      }
      stores(first: 100) {
        edges {
          node {
            id
            name
            address
            city
            postcode
            region
            state
            description
            fax
            phone
            lat
            lng
            canPickup
            hours {
              monday {
                open
                close
              }
              tuesday {
                open
                close
              }
              wednesday {
                open
                close
              }
              thursday {
                open
                close
              }
              friday {
                open
                close
              }
              saturday {
                open
                close
              }
              sunday {
                open
                close
              }
            }
          }
        }
      }
    }
  `,
});
