/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CareerPage_viewer$ref = any;
export type routes_CareerPage_QueryVariables = {|
  urlSlug: string
|};
export type routes_CareerPage_QueryResponse = {|
  +viewer: ?{|
    +stores: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +cmsPage: ?{|
      +title: ?string,
      +urlSlug: ?string,
      +content: ?string,
    |},
    +$fragmentRefs: CareerPage_viewer$ref,
  |}
|};
export type routes_CareerPage_Query = {|
  variables: routes_CareerPage_QueryVariables,
  response: routes_CareerPage_QueryResponse,
|};
*/


/*
query routes_CareerPage_Query(
  $urlSlug: String!
) {
  viewer {
    ...CareerPage_viewer
    stores(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
    cmsPage(urlSlug: $urlSlug) {
      title
      urlSlug
      content
    }
    id
  }
}

fragment CareerPage_viewer on Customer {
  stores(first: 100) {
    edges {
      node {
        id
        name
      }
    }
  }
  cmsPage(urlSlug: $urlSlug) {
    title
    urlSlug
    content
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "urlSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100
    }
  ],
  "concreteType": "StoreConnection",
  "kind": "LinkedField",
  "name": "stores",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Store",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "stores(first:100)"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "urlSlug",
      "variableName": "urlSlug"
    }
  ],
  "concreteType": "CmsPage",
  "kind": "LinkedField",
  "name": "cmsPage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CareerPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CareerPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_CareerPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b163617f03e124214e888ee788a6d10",
    "id": null,
    "metadata": {},
    "name": "routes_CareerPage_Query",
    "operationKind": "query",
    "text": "query routes_CareerPage_Query(\n  $urlSlug: String!\n) {\n  viewer {\n    ...CareerPage_viewer\n    stores(first: 100) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    cmsPage(urlSlug: $urlSlug) {\n      title\n      urlSlug\n      content\n    }\n    id\n  }\n}\n\nfragment CareerPage_viewer on Customer {\n  stores(first: 100) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  cmsPage(urlSlug: $urlSlug) {\n    title\n    urlSlug\n    content\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fb25949afb1841adaf0d0941f0a6533';

module.exports = node;
