import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Collapse as DefaultCollapse, Divider } from 'antd';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import { uniqueId } from 'lodash';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import { sanitiseCharacters } from '~/helper';

const Collapse = (props) => {
  const { titleId, titleText, activeKeys, panelContent, setActiveKeys } = props;
  const openedKey = activeKeys.includes(titleId) ? [titleId] : [];

  const handleClick = () => {
    const index = activeKeys.indexOf(titleId);
    const newActiveKeys = [...activeKeys];
    if (index > -1) {
      newActiveKeys.splice(index, 1);
    } else {
      newActiveKeys.push(titleId);
    }
    setActiveKeys(newActiveKeys)
  }

  return (
    <DefaultCollapse
      ghost
      activeKey={openedKey}
      onChange={() => handleClick()}
      destroyInactivePanel
    >
      <DefaultCollapse.Panel
        header={<span style={{ fontWeight: 'bold' }}>{titleText}</span>}
        id={titleId}
        key={titleId}
      >
        {panelContent}
      </DefaultCollapse.Panel>
    </DefaultCollapse>
  )
}

Collapse.propTypes = {
  titleId: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  activeKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActiveKeys: PropTypes.func.isRequired,
  panelContent: PropTypes.node.isRequired,
}

const CmsContent = ({ pageContent, initTocList }) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const activeKeysRef = useRef(activeKeys);
  const tocList = [];
  const countDict = {};
  const currentPath = [];

  useEffect(() => {
    if (initTocList) {
      initTocList(tocList);
    }
  }, []);

  useEffect(() => {
    activeKeysRef.current = activeKeys;
  }, [activeKeys]);

  const createCarousel = (domNode) => {
    const carouselId = uniqueId('carousel-');
    return (
      <div
        id={carouselId}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          margin: '20px 0px',
        }}
      >
        <div
          className="swiper-button-prev"
          style={{ position: 'relative', left: 'unset', margin: '0px 10px 0px 0px', '--swiper-navigation-size': '40px' }}
        />
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          modules={[Navigation]}
          loop
          navigation={{
            nextEl: `#${carouselId} .swiper-button-next`,
            prevEl: `#${carouselId} .swiper-button-prev`,
          }}
        >
          {
            domNode
              .filter(node => (node.name === 'img' && node.type === 'tag'))
              .map((node) => {
                const childProps = attributesToProps(node.attribs);
                return (
                  <SwiperSlide key={childProps.src}>
                    <img
                      {...childProps}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      alt={childProps.alt || 'carousel-image'}
                    />
                  </SwiperSlide>
                )
              })
          }
        </Swiper>
        <div
          className="swiper-button-next"
          style={{ position: 'relative', right: 'unset', margin: '0px 0px 0px 10px', '--swiper-navigation-size': '40px' }}
        />
      </div>
    )
  }

  const handleLinkClickFunc = (relatedKeys, titleId) => {
    return () => {
      const newActiveKeys = relatedKeys.filter(key => !activeKeysRef.current.includes(key));
      setActiveKeys([...activeKeysRef.current, ...newActiveKeys]);

      // wait 100ms, then try to scroll to the title every 30ms
      setTimeout(() => {
        const startTime = Date.now();
        const interval = setInterval(() => {
          const target = document.getElementById(titleId);
          if (target) {
            const rect = target.getBoundingClientRect();
            if (rect.top >= 0 && rect.top <= 40) {
              clearInterval(interval);
            } else {
              target.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }

          if (Date.now() - startTime > 500) {
            clearInterval(interval);
          }
        }, 30);
      }, 100);
    }
  }

  const generateTitleNav = (title, TagName) => {
    const innerText = sanitiseCharacters(title.trim())
      .replace(/^\d+\./, '')
      .trim()
      .replace(/\s/g, '-')
      .toLowerCase();
    countDict[innerText] = countDict[innerText] ? countDict[innerText] + 1 : 1;
    const titleId = countDict[innerText] > 1 ? `${innerText}-${countDict[innerText]}` : innerText;

    tocList.push({
      tag: TagName,
      content: title,
      href: `#${titleId}`,
      handleLinkClick: handleLinkClickFunc([...currentPath, titleId], titleId),
    });
    return titleId;
  }

  const extractTextFromNode = (domNode) => {
    if (domNode.type === 'text') return domNode.data;
    if (!domNode.children) return '';
    return domNode.children.map(extractTextFromNode).join('');
  }

  const renderNavForTitle = (domNodes, TagName) => {
    const titleText = domNodes.map(extractTextFromNode).join('').trim();
    if (!titleText) return null;
    const titleId = generateTitleNav(titleText, TagName);

    return (
      <TagName id={titleId}>
        {domToReact(domNodes)}
      </TagName>
    )
  }

  const renderCollapse = (domNodes, options) => {
    const validChilds = domNodes.filter(node => node.type === 'tag');

    if (validChilds.length === 0) return null;

    const { children, name } = validChilds[0];
    const titleText = children.map(extractTextFromNode).join('').trim();
    const titleId = generateTitleNav(titleText, name);

    activeKeysRef.current.push(titleId);
    currentPath.push(titleId);
    const panelContent = domToReact(validChilds.slice(1), options);
    currentPath.pop();

    return (
      <div>
        <Divider style={{ margin: 0 }} />
        <Collapse
          titleId={titleId}
          titleText={titleText}
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          panelContent={panelContent}
        />
      </div>
    )
  }

  const renderElementFromHtml = (htmlContent) => {
    const options = {
      replace: (domNode) => {
        const { name: TagName, attribs, children } = domNode;
        if (!attribs || !children || children.length === 0) return domNode;

        if (attribs.class === 'carousel-container') {
          return createCarousel(children);
        }

        if (attribs.class === 'collapse-container') {
          return renderCollapse(children, options);
        }

        if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(TagName)) {
          return renderNavForTitle(children, TagName);
        }

        return domNode;
      }
    }
    return parse(htmlContent, options);
  }

  return (
    <div>
      {renderElementFromHtml(pageContent)}
    </div>
  )
};

CmsContent.propTypes = {
  pageContent: PropTypes.string.isRequired,
  initTocList: PropTypes.func,
};

CmsContent.defaultProps = {
  initTocList: () => { }
};

export default CmsContent;