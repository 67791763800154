/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EventAlertModal_viewer$ref = any;
export type EventAlertModalRefetchQueryVariables = {||};
export type EventAlertModalRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: EventAlertModal_viewer$ref
  |}
|};
export type EventAlertModalRefetchQuery = {|
  variables: EventAlertModalRefetchQueryVariables,
  response: EventAlertModalRefetchQueryResponse,
|};
*/


/*
query EventAlertModalRefetchQuery {
  viewer {
    ...EventAlertModal_viewer
    id
  }
}

fragment EventAlertModal_viewer on Customer {
  topAlerts(first: 5) {
    edges {
      node {
        id
        content
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventAlertModalRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventAlertModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventAlertModalRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "TopAlertConnection",
            "kind": "LinkedField",
            "name": "topAlerts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopAlertEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopAlert",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "topAlerts(first:5)"
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "App_viewer_topAlerts",
            "kind": "LinkedHandle",
            "name": "topAlerts"
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "409486d6c792259c71fe65395f8aaf94",
    "id": null,
    "metadata": {},
    "name": "EventAlertModalRefetchQuery",
    "operationKind": "query",
    "text": "query EventAlertModalRefetchQuery {\n  viewer {\n    ...EventAlertModal_viewer\n    id\n  }\n}\n\nfragment EventAlertModal_viewer on Customer {\n  topAlerts(first: 5) {\n    edges {\n      node {\n        id\n        content\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a6c4a08da8e7939e302fc3ed11f0f116';

module.exports = node;
