import React from 'react';
import PropTypes from 'prop-types';
import { debounce, get, isEmpty } from 'lodash';
import { AutoComplete, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { SearchOutlined } from '@ant-design/icons';
import { GetAddressDetailMutation, SearchAddressMutation } from '../checkout/mutations';

const { Item: FormItem } = Form;

const debounceMs = 200;

class FormItemStreet extends React.Component {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    formItemLayout: PropTypes.shape({}),
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    handleAddressSelect: PropTypes.func,
    handleAddressTyping: PropTypes.func,
    address: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      telephone: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      postcode: PropTypes.string,
      region: PropTypes.string,
      compnay: PropTypes.string,
      country: PropTypes.shape({
        alpha2: PropTypes.string,
      }),
      fax: PropTypes.string,
    }),
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    formItemLayout: {},
    disabled: false,
    type: null,
    handleAddressSelect: () => {},
    handleAddressTyping: () => {},
    address: {},
    placeholder: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      options: [],
    }
  }

  onSearch = debounce((v) => {
    const query = v.trim();

    if (query.length >= 4) {
      SearchAddressMutation.commit({
        environment: this.props.relay.environment,
        variables: { input: { query } },
        onCompleted: (resp) => {
          const result = get(resp, 'searchAddress.result', []);

          // id is not unique
          const opts = result.map((r) => ({
            addressId: r.id,
            value: r.fullAddress,
            label: <span style={{ fontSize: '13px' }}>{r.fullAddress}</span>,
          }));

          this.setState({ options: opts });
        },
      });
    }
  }, debounceMs);

  onSelect = (value, option) => {
    const { type, relay, form, handleAddressSelect, handleAddressTyping } = this.props;
    const { addressId: id } = option;

    GetAddressDetailMutation.commit({
      environment: relay.environment,
      variables: { input: { id } },
      onCompleted: (resp) => {
        const result = get(resp, 'getAddressDetail.result') || {};

        if (!isEmpty(result)) {
          form.setFieldsValue({
            [`${type}[]street`]: result.street,
            [`${type}[]city`]: result.suburb,
            [`${type}[]postcode`]: result.postcode,
            [`${type}[]region`]: result.city,
          });
          handleAddressTyping(type, 'street', result.street);
          handleAddressSelect(type, 'street', result);
        }
      },
    });
  }

  onInputChange = (e) => {
    let value = e;

    if (typeof(e) === "object") {
      value = get(e, 'target.value');
    }

    this.debounceOnInputChange(value);
  };

  debounceOnInputChange = debounce((value) => {
    const { handleAddressTyping, type } = this.props;
    handleAddressTyping(type, 'street', value)
  }, debounceMs);

  renderInput = () => {
    const { disabled, placeholder } = this.props;
    const { options } = this.state;

    if (process.env.COUNTRY === 'NZ') {
      return (
        <AutoComplete
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="googleignoreautofill"
          menuItemSelectedIcon={<SearchOutlined />}
          onSearch={this.onSearch}
          onChange={this.onInputChange}
          onSelect={this.onSelect}
          options={options}
        />
      )
    }

    return (
      <Input
        disabled={disabled}
        placeholder={placeholder}
        onChange={this.onInputChange}
      />
    )
  }

  render() {
    const { form, formItemLayout, type, address } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FormItem
        {...formItemLayout}
        label="Street No & Name"
        hasFeedback
      >
        {getFieldDecorator(`${type}[]street`, {
          rules: [
            { required: true, message: 'Required' },
            { message: "Please check your address is complete", validator: (rule, value, callback) => {
              if (!value || (value.match(/[a-zA-Z+]/g) && value.match(/[\d+]/g))) {
                callback();
              } else {
                callback(rule.message);
              }
            }},
          ],
          initialValue: get(address, 'street', ''),
        })(
          this.renderInput()
        )}
      </FormItem>
    )
  }
}

export default FormItemStreet;
