import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { get } from 'lodash';
import { Table } from 'antd';

import Helmet from '~/components/page/Helmet';
import 'antd/lib/table/style';
import { fromGlobalId } from '~/helper';

const IMAGE_PLACEHOLDER = '/static/images/placeholder.svg';

class StockReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      stockReport: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { host } = window.location;

    this.columns = [{
      title: 'Image',
      dataIndex: 'mainImage',
      key: 'mainImage',
      render: (image) => {
        const thumbnail = get(image, 'thumbnail');

        return (
          thumbnail ?
            <img width="50" alt="" src={thumbnail} />
              : <img width="50" alt="Place Holder" src={IMAGE_PLACEHOLDER} />
        );
      },
    }, {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <a href={`https://admin.${host}/product/${fromGlobalId(record.id).id}`} target="_blank" rel="noopener noreferrer">{text}</a>,
    }, {
      title: 'Aden Code',
      dataIndex: 'ac',
      key: 'ac',
    }, {
      title: 'Sku',
      dataIndex: 'sku',
      key: 'sku',
    }, {
      title: 'Stock',
      dataIndex: 'stockAvailable',
      key: 'stockAvailable',
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    }];
  }

  render() {
    const { viewer: { stockReport }} = this.props;

    return (
      <div>
        <Helmet title="Stock Report" />
        <h1>Stock Report</h1>
        <Table columns={this.columns} dataSource={stockReport} rowKey={record => record.id} pagination={false} />
      </div>
    );
  }
}

export default createFragmentContainer(StockReport, {
  viewer: graphql`
    fragment StockReport_viewer on Customer {
      stockReport {
        id
        name
        sku
        ac
        stockAvailable
        status
        mainImage {
          id
          thumbnail
        }
      }
    }
  `,
});
