import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const COLOR = {
  outOfStock: '#cb0000',
  partialStock: '#faad14',
  inStock: 'green',
};

export const getStockInfo = (stocks) => {
  const noOfProducts = stocks.length;

  const outOfStock = stocks.reduce((acc, curr) => {
    if (get(curr, 'stock') === "Out Of Stock") {
      return acc + 1
    }
    return acc;
  }, 0);

  let text = 'In Stock';
  let backgroundColor = COLOR.inStock;

  if (outOfStock > 0 && noOfProducts === outOfStock) {
    text = 'Out Of Stock';
    backgroundColor = COLOR.outOfStock;
  } else if (outOfStock > 0 && outOfStock < noOfProducts) {
    text = `${noOfProducts - outOfStock} of ${noOfProducts} items available`;
    backgroundColor = COLOR.partialStock;
  }

  return {text, backgroundColor};
};

const StockInfo = ({ store: { stocks }, style }) => {
  const {text, backgroundColor} = getStockInfo(stocks);

  return (
    <span style={{ ...style, backgroundColor }}>
      {text}
    </span>
  );
}

StockInfo.propTypes = {
  store: PropTypes.shape({
    stocks: PropTypes.arrayOf(PropTypes.shape({
      productId: PropTypes.string.isRequired,
      stock: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired,
  style: PropTypes.shape({}),
};

StockInfo.defaultProps = {
  style: {},
};

export default StockInfo;
