import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Link } from 'found';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import { getProductUrl } from '~/helper';

import { renderImg } from '../account/OrderLine';

const printOrder = () => {
    const order = document.getElementById("print-pos-order").innerHTML;
    const page = window.open('', '');

    page.document.write('<html><body>');
    page.document.write(order);
    page.document.write('</body></html>');
    page.document.close();

    page.print();
}

const Order = (props) => {
  const { order } = props;
  const lines = get(order, 'lines.edges', []);
  const surcharges = get(order, 'surcharges', []);

  return (
    <div id="print-pos-order">
      <Row style={{padding: '10px'}}>
        <Col xs={24}>
          <h1 style={{display: 'inline'}}>
            <b>#{order.name}</b>
            <Button id="print-pos-button" type="primary" style={{marginLeft: '10px'}} onClick={() => printOrder()}>Print</Button>
          </h1>
          <div>
            <b>Placed at:</b> {order.insertedAt && (
              moment(order.insertedAt).format('DD/MM/YYYY hh:mm:ss')
            )}
          </div>
          <div><b>Customer:</b> {order.firstname} {order.lastname}</div>
          <div><b>Phone:</b> {order.telephone}</div>
          {order.company && <div><b>Company:</b> {order.company}</div>}
        </Col>

        <Col xs={24}>
          <div><b>Payment:</b> {order.paymentMethod}</div>
          <div><b>Operator:</b> {order.operator}</div>
          <div><b>Store:</b> {order.store.name}</div>
        </Col>

        <Col xs={24}>
          {lines.map(({node}) => (
            <Line key={node.id} line={node} />
          ))}
        </Col>

        <Col xs={24} style={{fontWeight: 'bold'}}>
          <div>Subtotal: <span className="price">${order.subtotal}</span></div>

          {surcharges.length > 0 && surcharges.map((surcharge) => (
            <div key={surcharge.name}>
              {surcharge.name}:
              <span className="price"> ${surcharge.amount}</span>
            </div>
          ))}
          <div>Grand Total: <span className="price">${order.grandTotal}</span></div>
        </Col>
      </Row>
    </div>
  )
};

Order.propTypes = {
  order: PropTypes.shape({
    name: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    company: PropTypes.string,
    insertedAt: PropTypes.string.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    store: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    subtotal: PropTypes.number.isRequired,
    grandTotal: PropTypes.number.isRequired,
  }).isRequired,
};

const Line = (props) => {
  const { line } = props;
  const finalPrice = line.unitPrice + line.unitSurcharge;
  const url = getProductUrl(line);

  return (
    <Row>
      <Col xs={7} sm={3} md={2}>{renderImg(line)}</Col>
      <Col xs={17} sm={21} md={22} style={{paddingLeft: '10px', fontSize: '14px'}}>
        <Link to={url} style={{ textDecoration: 'underline' }}>{line.name}</Link>
        <div style={{marginTop: '5px'}}>
          <span className="price">{line.quantity} x ${(finalPrice).toFixed(2)}</span>
        </div>
      </Col>
    </Row>
  );
};

Line.propTypes = {
  line: PropTypes.shape({
    unitPrice: PropTypes.number.isRequired,
    unitSurcharge: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
};

class PosOrder extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesPosOrders: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
  }

  render() {
    const { edges } = this.props.viewer.salesPosOrders;

    return (
      <div>
        <h1>POS Order</h1>

        {edges.length === 1 && (
          <Order order={edges[0].node} />
        )}

        {edges.length === 0 && "Order Not Found"}
      </div>
    );
  }
}

export default createFragmentContainer(PosOrder, {
  viewer: graphql`
    fragment PosOrder_viewer on Customer {
      salesPosOrders(first: 1, ids: $ids, storeIds: $storeIds) {
        edges {
          node {
            id
            name
            firstname
            lastname
            email
            telephone
            company
            paymentMethod
            surcharges {
              name
              amount
            }
            subtotal
            grandTotal
            operator
            store {
              id
              name
            }
            insertedAt
            lines(first: 999) {
              edges {
                node {
                  id
                  name
                  quantity
                  unitPrice
                  unitDiscount
                  unitSurcharge
                  rowTotal
                  product {
                    urlSlug
                    status
                    mainImage {
                      id
                      url
                      thumbnail
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
