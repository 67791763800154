/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AusPostDamagedForm_viewer$ref: FragmentReference;
declare export opaque type AusPostDamagedForm_viewer$fragmentType: AusPostDamagedForm_viewer$ref;
export type AusPostDamagedForm_viewer = {|
  +id: string,
  +$refType: AusPostDamagedForm_viewer$ref,
|};
export type AusPostDamagedForm_viewer$data = AusPostDamagedForm_viewer;
export type AusPostDamagedForm_viewer$key = {
  +$data?: AusPostDamagedForm_viewer$data,
  +$fragmentRefs: AusPostDamagedForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AusPostDamagedForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6f4fdb4a52034ceea16a85d3c759d7c3';

module.exports = node;
