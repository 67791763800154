/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveCreditCardInput = {|
  id: string
|};
export type RemoveCreditCardMutationVariables = {|
  input: RemoveCreditCardInput
|};
export type RemoveCreditCardMutationResponse = {|
  +removeCreditCard: ?{|
    +removedCreditCardId: ?string
  |}
|};
export type RemoveCreditCardMutation = {|
  variables: RemoveCreditCardMutationVariables,
  response: RemoveCreditCardMutationResponse,
|};
*/


/*
mutation RemoveCreditCardMutation(
  $input: RemoveCreditCardInput!
) {
  removeCreditCard(input: $input) {
    removedCreditCardId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveCreditCardPayload",
    "kind": "LinkedField",
    "name": "removeCreditCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedCreditCardId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveCreditCardMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveCreditCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea7d608e0d5c0348f8d1345b17d46334",
    "id": null,
    "metadata": {},
    "name": "RemoveCreditCardMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveCreditCardMutation(\n  $input: RemoveCreditCardInput!\n) {\n  removeCreditCard(input: $input) {\n    removedCreditCardId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acada625b5f64bda3ddf4b145ab5747b';

module.exports = node;
