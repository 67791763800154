/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddressBook_viewer$ref = any;
type Logout_viewer$ref = any;
type SideNav_viewer$ref = any;
export type routes_AddressBook_QueryVariables = {||};
export type routes_AddressBook_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +email: ?string,
    +addresses: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstname: ?string,
          +lastname: ?string,
          +street: ?string,
          +city: ?string,
          +postcode: ?string,
          +region: ?string,
          +country: ?{|
            +alpha2: ?string,
            +name: ?string,
          |},
          +telephone: ?string,
          +company: ?string,
          +fax: ?string,
        |}
      |}>
    |},
    +defaultAddress: ?{|
      +id: string
    |},
    +$fragmentRefs: AddressBook_viewer$ref & SideNav_viewer$ref & Logout_viewer$ref,
  |}
|};
export type routes_AddressBook_Query = {|
  variables: routes_AddressBook_QueryVariables,
  response: routes_AddressBook_QueryResponse,
|};
*/


/*
query routes_AddressBook_Query {
  viewer {
    ...AddressBook_viewer
    id
    email
    addresses(first: 99) {
      edges {
        node {
          id
          firstname
          lastname
          street
          city
          postcode
          region
          country {
            alpha2
            name
          }
          telephone
          company
          fax
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    defaultAddress {
      id
    }
    ...SideNav_viewer
    ...Logout_viewer
  }
}

fragment AddressBook_viewer on Customer {
  id
  email
  addresses(first: 99) {
    edges {
      node {
        id
        firstname
        lastname
        street
        city
        postcode
        region
        country {
          alpha2
          name
        }
        telephone
        company
        fax
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  defaultAddress {
    id
  }
}

fragment Logout_viewer on Customer {
  email
}

fragment SideNav_viewer on Customer {
  email
  ...Logout_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerAddressEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAddress",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "street",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postcode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "region",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alpha2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "telephone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "company",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerAddress",
  "kind": "LinkedField",
  "name": "defaultAddress",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AddressBook_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": "addresses",
            "args": null,
            "concreteType": "CustomerAddressConnection",
            "kind": "LinkedField",
            "name": "__AddressBook_addresses_connection",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AddressBook_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SideNav_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Logout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_AddressBook_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "CustomerAddressConnection",
            "kind": "LinkedField",
            "name": "addresses",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": "addresses(first:99)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "AddressBook_addresses",
            "kind": "LinkedHandle",
            "name": "addresses"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20af873df0987fc98d1968fb3af9150f",
    "id": null,
    "metadata": {},
    "name": "routes_AddressBook_Query",
    "operationKind": "query",
    "text": "query routes_AddressBook_Query {\n  viewer {\n    ...AddressBook_viewer\n    id\n    email\n    addresses(first: 99) {\n      edges {\n        node {\n          id\n          firstname\n          lastname\n          street\n          city\n          postcode\n          region\n          country {\n            alpha2\n            name\n          }\n          telephone\n          company\n          fax\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    defaultAddress {\n      id\n    }\n    ...SideNav_viewer\n    ...Logout_viewer\n  }\n}\n\nfragment AddressBook_viewer on Customer {\n  id\n  email\n  addresses(first: 99) {\n    edges {\n      node {\n        id\n        firstname\n        lastname\n        street\n        city\n        postcode\n        region\n        country {\n          alpha2\n          name\n        }\n        telephone\n        company\n        fax\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  defaultAddress {\n    id\n  }\n}\n\nfragment Logout_viewer on Customer {\n  email\n}\n\nfragment SideNav_viewer on Customer {\n  email\n  ...Logout_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb4a8dc0cee3f35bbc32cc0acc21800e';

module.exports = node;
