/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PosSuccessView_viewer$ref: FragmentReference;
declare export opaque type PosSuccessView_viewer$fragmentType: PosSuccessView_viewer$ref;
export type PosSuccessView_viewer = {|
  +salesPosOrders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +$refType: PosSuccessView_viewer$ref,
|};
export type PosSuccessView_viewer$data = PosSuccessView_viewer;
export type PosSuccessView_viewer$key = {
  +$data?: PosSuccessView_viewer$data,
  +$fragmentRefs: PosSuccessView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    },
    {
      "kind": "RootArgument",
      "name": "storeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PosSuccessView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        },
        {
          "kind": "Variable",
          "name": "storeIds",
          "variableName": "storeIds"
        }
      ],
      "concreteType": "SalesPosOrderConnection",
      "kind": "LinkedField",
      "name": "salesPosOrders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesPosOrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesPosOrder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '1cecd39d63421fa8eb6ba61020f0d1ef';

module.exports = node;
