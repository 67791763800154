import CreditCard, { getTransDetails } from './CreditCard';
import SavedCard from './SavedCard';
import Threeds, { getExtra, injectACSIframe, processMethodData } from './Threeds';

const Tyro = {
  CreditCard,
  getTransDetails,
  SavedCard,
  Threeds,
  getExtra,
  injectACSIframe,
  processMethodData,
}

export default Tyro;
