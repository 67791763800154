/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CreditCard_viewer$ref = any;
type Logout_viewer$ref = any;
type SideNav_viewer$ref = any;
export type routes_CreditCard_QueryVariables = {||};
export type routes_CreditCard_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +creditCards: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +ccBrand: ?string,
          +ccHolder: ?string,
          +ccLast4: ?string,
          +ccExpMonth: ?number,
          +ccExpYear: ?number,
        |}
      |}>
    |},
    +email: ?string,
    +$fragmentRefs: CreditCard_viewer$ref & SideNav_viewer$ref & Logout_viewer$ref,
  |}
|};
export type routes_CreditCard_Query = {|
  variables: routes_CreditCard_QueryVariables,
  response: routes_CreditCard_QueryResponse,
|};
*/


/*
query routes_CreditCard_Query {
  viewer {
    ...CreditCard_viewer
    id
    creditCards(first: 999) {
      edges {
        node {
          id
          ccBrand
          ccHolder
          ccLast4
          ccExpMonth
          ccExpYear
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...SideNav_viewer
    email
    ...Logout_viewer
  }
}

fragment CreditCard_viewer on Customer {
  id
  creditCards(first: 999) {
    edges {
      node {
        id
        ccBrand
        ccHolder
        ccLast4
        ccExpMonth
        ccExpYear
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment Logout_viewer on Customer {
  email
}

fragment SideNav_viewer on Customer {
  email
  ...Logout_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreditCardEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditCard",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccBrand",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccHolder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccLast4",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccExpMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccExpYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CreditCard_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "creditCards",
            "args": null,
            "concreteType": "CreditCardConnection",
            "kind": "LinkedField",
            "name": "__App_viewer_creditCards_connection",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreditCard_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SideNav_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Logout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_CreditCard_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CreditCardConnection",
            "kind": "LinkedField",
            "name": "creditCards",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": "creditCards(first:999)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "App_viewer_creditCards",
            "kind": "LinkedHandle",
            "name": "creditCards"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9a9a3778ba7200ff7920fa153bfe0cb",
    "id": null,
    "metadata": {},
    "name": "routes_CreditCard_Query",
    "operationKind": "query",
    "text": "query routes_CreditCard_Query {\n  viewer {\n    ...CreditCard_viewer\n    id\n    creditCards(first: 999) {\n      edges {\n        node {\n          id\n          ccBrand\n          ccHolder\n          ccLast4\n          ccExpMonth\n          ccExpYear\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...SideNav_viewer\n    email\n    ...Logout_viewer\n  }\n}\n\nfragment CreditCard_viewer on Customer {\n  id\n  creditCards(first: 999) {\n    edges {\n      node {\n        id\n        ccBrand\n        ccHolder\n        ccLast4\n        ccExpMonth\n        ccExpYear\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Logout_viewer on Customer {\n  email\n}\n\nfragment SideNav_viewer on Customer {\n  email\n  ...Logout_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '693e549a9adabecc1820b2910865abb0';

module.exports = node;
