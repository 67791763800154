import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddProductToCartMutation($input: AddProductToCartInput!) {
    addProductToCart(input:$input) {
      cart{
        ...CartFragment_cart @relay(mask: false)
      }
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: (_, store) => {
    const rootProxy = store.getRoot();
    const viewerProxy = rootProxy.getLinkedRecord('viewer');

    const payload = store.getRootField('addProductToCart');
    if (!payload) {
      return;
    }
    const newCart = payload.getLinkedRecord('cart');

    const cart = viewerProxy.getLinkedRecord('cart');

    // Updte cart if it is new or cart ID changed
    if (!cart || cart.getDataID() !== newCart.getDataID()) {
      viewerProxy.setLinkedRecord(newCart, 'cart');
    }
  },
});

export default { commit };
