import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import PropTypes from 'prop-types';
import { Table } from 'antd';

import 'antd/lib/table/style';

const getDiscount = (originalPrice, tierPrice) => {
  if (originalPrice && tierPrice) {
    const save = ((originalPrice - tierPrice) / originalPrice) * 100;

    return <span>{save.toFixed(2)}%</span>;
  }
  return null;
};

class TierPrice extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      price: PropTypes.number,
      tierPrices: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const { product } = props;
    const { tierPrices } = product;

    this.columns = [
      {
        title: 'Buy',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: false,
        render: (qty) => <span>{qty}+ Units</span>
      },
      {
        title: 'Each',
        dataIndex: 'price',
        key: 'price',
        sorter: false,
        render: (price) => <span className="price">${price.toFixed(2)}</span>
      },
      {
        title: 'Save %',
        render: (row) => getDiscount(product.price, row.price)
      },
    ];

    this.data = tierPrices.map(({quantity, price}) => ({quantity, price}))
  }

  render() {
    const { tierPrices } = this.props.product;

    if (!Array.isArray(tierPrices) || tierPrices.length === 0) {
      return null;
    }

    return (
      <Table
        style={{
          borderLeft: '1px solid #e8e8e8',
          borderRight: '1px solid #e8e8e8',
          borderTop: '1px solid #e8e8e8',
        }}
        className="tier-price-table"
        columns={this.columns}
        dataSource={this.data}
        footer={null}
        rowKey="price"
        pagination={false}
      />
    );
  }
}

export default createFragmentContainer(TierPrice, {
  product: graphql`
    fragment TierPrice_product on Product {
      price
      tierPrices
    }
  `,
});
