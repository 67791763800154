/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PosLogin_viewer$ref = any;
export type routes_PosLogin_QueryVariables = {||};
export type routes_PosLogin_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: PosLogin_viewer$ref,
  |}
|};
export type routes_PosLogin_Query = {|
  variables: routes_PosLogin_QueryVariables,
  response: routes_PosLogin_QueryResponse,
|};
*/


/*
query routes_PosLogin_Query {
  viewer {
    ...PosLogin_viewer
    id
  }
}

fragment PosLogin_viewer on Customer {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_PosLogin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PosLogin_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_PosLogin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b193943f024acf5640d179219924b56b",
    "id": null,
    "metadata": {},
    "name": "routes_PosLogin_Query",
    "operationKind": "query",
    "text": "query routes_PosLogin_Query {\n  viewer {\n    ...PosLogin_viewer\n    id\n  }\n}\n\nfragment PosLogin_viewer on Customer {\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8fe7a522f1840a085fb9c5f7ab5f6dc';

module.exports = node;
