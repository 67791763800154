import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation EmptyCartMutation {
    emptyCart {
      cartId
    }
  }
`;

const commit = buildMutation(mutation, {
  updater: ({viewer}, store) => {
    const payload = store.getRootField('emptyCart');

    // When backend returns an empty cartId, use the id from relay store
    const cartId = payload.getValue('cartId') || viewer.cart.id;

    store.delete(cartId);
  },
});

export default { commit };
